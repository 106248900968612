import React from 'react'
import styled from 'styled-components'

import Input from './Input'
import PhoneInput from './PhoneInput'
import FieldErrorMessageComponent from '../components/error/FieldErrorMessageComponent'
import Colors from '../Colors'

/**
 * @param {string} id - identificação unica do elemento
 * @param {string} name - nome do elemento
 * @param {boolean} isInvalid - diz se o input é invalido
 * @param {string} invalidMessage - mensagem a ser mostrada quando o input for invalido
 * @param {string} className - className do elemento
 * @param {string} type - tipo do elemento
 * @param {string} placeholder - texto a ser motrado quando o elemento não tem valor
 * @param {array} value - valor do elemento
 * @param {any} onChange - chamado quando o valor é alterado
 * @param {any} onBlur - chamado quando o elemento perde o foco
 * @param {boolean} readOnly - quando verdadeiro especifica que o componente não permite input, e sim apenas visualização
 * @param {any} onFocus - chamado quando o elemento recebe foco
 */

export default function InputWithMessage({
    id,
    name,
    isInvalid,
    invalidMessage,
    className,
    type = 'text',
    placeholder,
    value,
    onChange,
    onBlur,
    readOnly = false,
    onFocus,
}) {
    const renderInput = () => {
        return type === 'tel' ? (
            <PhoneInput
                id={id}
                name={name}
                className={className}
                placeholder={placeholder}
                value={value}
                isInvalid={isInvalid}
                onChange={onChange}
                onBlur={onBlur}
                readOnly={readOnly}
                onFocus={onFocus}
            />
        ) : (
            <InputText
                id={id}
                name={name}
                type={type}
                className={className}
                placeholder={placeholder}
                value={value}
                isInvalid={isInvalid}
                onChange={onChange}
                onBlur={onBlur}
                readOnly={readOnly}
                onFocus={onFocus}
            />
        )
    }

    return (
        <Container data-testid="input-text-container">
            {renderInput()}
            <FieldErrorMessageComponent
                isInvalid={isInvalid}
                message={invalidMessage}
            />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const InputText = styled(
    ({
        id,
        name,
        className,
        type,
        placeholder,
        value,
        onChange,
        onBlur,
        readOnly,
        onFocus,
    }) => (
        <Input
            id={id}
            name={name}
            type={type}
            className={className}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            readOnly={readOnly}
            onFocus={onFocus}
        />
    )
)`
    border-color: ${props => (props.isInvalid ? 'red' : '#dadada')} !important;

    &::placeholder {
        font-family: 'Roboto';
        color: ${Colors.lightBlueGreyTwo};
        font-size: 14px !important;
    }
`
