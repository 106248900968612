import React, { useState } from 'react'
import styled from 'styled-components'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import * as FragmentedOrderUtils from '../dashboard/FragmentedOrderModal/FragmentedOrderUtils'
import * as NumberUtils from '../commons/NumberUtils'
import * as DateUtils from '../commons/DateUtils'
import * as PriceUtils from '../price/PriceUtils'
import Colors from '../commons/Colors'

export const OrderDetailsAccordionComponent = ({ shippings, order }) => {
    const [visible, setVisible] = useState(false)

    const isUseFreight = () => {
        return Boolean(
            order?.freightType === 'CIF' || order?.freightType === 'FOB'
        )
    }

    const renderIcon = () => {
        if (!visible) return <MdArrowDropDown />

        return <MdArrowDropUp />
    }

    const renderShippingStatus = statusName => {
        const { label } = FragmentedOrderUtils.getMountedStatus(statusName)

        return label
    }

    const renderShippingNumber = number => {
        const limit = 10

        if (number < limit) return `0${number}`
        return number
    }

    const getWeightInTonnes = weightInTonnes => {
        return `${NumberUtils.formatDecimalPlace(weightInTonnes)} ton.`
    }

    const shippingItemsKey = order.isUseShipment
        ? 'shipmentItems'
        : 'fragmentedOrderItems'

    const renderFragmentedOrderItems = fragment => {
        return fragment[shippingItemsKey].map(item => (
            <ProductContainer key={item.id}>
                <ProductInfo>{item.productName}</ProductInfo>
                <MeasureContainer>
                    <ProductInfo>
                        {FragmentedOrderUtils.getUnQuantity(
                            item.unQuantity,
                            item.quantity
                        )}
                    </ProductInfo>
                </MeasureContainer>
            </ProductContainer>
        ))
    }

    const renderShipmentItems = shipment => {
        return shipment[shippingItemsKey].map(item => (
            <ProductContainer key={item.id}>
                <ProductLegacyCodeContainer>
                    <ProductInfo>{item.legacyCode}</ProductInfo>
                </ProductLegacyCodeContainer>
                <ProductNameAndMeasureContainer>
                    <ProductInfo>{item.productName}</ProductInfo>
                    <ProductSubinfo>
                        {FragmentedOrderUtils.getUnQuantity(
                            item.unQuantity,
                            item.quantity
                        )}
                    </ProductSubinfo>
                </ProductNameAndMeasureContainer>
                <QuantityContainer>
                    <ProductInfo>
                        {PriceUtils?.getPriceString(item.price)}
                    </ProductInfo>
                </QuantityContainer>
            </ProductContainer>
        ))
    }

    const renderShipmentTotalDetails = shipment => {
        return (
            <WeightFreightAndTotalContainer>
                <WeightAndTotalContainer>
                    <ProductInfo>
                        Peso total (toneladas):{' '}
                        <ProductSubinfo>
                            {getWeightInTonnes(shipment.weightInTonnes)}
                        </ProductSubinfo>
                    </ProductInfo>
                    <ProductInfo>
                        TOTAL: {PriceUtils.getPriceString(shipment.price)}
                    </ProductInfo>
                </WeightAndTotalContainer>

                {isUseFreight() && (
                    <ProductInfo>
                        Valor frete parcial:{' '}
                        <ProductSubinfo>
                            {PriceUtils?.getPriceString(shipment.freightPrice)}
                        </ProductSubinfo>
                    </ProductInfo>
                )}
            </WeightFreightAndTotalContainer>
        )
    }

    const renderDeliveryDate = shipment => {
        return (
            <DeliveryDateContainer>
                <CardSubtitle>Data de entrega: </CardSubtitle>
                <ProductSubinfo>
                    {DateUtils.dateFormat(shipment.deliveryDate)}
                </ProductSubinfo>
            </DeliveryDateContainer>
        )
    }

    const renderProductsQuantity = items => {
        return <CardSubtitle>Produtos ({items.length})</CardSubtitle>
    }

    return (
        <AccordionContainer>
            <AccordionHeader>
                <AccordionButton visible={visible}>
                    <HeaderContainer>Remessas</HeaderContainer>
                    <IconContainer onClick={() => setVisible(!visible)}>
                        {renderIcon()}
                    </IconContainer>
                </AccordionButton>
            </AccordionHeader>
            <AccordionContent
                data-testid="orderDetails-accordion-content"
                visible={visible}
            >
                {shippings.length &&
                    shippings.map(shipping => (
                        <div>
                            <CardContainer key={shipping.id}>
                                <CardHeaderContainer>
                                    <CardTitleContainer>
                                        <CardHeaderText>
                                            Remessa{' '}
                                            {renderShippingNumber(
                                                shipping.number
                                            )}
                                        </CardHeaderText>
                                        <CardHeaderText>
                                            Pedido Nº {order.orderNumber}/
                                            {shipping.number}
                                        </CardHeaderText>
                                        <CardIconsContainer>
                                            <OrderStatus>
                                                {renderShippingStatus(
                                                    shipping.status.name
                                                )}
                                            </OrderStatus>
                                        </CardIconsContainer>
                                    </CardTitleContainer>
                                    <CardSubtitleContainer>
                                        {order?.isUseShipment &&
                                            renderDeliveryDate(shipping)}
                                        {renderProductsQuantity(
                                            shipping[shippingItemsKey]
                                        )}
                                    </CardSubtitleContainer>
                                </CardHeaderContainer>
                                {order?.isUseShipment
                                    ? renderShipmentItems(shipping)
                                    : renderFragmentedOrderItems(shipping)}
                            </CardContainer>
                            {order?.isUseShipment &&
                                renderShipmentTotalDetails(shipping)}
                        </div>
                    ))}
            </AccordionContent>
        </AccordionContainer>
    )
}

const AccordionContainer = styled.div``

const AccordionHeader = styled.div`
    border: 1px solid #c7c7cc;
    border-radius: 4px;
`

const AccordionButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    border: none;

    :focus {
        outline: none;
    }
`

const HeaderContainer = styled.div`
    color: ${Colors.black};
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
`

const IconContainer = styled.div`
    display: flex;
    align-content: center;
    cursor: pointer;
    font-size: 30px;
`

const AccordionContent = styled.div`
    max-height: ${props => (props.visible ? 'auto' : '0')};
    opacity: ${props => (props.visible ? '1' : '0')};
    overflow: ${props => (props.visible ? 'visible' : 'hidden')};
    padding: 0;
    background-color: ${Colors.white};
    transition: all 0.3s ease;
`

const CardContainer = styled.div`
    margin-top: 15px;
    background-color: ${Colors.veryLightPink};
    padding: 15px 25px 0 25px;
`

const CardHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const CardTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const CardHeaderText = styled.span`
    color: ${Colors.deepSeaBlue};
    font-family: Roboto;
    font-size: 14px;
`

const CardIconsContainer = styled.div`
    display: flex;
`

const OrderStatus = styled.span`
    background-color: ${props =>
        props.statusCanceled ? '#333333' : Colors.sienna};
    border-radius: 5px;
    color: ${Colors.white};
    font-family: Roboto;
    font-size: 14px;
    height: 20px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    padding: 0 20px;
`

const ProductContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding-top: 8px;
    border-bottom: solid 1px ${Colors.lightBlueGreyTwo};

    &:last-child {
        border-bottom: none;
    }
`

const CardSubtitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 11px;
`

const DeliveryDateContainer = styled.div`
    padding-bottom: 14px;
`

const CardSubtitle = styled.span`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.25px;
    color: ${Colors.black};
    padding-bottom: 10px;
`

const ProductInfo = styled.span`
    color: ${Colors.black};
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 1.33;
    text-transform: capitalize;
`

const ProductSubinfo = styled.span`
    color: ${Colors.brownishGrey};
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 1.33;
    text-transform: capitalize;
`

const MeasureContainer = styled.div`
    height: 45px;
    text-align: right;
    width: 25%;
`

const QuantityContainer = styled.div`
    height: 45px;
    text-align: right;
`

const ProductLegacyCodeContainer = styled.div`
    display: flex;
`

const ProductNameAndMeasureContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const WeightFreightAndTotalContainer = styled.div`
    display: flex;
    background-color: ${Colors.darkGray};
    flex-direction: column;
    widht: 100%;
    padding: 15px 25px 15px 25px;
`

const WeightAndTotalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${Colors.darkGray};
    widht: 100%;
    padding-bottom: 8px;
`

export default OrderDetailsAccordionComponent
