import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import * as OrderAPI from '../../dashboard/OrderAPI'
import { MessageType, addMessage } from '../../commons/MessageUtils'
import { PromiseWrapper } from '../../commons/PromiseUtils'
import BasicModal from '../../commons/components/ModalResponsive'
import Loading from '../../commons/components/Loading'
import CancelContent from './CancelContent'

export const CancelOrderModal = ({ visible, onClose, onCancel, order }) => {
    const [validation, setValidation] = useState(null)

    useEffect(() => {
        initializeModal(order)
        // eslint-disable-next-line
    }, [visible])

    const initializeModal = async order => {
        if (!visible) {
            setValidation(null)
            return
        }
        PromiseWrapper({
            promise: OrderAPI.verifiesOrderCanBeCancelled(order._id),
            onFulfilled: ({ data }) => setValidation(data),
            onRejected: () => {
                addMessage(
                    MessageType.ERROR,
                    'Ocorreu um erro ao cancelar o pedido.'
                )
                setValidation(null)
            },
        })
    }

    if (!validation) {
        return (
            <Modal visible={visible} onClose={onClose}>
                <LoadingContainer>
                    <Loading />
                </LoadingContainer>
            </Modal>
        )
    }

    return (
        <Modal visible={visible} onClose={onClose}>
            <Container>
                <CancelContent
                    order={order}
                    validation={validation}
                    onClose={onClose}
                    onCancel={onCancel}
                />
            </Container>
        </Modal>
    )
}

const Modal = styled(BasicModal)`
    align-items: center;
`

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    min-height: 150px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 32px 0 32px 24px;
    margin-right: 0px !important;
`

export default CancelOrderModal
