import hash from 'object-hash'

import * as OrderAPI from '../dashboard/OrderAPI'
import * as PendingOrderAPI from './PendingOrderAPI'
import * as MeasurementUnitUtils from '../commons/MeasurementUnitUtils'

import {
    calculatesMinAndMaxPrice,
    calculatesPriceWithIcms,
} from '../price/PriceUtils'
import * as NumberUtils from '../commons/NumberUtils'
import { getDaysDiffV2 } from '../commons/DateUtils'
import { createFieldValidator } from '../commons/FieldValidator'

export const CUSTOM_DEADLINE_NAME = 'CUSTOM_DEADLINE'

export const getOrderStructure = (selectedOrder = {}) => {
    const orderSource = 'portal'

    const sellerValue = {
        ...selectedOrder?.seller,
        categoryName: selectedOrder?.category?.name,
    }

    const deliveryDateValue = selectedOrder?.deliveryDate
        ? new Date(selectedOrder.deliveryDate)
        : ''

    const paymentDateValue = isCustomDeadline(selectedOrder)
        ? new Date(selectedOrder?.paymentDate)
        : ''

    const orderItemsValue = selectedOrder?.items
        ? enrichOrderItems(selectedOrder.items)
        : []

    const orderStructure = {
        ...selectedOrder,
        isOrderEdition: selectedOrder?.isOrderEdition || false,
        orderNumber: selectedOrder?.orderNumber || undefined,
        farm: selectedOrder?.farm || '',
        seller: selectedOrder?.seller ? sellerValue : {},
        category: selectedOrder?.category || {},
        items: orderItemsValue,
        note: selectedOrder?.note || '',
        reasonEdition: selectedOrder?.reasonEdition || '',
        deliveryDate: deliveryDateValue,
        paymentDate: paymentDateValue,
        freightType: selectedOrder?.freightType || '',
        weightInTonnes: selectedOrder?.weightInTonnes || 0,
        orderFrom: selectedOrder?.orderFrom || {
            isConfirmedOrder: false,
            isPendingOrder: false,
        },
        tributesPrice: selectedOrder?.tributesPrice || 0,
        tributes: selectedOrder?.farm ? getTributes(selectedOrder.farm) : {},
        chargesPrice: selectedOrder?.chargesPrice || 0,
        freightPrice: selectedOrder?.freightPrice || 0,
        subtotal: selectedOrder?.subtotal || 0,
        price: selectedOrder?.price || 0,
        paymentForm: selectedOrder?.paymentForm || '',
        paymentFormComplete: selectedOrder?.paymentFormComplete || {},
        paymentDeadline: selectedOrder?.paymentDeadline || '',
        paymentDeadlineComplete: selectedOrder?.paymentDeadlineComplete || {},
        source: selectedOrder?.source || orderSource,
        requestDate: selectedOrder?.requestDate || new Date(),
        needsChapa: selectedOrder?.needsChapa || false,
        needsPallet: selectedOrder?.needsPallet || false,
        isInvalidOrder: true,
    }

    return enrichOrder(orderStructure)
}

export const mountOrder = (selectedOrder, isMillHasFreight) => {
    const orderStructure = getOrderStructure(selectedOrder)
    const recalculatedOrder = recalculatesOrder(orderStructure)
    const { isInvalid } = isInvalidOrder(recalculatedOrder, isMillHasFreight)
    return {
        ...recalculatedOrder,
        isInvalidOrder: isInvalid,
    }
}

export function isOrderInvalidFields(
    order,
    isMillHasFreight,
    isNewSimpleBilling
) {
    const isPaymentDateRequired = isCustomDeadline(order.paymentDeadline)
    const fieldValidator = createFieldValidator()
    const types = fieldValidator.getAvailableValidators()

    const requiredFields = [
        { keys: ['farm'], type: types.STRING },
        { keys: ['paymentForm'], type: types.STRING },
        { keys: ['paymentDeadline'], type: types.STRING },
        { keys: ['price'], type: types.POSITIVE_NUMBER },
        { keys: ['seller', '_id'], type: types.STRING },
        { keys: ['items'], type: types.ARRAY },
        { keys: ['updatedProducts'], type: types.ARRAY },
    ]

    if (isMillHasFreight) {
        requiredFields.push({
            keys: ['freightType'],
            type: types.STRING,
        })
    }

    if (!isNewSimpleBilling) {
        requiredFields.push({
            keys: ['deliveryDate'],
            type: types.DATE,
        })
    }

    if (isPaymentDateRequired) {
        requiredFields.push({
            keys: ['paymentDate'],
            type: types.DATE,
        })
    }

    const schema = fieldValidator.composeValidationSchema(requiredFields, order)
    const invalidFields = fieldValidator.getInvalidFields(schema)
    const isInvalidFields = fieldValidator.isInvalidFields(schema)

    return { isInvalidFields, invalidFields }
}

const isOrderItemPriceInvalid = (order, allowsOutOfPricePolicy) => {
    if (allowsOutOfPricePolicy) return false

    const updatedProducts = order.updatedProducts || []
    const tributes = order.tributes
    const sellerPriceRate = getSellerRates(order)

    const orderItemsValidation = order.items.map(item => {
        const isDisabled = isProductDisabled(item, order)

        if (isDisabled || item.isUnitPriceValid) return true
        if (item.isUnitPriceValid === false) return false

        const product = getUpdatedOrderItem(item, updatedProducts)
        const { minPrice, maxPrice } = calculatesMinAndMaxPrice(
            product,
            sellerPriceRate,
            tributes
        )
        const isPriceValid = isUnitPriceValid(
            item.unitPrice,
            minPrice,
            maxPrice
        )
        return isPriceValid
    })

    return orderItemsValidation.includes(false)
}

export const isInvalidOrder = (
    order,
    isMillHasFreight,
    isNewSimpleBilling,
    allowsOutOfPricePolicy
) => {
    const { isInvalidFields, invalidFields } = isOrderInvalidFields(
        order,
        isMillHasFreight,
        isNewSimpleBilling
    )
    const isInvalidOrderItems = isOrderItemPriceInvalid(
        order,
        allowsOutOfPricePolicy
    )
    const isInvalid = isInvalidFields || isInvalidOrderItems
    return { isInvalid, invalidFields }
}

export const replaceOldOrderItems = (updatedProducts, orderItems) => {
    if (!orderItems.length) return []

    return orderItems.reduce((replacedItems, orderItem) => {
        const updatedProduct = updatedProducts.find(
            updatedProduct =>
                updatedProduct._id === orderItem.product._id ||
                updatedProduct?.sydleId === orderItem.product._id ||
                updatedProduct._id === orderItem.product?.gcpId
        )

        if (updatedProduct) {
            const updatedOrderItem = {
                ...orderItem,
                product: updatedProduct,
            }
            return [...replacedItems, updatedOrderItem]
        }

        return [...replacedItems, orderItem]
    }, [])
}

export const getUpdatedOrderItem = (orderItem, updatedProducts) => {
    const updatedProduct = updatedProducts.find(product => {
        return (
            product._id === orderItem.product._id ||
            product?.sydleId === orderItem.product._id ||
            product._id === orderItem.product?.gcpId
        )
    })

    return updatedProduct || {}
}

export const isUnitPriceValid = (unitPrice, minPrice, maxPrice) => {
    unitPrice = NumberUtils.parseNumber(unitPrice)
    if (unitPrice) {
        return Boolean(unitPrice >= minPrice && unitPrice <= maxPrice)
    }

    return true
}

export const getUnitPriceInvalidMessage = (unitPrice, minPrice, maxPrice) => {
    unitPrice = NumberUtils.parseNumber(unitPrice)
    if (unitPrice) {
        if (unitPrice < minPrice) return 'Abaixo do limite'
        if (unitPrice > maxPrice) return 'Acima do limite'
    }
}

export const getQuantityPlaceholder = product => {
    if (product.measurementUnit) {
        const placeholder = `${product.measurementUnit.measure} (${product.measurementUnit.quantity} ${product.measurementUnit.weight})`
        return placeholder[0].toUpperCase() + placeholder.slice(1)
    }
    return ''
}

export const validatesFields = (
    alteredField,
    orderItem,
    extraValidationFunc,
    maxPrice,
    minPrice
) => {
    const validationMethods = {
        quantity: quantityValidation,
        unitPrice: unitPriceValidation,
    }

    const validationMethod = validationMethods[alteredField.name]
    const { isValid, alteredOrderItem } = validationMethod(
        alteredField,
        orderItem,
        extraValidationFunc,
        maxPrice,
        minPrice
    )

    if (isValid && alteredOrderItem.unitPrice && alteredOrderItem.quantity) {
        alteredOrderItem.price = calculatesPrice(
            alteredOrderItem.unitPrice,
            alteredOrderItem.quantity
        )
    } else if (isValid) {
        alteredOrderItem.price = 0
    }

    return {
        isValid: isValid,
        alteredOrderItem: alteredOrderItem,
    }
}

export const createAndEnrichOrderItems = products => {
    return products.map(createAndEnrichOrderItem)
}

export const createAndEnrichOrderItem = product => {
    const orderItem = {
        product: product,
        quantity: '',
        unitPrice: '',
        price: 0,
        increaseMaxPercentage: product.increaseMaxPercentage,
        discountMaxPercentage: product.discountMaxPercentage,
        isUnitPriceValid: true,
        freightPrice: 0,
    }

    return enrichOrderItem(orderItem)
}

export const enrichOrderAndItems = order => {
    const enrichedOrder = enrichOrder(order)
    const enrichedOrderItems = enrichOrderItems(order.items)

    return { ...enrichedOrder, items: enrichedOrderItems }
}

export const enrichOrder = order => {
    return {
        ...order,
        calculatesPrice: function () {
            return this.items.reduce((total, item) => {
                return total + item.calculatesPrice()
            }, 0)
        },
        calculatesSubtotal: function () {
            const subtotal = this.items.reduce((total, item) => {
                return total + item.calculatesSubtotal()
            }, 0)

            return NumberUtils.round(subtotal, 2)
        },
        getTotalWeight: function () {
            const totalWeight = this.items.reduce((total, item) => {
                return total + item.getWeightInTonnes()
            }, 0)

            return NumberUtils.round(totalWeight, 3)
        },
        calculatesFreight: function () {
            const freight = this.items.reduce((total, item) => {
                return total + item.freightPrice
            }, 0)

            return NumberUtils.round(freight, 2)
        },
        calculatesCharges: function () {
            const freight = this.items.reduce((total, item) => {
                return total + item.chargesPrice
            }, 0)

            return NumberUtils.round(freight, 2)
        },
        calculatesTributes: function () {
            const tributes = this.items.reduce((total, item) => {
                return total + item.tributesPrice
            }, 0)

            return NumberUtils.round(tributes, 2)
        },
    }
}

export const enrichOrderItems = orderItems => {
    const enrichedOrderItems = orderItems.map(enrichOrderItem)
    return enrichedOrderItems
}

export const enrichOrderItem = orderItem => {
    return {
        ...orderItem,
        calculatesPrice: function () {
            const freightPrice = this.freightPrice || 0
            const chargesPrice = this.chargesPrice || 0
            return NumberUtils.round(
                this.calculatesSubtotal() + freightPrice + chargesPrice,
                2
            )
        },
        calculatesSubtotal: function () {
            if (!this.unitPrice || !this.quantity) return 0

            return NumberUtils.round(
                NumberUtils.parseNumber(this.unitPrice) *
                    NumberUtils.parseNumber(this.quantity)
            )
        },
        getWeightInTonnes: function () {
            if (!this.quantity || !this.isRationItem()) return 0

            return NumberUtils.round(
                NumberUtils.parseNumber(standardizeWeight(this)),
                3
            )
        },
        calculatesFreight: function (order, freightTable, millConfig) {
            if (
                !freightTable ||
                order.freightType !== 'CIF' ||
                !this.quantity
            ) {
                return 0
            }

            const orderWeight = order.getTotalWeight()
            const freightRange = getFreightRange(
                freightTable,
                orderWeight * 1000
            )
            if (!freightRange) {
                return 0
            }
            const freightPrice = freightRange.freightPrice
            const bagWeight = this.product.measurementUnit.quantity || 0
            const productQuantity = NumberUtils.parseNumber(this.quantity)
            const freightTributes = order.farm.tributes?.freightTributes || 0

            const freight = NumberUtils.round(
                (((freightPrice / 1000) * bagWeight) /
                    (1 - freightTributes / 100)) *
                    productQuantity,
                2
            )

            if (millConfig.useChapa && order.needsChapa) {
                const chapaValue =
                    this.getWeightInTonnes() * millConfig.chapaValue
                return freight + chapaValue
            }

            return freight
        },
        calculatesCharges: function (order, freight) {
            if (
                !order.paymentDeadlineComplete ||
                !order.paymentFormComplete ||
                !this.unitPrice ||
                !this.quantity
            ) {
                return 0
            }

            const increasePercentage = this.calculatesIncreasePercentage(order)
            const productPrice =
                NumberUtils.parseNumber(this.unitPrice) *
                NumberUtils.parseNumber(this.quantity)
            const freightPrice = freight || 0

            return NumberUtils.round(
                (productPrice + freightPrice) * (increasePercentage / 100),
                2
            )
        },
        calculatesIncreasePercentage: function (order) {
            if (isCustomDeadline(order.paymentDeadline)) {
                const dailyPercentage =
                    order.paymentFormComplete.dailyPercentage
                const paymentDate = new Date(order.paymentDate)
                const daysDiff = getDaysDiffV2(paymentDate)

                return dailyPercentage * daysDiff
            }

            return order.paymentDeadlineComplete?.increasePercentage || 0
        },
        calculatesTributes: function (tributes) {
            const basePrice = calculatesPriceWithIcms(
                tributes,
                this.product.price
            )
            return NumberUtils.round(basePrice - this.product.price, 2)
        },
        isRationItem: function () {
            return MeasurementUnitUtils.isRationItem(
                this.product.measurementUnit
            )
        },
    }
}

export const getFreightTable = (client, freightTables) => {
    const freightTable = freightTables.find(table => {
        return table.id === client.region
    })

    return freightTable ? freightTable : {}
}

const getFreightRange = (freightTable, weight) => {
    if (freightTable?.freightRanges) {
        return freightTable.freightRanges.find(
            freightRange =>
                freightRange.minimumWeight <= weight &&
                (freightRange.maximumWeight > weight ||
                    freightRange.maximumWeight === null)
        )
    }
}

export const isFreight = order =>
    order?.freightType === 'CIF' || order?.freightType === 'FOB'

export const isCharges = order => {
    const isCustomIncrease = Boolean(
        order.paymentDate && order.paymentFormComplete?.dailyPercentage
    )
    const isIncrease = Boolean(
        order.paymentDeadlineComplete?.increasePercentage
    )

    return isIncrease || isCustomIncrease
}

export const getFreightPricePerQuantity = (
    orderItem,
    order,
    freightTable,
    millConfig
) => {
    if (orderItem.product.measurementUnit.measure === 'granel')
        return orderItem.calculatesFreight(order, freightTable, millConfig)
    return (
        orderItem.calculatesFreight(order, freightTable, millConfig) /
        orderItem.quantity
    )
}

export const getChargesPricePerQuantity = (
    orderItem,
    order,
    freightTable,
    millConfig
) => {
    const freightPrice = orderItem.calculatesFreight(
        order,
        freightTable,
        millConfig
    )
    const chargesPrice =
        orderItem.calculatesCharges(order, freightPrice) /
        NumberUtils.parseNumber(orderItem.quantity)

    return chargesPrice
}

const mountPaymentDeadlines = paymentDeadlines => {
    const mountedDeadlines = paymentDeadlines.map(deadline => {
        return {
            ...deadline,
            label: deadline.name,
        }
    })

    return mountedDeadlines || []
}

export const getPaymentDeadlines = (paymentForms, selectedPaymentForm) => {
    if (!selectedPaymentForm || !paymentForms) return []

    const paymentForm = paymentForms.find(
        pf => pf.paymentForm === selectedPaymentForm
    )

    const customDeadline = {
        label: '',
        name: CUSTOM_DEADLINE_NAME,
        legacyCode: null,
        increasePercentage: paymentForm?.dailyPercentage,
        paymentDate: null,
    }

    const registredDeadlines = paymentForm?.paymentDeadlines || []

    const mountedDeadlines = mountPaymentDeadlines(registredDeadlines)

    const paymentDeadlines = mountedDeadlines.length
        ? [...mountedDeadlines, customDeadline]
        : []

    return paymentDeadlines
}

export const getIncreasePercentage = (
    selectedPaymentDeadline,
    paymentDeadlines
) => {
    if (!selectedPaymentDeadline || !paymentDeadlines) return 0

    const deadlines = paymentDeadlines.find(
        pd => pd.name === selectedPaymentDeadline
    )

    return deadlines?.increasePercentage || null
}

export const sortFarmsByName = clients => {
    return clients.sort((c1, c2) =>
        c1.fancyName.toLowerCase() > c2.fancyName.toLowerCase() ? 1 : -1
    )
}

export const mountFarmDropdownOptions = clients => {
    return clients.map(mountFarmDropdownOption)
}

export const mountFarmDropdownOption = client => {
    return {
        value: client,
        label: `${client?.fancyName} - ${client?.owner?.name}`,
    }
}

export const mountProductDropdownOptions = products => {
    return products.map(mountProductDropdownOption)
}

export const mountProductDropdownOption = product => {
    return {
        label: product.name,
        value: standardizeProduct(product),
    }
}

export const standardizeProduct = product => {
    return {
        _id: product._id,
        gcpId: product.gcpId,
        sydleId: product.sydleId,
        mill: product.mill,
        legacyCode: product.legacyCode,
        name: product.name,
        increaseMaxPercentage: product.increaseMaxPercentage,
        discountMaxPercentage: product.discountMaxPercentage,
        measurementUnit: product.measurementUnit,
        price: product.price,
        active: product.active,
    }
}

const standardizeWeight = orderItem => {
    if (
        orderItem.product.measurementUnit.weight === 'kg' &&
        orderItem.product.measurementUnit.measure === 'granel'
    ) {
        return NumberUtils.parseNumber(orderItem.quantity) / 1000
    } else if (
        orderItem.product.measurementUnit.weight === 'kg' &&
        orderItem.product.measurementUnit.measure === 'saco'
    ) {
        return (
            (orderItem.quantity * orderItem.product.measurementUnit.quantity) /
            1000
        )
    } else if (
        orderItem.product.measurementUnit.weight === 'kg' &&
        orderItem.product.measurementUnit.measure === 'bag'
    ) {
        return (
            (orderItem.quantity * orderItem.product.measurementUnit.quantity) /
            1000
        )
    } else if (
        orderItem.product.measurementUnit.weight === 'ton' &&
        orderItem.product.measurementUnit.measure === 'granel'
    ) {
        return orderItem.quantity
    }
}

const quantityValidation = (alteredField, orderItem) => {
    if (!NumberUtils.isValid(alteredField.value, 4)) {
        return { isValid: false }
    }
    alteredField.value = formatQuantity(alteredField.value, orderItem)

    const alteredOrderItem = {
        ...orderItem,
        [alteredField.name]: alteredField.value,
    }

    return {
        isValid: true,
        alteredOrderItem: alteredOrderItem,
    }
}

const unitPriceValidation = (
    alteredField,
    orderItem,
    minPrice,
    MaxPrice,
    extraValidationFunc
) => {
    const alteredOrderItem = {
        ...orderItem,
        [alteredField.name]: alteredField.value,
    }

    if (!NumberUtils.isValid(alteredField.value, 3)) {
        return { isValid: false }
    }

    const alteredPrice = parseFloat(
        NumberUtils.formatsDecimalNumber(alteredField.value, 2)
    )

    alteredOrderItem.unitPrice = alteredPrice
        .toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
        .replace(/\./g, '')

    alteredOrderItem.isUnitPriceValid = extraValidationFunc(
        alteredOrderItem.unitPrice,
        minPrice,
        MaxPrice
    )

    return {
        isValid: true,
        alteredOrderItem: alteredOrderItem,
    }
}

const formatQuantity = (quantity, orderItem) => {
    if (quantity === '' || !quantity) return ''

    let formatedQuantity
    switch (orderItem.product.measurementUnit.unitType) {
        case 'decimal':
            formatedQuantity = parseFloat(
                NumberUtils.formatsDecimalNumber(quantity)
            )
                .toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                })
                .replace(/\./g, '')
            break
        case 'inteiro':
        default:
            if ('number' !== typeof quantity) {
                quantity = NumberUtils.parseNumber(quantity)
            }
            formatedQuantity = Math.trunc(quantity)
            break
    }
    return /*NumberUtils.numberFormat(*/ formatedQuantity //, 3)
}

const calculatesPrice = (unitPrice, quantity) => {
    return (
        NumberUtils.parseNumber(unitPrice) * NumberUtils.parseNumber(quantity)
    ).toFixed(2)
}

export const sortProductsByName = products => {
    return products.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    )
}

export const getOrderItemMeasureUnit = orderItem => {
    return orderItem.product.measurementUnit.measure
}

export const getPaymentDeadlineLegacyCode = (
    paymentFormComplete,
    paymentDeadline
) => {
    if (paymentDeadline && paymentFormComplete) {
        const paymentDeadlineComplete =
            paymentFormComplete.paymentDeadlines.find(
                deadline => deadline.name === paymentDeadline
            )

        return paymentDeadlineComplete?.legacyCode || null
    }
    return null
}

export const getPaymentDeadlineId = (paymentFormComplete, paymentDeadline) => {
    if (paymentDeadline && paymentFormComplete) {
        const paymentDeadlineComplete =
            paymentFormComplete.paymentDeadlines.find(
                deadline => deadline.name === paymentDeadline
            )

        return paymentDeadlineComplete?._id || null
    }
    return null
}

export const recalculatesOrder = (order, freightTable, millConfig) => {
    const orderWithUpdatedItems = {
        ...order,
        items: recalculatesOrderItems(order, freightTable, millConfig),
    }

    return {
        ...orderWithUpdatedItems,
        price: orderWithUpdatedItems.calculatesPrice(),
        subtotal: orderWithUpdatedItems.calculatesSubtotal(),
        weightInTonnes: orderWithUpdatedItems.getTotalWeight(),
        freightPrice: orderWithUpdatedItems.calculatesFreight(),
        chargesPrice: orderWithUpdatedItems.calculatesCharges(),
        tributesPrice: orderWithUpdatedItems.calculatesTributes(),
    }
}

const recalculatesOrderItems = (order, freightTable, millConfig) => {
    return order.items.map(orderItem => {
        const freightPrice = orderItem.calculatesFreight(
            order,
            freightTable,
            millConfig
        )
        const chargesPrice = orderItem.calculatesCharges(order, freightPrice)
        const tributesPrice = orderItem.calculatesTributes(order.tributes)

        return {
            ...orderItem,
            freightPrice,
            chargesPrice,
            tributesPrice,
        }
    })
}

export const generateOrderHash = order => {
    return hash({
        farm: { _id: order.farm._id },
        seller: { _id: order.seller._id },
        requestDate: order.requestDate.toISOString(),
    })
}

export const isCustomDeadline = deadline =>
    Boolean(
        deadline?.name === CUSTOM_DEADLINE_NAME ||
            deadline?.paymentDate ||
            deadline === CUSTOM_DEADLINE_NAME
    )

export const getSellerRates = order => {
    return {
        discountMaxPercentage: order.seller?.discountMaxPercentage,
        increaseMaxPercentage: order.seller?.increaseMaxPercentage,
    }
}

export const isProductDisabled = (orderItem, order) => {
    if (!order || !orderItem || !order.updatedProducts) return false
    return !order.updatedProducts.some(
        updatedProduct =>
            updatedProduct._id === orderItem.product._id ||
            updatedProduct.sydleId === orderItem.product._id ||
            updatedProduct._id === orderItem.product?.gcpId
    )
}

export const getTributes = farm => (farm?.tributes ? farm.tributes : {})

export function convertGcpResponseToSydle(gcpResponse) {
    const loggedUserResponse = []
    gcpResponse.forEach(item => {
        const convertedItem = {
            increaseMaxPercentage: item.increaseMaxPercentage,
            gcpId: item.id,
            price: item.price,
            name: item.name,
            _id: item.id,
            sydleId: item.sydleId,
            legacyCode: item.legacyCode,
            category: item.category,
            sellingPlace: null,
            productLabel: {
                usageIndication: item?.label?.usageIndication,
                recommendedConsumption: item?.label?.recommendedConsumption,
                usageMode: item?.label?.usageMode,
                restrictions: item?.label?.restrictions,
                _id: item?.label?.id,
                gcpId: item?.label?.id,
                productPresentation: item?.label?.productPresentation,
                shelfLife: item?.label?.shelfLife,
                recommendations: item?.label?.recommendations,
                warrantyLevels: item?.label?.warrantyLevels.map(
                    warrantyLevel => ({
                        _id: warrantyLevel.id,
                        gcpId: warrantyLevel.id,
                        quantity: warrantyLevel.quantity,
                        nutrient: {
                            _id: warrantyLevel.nutrient.id,
                            gcpId: warrantyLevel.nutrient.id,
                            name: warrantyLevel.nutrient.name,
                            classification: {
                                _id: warrantyLevel.nutrient
                                    .nutrientClassification.id,
                                gcpId: warrantyLevel.nutrient
                                    .nutrientClassification.id,
                                name: warrantyLevel.nutrient
                                    .nutrientClassification.name,
                                measurementUnit:
                                    warrantyLevel.nutrient
                                        .nutrientClassification.measurementUnit,
                            },
                        },
                    })
                ),
            },
            discountMaxPercentage: item.discountMaxPercentage,
            description: item.description,
            active: item.active,
            measurementUnit: {
                unitType: item.measurementUnit.unitType,
                grossWeight: null,
                quantity: item.weight,
                measure: item.measurementUnit.measure,
                weight: item.measurementUnit.unit,
                _id: item.measurementUnit.id,
                gcpId: item.measurementUnit.id,
            },
            mill: {
                _id: item.millSydleId,
            },
            trackStock: item.trackStock,
            uF: null,
            productClassification: {
                name: item.classification,
                _id: item.classificationId,
                gcpId: item.classificationId,
            },
        }

        loggedUserResponse.push(convertedItem)
    })

    return loggedUserResponse
}

export function convertGcpClientToSydle(gcpClients) {
    return gcpClients.map(client => {
        const ownerSydle = {
            ...client.owner,
            _id: client.owner.id,
            gcpId: client.owner.id,
        }

        const tributesSydle = {
            icms: client.icmsAliquot,
            freightTributes: client.freightRate,
        }
        const sydlePorfolios = client.portfolios.reduce(
            (portfoliosSydle, portfolio) => {
                portfolio.users.forEach(user => {
                    portfoliosSydle.push({
                        seller: {
                            name: user.name,
                            _id: user.id,
                            // ---------------- remover ou não
                            discountMaxPercentage: user.discountMaxPercentage,
                            increaseMaxPercentage: user.increaseMaxPercentage,
                            isSeller: true,
                            // --------------- remover ou não
                        },
                        name: portfolio.name,
                        _id: portfolio.id,
                    })
                })
                return portfoliosSydle
            },
            []
        )

        const sydleClient = {
            corporateName: client.companyName,
            owner: ownerSydle,
            address: client.address,
            tributes: tributesSydle,
            note: client.note,
            debtor: client.debtor,
            addressReference: client.addressReference,
            fancyName: client.tradeName,
            categories: sydlePorfolios,
            _id: client.id,
            gcpId: client.id,
            sydleId: client.sydleId,
            legacyCode: client.legacyCode,
            region: client.freightTableId,
            stockSafetyDays: client.stockSafetyDays,
            deleted: client.deletedAt ? true : false,
            mill: { _id: client.millSydleId, gcpId: client.millId },
            billingAddress: client.billingAddress,
        }

        return sydleClient
    })
}

export function removeInvalidSydleIds(order) {
    return JSON.parse(JSON.stringify(order), (key, value) => {
        if (key === '_id' && value?.length !== 24) return undefined
        return value
    })
}

export async function handleOnSaveOrder({
    isSimpleBilling,
    isConfirmedOrder,
    orderId,
    order,
}) {
    const hash = order.hash ? order.hash : generateOrderHash(order)

    if (isSimpleBilling) {
        return await OrderAPI.createSimpleBilling({ ...order, hash })
    }

    if (isConfirmedOrder) {
        return await OrderAPI.saveEditedOrder(orderId, {
            ...order,
            isEdited: true,
        })
    }

    return orderId
        ? await PendingOrderAPI.updatePendingOrder(orderId, { ...order, hash })
        : await PendingOrderAPI.createPendingOrder({ ...order, hash })
}
