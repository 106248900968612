import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MdDone } from 'react-icons/md'

import { useHistory } from 'react-router-dom'
import Colors from '../commons/Colors'
import InputTextarea from '../commons/components/InputTextarea'
import InputWithMessage from '../commons/components/InputWithMessage'
import RoundButton from '../commons/components/RoundButton'
import * as ProductAPI from '../price/ProductAPI'
import { addMessage, MessageType } from '../commons/MessageUtils'
import BasicFormScreen from '../commons/components/BasicFormScreen'
import { Label } from '../commons/commonStyles'

export const ProductLabelFormScreen = ({ product }) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        productPresentation: '',
        usageMode: '',
        recommendedConsumption: '',
        usageIndication: '',
        restrictions: '',
        shelfLife: '',
        recommendations: '',
    })
    useEffect(() => {
        const productLabel = product.label ? product.label : {}
        setForm({
            productPresentation: productLabel.productPresentation || '',
            usageMode: productLabel.usageMode || '',
            recommendedConsumption: productLabel.recommendedConsumption || '',
            usageIndication: productLabel.usageIndication || '',
            restrictions: productLabel.restrictions || '',
            shelfLife: productLabel.shelfLife || '',
            recommendations: productLabel.recommendations || '',
        })
    }, [product])

    const handleChangeForm = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }

    const saveProductLabel = async form => {
        try {
            setLoading(true)
            product.label = {
                ...product.label,
                ...form,
            }

            const request = {
                id: product.id,
                productLabel: form,
            }

            if (product.label.id) {
                await ProductAPI.updateProductLabel({
                    ...request,
                    productId: request.id,
                    labelId: product.label.id,
                })
            } else {
                await ProductAPI.saveProductLabel(request)
                history.push('/produtos')
            }

            addMessage(MessageType.SUCCESS, 'Rótulo Salvo com Sucesso.')

            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.error(err.message)
            addMessage(MessageType.ERROR, 'Erro ao salvar o rótulo.')
        }
    }

    return (
        <BasicFormScreen title={'Informações do Rótulo'} linkTo={'/produtos'}>
            <Form>
                <Label htmlFor="productPresentation">
                    Apresentação do Produto
                </Label>
                <InputTextarea
                    id="productPresentation"
                    name="productPresentation"
                    placeholder={'Escreva sobre o produto'}
                    value={form.productPresentation}
                    onChange={handleChangeForm}
                />

                <Label htmlFor="usageMode">Modo de Uso</Label>
                <InputTextarea
                    id="usageMode"
                    name="usageMode"
                    placeholder={'Escreva sobre o uso'}
                    value={form.usageMode}
                    onChange={handleChangeForm}
                />

                <Label htmlFor="recommendedConsumption">
                    Consumo Recomendado
                </Label>
                <InputWithMessage
                    id="recommendedConsumption"
                    name="recommendedConsumption"
                    placeholder={'Escreva recomendações para o consumo'}
                    value={form.recommendedConsumption}
                    onChange={handleChangeForm}
                />

                <ThreeColumnsContainer>
                    <SingleFieldGrouping>
                        <Label htmlFor="usageIndication">Indicação</Label>
                        <InputWithMessage
                            id="usageIndication"
                            name="usageIndication"
                            placeholder={'Indicação de uso'}
                            value={form.usageIndication}
                            onChange={handleChangeForm}
                        />
                    </SingleFieldGrouping>

                    <SingleFieldGrouping>
                        <Label htmlFor="restrictions">Restrições</Label>
                        <InputWithMessage
                            id="restrictions"
                            name="restrictions"
                            value={form.restrictions}
                            onChange={handleChangeForm}
                        />
                    </SingleFieldGrouping>

                    <SingleFieldGrouping>
                        <Label htmlFor="shelfLife">Prazo de Validade</Label>
                        <InputWithMessage
                            id="shelfLife"
                            name="shelfLife"
                            placeholder={'Descreva a validade'}
                            value={form.shelfLife}
                            onChange={handleChangeForm}
                        />
                    </SingleFieldGrouping>
                </ThreeColumnsContainer>

                <Label htmlFor="recommendations">Outras Recomendações</Label>
                <InputTextarea
                    id="recommendations"
                    name="recommendations"
                    placeholder={'Escreva sobre outras recomendações'}
                    value={form.recommendations}
                    onChange={handleChangeForm}
                />
            </Form>

            <FooterActionsContainer>
                <RoundButton
                    loading={loading}
                    onClick={() => saveProductLabel(form)}
                >
                    SALVAR <MdDone />
                </RoundButton>
            </FooterActionsContainer>
        </BasicFormScreen>
    )
}

const Form = styled.form`
    display: grid;
    border-radius: 3px;
    border: solid 1px #eceeee;
    background-color: ${Colors.white};
    padding: 2.8vh 1.3vw !important;
`

const ThreeColumnsContainer = styled.div`
    display: grid;
    grid-template-columns: 0.6fr 1fr 0.6fr;
    grid-column-gap: 1vw;
`

const SingleFieldGrouping = styled.div`
    display: flex;
    flex-direction: column;
`

const FooterActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2.16vh;
`

export default ProductLabelFormScreen
