import React from 'react'
import styled from 'styled-components'
import { RadioButton as BasicRadioButton } from 'primereact/radiobutton'

import Colors from '../Colors'

/**
 *
 * @param {string} className - className do componente
 * @param {string} id - identificação unica do componente
 * @param {string} name - nome do componente
 * @param {any} value - valor do componente
 * @param {boolean} checked - especifica quando o radio button deve ser 'checked ou não
 * @param {event} onChange - callback a ser chamada quando o radio button é clicado
 * @param {boolean} isInvalid - especifica se o campo deixou de ser preenchido
 * @param {boolean} disabled - especifica se o botão está desativado
 */

const RadioButton = ({
    className,
    id,
    name,
    value,
    checked,
    onChange,
    isInvalid,
    disabled,
    ariaLabelledBy,
}) => {
    return (
        <Container
            className={className}
            isInvalid={isInvalid}
            disabled={disabled}
        >
            <BasicRadioButton
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                ariaLabelledBy={ariaLabelledBy}
            />
        </Container>
    )
}

const Container = styled.div`
    .p-radiobutton .p-radiobutton-box {
        border: ${props =>
            props.isInvalid ? '1px solid red' : '1px solid #dadada'};
    }
    .p-highlight :focus :enabled {
        outline-style: none !important;
    }
    .p-focus {
        outline: none !important;
        box-shadow: none !important;
    }
    .p-radiobutton {
        display: inline-flex !important;
        align-items: center !important;
        margin: 0 6px;
        cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    }
    .p-radiobutton-box {
        width: 16px !important;
        height: 16px !important;
    }
    .p-radiobutton-icon {
        margin: 0 !important;
        left: 2px !important;
        top: 2px !important;
    }
    .p-highlight {
        background-color: #ffffff !important;
        border-color: ${props =>
            props.disabled
                ? Colors.lightBlueGreyTwo
                : Colors.orange} !important;
    }
    .pi-circle-on {
        background-color: ${props =>
            props.disabled
                ? Colors.lightBlueGreyTwo
                : Colors.orange} !important;
    }
`

export default RadioButton
