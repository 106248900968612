import React, { useReducer } from 'react'
import styled from 'styled-components'

import Colors from '../../commons/Colors'
import * as ProductUsageUtils from '../ProductUsageUtils'

import ModalResponsive from '../../commons/components/ModalResponsive'
import RoundButton from '../../commons/components/RoundButton'
import SwitchButton from '../../commons/components/SwitchButtonComponent'
import Carousel from '../../commons/components/Carousel'

const EditProductUsageModalComponent = ({
    isVisible,
    onClose,
    onSave,
    pageIndex,
    onChangePage,
    productsUsage,
}) => {
    const [carouselItems, updateCarouselItems] = useReducer(
        (prev, changedMonth) => {
            const { month, active, productId } = changedMonth

            const productToChange = prev.find(
                product => product.productId === productId
            )
            const productIndex = prev.indexOf(productToChange)

            productToChange[month] = {
                ...productToChange[month],
                active,
            }

            const newCarouselItems = Array.from(prev)
            newCarouselItems[productIndex] = productToChange
            return newCarouselItems
        },
        JSON.parse(JSON.stringify(productsUsage))
    )

    const [editedItems, updateEditedItems] = useReducer(
        (prev, changedMonth) => {
            const { productId, month } = changedMonth
            const newEditedItems = Object.assign({}, prev)

            if (!newEditedItems[productId]) newEditedItems[productId] = {}

            newEditedItems[productId][month] = {
                ...productsUsage.find(item => item.productId === productId)[
                    month
                ],
                ...changedMonth,
            }

            return newEditedItems
        },
        {}
    )

    const REQUIRED_MONTHS = ProductUsageUtils.getMinimumRequiredDates()

    const getMonthsToDisplay = productUsage => {
        const monthRangeStructure =
            ProductUsageUtils.generateMonthRangeStructure()

        return monthRangeStructure.map(month => ({
            ...month,
            [month.key]: {
                month: month.key,
                active: productUsage[month.key]?.active,
                productId: productUsage.productId,
                id: productUsage[month.key]?.id,
                millId: productUsage.millId,
                clientId: productUsage?.clientId,
            },
        }))
    }

    const handleChange = event => {
        updateEditedItems(event)
        updateCarouselItems(event)
    }

    const isSaveButtonDisabled = () => {
        let isInvalid = false

        carouselItems.forEach(product =>
            REQUIRED_MONTHS.forEach(month => {
                if (!product[month]) isInvalid = true
            })
        )
        return isInvalid
    }

    const getSwitchLabel = active => {
        if (active === undefined) return 'Planejar'

        return active ? 'Ativo' : 'Inativo'
    }

    const getRequiredMonthIndicator = key =>
        REQUIRED_MONTHS.indexOf(key) === -1 ? null : (
            <RequiredText>*</RequiredText>
        )

    const renderProductUsage = product => {
        return (
            <ProductContainer>
                <ProductName>{product.name}</ProductName>
                <SwitchesGrid>
                    {getMonthsToDisplay(product).map((month, index) => (
                        <SwitchesContainer key={`${index}-${month.key}`}>
                            <MonthText>
                                {getRequiredMonthIndicator(month.key)}
                                {month.title}
                            </MonthText>
                            <SwitchButton
                                onChange={e =>
                                    handleChange({
                                        productId:
                                            month[e.target.name].productId,
                                        month: e.target.name,
                                        active: e.target.value,
                                        millId: product.millId,
                                        clientId: product?.clientId,
                                    })
                                }
                                checked={product[month.key]?.active}
                                name={month.key}
                            />
                            <SwitchLabel>
                                {getSwitchLabel(product[month.key]?.active)}
                            </SwitchLabel>
                        </SwitchesContainer>
                    ))}
                </SwitchesGrid>
            </ProductContainer>
        )
    }

    return (
        <ModalResponsive
            visible={isVisible}
            title="Editar status dos produtos"
            onClose={onClose}
        >
            <ModalContainer>
                <ModalBody>
                    <DescriptionContainer>
                        <Description>
                            Nessa unidade, você está planejando o status de
                            produtos, sendo possível ativá-los e inativá-los.
                            <br />
                            <br />
                            <RequiredDescription>
                                Para habilitar o botão SALVAR, é imprescindível
                                o planejamento dos meses assinalados como
                                OBRIGATÓRIOS à todos os produtos desta sessão.
                            </RequiredDescription>
                        </Description>
                    </DescriptionContainer>
                    <Carousel
                        value={carouselItems}
                        itemTemplate={renderProductUsage}
                        page={pageIndex}
                        onPageChange={e => onChangePage(e.page)}
                    />
                </ModalBody>
                <ModalFooter>
                    <ButtonsContainer>
                        <CancelButton onClick={onClose}>Cancelar</CancelButton>
                        <SaveButton
                            onClick={async () => await onSave(editedItems)}
                            disabled={isSaveButtonDisabled()}
                        >
                            *Salvar
                        </SaveButton>
                    </ButtonsContainer>
                    <FooterText>
                        *Todos os produtos editados nessa sessão serão salvos.
                    </FooterText>
                </ModalFooter>
            </ModalContainer>
        </ModalResponsive>
    )
}

const ModalContainer = styled.div`
    padding: 16px 22px 22px 22px;
    max-width: 1027px;
    display: flex;
    flex-direction: column;
`

const ModalBody = styled.div``

const DescriptionContainer = styled.div`
    padding-bottom: 10px;
`

const Description = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 10px;
    letter-spacing: normal;
    text-align: left;
    color: #6c6e6f;
`

const SwitchesContainer = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 1.5fr 1fr 1fr;
    border-bottom: 1px solid rgba(198, 203, 212, 0.3);
    padding: 16px;
    width: 235px;
`

const MonthText = styled.span`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #7f7f7f;
`

const SwitchLabel = styled.span`
    font-family: Roboto;
    font-size: 16px;
    text-align: left;
`

const ModalFooter = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    align-self: flex-end;
    padding-top: 21px;
`

const CancelButton = styled(RoundButton)`
    color: ${Colors.orange};
    border-color: ${Colors.orange};
    background-color: ${Colors.white};
`

const ButtonsContainer = styled.div`
    display: flex;
    gap: 12px;
`

const SaveButton = styled(RoundButton)`
    color: ${Colors.white};
    border-color: ${Colors.orange};
    background-color: ${Colors.orange};
`

const SwitchesGrid = styled.div`
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-auto-flow: column;
    column-gap: 50px;
`

const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ProductName = styled.h3`
    color: ${Colors.black};
    font-size: 14px;
    margin: 0;
    padding: 30px 0;
`

const FooterText = styled.p`
    color: ${Colors.orange};
    font-size: 11px;
    margin: 0;
    padding-top: 16px;
`

const RequiredText = styled.span`
    color: ${Colors.strawberry};
`
const RequiredDescription = styled.span`
    color: ${Colors.orange};
    font-weight: bold;
`

export default EditProductUsageModalComponent
