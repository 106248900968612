import React from 'react'
import styled from 'styled-components'

import NewsImage from '../assets/dashboard/news-image.png'
import NotificationBellIcon from '../assets/customIcons/NotificationBellIcon'

import Colors from '../commons/Colors'

import ModalResponsive from '../commons/components/ModalResponsive'

const NewsModalComponent = ({ isVisible, onClose, onConfirm }) => {
    return (
        <Modal visible={isVisible} onClose={onClose}>
            <ComponentContainer>
                <TitleContainer>
                    <Title>Temos novidades!</Title>
                </TitleContainer>
                <ImageContainer>
                    <Image src={NewsImage} alt="News Image" />
                </ImageContainer>

                <TextContainer>
                    <Text>
                        <TextNormal>
                            Olá! Estamos muito felizes em trazer novidades para
                            você!{' '}
                            <TextBold>
                                Uma nova versão já está disponível
                            </TextBold>
                            , para conferir os lançamentos{' '}
                            <TextBold>
                                basta clicar no ícone{' '}
                                <NotificatioonBellIconContainer>
                                    <NotificationBellIcon />
                                </NotificatioonBellIconContainer>
                            </TextBold>
                            <TextBold>
                                no canto superior do sistema ou clique no botão
                                abaixo.
                            </TextBold>
                        </TextNormal>
                    </Text>
                </TextContainer>

                <FooterButtonContainer>
                    <FooterButtom onClick={event => onConfirm(event)}>
                        Veja Agora
                    </FooterButtom>
                </FooterButtonContainer>
            </ComponentContainer>
        </Modal>
    )
}

const Modal = styled(ModalResponsive)``

const ComponentContainer = styled.div`
    width: 400px;
`
const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 20px;
`
const Title = styled.h1`
    color: ${Colors.lightishBlue};
    font-family: Sen;
    font-size: 24px;
    line-height: 1.17;
    letter-spacing: 0.44px;
    color: #4b74ff;
    margin: 0;
    padding: 0;
`
const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 33px;
`

const Image = styled.img`
    width: 177px;
    height: 175px;
`

const TextContainer = styled.div`
    padding: 0 52px 0 41px;
`

const Text = styled.p`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.43;
    color: ${Colors.brownishGrey};
    text-align: center;
    padding: 0;
    margin: 0;
    display: flex;
`

const TextNormal = styled.span``

const TextBold = styled.span`
    font-weight: bold;
`

const NotificatioonBellIconContainer = styled.div`
    color: ${Colors.orange};
    vertical-align: middle;
    display: inline-block;
    width: 24px;
`

const FooterButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 32px 0 48px 0;
`

const FooterButtom = styled.button`
    height: 41px;
    width: 184px;
    padding: 0.7vh 0.9vw;
    font-family: Roboto;
    font-size: 14px;
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
    border-radius: 25px;
    border-style: solid;
    color: ${Colors.white};
    cursor: pointer;
`

export default NewsModalComponent
