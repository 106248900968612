import React, { useState } from 'react'
import styled from 'styled-components'
import { MdSearch } from 'react-icons/md'
import InputWithMessage from './InputWithMessage'
import Colors from '../Colors'

/**
 * @param {string} name - nome do elemento
 * @param {string} value - valor do elemento
 * @param {string} placeholder - texto a ser motrado quando o elemento não tem valor
 * @param {event} onChange - chamado quando o valor é alterado
 */

export const SearchInput = ({ name, value, placeholder, onChange }) => {
    const [containerOnFocus, setContainerOnFocus] = useState(false)

    return (
        <Container
            data-testid="container-search-input"
            focus={containerOnFocus}
            onFocus={() => setContainerOnFocus(true)}
            onBlur={() => setContainerOnFocus(false)}
        >
            <Input
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={e => onChange(e)}
            />
            <SearchIcon />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #dadada;
    border-radius: 2px;
    height: 40px;
    box-shadow: ${props => (props.focus ? '0 0 0 0.2em #e4e9ec' : '')};
    transition: box-shadow 0.2s;
    background-color: ${Colors.white};

    input:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }
`

const Input = styled(InputWithMessage).attrs(() => ({
    type: 'text',
}))`
    width: 400px;
    border: none !important;
    height: 38px;
`

const SearchIcon = styled(MdSearch)`
    font-size: 23px;
    margin-right: 4px;
    color: #8e8e93;
`

export default SearchInput
