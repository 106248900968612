import React from 'react'
import styled from 'styled-components'

import * as NumberUtils from '../../commons/NumberUtils'
import * as DateUtils from '../../commons/DateUtils'
import { sumTotalWeight } from '../OrderGroupUtils'

const OrderGroupPrintTemplate = React.forwardRef(({ orderGroup }, ref) => {
    return (
        <Container ref={ref}>
            <Header>
                <Text>{DateUtils.dateFormat(new Date())}</Text>
                <Text>FarmTell Mills</Text>
            </Header>

            <OrderGroupInfo>
                <OrderGroupNote>
                    <SpaceBetween>
                        <Text>Observações: </Text>
                        <Text>
                            {NumberUtils.numberFormat(
                                sumTotalWeight(orderGroup),
                                2,
                                'Carga total: ',
                                ' Ton.'
                            )}
                        </Text>
                    </SpaceBetween>
                    <Text>{orderGroup.note}</Text>
                </OrderGroupNote>

                <div>
                    {orderGroup.orders.map(order => (
                        <OrderInfo key={order._id}>
                            <Border>
                                <GeneralInfo>
                                    <Column>
                                        <HeaderText>
                                            Pedido Nº {order.orderNumber}
                                        </HeaderText>

                                        <Text>
                                            Cliente: {order.farm.owner.name}
                                        </Text>

                                        <Text>
                                            Código do cliente:{' '}
                                            {order.farm.legacyCode}
                                        </Text>

                                        <Text>{order.farm.fancyName}</Text>

                                        <Text>Rota: {order.category.name}</Text>
                                    </Column>

                                    <CenteredColumn>
                                        <HeaderText color={'#000000'}>
                                            Dia do pagamento
                                        </HeaderText>

                                        <PaymentDate />

                                        <Status>{order.statusText}</Status>
                                    </CenteredColumn>

                                    <RightColumn>
                                        <HeaderText>
                                            Data de Entrega:{' '}
                                            {DateUtils.dateFormat(
                                                order.deliveryDate
                                            )}
                                        </HeaderText>

                                        <Text>
                                            Data do pedido:{' '}
                                            {DateUtils.dateFormat(
                                                order.requestDate
                                            )}{' '}
                                            | Hora:{' '}
                                            {DateUtils.timeFormat(
                                                order.requestDate
                                            )}
                                        </Text>

                                        <Text>
                                            Forma de pagto: {order.paymentForm}{' '}
                                            | Prazo:{' '}
                                            {order.paymentDate
                                                ? DateUtils.dateFormat(
                                                      order.paymentDate
                                                  )
                                                : order.paymentDeadline}
                                        </Text>

                                        <Text>
                                            Vendedor: {order.seller.name}
                                        </Text>

                                        <Text>
                                            {NumberUtils.numberFormat(
                                                order.weightInTonnes,
                                                2,
                                                'Total: ',
                                                ' ton.'
                                            )}
                                        </Text>
                                    </RightColumn>
                                </GeneralInfo>

                                {Boolean(order.farm.note) && (
                                    <Note
                                        style={{
                                            marginTop: '3px',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        <Text>
                                            Obs do cliente: {order.farm.note}
                                        </Text>
                                    </Note>
                                )}
                            </Border>

                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '15%' }}>
                                            <LeftTableContent>
                                                <Text color={'#025287'}>
                                                    Cód. Produto
                                                </Text>
                                            </LeftTableContent>
                                        </th>
                                        <th style={{ width: '45%' }}>
                                            <LeftTableContent>
                                                <Text color={'#025287'}>
                                                    Produto(s)
                                                </Text>
                                            </LeftTableContent>
                                        </th>
                                        <th style={{ width: '25%' }}>
                                            <LeftTableContent>
                                                <Text color={'#025287'}>
                                                    Quantidade / Preço unitário
                                                </Text>
                                            </LeftTableContent>
                                        </th>
                                        <th style={{ width: '15%' }}>
                                            <RightTableContent>
                                                <Text color={'#025287'}>
                                                    Preço
                                                </Text>
                                            </RightTableContent>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {order.items.map(item => (
                                        <tr key={item.product._id}>
                                            <td style={{ width: '15%' }}>
                                                <LeftTableContent>
                                                    <Text>
                                                        {
                                                            item.product
                                                                .legacyCode
                                                        }
                                                    </Text>
                                                </LeftTableContent>
                                            </td>
                                            <td
                                                style={{
                                                    width: '45%',
                                                }}
                                            >
                                                <LeftTableContent>
                                                    <Text>
                                                        {item.product.name}
                                                    </Text>
                                                </LeftTableContent>
                                            </td>
                                            <td
                                                style={{
                                                    width: '25%',
                                                }}
                                            >
                                                <LeftTableContent>
                                                    <Text>
                                                        {`${
                                                            item.unQuantity
                                                        } / R$: ${NumberUtils.numberFormat(
                                                            item.unitPrice
                                                        )} Por ${
                                                            item.product
                                                                .measurementUnit
                                                                .measure
                                                        }`}
                                                    </Text>
                                                </LeftTableContent>
                                            </td>
                                            <td style={{ width: '15%' }}>
                                                <RightTableContent>
                                                    <Text>
                                                        R${' '}
                                                        {NumberUtils.numberFormat(
                                                            item.price
                                                        )}
                                                    </Text>
                                                </RightTableContent>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Note>
                                <Text>Obs do pedido: {order.note}</Text>
                            </Note>
                        </OrderInfo>
                    ))}
                </div>
            </OrderGroupInfo>
        </Container>
    )
})

const Container = styled.div`
    width: 100%;
    padding: 7px 13px;
`

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
`

const Header = styled.div`
    display: grid;
    grid-template-columns: 0.8fr 1fr;
`

const Text = styled.span`
    font-size: 10px;
    color: ${props => (props.color ? props.color : '#3c3c3b')};
`

const HeaderText = styled(Text)`
    font-size: 11px;
`

const OrderGroupInfo = styled.div`
    padding: 9px 20px;
`

const Note = styled.div`
    padding: 4px 9px;
    background-color: #e1e1e1;
`

const OrderGroupNote = styled(Note)`
    display: flex;
    flex-direction: column;
`

const OrderInfo = styled.div`
    border: solid 1px #c6c6c6;
    padding: 6px;
    &:nth-child(1) {
        margin-top: 4px;
    }
    page-break-inside: avoid;
`

const Border = styled.div`
    border-bottom: dashed 1px #c6c6c6;
`

const GeneralInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.5fr 1fr;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const CenteredColumn = styled(Column)`
    align-items: center;
`

const RightColumn = styled(Column)`
    align-items: flex-end;
`

const PaymentDate = styled.div`
    background-color: #e1e1e1;
    height: 18px;
    width: 75%;
    margin: 3px 0;
`

const Status = styled(Text)`
    width: 70%;
    padding: 3px 8px;
    border-radius: 6px;
    background-color: #676767;
    color: white;
    text-align: center;
`

const Table = styled.table`
    width: 100%;
`

const LeftTableContent = styled.div`
    display: flex;
    justify-content: flex-start;
`
const RightTableContent = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default OrderGroupPrintTemplate
