import React from 'react'
import styled from 'styled-components'

import Colors from '../commons/Colors'

import { Label } from '../commons/commonStyles'
import Input from '../commons/components/Input'
import InputTextArea from '../commons/components/InputTextarea'

const OrderEditionBasicInformation = ({ order, setEditNote }) => {
    return (
        <Panel>
            <LabelWithAnswer>
                <Label>NÚMERO DO PEDIDO</Label>
                <OrderData
                    id="orderNumber"
                    value={order.orderNumber || '-'}
                    readOnly
                />
            </LabelWithAnswer>

            <LabelWithAnswerTextArea>
                <Label htmlFor="editNote">MOTIVO DA EDIÇÃO</Label>
                <InputText
                    id="editNote"
                    name="editNote"
                    placeholder="Digite o motivo pelo qual o pedido será editado"
                    value={order.reasonEdition}
                    rows={1}
                    onChange={e => setEditNote('reasonEdition', e.target.value)}
                />
            </LabelWithAnswerTextArea>
        </Panel>
    )
}

const Panel = styled.div`
    display: flex;
    width: 100%;
    border-radius: 3px;
    border: solid 1px #eceeee;
    background-color: ${Colors.white};
    padding: 21px;
    align-items: center;
`

const LabelWithAnswer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 21px;
    width: 13%;
`

const LabelWithAnswerTextArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
`

const InputText = styled(InputTextArea)`
    padding: 9px;
`

const OrderData = styled(Input)`
    border-radius: 4px;
    border-color: ${Colors.lightBlueGreyTwo};
    color: ${Colors.black};
    width: 100%;
`

export default OrderEditionBasicInformation
