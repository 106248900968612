import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import Colors from '../commons/Colors'

import ModalResponsive from '../commons/components/ModalResponsive'
import RoundButton from '../commons/components/RoundButton'

export function NotSavedAlertModalComponent({ isVisible }) {
    const history = useHistory()

    const [showPrompt, setShowPrompt] = useState(false)
    const [currentPath, setCurrentPath] = useState('')

    useEffect(() => {
        if (isVisible) {
            history.block(prompt => {
                setCurrentPath(prompt.pathname)
                setShowPrompt(true)
                return 'true'
            })
        } else {
            history.block(() => {})
        }

        return () => {
            history.block(() => {})
        }
    }, [history, isVisible])

    const handleOnConfirm = () => {
        history.block(() => {})
        history.push(currentPath)
    }

    const handleOnCancel = () => {
        setShowPrompt(false)
    }

    return showPrompt ? (
        <ModalResponsive
            title="Tem certeza que deseja sair sem planejar?"
            visible={isVisible}
            onClose={handleOnCancel}
        >
            <ModalContainer>
                <BodyContainer>
                    <Subtitle>
                        Todos os produtos adicionados que não foram planejados,
                        serão excluidos. Tem certeza que deseja sair da pagina?
                    </Subtitle>
                </BodyContainer>
                <FooterContainer>
                    <CancelButton onClick={() => handleOnCancel()}>
                        Cancelar
                    </CancelButton>
                    <ConfirmButton onClick={() => handleOnConfirm()}>
                        Sim, sair
                    </ConfirmButton>
                </FooterContainer>
            </ModalContainer>
        </ModalResponsive>
    ) : null
}

const ModalContainer = styled.div`
    width: 500px;
    padding: 24px;
`

const BodyContainer = styled.div``

const Subtitle = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #6c6e6f;
`

const FooterContainer = styled.div`
    display: flex;
    padding-top: 35px;
    justify-content: flex-end;
`

const CancelButton = styled(RoundButton)`
    margin-right: 12px;
    background-color: ${Colors.white};
    color: #f44336;
    border-color: #f44336;
`

const ConfirmButton = styled(RoundButton)`
    background-color: #f44336;
    color: ${Colors.white};
    border-color: #f44336;
`

export default NotSavedAlertModalComponent
