import React from 'react'

export default function LeftArrow() {
    return (
        <svg
            width="24"
            height="72"
            viewBox="0 0 24 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="12" fill="#005186" />
            <path
                d="M18 11.25H8.873l4.192-4.192L12 6l-6 6 6 6 1.057-1.058-4.184-4.192H18v-1.5z"
                fill="#fff"
            />
        </svg>
    )
}
