import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Colors from '../commons/Colors'
import BasicDatePickerCalendar from '../commons/components/DatePickerCalendar'
import RoundButton from '../commons/components/RoundButton'
import Modal from '../commons/components/ModalResponsive'
import { MdAssessment } from 'react-icons/md'
import { Label } from '../commons/commonStyles'
import { MessageType, addMessage } from '../commons/MessageUtils'
import { dateFormatToReport } from '../commons/DateUtils'
import { STATUS_OPTIONS_REPORT } from '../commons/Status'
import AutoCompleteAsync from '../commons/components/AutoCompleteAsync'
import * as PromiseUtils from '../commons/PromiseUtils'
import * as ClientPortfolioAPI from '../portfolio/ClientPortfolioAPI'
import * as ProductAPI from '../price/ProductAPI'
import * as ClientAPI from '../client/ClientAPI'
import * as ReportAPI from './ReportAPI'
import * as UserAPI from '../login/UserAPI'
import { useMillContext } from '../context/MillContext'

const ReportsModal = ({ onCloseReportsModal }) => {
    const { mill } = useMillContext()
    const [isGeneratingReport, setIsGeneratingReport] = useState(false)

    const [invalidDate, setInvalidDate] = useState(false)
    const [period, setPeriod] = useState({
        initialDate: null,
        finalDate: null,
    })

    const [selectedSellers, setSelectedSellers] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [status, setStatus] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedClients, setSelectedClients] = useState([])

    useEffect(() => {
        setInvalidDate(period.initialDate > period.finalDate)
    }, [period])

    const handlePeriodChange = event => {
        const { name, value } = event.target

        setPeriod(period => ({
            ...period,
            [name]: value,
        }))
    }

    const extractId = arr => {
        return arr.map(element => element._id)
    }
    const extractGcpId = arr => {
        return arr.map(element => element.id)
    }

    const formatPeriod = period => {
        return {
            initialDate: dateFormatToReport(
                period.initialDate || new Date('2020-01-01T00:00:00.000Z')
            ),
            finalDate: dateFormatToReport(period.finalDate || new Date()),
        }
    }

    const showPDFOnNewTab = pdfBlob => {
        const file = new File([pdfBlob], 'Relatório.pdf', {
            type: 'application/pdf',
        })

        const pdfURL = URL.createObjectURL(file)
        window.open(pdfURL)
    }

    const generateReport = async () => {
        try {
            setIsGeneratingReport(true)

            const formatedPeriod = formatPeriod(period)
            const params = {
                vf_nk_fabrica: mill.gcpId,
                vf_DataInicio: formatedPeriod.initialDate,
                vf_DataFim: formatedPeriod.finalDate,
                vf_nk_vendedor: extractId(selectedSellers),
                vf_nk_status_pedido: extractGcpId(status),
                vf_nk_produto: extractGcpId(selectedProducts),
                vf_nk_rota: extractGcpId(selectedCategories),
                vf_nk_fazenda: extractGcpId(selectedClients),
            }

            const response = await ReportAPI.queryViewPDF(params)

            const pdfBlob = await response.blob()
            showPDFOnNewTab(pdfBlob)
        } catch (err) {
            console.error(err)
            addMessage(MessageType.ERROR, 'Erro ao gerar relatório')
        } finally {
            setIsGeneratingReport(false)
        }
    }

    const fetchClients = async term => {
        return PromiseUtils.PromiseWrapper({
            promise: ClientAPI.getAllClients(15, 0, term),
            onFulfilled: ({ data }) => {
                return data.map(item => ({
                    value: item,
                    label: item.owner.name,
                }))
            },
        })
    }

    const fetchStatus = () => Promise.resolve(STATUS_OPTIONS_REPORT)

    const fetchProducts = async term => {
        return PromiseUtils.PromiseWrapper({
            promise: ProductAPI.searchByLoggedUser(15, 0, term),
            onFulfilled: ({ data }) => {
                return data.map(item => ({
                    value: item,
                    label: item.name,
                }))
            },
        })
    }

    const fetchSellers = async () => {
        return PromiseUtils.PromiseWrapper({
            promise: UserAPI.getAllSellersAndStc(),
            onFulfilled: ({ data }) => {
                return data.map(item => ({
                    value: item,
                    label: item.name,
                }))
            },
        })
    }

    const fetchCategories = async term => {
        return PromiseUtils.PromiseWrapper({
            promise: ClientPortfolioAPI.searchPortfoliosByFilter(15, 0, term),
            onFulfilled: ({ data }) => {
                return data.map(item => ({
                    value: item,
                    label: item.name,
                }))
            },
        })
    }

    const checkIsSelected = (addedItem, selectedItems) => {
        if (!selectedItems || !addedItem) return false

        return selectedItems.some(selectedItem => {
            if (selectedItem?.id) {
                return selectedItem.id === addedItem.id
            }

            return selectedItem._id === addedItem._id
        })
    }

    const onAddItem = (addedItem, selectedItems, setFunction) => {
        const isAlreadySelected = checkIsSelected(addedItem, selectedItems)

        if (isAlreadySelected) {
            return onRemoveItem(addedItem, setFunction)
        }

        setFunction(prev => [...prev, addedItem])
    }

    const onRemoveItem = (removedItem, setFunction) => {
        setFunction(prev =>
            prev.filter(
                item =>
                    item?._id !== removedItem?._id ||
                    item?.id !== removedItem?.id
            )
        )
    }

    const handleAutoCompleteActions = (payload, selectedItems, setFunction) => {
        switch (payload.action) {
            case 'remove-value':
            case 'pop-value':
                onRemoveItem(payload.removedValue.value, setFunction)
                return
            case 'clear':
                setFunction([])
                return
            default:
                onAddItem(payload.option.value, selectedItems, setFunction)
                return
        }
    }

    const reportsToRender = [
        {
            title: 'POR CLIENTE',
            placeholder: 'Pesquise os clientes para exibir no relatório',
            entityText: {
                singular: 'cliente selecionado',
                plural: 'clientes selecionados',
            },
            selectedItems: selectedClients,
            setFunction: setSelectedClients,
            fetchFunction: fetchClients,
        },
        {
            title: 'POR SITUAÇÃO',
            placeholder: 'Pesquise as situações para exibir no relatório',
            entityText: {
                singular: 'situações selecionadas',
                plural: 'situação selecionada',
            },
            selectedItems: status,
            setFunction: setStatus,
            fetchFunction: fetchStatus,
        },
        {
            title: 'POR PRODUTO',
            placeholder: 'Pesquise os produtos para exibir no relatório',
            entityText: {
                singular: 'produto selecionado',
                plural: 'produtos selecionados',
            },
            selectedItems: selectedProducts,
            setFunction: setSelectedProducts,
            fetchFunction: fetchProducts,
        },
        {
            title: 'POR VENDEDOR',
            placeholder: 'Pesquise os vendedores para exibir no relatório',
            entityText: {
                singular: 'vendedor selecionado',
                plural: 'vendedores selecionados',
            },
            selectedItems: selectedSellers,
            setFunction: setSelectedSellers,
            fetchFunction: fetchSellers,
        },
        {
            title: 'POR ROTA',
            placeholder: 'Pesquise as rotas para exibir no relatório',
            entityText: {
                singular: 'rota selecionada',
                plural: 'rotas selecionadas',
            },
            selectedItems: selectedCategories,
            setFunction: setSelectedCategories,
            fetchFunction: fetchCategories,
        },
    ]

    return (
        <Modal visible={true} onClose={onCloseReportsModal}>
            <ModalHeader>
                <ModalTitle>Relatórios do Painel de Pedidos</ModalTitle>

                <ModalSubtitle>
                    <SubtitleIcon />
                    <SubtitleLabel>
                        Receba as informações da sua fábrica no período
                        desejado.
                    </SubtitleLabel>
                </ModalSubtitle>
            </ModalHeader>

            <ModalContent>
                <ModalInfo>
                    * Por padrão, caso não sejam habilitados quaisquer filtros,
                    o relatório apresentará <strong>todas</strong> as
                    informações registradas na base, referentes à fábrica.
                    Utilize os filtros sempre que desejar refinar as informações
                    a serem exibidas.
                </ModalInfo>

                <Separator />

                <Label>Filtrar</Label>

                <FiltersContainer>
                    <FilterContainer>
                        <ReportLabel>POR DATA DE SOLICITAÇÃO</ReportLabel>

                        <PeriodFilter>
                            <DatePickerCalendar
                                isInvalidDate={invalidDate}
                                dateIconColor={Colors.orange}
                                placeholderColor={Colors.orange}
                                placeholder="De"
                                name="initialDate"
                                value={period.initialDate}
                                onChange={handlePeriodChange}
                                showButtonBar
                                todayButtonClassName="today-button"
                            />

                            <DatePickerCalendar
                                isInvalidDate={invalidDate}
                                dateIconColor={Colors.orange}
                                placeholderColor={Colors.orange}
                                placeholder="Até"
                                name="finalDate"
                                value={period.finalDate}
                                onChange={handlePeriodChange}
                                showButtonBar
                                todayButtonClassName="today-button"
                            />
                        </PeriodFilter>

                        {invalidDate && (
                            <InvalidDateField>
                                * A data final deve ser posterior a data inicial
                            </InvalidDateField>
                        )}
                    </FilterContainer>

                    {reportsToRender.map(params => (
                        <FilterContainer>
                            <ReportLabel>{params.title}</ReportLabel>

                            <AutoCompleteAsync
                                isMulti
                                useOrangeMode
                                useCustomInput
                                placeholder={params.placeholder}
                                customInputText={{
                                    singular: `${params.entityText.singular} | `,
                                    plural: `${params.entityText.plural} | `,
                                }}
                                value={params.selectedItems}
                                fetchSuggestions={params.fetchFunction}
                                getSelectedOption={item =>
                                    checkIsSelected(item, params.selectedItems)
                                }
                                onChange={(_value, payload) =>
                                    handleAutoCompleteActions(
                                        payload,
                                        params.selectedItems,
                                        params.setFunction
                                    )
                                }
                            />
                        </FilterContainer>
                    ))}
                </FiltersContainer>

                <FooterContainer>
                    <GenerateButton
                        loading={isGeneratingReport}
                        disabled={invalidDate}
                        onClick={generateReport}
                    >
                        Gerar Relatório
                    </GenerateButton>
                </FooterContainer>
            </ModalContent>
        </Modal>
    )
}

const ModalHeader = styled.div`
    padding: 16px;
    background-color: #eceeee;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`

const ModalTitle = styled.span`
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.29px;
    color: ${Colors.black};
`
const ModalSubtitle = styled.span`
    display: flex;
    align-items: center;
    margin-top: 12px;
`

const SubtitleLabel = styled.label`
    font-family: Roboto;
    font-size: 14px;
    margin-left: 8px;
    letter-spacing: 0.25px;
    color: ${Colors.black};
`

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
`

const SubtitleIcon = styled(MdAssessment)`
    width: 27px;
    height: 27px;
    color: ${Colors.brownishGrey};
`

const ModalInfo = styled.div`
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    line-height: 1.83;
    color: ${Colors.brownishGrey};
    padding: 8px;
    padding-top: 0;
    width: 670px;
`

const Separator = styled.div`
    height: 1px;
    background-color: ${Colors.lightBlueGreyTwo};
    margin-bottom: 8px;
`

const FiltersContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 16px;
`

const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: solid #eceeee 1px;
    border-radius: 4px;
    padding: 10px;
    margin-top: 16px;

    label {
        margin-bottom: 10px;
    }
`
const ReportLabel = styled(Label)`
    margin-bottom: 10px;
`

const PeriodFilter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const InvalidDateField = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.strawberry};
    font-size: 12px;
    margin-top: 8px;
`

const DatePickerCalendar = styled(BasicDatePickerCalendar)`
    padding-left: 16px;

    .p-inputtext {
        border-radius: 2px;
    }

    .p-button {
        border-radius: 2px;
    }

    .today-button {
        display: none;
    }
`

const FooterContainer = styled.div`
    display: flex;
    width: 100%;
    background-color: ${Colors.white};
    padding: 10px 16px;
    align-items: center;
    justify-content: flex-end;
`

const GenerateButton = styled(RoundButton)`
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
`

export default ReportsModal
