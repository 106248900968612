import { getBaseURL, getToken, getAPIKey } from '../environment'

const baseURL = getBaseURL()
const gcpBaseURL = `${getBaseURL()}/orders`

export const getFreightTablesV1 = () => {
    return fetch(`${baseURL}/orders/freightTablesV1`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': getAPIKey(),
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getFreightTables = async () => {
    return fetch(`${gcpBaseURL}/freightTables`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': getAPIKey(),
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
