const ConfirmService = {
    _subscribers: [],

    /**
     * @param {object} options
     * @param {string} options.title - Título do Confirm
     * @param {string} options.actionButtonText - Texto do botão de confirmar
     * @param {Function} options.actionButtonFunction - Função a ser chamada quando confirmar
     * @param {Function} options.renderMessage - Função que retorna o JSX da mensagem
     * @param {Function} [options.onClose] - Função a ser chamada quando fechar o confirm
     */
    addConfirmMessage(options) {
        this._subscribers.map(subscriber => subscriber.fn(options))
    },

    subscribe(fn) {
        const id = Math.random() * 100000
        this._subscribers.push({ id, fn })
        return id
    },

    unsubscribe(id) {
        this._subscribers = this._subscribers.filter(
            subscriber => subscriber.id !== id
        )
    },
}

export default ConfirmService
