import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { MessageType, addMessage } from '../commons/MessageUtils'

import ProductForm from './ProductForm'
import * as ProductAPI from '../price/ProductAPI'
import * as MeasurementUnitAPI from './MeasurementUnitAPI'
import * as ProductClassificationAPI from './ProductClassificationAPI'

import BasicFormScreen from '../commons/components/BasicFormScreen'

export const ProductFormScreen = ({
    initialFormValue,
    setInitialFormValue,
    isEdition,
}) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [measurementUnitOptions, setMeasurementUnitOptions] = useState([])
    const [productClassificationOptions, setProductClassificationOptions] =
        useState([])

    useEffect(() => {
        const formatMeasurementUnitOptions = measurementUnits => {
            const options = measurementUnits.map(measurementUnit => {
                return {
                    label:
                        measurementUnit.unit + ' / ' + measurementUnit.measure,
                    value: measurementUnit.id,
                }
            })
            return options
        }
        const getAllMeasurementUnits = async () => {
            const response = await MeasurementUnitAPI.getAllMeasurementUnits()
            const measurementUnits = response?.data.entities
            if (measurementUnits)
                setMeasurementUnitOptions(
                    formatMeasurementUnitOptions(measurementUnits)
                )
        }

        try {
            getAllMeasurementUnits()
        } catch (err) {
            console.error(err)
        }
    }, [])

    useEffect(() => {
        const formatProductClassificationOptions = classifications => {
            const options = classifications.map(classification => {
                return {
                    label: classification.name,
                    value: classification.id,
                }
            })
            return options
        }
        const getAllProductsClassifications = async () => {
            const response =
                await ProductClassificationAPI.getAllProductsClassifications()
            const classification = response?.data.entities
            if (classification) {
                setProductClassificationOptions(
                    formatProductClassificationOptions(classification)
                )
            }
        }

        try {
            getAllProductsClassifications()
        } catch (err) {
            console.error(err)
        }
    }, [])

    useEffect(() => {
        const isMeasurementUnitValid = () => {
            return measurementUnitOptions.some(option => {
                const { value } = option
                return value === initialFormValue.measurementUnitId
            })
        }
        if (
            initialFormValue.measurementUnitId &&
            measurementUnitOptions.length &&
            !isMeasurementUnitValid()
        )
            setInitialFormValue({
                ...initialFormValue,
                measurementUnitId: '',
            })
    }, [measurementUnitOptions, initialFormValue, setInitialFormValue])

    const saveProduct = async form => {
        try {
            setLoading(true)
            const saveForm = {
                ...form,
                active: !form.active,
            }
            if (form.id) {
                await ProductAPI.editProduct(saveForm)
            } else {
                await ProductAPI.createProduct(saveForm)
            }
            addMessage(
                MessageType.SUCCESS,
                <span>
                    Produto <strong>{form.name}</strong> salvo com sucesso!
                </span>
            )

            history.push('/produtos')
        } catch (err) {
            console.error(err.message)
            addMessage(
                MessageType.ERROR,
                <span>
                    Erro ao salvar o produto <strong>{form.name}</strong>.
                </span>
            )
            setLoading(false)
        }
    }

    return (
        <BasicFormScreen
            title={'Novo Produto'}
            editionTitle={'Editar Produto'}
            isEdition={isEdition}
            linkTo={'/produtos'}
        >
            <ProductForm
                saveProduct={saveProduct}
                buttonLoading={loading}
                initialValues={initialFormValue}
                measurementUnitOptions={measurementUnitOptions}
                productClassificationOptions={productClassificationOptions}
            ></ProductForm>
        </BasicFormScreen>
    )
}

export default ProductFormScreen
