import React from 'react'
import styled from 'styled-components'
import Tooltip from './Tooltip'

import editIconIndicator from '../../assets/dashboard/icon-edition.png'

function EditedOrderIndicator({ edited, tooltip = false }) {
    const editedOrderIcon = () => {
        return tooltip ? (
            <Tooltip label={'Pedido editado pelo STC'}>
                <EditedIcon data-testid="edited-icon" />
            </Tooltip>
        ) : (
            <SectionEdition>
                <EditedIcon data-testid="edited-icon" />
            </SectionEdition>
        )
    }

    return <>{edited ? editedOrderIcon() : <></>}</>
}

const EditedIcon = styled.img`
    width: 24px;
`
EditedIcon.defaultProps = {
    src: editIconIndicator,
    alt: 'Icone para pedidos editados',
}

const SectionEdition = styled.div`
    display: flex;
    flex-direction: row;
`

export default EditedOrderIndicator
