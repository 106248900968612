import * as StringUtils from '../commons/StringUtils'
import { STATUS, STATUS_TEXT } from '../commons/Status'

export const FILTER_TYPE = {
    DEFAULT: 'DEFAULT',
    ORDERGROUP: 'ORDERGROUP',
}

export const mountBoardData = (processesCards, baseBoard = BASE_BOARD) => {
    const EMPTY_BOARD = { lanes: [] }

    const boardData = baseBoard.lanes.reduce((data, lane) => {
        const processedCards = processesCards(lane)

        const mountedLane = {
            ...processedCards.lane,
            cards: processedCards.cards,
        }

        data.lanes.push(mountedLane)
        return data
    }, EMPTY_BOARD)
    return boardData
}

export const composeFunctions =
    (...functions) =>
    arg =>
        functions.reduce(
            (previousFunctionResult, currentFunction) =>
                currentFunction(previousFunctionResult),
            arg
        )

export const unifiesOrdersAndOrderGroups = params => {
    const {
        orders,
        orderGroups,
        ordersWithFragments,
        fragmentedOrders,
        shipments,
        simpleBillings,
    } = params

    if (
        !orders ||
        !orderGroups ||
        !ordersWithFragments ||
        !fragmentedOrders ||
        !shipments ||
        !simpleBillings
    )
        return params

    const {
        mountedShipments,
        mountedOrders,
        mountedOrderGroups,
        mountedFragmentedOrders,
    } = handleOrders(params)

    return {
        ...params,
        cards: [
            ...mountedOrders,
            ...mountedOrderGroups,
            ...mountedFragmentedOrders,
            ...mountedShipments,
        ],
    }
}

export const allowsEdition = orderGroup => {
    return !orderGroup.orders.some(
        order =>
            order.status === STATUS.ENTREGUE ||
            order.status === STATUS.EM_EXPEDICAO_TRANSITO
    )
}

export const removeOrdersInGroups = (orders, orderGroups) => {
    return orders.filter(order => orderNotExistOnGroup(order, orderGroups))
}

export const handleOrders = params => {
    const orders = [
        ...params.orders,
        ...params.simpleBillings,
        ...params.ordersWithFragments,
    ]

    const mountedOrderGroups = mountOrderGroups(params)
    const { mountedShipments, mountedOrders, mountedFragmentedOrders } =
        orders.reduce(
            (acc, order) => {
                if (!orderNotExistOnGroup(order, mountedOrderGroups)) return acc

                if (order.isUseFragmentOrder) {
                    acc.mountedFragmentedOrders.push(
                        ...mountFragmentedOrders({
                            order,
                            fragmentedOrders: params.fragmentedOrders,
                        })
                    )
                    return acc
                }

                if (order.isUseShipment) {
                    acc.mountedShipments.push(
                        ...mountShipments({
                            order,
                            shipments: params.shipments,
                        })
                    )
                    return acc
                }

                acc.mountedOrders.push(order)
                return acc
            },
            {
                mountedShipments: [],
                mountedOrders: [],
                mountedFragmentedOrders: [],
            }
        )

    return {
        mountedShipments,
        mountedOrders,
        mountedFragmentedOrders,
        mountedOrderGroups,
    }
}

export const mountCards = params => {
    const { cards } = params

    if (!cards) return params

    const mountedCards = cards.map(card => {
        return card.isOrderGroup ? mountOrderGroupCard(card) : mountCard(card)
    })

    return {
        ...params,
        cards: mountedCards,
    }
}

const mountCard = item => ({
    ...item,
    id: item._id,
})

const mountOrderGroupCard = card => ({
    ...card,
    id: card._id,
    orders: card.orders.map(order => mountCard(order)),
})

export const mountFragmentedOrders = ({ order, fragmentedOrders }) => {
    if (!order || !fragmentedOrders) return []

    const mountedFragment = fragmentedOrders
        .filter(fragmentedOrder => fragmentedOrder.orderId === order._id)
        .map(fragmentedOrder => {
            return {
                ...fragmentedOrder,
                _id: fragmentedOrder.id,
                orderNumber: order.orderNumber,
                category: order.category,
                seller: order.seller,
                requestDate: order.requestDate,
                deliveryDate: order.deliveryDate,
                farm: order.farm,
                isUrgent: order.isUrgent,
                isFragmentedOrder: true,
            }
        })

    return mountedFragment
}

export const mountShipments = ({ order, shipments }) => {
    if (!order || !shipments) return []

    return shipments
        .filter(shipment => shipment.orderId === order._id)
        .map(shipment => ({
            ...shipment,
            _id: shipment.id,
            requestDate: shipment.createdAt,
            orderNumber: order.orderNumber,
            category: order.category,
            seller: order.seller,
            farm: order.farm,
            isUseShipment: order.isUseShipment,
        }))
}

const mountOrderGroups = ({ orderGroups, orders }) => {
    const filledOrderGroups = orderGroups.map(orderGroup => ({
        ...orderGroup,
        orders: findOrdersFromGroup(orderGroup.orders, orders),
    }))

    return filledOrderGroups.map(filledOrderGroup => ({
        ...filledOrderGroup,
        isOrderGroup: true,
        isEditable: allowsEdition(filledOrderGroup),
    }))
}

const orderNotExistOnGroup = (order, orderGroups) => {
    return !orderGroups.some(ordersGroup =>
        ordersGroup.orders.some(o => o._id === order._id)
    )
}

const findOrdersFromGroup = (ordersFromGroup, orders) => {
    return ordersFromGroup.map(orderFromGroup => {
        const fetchedOrder = orders.find(o => o._id === orderFromGroup._id)
        if (fetchedOrder) {
            return fetchedOrder
        }
        return orderFromGroup
    })
}

export const filterCardsWithSearchTerm = params => {
    const { cards, searchTerm, filterType } = params

    if (!cards || !searchTerm) return params

    if (filterType === FILTER_TYPE.ORDERGROUP) {
        const filteredCards = filterCardsByOrderGroupNumber(cards, searchTerm)
        return {
            ...params,
            cards: filteredCards,
        }
    }

    const filteredCards = cards.reduce((response, card) => {
        return card.isOrderGroup
            ? filterOrderGroupBySearchTerm(card, searchTerm, response)
            : filterBySearchTerm(card, searchTerm, response)
    }, [])

    return {
        ...params,
        cards: filteredCards,
    }
}

const filterOrderGroupBySearchTerm = (card, searchTerm, response) => {
    const filteredOrders = card.orders.reduce(
        (response, order) => filterBySearchTerm(order, searchTerm, response),
        []
    )

    if (filteredOrders.length > 0) {
        response.push({
            ...card,
            orders: filteredOrders,
        })
    }

    return response
}

const filterBySearchTerm = (card, term, response) => {
    const searchTerm = StringUtils.normalize(term)

    if (
        StringUtils.isEmpty(searchTerm) ||
        StringUtils.normalize(card.farm.owner.name).includes(searchTerm) ||
        StringUtils.normalize(card.category.name).includes(searchTerm) ||
        StringUtils.normalize(String(card.orderNumber)).includes(searchTerm) ||
        StringUtils.normalize(card.farm.fancyName).includes(searchTerm)
    ) {
        response.push(card)
    }

    return response
}

const filterCardsByOrderGroupNumber = (cards, searchTerm) => {
    const orderGroupNumber = StringUtils.normalize(searchTerm)

    return cards
        .filter(card => card.isOrderGroup)
        .filter(card =>
            StringUtils.normalize(card.orderGroupNumber.toString()).includes(
                orderGroupNumber
            )
        )
}

export const filterCardsFromLane = params => {
    const { cards, lane } = params

    if (!cards || !lane) return params

    const filteredCards = filterAllOrders(cards, lane)

    return {
        ...params,
        cards: filteredCards,
    }
}

const filterAllOrders = (cards, lane) => {
    return cards.reduce((mappedCards, card) => {
        if (card.isOrderGroup) return filterOrderGroup(card, lane, mappedCards)
        if (card.isFragmentedOrder || card.isUseShipment)
            return filterOrderFromGcp(card, lane, mappedCards)
        return filterOrder(card, lane, mappedCards)
    }, [])
}

const filterOrderFromGcp = (card, lane, response) => {
    if (card.status.name === lane.id) response.push(card)

    return response
}

const filterOrder = (card, lane, response) => {
    if (card.status === lane.id) response.push(card)

    return response
}

const filterOrderGroup = (card, lane, response) => {
    const filteredOrders = card.orders.filter(card => card.status === lane.id)

    if (filteredOrders.length > 0) {
        response.push({
            ...card,
            orders: filteredOrders,
        })
    }

    return response
}

export const sortCardsByUrgencyAndRequestDate = params => {
    const { cards } = params

    if (!cards) return params

    return {
        ...params,
        cards: Array.from(cards).sort((card1, card2) => {
            if (card1.isOrderGroup && !card2.isOrderGroup) return -1
            else if (!card1.isOrderGroup && card2.isOrderGroup) return 1
            else if (card1.isUrgent && !card2.isUrgent) return -1
            else if (!card1.isUrgent && card2.isUrgent) return 1
            else return card1.requestDate >= card2.requestDate ? -1 : 1
        }),
    }
}

export const isOrderOutOfPricePolicy = (order, status) => {
    return order.isOutOfPricePolicy && status === STATUS.EM_ANALISE
}

export const BASE_BOARD = {
    lanes: [
        {
            id: STATUS.EM_ANALISE,
            description: STATUS_TEXT.EM_ANALISE,
            cards: [],
            style: {
                backgroundColor: '#e64858',
                minWidth: '280px',
            },
            titleColor: 'white',
            subtitleColor: '#f0f0f6',
        },
        {
            id: STATUS.EM_PRODUCAO,
            description: STATUS_TEXT.EM_PRODUCAO,
            cards: [],
            style: {
                backgroundColor: '#e67508',
                minWidth: '280px',
            },
            titleColor: 'white',
            subtitleColor: '#f0f0f6',
        },
        {
            id: STATUS.EM_ESTOQUE,
            description: STATUS_TEXT.EM_ESTOQUE,
            cards: [],
            style: {
                backgroundColor: '#f1b63a',
                minWidth: '280px',
            },
            subtitleColor: '#6f6f74',
        },
        {
            id: STATUS.EM_EXPEDICAO_TRANSITO,
            description: STATUS_TEXT.EM_EXPEDICAO_TRANSITO,
            cards: [],
            style: {
                backgroundColor: '#005186',
                minWidth: '280px',
            },
            titleColor: 'white',
            subtitleColor: '#f0f0f6',
        },
        {
            id: STATUS.ENTREGUE,
            description: STATUS_TEXT.ENTREGUE,
            cards: [],
            style: {
                backgroundColor: '#16a074',
                minWidth: '280px',
            },
            titleColor: 'white',
            subtitleColor: '#f0f0f6',
        },
    ],
}

export const LOADING_BOARD = {
    lanes: [
        {
            id: STATUS.EM_ANALISE,
            description: STATUS_TEXT.EM_ANALISE,
            cards: [
                { id: '1', draggable: false, loading: true },
                { id: '2', draggable: false, loading: true },
            ],
            style: {
                backgroundColor: '#e64858',
                minWidth: '280px',
            },
            titleColor: 'white',
            subtitleColor: '#f0f0f6',
        },
        {
            id: STATUS.EM_PRODUCAO,
            description: STATUS_TEXT.EM_PRODUCAO,
            cards: [
                { id: '3', draggable: false, loading: true },
                { id: '4', draggable: false, loading: true },
                { id: '5', draggable: false, loading: true },
            ],
            style: {
                backgroundColor: '#e67508',
                minWidth: '280px',
            },
            titleColor: 'white',
            subtitleColor: '#f0f0f6',
        },
        {
            id: STATUS.EM_ESTOQUE,
            description: STATUS_TEXT.EM_ESTOQUE,
            cards: [{ id: '123', draggable: false, loading: true }],
            style: {
                backgroundColor: '#f1b63a',
                minWidth: '280px',
            },
            subtitleColor: '#6f6f74',
        },
        {
            id: STATUS.EM_EXPEDICAO_TRANSITO,
            description: STATUS_TEXT.EM_EXPEDICAO_TRANSITO,
            cards: [
                { id: '6', draggable: false, loading: true },
                { id: '7', draggable: false, loading: true },
            ],
            style: {
                backgroundColor: '#005186',
                minWidth: '280px',
            },
            titleColor: 'white',
            subtitleColor: '#f0f0f6',
        },
        {
            id: STATUS.ENTREGUE,
            description: STATUS_TEXT.ENTREGUE,
            cards: [
                { id: '8', draggable: false, loading: true },
                { id: '9', draggable: false, loading: true },
                { id: '10', draggable: false, loading: true },
            ],
            style: {
                backgroundColor: '#16a074',
                minWidth: '280px',
            },
            titleColor: 'white',
            subtitleColor: '#f0f0f6',
        },
    ],
}
