import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import Colors from '../commons/Colors'

const LoadingOrderCard = () => {
    return (
        <Container>
            <Card>
                <Owner>
                    <Skeleton />
                </Owner>

                <OrderNumber>
                    <Skeleton />
                </OrderNumber>

                <LittleField>
                    <Skeleton />
                </LittleField>

                <LittleField>
                    <Skeleton />
                </LittleField>

                <Seller>
                    <Skeleton />
                </Seller>

                <DeliveryDate>
                    <Skeleton />
                </DeliveryDate>
            </Card>
        </Container>
    )
}

const Container = styled.div`
    margin-top: 6px;
    box-shadow: 0 1px 4px 0 #c7c7cc;
    background-color: white;
    border-radius: 4px;
`

const Card = styled.div`
    width: 260px;
    padding: 10px;
`

const Owner = styled.div`
    font-size: 16px;
    width: 80%;
    background-color: ${Colors.veryLightPink};
    border-radius: 4px;
`

const OrderNumber = styled.div`
    font-size: 14px;
    width: 60%;
    background-color: ${Colors.veryLightPink};
    border-radius: 4px;
    margin-top: 6px;
`

const LittleField = styled.div`
    font-size: 12px;
    width: 40%;
    background-color: ${Colors.veryLightPink};
    border-radius: 4px;
    margin-top: 6px;
`

const Seller = styled.div`
    font-size: 14px;
    width: 95%;
    background-color: ${Colors.veryLightPink};
    border-radius: 4px;
    margin-top: 6px;
`

const DeliveryDate = styled.div`
    font-size: 14px;
    width: 75%;
    background-color: ${Colors.veryLightPink};
    border-radius: 4px;
    margin-top: 6px;
`

export default LoadingOrderCard
