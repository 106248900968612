import React from 'react'
import styled from 'styled-components'
import { Column } from 'primereact/column'

import { removeDuplicatedCategories } from '../../client/utils/ClientsUtils'

import BasicPage from '../../commons/components/BasicPage'
import BasicListScreen from '../../commons/components/BasicListScreen'
import Table from '../../commons/components/Table'
import SearchNotFoundComponent from '../../commons/components/feedback/SearchNotFoundComponent'
import NoRecordsFoundComponent from '../../commons/components/feedback/NoRecordsFoundComponent'
import LoadingErrorComponent from '../../commons/components/error/LoadingErrorComponent'
import AddClientIcon from '../../assets/customIcons/AddClientIcon'

function ClientView({
    clients,
    clientsSearchTerm,
    isLoading,
    totalClients,
    handleClientsFilter,
    indexOfFirstTableItem,
    handleClientsTablePagination,
    handleClientsRowOnClick,
    isError,
}) {
    const getColumnStyle = (textAlign = 'start', width = '25%') => {
        return {
            textAlign,
            width,
        }
    }

    const isNoClientsRegistred = () =>
        Boolean(!clients.length && clientsSearchTerm === '' && !isLoading)

    const isNoClientsFound = () => Boolean(!clients.length && !isLoading)

    const renderNoBreakLineColumn = client => {
        let columnContent = []
        columnContent = removeDuplicatedCategories(client.portfolios)
        if (!columnContent.length) columnContent = ['-']

        return (
            <NoBreakLineContent>{columnContent.join(', ')}</NoBreakLineContent>
        )
    }

    const renderNameColumn = client => {
        return <NameCell>{client.owner.name}</NameCell>
    }

    const renderNoClientsFoundFeedback = () => {
        if (isNoClientsFound() && !isNoClientsRegistred() && !isError)
            return <SearchNotFoundComponent />
    }

    const renderNoClientsRegistredFeedback = () => {
        const renderText = () => {
            return (
                <>Você ainda não possui nenhum cliente cadastrado no sistema.</>
            )
        }

        const renderIcon = () => {
            return (
                <>
                    <AddClientIcon />
                </>
            )
        }

        if (isNoClientsRegistred() && !isError)
            return (
                <NoRecordsFoundComponent
                    renderIcon={renderIcon}
                    renderText={renderText}
                />
            )
    }

    const renderErrorComponent = () => {
        if (isError && !isLoading) return <LoadingErrorComponent />
    }

    const renderClientsTable = () => {
        if (!isNoClientsFound() && !isNoClientsRegistred() && !isError)
            return (
                <Table
                    value={clients}
                    loading={isLoading}
                    onPage={handleClientsTablePagination}
                    rows={10}
                    first={indexOfFirstTableItem}
                    totalRecords={totalClients}
                    totalRecordsText="{totalRecords} Clientes Encontrados"
                    onRowClick={handleClientsRowOnClick}
                    rowHover
                >
                    <Column
                        field="legacyCode"
                        header="Código"
                        style={getColumnStyle()}
                    />

                    <Column
                        field="owner.name"
                        header="Nome do Cliente"
                        body={renderNameColumn}
                        style={getColumnStyle()}
                    />

                    <Column
                        field="tradeName"
                        header="Nome Fantasia"
                        style={getColumnStyle()}
                    />

                    <Column
                        header="Carteira/Rota"
                        body={rowData => renderNoBreakLineColumn(rowData)}
                        style={getColumnStyle('center')}
                    />
                </Table>
            )
    }

    return (
        <BasicPage title="Lista de Clientes">
            <BasicListScreen
                searchValue={clientsSearchTerm}
                searchOnChange={handleClientsFilter}
                searchPlaceholder={'Pesquise por Código, Nome, Nome Fantasia'}
            >
                {renderNoClientsRegistredFeedback()}
                {renderNoClientsFoundFeedback()}
                {renderErrorComponent()}
                {renderClientsTable()}
            </BasicListScreen>
        </BasicPage>
    )
}

export default ClientView

const NameCell = styled.span``

const NoBreakLineContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
