import React from 'react'
import { useHistory } from 'react-router-dom'

import { useMillContext } from '../../context/MillContext'

import OrderModalContainer from './OrderModalContainer'

const OrderModalComponent = ({ selectedOrderId, clearSelectedOrderId }) => {
    const history = useHistory()
    const { millConfig, mill } = useMillContext()

    const onEditClick = orderId => {
        return history.push({
            pathname: `/pedidos/editar/${orderId}`,
            state: { isConfirmedOrder: true },
        })
    }

    return (
        <OrderModalContainer
            selectedOrderId={selectedOrderId}
            clearSelectedOrderId={clearSelectedOrderId}
            onEditClick={onEditClick}
            millConfig={millConfig}
            mill={mill}
        />
    )
}

export default OrderModalComponent
