import { round, parseNumber } from '../commons/NumberUtils'

export const unitPricingType = {
    reais: 0,
    percentage: 1,
}

export const calculatesPriceWithIcms = (tributes, basePrice) => {
    if (tributes?.icms) return basePrice / (1 - tributes.icms / 100)
    return basePrice
}

export const calculatesMinAndMaxPrice = (product, sellerRates, tributes) => {
    const basePriceBeforeAdjustments = parseNumber(product.price)
    const basePrice = calculatesPriceWithIcms(
        tributes,
        basePriceBeforeAdjustments
    )
    const sellerDiscountMaxPercentage = sellerRates?.discountMaxPercentage || 0
    const sellerIncreaseMaxPercentage = sellerRates?.increaseMaxPercentage || 0

    const minPrice = round(
        basePrice -
            (Number(
                parseNumber(product.discountMaxPercentage) +
                    parseNumber(sellerDiscountMaxPercentage)
            ) /
                100) *
                basePrice
    )
    const maxPrice = round(
        basePrice +
            (Number(
                parseNumber(product.increaseMaxPercentage) +
                    parseNumber(sellerIncreaseMaxPercentage)
            ) /
                100) *
                basePrice
    )
    return {
        ...product,
        minPrice: minPrice ? minPrice : 0,
        maxPrice: maxPrice ? maxPrice : 0,
    }
}

export const calculatesMinPercentage = product => {
    const discountMaxPercentage = Math.abs(
        round(
            (parseNumber(product.minPrice) / parseNumber(product.price) - 1) *
                100,
            4
        )
    )

    return {
        ...product,
        discountMaxPercentage,
    }
}

export const calculatesMaxPercentage = product => {
    const increaseMaxPercentage = round(
        (parseNumber(product.maxPrice) / parseNumber(product.price) - 1) * 100,
        4
    )

    return {
        ...product,
        increaseMaxPercentage,
    }
}

export const recalculateFields = (field, product) => {
    switch (field) {
        case 'price':
            product = calculatesMinAndMaxPrice(product)
            break
        case 'discountMaxPercentage':
            product = calculatesMinAndMaxPrice(product)
            break
        case 'increaseMaxPercentage':
            product = calculatesMinAndMaxPrice(product)
            break
        case 'maxPrice':
            product = calculatesMaxPercentage(product)
            break
        case 'minPrice':
            product = calculatesMinPercentage(product)
            break
        default:
            break
    }
    return product
}

export const validateField = (field, product) => {
    const validators = {
        priceValidator: priceValidator,
        minPriceValidator: minPriceValidator,
        maxPriceValidator: maxPriceValidator,
        discountMaxPercentageValidator: discountMaxPercentageValidator,
        increaseMaxPercentageValidator: increaseMaxPercentageValidator,
    }

    const validatorMethod = validators[`${field}Validator`]
    return validatorMethod(field, product)
}

const priceValidator = (field, product) => {
    product[`${field}IsInvalid`] = false
    const productPrice = parseNumber(product.price)

    if (productPrice < 0 || productPrice === 0) {
        product[`${field}IsInvalid`] = true
        return false
    }
    product['minPriceIsInvalid'] = false
    product['maxPriceIsInvalid'] = false
    return true
}

const minPriceValidator = (field, product) => {
    product[`${field}IsInvalid`] = false
    const productMinPrice = parseNumber(product.minPrice)
    const productPrice = parseNumber(product.price)

    if (
        productMinPrice < 0 ||
        productMinPrice > productPrice ||
        productMinPrice === 0
    ) {
        product[`${field}IsInvalid`] = true
        return false
    }
    product['discountMaxPercentageIsInvalid'] = false
    return true
}

const maxPriceValidator = (field, product) => {
    product[`${field}IsInvalid`] = false
    const productMaxPrice = parseNumber(product.maxPrice)
    const productPrice = parseNumber(product.price)

    if (
        productMaxPrice < 0 ||
        productMaxPrice < productPrice ||
        productMaxPrice === 0
    ) {
        product[`${field}IsInvalid`] = true
        return false
    }
    product['increaseMaxPercentageIsInvalid'] = false
    return true
}

const discountMaxPercentageValidator = (field, product) => {
    product[`${field}IsInvalid`] = false
    const productDiscountMax = parseNumber(product.discountMaxPercentage)

    if (productDiscountMax < 0 || productDiscountMax === 0) {
        product[`${field}IsInvalid`] = true
        return false
    }
    product['minPriceIsInvalid'] = false
    return true
}

const increaseMaxPercentageValidator = (field, product) => {
    product[`${field}IsInvalid`] = false
    const productIncreaseMax = parseNumber(product.increaseMaxPercentage)

    if (productIncreaseMax < 0 || productIncreaseMax === 0) {
        product[`${field}IsInvalid`] = true
        return false
    }
    product['maxPriceIsInvalid'] = false
    return true
}

export const validateExistsInvalidFields = (
    products,
    setIsSaveButtonDisabled
) => {
    products.some(product => {
        const attrs = Object.keys(product)
        const attrsInvalidFields = attrs.filter(attr =>
            attr.endsWith('IsInvalid')
        )
        let existsInvalidFields = false

        attrsInvalidFields.some(attr => {
            if (product[attr]) {
                existsInvalidFields = true
                return true
            }
            return false
        })

        if (existsInvalidFields) {
            setIsSaveButtonDisabled(true)
            return true
        }
        setIsSaveButtonDisabled(false)
        return false
    })
}

export const sortProducts = products => {
    return products.sort((a, b) => (a.name > b.name ? 1 : -1))
}

export const getDecimalString = (num, numDecimalDigits) => {
    const newNum = isNaN(num) ? 0 : Number(num)
    const formatedNum = newNum
        .toFixed(numDecimalDigits)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return formatedNum
}

export const getPriceString = num => {
    return 'R$' + getDecimalString(num, 2)
}

export const getDiscountString = num => {
    return num > 0 ? '+' : '-' + getDecimalString(num, 2)
}

export const mountPriceChangeRequest = products => {
    return products.map(mountPriceChange)
}

export const mountPriceChange = product => {
    return {
        productId: product.id,
        basePrice: parseNumber(product.price),
        increaseMaxPercentage: parseNumber(product.increaseMaxPercentage),
        discountMaxPercentage: parseNumber(product.discountMaxPercentage),
    }
}
