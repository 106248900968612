import React from 'react'
import styled from 'styled-components'
import * as ProductAPI from '../../price/ProductAPI'
import Colors from '../../commons/Colors'
import { DeleteIcon } from '../../commons/commonStyles'
import { PromiseWrapper } from '../../commons/PromiseUtils'
import ModalResponsive from '../../commons/components/ModalResponsive'
import AutoCompleteAsync from '../../commons/components/AutoCompleteAsync'
import RoundButton from '../../commons/components/RoundButton'

const AddProductModalComponent = ({
    isVisible,
    setIsVisible,
    onSave,
    addedProducts,
    setAddedProducts,
    onDelete,
}) => {
    const multiSelectPlaceholder = ' Selecione ou digite o nome de um produto'
    const searchSize = 20
    const searchPage = 1

    const fetchProducts = async term => {
        return PromiseWrapper({
            promise: ProductAPI.searchByLoggedUser(
                searchSize,
                searchPage,
                term
            ),
            onFulfilled: ({ data }) => {
                return data.map(product => ({
                    label: getOptionLabel(product),
                    value: {
                        id: product.id,
                        sydleId: product.sydleId,
                        name: product.name,
                        legacyCode: product.legacyCode,
                    },
                }))
            },
        })
    }

    function getOptionLabel(product) {
        return `${product.legacyCode} - ${product.name} - ${product.measurementUnit.measure} (${product.measurementUnit.unit})`
    }

    const renderSelectedProductCard = () =>
        addedProducts.map(product => (
            <ProductCardContainer key={Math.random()}>
                <ProductCard>
                    <ProductOptionText>{product.label}</ProductOptionText>
                    <ProductCardIcon onClick={() => onDelete(product.value.id)}>
                        <DeleteIcon />
                    </ProductCardIcon>
                </ProductCard>
            </ProductCardContainer>
        ))

    const onCloseModal = () => {
        if (addedProducts.length) {
            setAddedProducts(addedProducts)
        }

        setIsVisible()
    }

    return (
        <ModalResponsive
            visible={isVisible}
            onClose={onCloseModal}
            title="Adicionar Produto"
        >
            <ModalContainer>
                <ModalBody>
                    <SubtitleContainer>
                        <Subtitle>
                            Pesquise abaixo qual produto deseja adicionar
                            (busque por nome do produto ou código).
                        </Subtitle>
                    </SubtitleContainer>

                    <AutoCompleteContainer>
                        <AutoCompleteLabel htmlFor="add-product">
                            <RequiredField>*</RequiredField> Produto
                        </AutoCompleteLabel>

                        <AutoCompleteAsync
                            isMulti
                            useOrangeMode
                            useCustomInput
                            placeholder={multiSelectPlaceholder}
                            customInputText={{
                                singular: 'produto selecionado | ',
                                plural: 'produtos selecionados | ',
                            }}
                            menuDataTestId="productsAutoComplete"
                            value={addedProducts}
                            getSelectedOption={option =>
                                addedProducts.some(
                                    addedProduct =>
                                        addedProduct.value.id === option.id
                                )
                            }
                            onChange={event => setAddedProducts(event)}
                            fetchSuggestions={fetchProducts}
                        />
                    </AutoCompleteContainer>

                    {renderSelectedProductCard()}
                </ModalBody>
                <ModalFooter>
                    <CancelButtonContainer>
                        <CancelButton onClick={setIsVisible}>
                            Cancelar
                        </CancelButton>
                    </CancelButtonContainer>
                    <SaveButton
                        disabled={!addedProducts.length}
                        onClick={() => onSave(addedProducts)}
                    >
                        Salvar
                    </SaveButton>
                </ModalFooter>
            </ModalContainer>
        </ModalResponsive>
    )
}

const ModalContainer = styled.div`
    padding: 24px;
    max-width: 600px;
`

const ModalBody = styled.div``

const SubtitleContainer = styled.div``

const Subtitle = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #6c6e6f;
`

const AutoCompleteContainer = styled.div`
    padding-top: 20px;
    padding-bottom: 16px;
`
const AutoCompleteLabel = styled.label`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.09px;
    text-align: left;
    color: ${Colors.brownishGrey};
`

const RequiredField = styled.span`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    color: ${Colors.heavyRed};
`

const ProductOptionValueContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
`

const ProductCardContainer = styled.div`
    padding-bottom: 8px;
`

const ProductCard = styled(ProductOptionValueContainer)`
    border: 1px solid #eceeee;
    padding: 13px 16px 13px 8px;
    border-radius: 4px;
    background-color: #fbfbfb;
    align-items: center;
    box-shadow: 0 4px 2px -2px #eceeee;
`

const ProductOptionText = styled.span`
    font-family: Roboto;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    text-transform: capitalize;
`
const ProductCardIcon = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
`

const ModalFooter = styled.div`
    display: flex;
    padding-top: 35px;
    justify-content: flex-end;
`

const CancelButtonContainer = styled.div`
    padding-right: 12px;
`

const CancelButton = styled(RoundButton)`
    color: ${Colors.orange};
    border-color: ${Colors.orange};
    background-color: ${Colors.white};
`

const SaveButton = styled(RoundButton)`
    color: ${Colors.white};
    border-color: ${Colors.orange};
    background-color: ${Colors.orange};
`

export default AddProductModalComponent
