import { getBaseURL, getAPIKey, getToken } from '../environment'

const service = 'orders'
const endpoint = 'shipments'
const apiKey = getAPIKey()
const url = `${getBaseURL()}/${service}/${endpoint}`

export const getAllShipments = args => {
    const queryParams = new URLSearchParams(args)

    return fetch(`${url}?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            'x-api-key': apiKey,
            Authorization: `${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const createShipments = shipments => {
    const body = { shipments: shipments }

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const updateShipment = (id, statusName) => {
    return fetch(`${url}/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `${getToken()}`,
        },
        body: JSON.stringify({
            status: {
                name: statusName,
            },
        }),
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const deleteShipments = shipmentsIds => {
    const queryParams = new URLSearchParams({})
    shipmentsIds.forEach(shipmentId => queryParams.append('id', shipmentId))

    return fetch(`${url}?${queryParams.toString()}`, {
        method: 'DELETE',
        headers: {
            'x-api-key': apiKey,
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => (response.ok ? { data: null } : response.json()))
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
