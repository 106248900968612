export const getMeasurementUnitText = measurementUnit => {
    const text = `${measurementUnit.measure} (${measurementUnit.quantity} ${measurementUnit.weight})`
    return text[0].toUpperCase() + text.slice(1)
}

export const isDecimalUnitType = measurementUnit => {
    return (
        (measurementUnit.weight === 'kg' &&
            measurementUnit.measure === 'granel') ||
        (measurementUnit.weight === 'ton' &&
            measurementUnit.measure === 'granel')
    )
}

export const isRationItem = measurementUnit => {
    return (
        (measurementUnit.weight === 'kg' &&
            measurementUnit.measure === 'granel') ||
        (measurementUnit.weight === 'kg' &&
            measurementUnit.measure === 'saco') ||
        (measurementUnit.weight === 'kg' &&
            measurementUnit.measure === 'bag') ||
        (measurementUnit.weight === 'ton' &&
            measurementUnit.measure === 'granel')
    )
}

export const standardizeWeight = (measurementUnit, quantity) => {
    if (
        measurementUnit.weight === 'kg' &&
        measurementUnit.measure === 'granel'
    ) {
        return quantity / 1000
    } else if (
        measurementUnit.weight === 'kg' &&
        measurementUnit.measure === 'saco'
    ) {
        return (quantity * measurementUnit.quantity) / 1000
    } else if (
        measurementUnit.weight === 'kg' &&
        measurementUnit.measure === 'bag'
    ) {
        return (quantity * measurementUnit.quantity) / 1000
    } else if (
        measurementUnit.weight === 'ton' &&
        measurementUnit.measure === 'granel'
    ) {
        return quantity
    }
}
