import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as PromiseUtils from '../commons/PromiseUtils'

import { Label } from '../commons/commonStyles'
import AutoCompleteAsync from '../commons/components/AutoCompleteAsync'
import * as ProductAPI from '../price/ProductAPI'
import {
    convertGcpResponseToSydle,
    createAndEnrichOrderItem,
    mountProductDropdownOption,
    mountProductDropdownOptions,
    replaceOldOrderItems,
    sortProductsByName,
} from './OrderFormScreenUtils'
import ProductCard from './ProductCard'

const OrderItems = ({
    order,
    orderItems,
    setOrder,
    tributes,
    freightTable,
    sellerRates,
}) => {
    const [fetchedProducts, setFetchedProducts] = useState([])

    const fetchProducts = async term => {
        return await PromiseUtils.PromiseWrapper({
            promise: ProductAPI.searchByLoggedUser(10, 0, term),
            onFulfilled: ({ data }) => {
                const productsSydleDTO = convertGcpResponseToSydle(data)
                setFetchedProducts(prev => [...prev, ...productsSydleDTO])
                const options = mountProductDropdownOptions(productsSydleDTO)
                return sortProductsByName(options)
            },
        })
    }

    useEffect(() => {
        setOrder('items', replaceOldOrderItems(fetchedProducts, orderItems))
        setOrder('updatedProducts', fetchedProducts)
    }, [fetchedProducts])

    const onChangeOrderItem = changedOrderItem => {
        const updatedOrderItems = orderItems.reduce(
            (updatedItems, orderItem) => {
                const isChanged =
                    orderItem.product._id === changedOrderItem.product._id

                return [
                    ...updatedItems,
                    isChanged ? changedOrderItem : orderItem,
                ]
            },
            []
        )

        setOrder('items', updatedOrderItems)
    }

    const getSelectedOption = product => {
        return orderItems.some(
            orderItem => orderItem.product._id === product._id
        )
    }

    const onAddProduct = addedProduct => {
        const isAlreadySelected = getSelectedOption(addedProduct)
        if (isAlreadySelected) {
            return onRemoveProduct(addedProduct)
        }

        setOrder('items', [
            ...orderItems,
            createAndEnrichOrderItem(addedProduct),
        ])
    }

    const onRemoveProduct = removedProduct => {
        setOrder(
            'items',
            orderItems.filter(item => item.product._id !== removedProduct._id)
        )
    }

    const handleAutoCompleteActions = (_selectedOptions, payload) => {
        switch (payload.action) {
            case 'remove-value':
            case 'pop-value':
                onRemoveProduct(payload.removedValue.value)
                return
            case 'clear':
                setOrder('items', [])
                return
            default:
                onAddProduct(payload.option.value)
                return
        }
    }

    function parseOrderItemsToProductOptions(orderItems) {
        return orderItems.map(orderItem =>
            mountProductDropdownOption(orderItem.product)
        )
    }

    return (
        <>
            <Label>ADICIONAR PRODUTOS</Label>

            <AutoCompleteAsync
                isMulti
                useOrangeMode
                useCustomInput
                placeholder="Selecione os produtos do pedido..."
                customInputText={{
                    singular: 'produto selecionado | ',
                    plural: 'produtos selecionados | ',
                }}
                getSelectedOption={getSelectedOption}
                value={parseOrderItemsToProductOptions(orderItems)}
                onChange={handleAutoCompleteActions}
                fetchSuggestions={fetchProducts}
                getCustomStyle={{
                    container: styles => ({ ...styles, width: '600px' }),
                }}
            />

            <ProductsContainer>
                {orderItems.map(orderItem => (
                    <ProductCard
                        key={orderItem.product._id}
                        orderItem={orderItem}
                        onRemove={onRemoveProduct}
                        onChange={onChangeOrderItem}
                        sellerRates={sellerRates}
                        tributes={tributes}
                        freightTable={freightTable}
                        order={order}
                        isDisabled={!orderItem.product.active}
                    />
                ))}
            </ProductsContainer>
        </>
    )
}

const ProductsContainer = styled.div`
    display: grid;
    margin-top: 4.2vh;
    grid-row-gap: 2vh;
`

export default OrderItems
