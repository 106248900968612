import React from 'react'

import ProductCardContainer from './ProductCardContainer'

const ProductCardComponent = ({
    order,
    orderItem,
    onRemove,
    onChange,
    freightTable,
    sellerRates,
    tributes,
    isDisabled,
}) => {
    return (
        <ProductCardContainer
            order={order}
            orderItem={orderItem}
            onRemove={onRemove}
            onChange={onChange}
            freightTable={freightTable}
            sellerRates={sellerRates}
            tributes={tributes}
            isDisabled={isDisabled}
        />
    )
}

export default ProductCardComponent
