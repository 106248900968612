import React from 'react'
import styled from 'styled-components'
import Colors from '../Colors'
import { Calendar } from 'primereact/calendar'
import FieldErrorMessageComponent from './error/FieldErrorMessageComponent'

/**
 * @param {string} name - nome do componente
 * @param {boolean} calendarIcon - renderiza o icone de calendario
 * @param {string} value - valor do input do componente
 * @param {function} onChange - evento chamado quando o valor muda
 * @param {boolean} disabled - renderiza o componente desabilitado ou não
 * @param {boolean} readOnlyInput - controla a insersão manual da data via texto
 * @param {boolean} isInvalidDate - quando a data é invalida
 * @param {date} maxDate - máxima data para ser selecionada
 * @param {string} dateIconColor - cor do icone da data
 * @param {string} placeholderColor - cor do placeholder
 * @param {string} view - tipo de visualização
 */

export function DatePickerCalendar({
    id,
    className,
    name,
    calendarIcon = true,
    value,
    onFocus,
    onChange,
    placeholder = '__ / __ / ____',
    disabled = false,
    readOnlyInput = false,
    minDate,
    maxDate,
    showButtonBar = false,
    todayButtonClassName,
    clearButtonClassName,
    isInvalidDate = false,
    invalidMessage,
    placeholderColor = '#C7C7CC',
    dateIconColor = '#666666',
    yearNavigator,
    yearRange,
    monthNavigator,
    view,
}) {
    // prettier-ignore
    const pt = {
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        today: 'Hoje',
        clear: 'Limpar',
    }

    return (
        <Container
            isInvalidDate={isInvalidDate}
            placeholderColor={placeholderColor}
            dateIconColor={dateIconColor}
        >
            <Calendar
                onFocus={onFocus}
                inputId={id}
                className={className}
                name={name}
                value={value}
                onChange={onChange}
                locale={pt}
                dateFormat="dd/mm/yy"
                showIcon={calendarIcon}
                placeholder={placeholder}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                readOnlyInput={readOnlyInput}
                showButtonBar={showButtonBar}
                clearButtonClassName={clearButtonClassName}
                todayButtonClassName={todayButtonClassName}
                yearNavigator={yearNavigator}
                yearRange={yearRange}
                monthNavigator={monthNavigator}
                view={view}
            />
            <FieldErrorMessageComponent
                isInvalid={isInvalidDate}
                message={invalidMessage}
            />
        </Container>
    )
}

const Container = styled.div`
    .p-calendar {
        width: 100% !important;
    }

    & .p-inputwrapper-focus {
        transition: box-shadow 0.2s;
        border-radius: 4px;
        box-shadow: 0 0 0 0.2em #e4e9ec;
    }

    .p-inputtext {
        background-color: ${Colors.white};
        height: 40px;
        width: 80%;
        box-shadow: none;
        border-radius: 4px;
        border-color: ${props => props.isInvalidDate && 'red'};
    }

    .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
        background-color: ${Colors.white};
    }

    .p-button-icon-only .p-button-icon-left {
        color: ${Colors.brownishGrey};
        font-size: 25px;
    }

    .pi.pi-calendar.p-c.p-button-icon-left {
        color: ${props =>
            props.isInvalidDate ? Colors.strawberry : props.dateIconColor};
    }

    .p-inputtext.p-component.p-inputtext.p-component {
        color: ${props =>
            props.isInvalidDate ? Colors.strawberry : props.placeholderColor};
        ::placeholder {
            color: ${props =>
                props.isInvalidDate
                    ? Colors.strawberry
                    : props.placeholderColor};
        }
    }

    .p-button.p-component.p-datepicker-trigger.p-calendar-button.p-button-icon-only {
        border-color: ${props =>
            props.isInvalidDate ? Colors.strawberry : '#dadada'};
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        width: 20% !important;
    }

    .p-inputtext:enabled:hover,
    .p-button:enabled:hover {
        border-color: ${props =>
            props.isInvalidDate ? Colors.strawberry : '#dadada'};
    }

    .p-inputtext:enabled:focus,
    .p-button:enabled:focus {
        box-shadow: none;
        border-color: ${props =>
            props.isInvalidDate ? Colors.strawberry : '#dadada'};
    }
`

export default DatePickerCalendar
