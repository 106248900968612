import React from 'react'

const FragmentOrderIcon = ({ color = '#333', backgroundColor = '#C6CBD4' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
    >
        <rect width="17" height="17" rx="2" fill={backgroundColor} />
        <path
            d="m4.101 15.307-1.119-.864L12.9 1.693l1.12.864L4.1 15.307zM2.833 1.417v1.416H4.25V8.5h1.417V1.417H2.834zM10.625 8.5v1.417h2.834v1.416h-1.417a1.42 1.42 0 0 0-1.417 1.417v2.833h4.25v-1.416h-2.833V12.75h1.417c.786 0 1.416-.63 1.416-1.417V9.917A1.417 1.417 0 0 0 13.46 8.5h-2.834z"
            fill={color}
        />
    </svg>
)

export default FragmentOrderIcon
