import React from 'react'
import styled from 'styled-components'

export const HeaderModal = ({ title, children }) => {
    return (
        <Header>
            <Title>{title}</Title>
            {children}
        </Header>
    )
}

const Header = styled.div`
    width: 100%;
    height: 73px;
    border-radius: 4px;
    background-color: rgba(241, 141, 58, 0.15);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`

const Title = styled.span`
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: #005186;
    margin-left: 17px;

    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
`

export default HeaderModal
