export const getPortfolioUsers = (portfolio, users) => {
    const inPortfolioUsers = {}
    portfolio.users.forEach(
        user =>
            (inPortfolioUsers[user.id] = { name: user.name, inPortfolio: true })
    )
    const dropdownOptions = getUsersDropdownOptions(users, inPortfolioUsers)
    return { inPortfolioUsers, dropdownOptions }
}
export const getUsersDropdownOptions = (users, inPortfolioUsers) => {
    return users
        .filter(user =>
            inPortfolioUsers ? !inPortfolioUsers[user.gcpId] : user
        )
        .map(seller => ({
            label: seller.name,
            value: seller.gcpId,
            inPortfolio: false,
        }))
}

export const getPortfolioClients = portfolio => {
    if (!portfolio) return {}

    const { clients } = portfolio
    const associatedClients = {}
    ;(clients || []).forEach(client => {
        const { id, tradeName, companyName } = client
        associatedClients[id] = {
            isSelected: false,
            tradeName,
            companyName,
            inPortfolio: true,
        }
    })

    return sortByKey(associatedClients, 'tradeName')
}

export const getNonAssociatedClients = (clients, associatedClients) => {
    const newNonAssociatedClients = {}

    clients
        .filter(client =>
            associatedClients ? !associatedClients[client.id] : client
        )
        .forEach(client => {
            newNonAssociatedClients[client.id] = {
                isSelected: false,
                tradeName: client.tradeName,
                companyName: client.companyName,
                inPortfolio: false,
            }
        })
    return newNonAssociatedClients
}

export const buildCreatePortfolioRequest = (form, mill) => {
    const clientsIds = Object.keys(form.clients)
    const usersIds = Object.keys(form.users)
    return {
        name: form.name,
        millId: mill._id,
        clients: clientsIds.map(id => ({ id })),
        users: usersIds.map(id => ({ id })),
    }
}

export const buildFindDuplicateRelationRequest = (
    clients,
    mill,
    form,
    portfolio
) => {
    const clientsIds = Object.keys(clients)
    const usersIds = Object.keys(form.users)
    return {
        id: portfolio.id,
        name: form.name || 'Validate Duplicate Relation',
        millId: mill._id,
        clients: clientsIds.map(id => ({ id })),
        users: usersIds.map(id => ({ id })),
    }
}

export const buildUpdatePortfolioRequest = (currentPortfolio, form) => {
    const clientsIdsToUpdate = Object.entries(form.clients)
        .filter(([id, client]) => !client.inPortfolio)
        .map(([id]) => id)

    const usersIdsToUpdate = Object.entries(form.users)
        .filter(([id, client]) => !client.inPortfolio)
        .map(([id]) => id)

    const clientsIdsToRemove = currentPortfolio.clients
        .filter(client => !Object.keys(form.clients).includes(client.id))
        .map(client => client.id)

    const usersIdsToRemove = currentPortfolio.users
        .filter(user => !Object.keys(form.users).includes(user.id))
        .map(user => user.id)

    return [
        {
            name: form.name,
            clientPortfolioId: currentPortfolio.id,
            clients: {
                remove: clientsIdsToRemove,
                add: clientsIdsToUpdate,
            },
            users: {
                remove: usersIdsToRemove,
                add: usersIdsToUpdate,
            },
        },
    ]
}

export const sortByKey = (jsObj, orderKey) => {
    const arr = Object.entries(jsObj)
        .map(([key, value]) => ({ key, value }))
        .sort((a, b) => a.value[orderKey].localeCompare(b.value[orderKey]))

    return Object.fromEntries(arr.map(({ key, value }) => [key, value]))
}
