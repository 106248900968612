import React from 'react'
import styled from 'styled-components'
import { useDashboardContext } from '../context/DashboardContext'
import * as DateUtils from '../commons/DateUtils'
import Colors from '../commons/Colors'
import FragmentOrderButton from '../commons/components/FragmentOrderButton'
import OrderCardIndicatorsComponent from './OrderCardIndicatorsComponent'
import { isOrderOutOfPricePolicy } from './DashboardScreenUtils'

const FragmentedOrderCardComponent = props => {
    const { setSelectedOrderCard } = useDashboardContext()

    const onSelectOrder = id => {
        if (!props.isOrdersDropdown) {
            setSelectedOrderCard(id)
        }
    }

    return (
        <Container
            isOrderGroup={props.isOrderGroup}
            data-testid="card-container"
            onClick={() => onSelectOrder(props.orderId)}
        >
            <UrgentContainer isUrgent={props.isUrgent}>
                <Card>
                    <SpaceBetween>
                        <Owner>{props.farm.owner.name}</Owner>
                    </SpaceBetween>

                    <ActionsContainer>
                        <IndicatorsContainer>
                            <OrderCardIndicatorsComponent
                                isDebtorClient={props.farm.debtor}
                                isOutOfPricePolicy={isOrderOutOfPricePolicy(
                                    props,
                                    props.status.name
                                )}
                            />
                        </IndicatorsContainer>

                        {!props.isOrderGroup && (
                            <FragmentOrderButton
                                fragmentedOrder={{
                                    orderId: props.orderId,
                                    fragmentedOrderId: props.id,
                                }}
                                backgroundColor={'#f7a6a0'}
                            />
                        )}
                    </ActionsContainer>

                    <SpaceBetween>
                        <OrderNumber>
                            Pedido Nº {props.orderNumber}/{props.number}
                        </OrderNumber>
                    </SpaceBetween>

                    <BasicText>{props.farm.fancyName}</BasicText>

                    <SpaceBetween>
                        <BasicText>{props.category.name}</BasicText>
                    </SpaceBetween>

                    <BasicText>
                        Feito por <strong>{props.seller.name}</strong> em{' '}
                        <strong>
                            {DateUtils.dateFormat(props.requestDate)}
                        </strong>
                    </BasicText>
                    <Delivery>
                        <DeliveryLabel>Data de Entrega:</DeliveryLabel>
                        <DeliveryDate>
                            {DateUtils.dateFormat(props.deliveryDate)}
                        </DeliveryDate>
                    </Delivery>
                </Card>
            </UrgentContainer>
        </Container>
    )
}

const Container = styled.div`
    margin-top: ${props => (props.isOrderGroup ? 0 : '10px')};
    box-shadow: ${props =>
        props.isOrderGroup ? 'none' : '0 1px 4px 0 #c7c7cc'};
    background-color: #fef6f5;
    border-radius: 4px;
    border-style: dashed;
    border-color: red;
    border-width: 1px;
    position: relative;
`

const UrgentContainer = styled(Container)`
    border-width: ${props => (props.isUrgent ? '0 0 0 8px' : 'none')};
    border-color: ${props => (props.isUrgent ? '#9f1111' : 'white')};
    border-style: ${props => (props.isUrgent ? 'solid' : 'none')};
    margin-top: 0;
`

const Card = styled.div`
    width: 260px;
    padding: 10px;
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 8px;

    position: absolute;
    right: 0;
    top: 0;
`

const BasicText = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.21px;
    color: #8e8e93;
    white-space: break-spaces;
`

const Owner = styled(BasicText)`
    font-size: 16px;
    font-weight: bold;
    color: ${Colors.black};
    max-width: 220px;
`

const SpaceBetween = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const OrderNumber = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.black};
`

const IndicatorsContainer = styled.div`
    display: flex;
    flex-direction: 'row';
    justify-content: flex-end;
`

const Delivery = styled.div`
    display: flex;
`

const DeliveryLabel = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.black};
    margin-right: 5px;
`

const DeliveryDate = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: #eb394b;
`
export default FragmentedOrderCardComponent
