import React from 'react'
import { useHistory } from 'react-router-dom'

import ProductUsageContainer from './ProductUsageContainer'
import { useMillContext } from '../../context/MillContext'

function ClientScreen() {
    const history = useHistory()
    const { mill } = useMillContext()

    const clientName = history.location.state.clientName
    const clientId = history.location.state.clientId

    const clientData = {
        clientName,
        clientId,
    }

    const onBack = () => history.push('/')

    return (
        <ProductUsageContainer
            onBack={onBack}
            clientData={clientData}
            millData={{ millSydleId: mill._id, millId: mill.gcpId }}
        />
    )
}

export default ClientScreen
