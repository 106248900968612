import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'

import { MillContextProvider } from './context/MillContext'

import PrivateRoute from './login/PrivateRoute'
import AuthorizationRoute from './login/AuthorizationRoute'

import { interceptor } from './commons/Interceptor'

import LoginScreen from './login/LoginScreen'

import ClientScreen from './productUsage/ClientScreen'
import ProductUsageScreen from './productUsage/ProductUsageScreen'

import PriceChangeScreen from './price/PriceChangeScreen'
import ProductListScreen from './product/ProductListScreen'
import ProductManagementScreen from './product/ProductManagementScreen'

import PortfolioListScreen from './portfolio/PortfolioListScreen'
import PortfolioFormScreen from './portfolio/PortfolioFormScreen'

import ClientListScreen from './client/ClientListScreen'
import ClientFormScreen from './client/ClientFormScreen'

import PendingOrderListScreen from './order/PendingOrderListScreen'
import OrderFormScreen from './order/OrderFormScreen'
import OrderListScreen from './order/OrderListScreen'

import HomeScreen from './home'

const Routes = () => {
    useEffect(() => {
        interceptor()
    })

    return (
        <MillContextProvider>
            <Switch>
                <Route
                    path="/login"
                    exact={true}
                    render={() => (
                        <SimpleContainer>
                            <LoginScreen />
                        </SimpleContainer>
                    )}
                />

                <Route path="/" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute isStcRoute={false}>
                                <SimpleContainer>
                                    <ClientScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/estoque-virtual/:id" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute isStcRoute={false}>
                                <SimpleContainer>
                                    <ProductUsageScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/home/gestao-pedidos" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <HomeScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/home/simples-faturamentos" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <HomeScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/precos/alteracao" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <PriceChangeScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/produtos" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <ProductListScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/produtos/gestao/novo" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <ProductManagementScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/produtos/gestao/editar/:id" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <ProductManagementScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/carteiras" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <PortfolioListScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/carteiras/novo" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <PortfolioFormScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/carteiras/editar/:id" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <PortfolioFormScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/clientes" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <ClientListScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/clientes/novo" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <ClientFormScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/clientes/editar/:id" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <ClientFormScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route
                    path="/pedidos"
                    exact={true}
                    render={() => (
                        <AuthorizationRoute>
                            <SimpleContainer>
                                <PendingOrderListScreen />
                            </SimpleContainer>
                        </AuthorizationRoute>
                    )}
                />

                <Route
                    path="/pedidos/historico"
                    exact={true}
                    render={() => (
                        <AuthorizationRoute>
                            <SimpleContainer>
                                <OrderListScreen />
                            </SimpleContainer>
                        </AuthorizationRoute>
                    )}
                />

                <Route
                    path="/pedidos/novo"
                    exact={true}
                    render={() => (
                        <AuthorizationRoute>
                            <SimpleContainer>
                                <OrderFormScreen />
                            </SimpleContainer>
                        </AuthorizationRoute>
                    )}
                />

                <Route path="/pedidos/editar/:id" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <OrderFormScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>

                <Route path="/simples-faturamento/novo" exact={true}>
                    <PrivateRoute
                        render={() => (
                            <AuthorizationRoute>
                                <SimpleContainer>
                                    <OrderFormScreen />
                                </SimpleContainer>
                            </AuthorizationRoute>
                        )}
                    />
                </Route>
            </Switch>
        </MillContextProvider>
    )
}

const SimpleContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export default Routes
