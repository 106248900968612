import React from 'react'
import styled from 'styled-components'

import * as NumberUtils from '../commons/NumberUtils'
import { sumTotalWeight } from '../orderGroup/OrderGroupUtils'

const CustomLaneHeader = ({
    id,
    description,
    cards,
    titleColor = 'black',
    subtitleColor = 'black',
}) => {
    const countOrdersInLane = cards => {
        const cardsCount = cards.reduce((count, card) => {
            if (card.isOrderGroup) {
                return count + card.orders.length
            }

            return count + 1
        }, 0)

        return getOrderQuatityText(cardsCount)
    }

    const sumWeightOrdersInLane = cards => {
        const totalWeight = cards.reduce((weight, card) => {
            if (card.isOrderGroup) {
                const totalWeightOnGroup = sumTotalWeight(card)
                return weight + totalWeightOnGroup
            }

            return weight + (card.weightInTonnes || 0)
        }, 0)

        return `Total: ${NumberUtils.formatDecimalPlace(totalWeight)} ton.`
    }

    const getOrderQuatityText = quantity => {
        if (quantity === 1) {
            return `${quantity} Pedido`
        }
        return `${quantity} Pedidos`
    }

    return (
        <Card data-testid="head-container">
            <Title color={titleColor}>{description}</Title>

            <SpaceBetween>
                <Info color={subtitleColor}>{countOrdersInLane(cards)}</Info>
                <Info color={subtitleColor}>
                    {sumWeightOrdersInLane(cards)}
                </Info>
            </SpaceBetween>
        </Card>
    )
}

const Card = styled.div`
    display: flex;
    flex-direction: column;
`

const Title = styled.h1`
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: ${props => props.color};
`

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
`

const Info = styled.span`
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: ${props => props.color};
`

export default CustomLaneHeader
