import { getStatusId } from '../environment'

export const STATUS = {
    EM_ANALISE: 'EM_ANALISE',
    EM_PRODUCAO: 'EM_PRODUCAO',
    EM_ESTOQUE: 'EM_ESTOQUE',
    EM_EXPEDICAO_TRANSITO: 'EM_EXPEDICAO_TRANSITO',
    ENTREGUE: 'ENTREGUE',
    CANCELADO: 'CANCELADO',
}

export const STATUS_TEXT = {
    EM_ANALISE: 'Em Análise',
    EM_PRODUCAO: 'Em Produção',
    EM_ESTOQUE: 'Em Estoque',
    EM_EXPEDICAO_TRANSITO: 'Em Expedição / Trânsito',
    ENTREGUE: 'Entregue',
    CANCELADO: 'Cancelado',
}

export const SIMPLE_BILLING_STATUS_TEXT = {
    ENTREGUE: 'Entregue',
    PENDENTE: 'Pendente',
}

const statusIds = getStatusId()

export const STATUS_OPTIONS = [
    {
        label: 'Em Análise',
        value: statusIds.EM_ANALISE,
    },
    {
        label: 'Em Produção',
        value: statusIds.EM_PRODUCAO,
    },
    {
        label: 'Em Estoque',
        value: statusIds.EM_ESTOQUE,
    },
    {
        label: 'Em Expedição / Trânsito',
        value: statusIds.EM_EXPEDICAO_TRANSITO,
    },
    {
        label: 'Entregue',
        value: statusIds.ENTREGUE,
    },
    {
        label: 'Cancelado',
        value: statusIds.CANCELADO,
    },
]

export const STATUS_OPTIONS_REPORT = [
    {
        label: 'Em Análise',
        value: { id: 'c88f40b0-071e-4d5d-b718-ee6e9f933344' },
    },
    {
        label: 'Em Produção',
        value: { id: '6d8aa29d-dd9a-403d-ab34-9b9d600cae82' },
    },
    {
        label: 'Em Estoque',
        value: { id: '39e6ff0d-4715-4862-aa90-086a04f700e8' },
    },
    {
        label: 'Em Expedição / Trânsito',
        value: { id: '4eba1ff4-0fd2-404f-abc2-6cb856ad82f0' },
    },
    {
        label: 'Entregue',
        value: { id: '02527cdc-943b-458f-bca2-22223c4a8101' },
    },
    {
        label: 'Cancelado',
        value: { id: '076820e4-7cdf-47cb-9139-3579f87101d3' },
    },
]

export const STATUS_OPTIONS_AND_TEXT = {
    EM_ANALISE: {
        value: STATUS.EM_ANALISE,
        label: STATUS_TEXT.EM_ANALISE,
    },
    EM_PRODUCAO: {
        value: STATUS.EM_PRODUCAO,
        label: STATUS_TEXT.EM_PRODUCAO,
    },
    EM_ESTOQUE: {
        value: STATUS.EM_ESTOQUE,
        label: STATUS_TEXT.EM_ESTOQUE,
    },
    EM_EXPEDICAO_TRANSITO: {
        value: STATUS.EM_EXPEDICAO_TRANSITO,
        label: STATUS_TEXT.EM_EXPEDICAO_TRANSITO,
    },
    ENTREGUE: {
        value: STATUS.ENTREGUE,
        label: STATUS_TEXT.ENTREGUE,
    },
}
