import React, { useEffect } from 'react'
import styled from 'styled-components'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import Colors from '../commons/Colors'
import RadioButton from '../commons/components/RadioButton'
import { Label } from '../commons/commonStyles'
import bTooltip from '../commons/components/Tooltip'

export default function ChapaOptionsComponent({
    setNeedsChapa,
    needsChapa,
    disabled,
}) {
    const chapaOptions = [
        { labelText: 'Sim', value: true },
        { labelText: 'Não', value: false },
    ]

    const handleOnChange = value => {
        setNeedsChapa('needsChapa', value)
    }

    useEffect(() => {
        if (disabled) setNeedsChapa('needsChapa', false)
    }, [disabled]) // eslint-disable-line

    return (
        <Content>
            <Tooltip
                label={
                    <TooltipLabel>
                        A opção encontra-se disponível apenas para fretes na
                        modalidade CIF.
                    </TooltipLabel>
                }
                position="top"
            >
                <Label>
                    Contratar Chapa <TooltipIcon size={18} />
                </Label>
            </Tooltip>

            <Options>
                {chapaOptions.map(chapaOption => (
                    <Option key={`${chapaOption.value}-chapa`}>
                        <RadioButton
                            id="chapaOption"
                            name="chapaOption"
                            value={chapaOption.value}
                            checked={needsChapa === chapaOption.value}
                            onChange={e => handleOnChange(e.value)}
                            disabled={disabled}
                            ariaLabelledBy={`chapaOption${chapaOption.labelText}Label`}
                        />
                        <OptionLabel
                            htmlFor="chapaOption"
                            id={`chapaOption${chapaOption.labelText}Label`}
                        >
                            {chapaOption.labelText}
                        </OptionLabel>
                    </Option>
                ))}
            </Options>
        </Content>
    )
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    background-color: ${Colors.white};
`

const Options = styled.div`
    display: grid;
    grid-row-gap: 2.8vh;
    margin-top: 1.68vh;
`

const Option = styled.div`
    display: flex;
    flex-direction: row;
`

const OptionLabel = styled.label`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: ${Colors.brownishGrey};
`
const Tooltip = styled(bTooltip)`
    justify-content: flex-start;
`

const TooltipLabel = styled.p`
    max-width: 175px;
`

const TooltipIcon = styled(AiOutlineInfoCircle)`
    position: relative;
    top: 4px;
`
