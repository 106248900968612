import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Dropdown from '../commons/components/Dropdown'
import Loading from '../commons/components/Loading'

import { MdDone } from 'react-icons/md'
import RoundButton from '../commons/components/RoundButton'
import InputWithMessage from '../commons/components/InputWithMessage'
import Checkbox from '../commons/components/Checkbox'
import Colors from '../commons/Colors'
import { isProductValid } from './ProductUtils'
import { Label } from '../commons/commonStyles'
import { MessageType, addMessage } from '../commons/MessageUtils'
import Tooltip from '../commons/components/Tooltip'
import InfoIcon from '../assets/customIcons/InfoIcon'

const ProductForm = ({
    saveProduct,
    buttonLoading,
    initialValues,
    measurementUnitOptions,
    productClassificationOptions,
}) => {
    const [form, setForm] = useState(initialValues)
    const [loadingForm, setLoadingForm] = useState(true)
    const [invalidFields, setInvalidFields] = useState({
        legacyCode: false,
        name: false,
        price: false,
        weight: false,
        measurementUnitId: false,
    })

    useEffect(() => {
        setForm(initialValues)

        if (!measurementUnitOptions.length) {
            setLoadingForm(true)
            return
        }

        setLoadingForm(false)
    }, [initialValues, measurementUnitOptions])

    useEffect(() => {
        if (isGranelProduct(form)) {
            setForm({
                ...form,
                weight: 1,
            })
        }

        // eslint-disable-next-line
    }, [form.measurementUnitId])

    const handleOnPressSave = e => {
        if (!isProductValid(form)) {
            const errorMessage =
                'Erro, existem campos obrigatórios sem preenchimento ou preenchidos de forma incorreta.'

            e.preventDefault()
            addMessage(MessageType.ERROR, errorMessage)
            setInvalidFields({
                legacyCode: !Boolean(form.legacyCode),
                name: !Boolean(form.name),
                price: !Boolean(form.price),
                weight: !Boolean(form.weight > 0),
                measurementUnitId: !Boolean(form.measurementUnitId),
            })
        } else {
            saveProduct(form)
        }
    }

    const handleFormChange = (e, value = 'value') => {
        setForm({
            ...form,
            [e.target.name]: e.target[value],
        })
    }

    const isGranelProduct = product => {
        const measurementUnit = measurementUnitOptions.find(
            option => option.value === product.measurementUnitId
        )
        if (measurementUnit && measurementUnit.label.indexOf('granel') !== -1)
            return true
        return false
    }

    return (
        <Container>
            {loadingForm ? (
                <Loading data- testid="form-loading" />
            ) : (
                <>
                    <Form>
                        <UpperForm>
                            <ProductField>
                                <Label htmlFor="legacyCode">
                                    * CÓDIGO DO PRODUTO
                                </Label>
                                <InputWithMessage
                                    id="legacyCode"
                                    type="text"
                                    name="legacyCode"
                                    value={form.legacyCode}
                                    onChange={handleFormChange}
                                    isInvalid={invalidFields.legacyCode}
                                    onFocus={() =>
                                        setInvalidFields(
                                            currentInvalidFields => ({
                                                ...currentInvalidFields,
                                                legacyCode: false,
                                            })
                                        )
                                    }
                                />
                            </ProductField>

                            <ProductField>
                                <LabelProductName htmlFor="name">
                                    * NOME DO PRODUTO
                                </LabelProductName>
                                <InputWithMessage
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={form.name}
                                    onChange={handleFormChange}
                                    isInvalid={invalidFields.name}
                                    onFocus={() =>
                                        setInvalidFields(
                                            currentInvalidFields => ({
                                                ...currentInvalidFields,
                                                name: false,
                                            })
                                        )
                                    }
                                />
                            </ProductField>

                            <ProductField>
                                <Label htmlFor="description">DESCRIÇÃO</Label>
                                <InputWithMessage
                                    id="description"
                                    placeholder={'Informações sobre o produto'}
                                    name="description"
                                    value={form.description}
                                    onChange={handleFormChange}
                                />
                            </ProductField>
                        </UpperForm>

                        <BottomForm>
                            <ProductField>
                                <Label htmlFor="category">CATEGORIA</Label>
                                <InputWithMessage
                                    id="category"
                                    placeholder={'Qualificação do produto'}
                                    name="category"
                                    value={form.category}
                                    onChange={handleFormChange}
                                />
                            </ProductField>

                            <ProductField>
                                <Label
                                    htmlFor="productClassification"
                                    id="productClassificationLabel"
                                >
                                    CLASSIFICAÇÃO DO PRODUTO
                                </Label>
                                <Dropdown
                                    ariaLabelledBy="productClassificationLabel"
                                    id="productClassification"
                                    value={form.classificationId}
                                    name={'classificationId'}
                                    options={productClassificationOptions}
                                    onChange={handleFormChange}
                                    placeholder={'Selecione a classificação'}
                                />
                            </ProductField>

                            <ProductField>
                                <Label htmlFor="price">* PREÇO</Label>
                                <InputWithMessage
                                    id="price"
                                    placeholder={'R$'}
                                    type="number"
                                    name="price"
                                    value={form.price}
                                    onChange={handleFormChange}
                                    isInvalid={invalidFields.price}
                                    onFocus={() =>
                                        setInvalidFields(
                                            currentInvalidFields => ({
                                                ...currentInvalidFields,
                                                price: false,
                                            })
                                        )
                                    }
                                />
                            </ProductField>

                            <ProductField>
                                <Label
                                    htmlFor="measurementUnitId"
                                    id="measurementUnitIdLabel"
                                >
                                    * UNIDADE DE MEDIDA
                                </Label>
                                <Dropdown
                                    ariaLabelledBy="measurementUnitIdLabel"
                                    id="measurementUnitId"
                                    type="text"
                                    name="measurementUnitId"
                                    value={form.measurementUnitId}
                                    options={measurementUnitOptions}
                                    onChange={handleFormChange}
                                    placeholder="Selecione o peso"
                                    isInvalid={invalidFields.measurementUnitId}
                                    onFocus={() =>
                                        setInvalidFields(
                                            currentInvalidFields => ({
                                                ...currentInvalidFields,
                                                measurementUnitId: false,
                                            })
                                        )
                                    }
                                />
                            </ProductField>

                            <ProductField>
                                <Label htmlFor="quantity">* QUANTIDADE</Label>
                                <InputWithMessage
                                    id="quantity"
                                    type="number"
                                    name="weight"
                                    value={form.weight}
                                    onChange={handleFormChange}
                                    placeholder={'Quantidade'}
                                    readOnly={isGranelProduct(form)}
                                    isInvalid={invalidFields.weight}
                                    onFocus={() =>
                                        setInvalidFields(
                                            currentInvalidFields => ({
                                                ...currentInvalidFields,
                                                weight: false,
                                            })
                                        )
                                    }
                                />
                            </ProductField>
                        </BottomForm>
                    </Form>
                    <Row>
                        <TrackStock>
                            <Checkbox
                                onChange={e => handleFormChange(e, 'checked')}
                                name="trackStock"
                                checked={form.trackStock}
                            />
                            <TrackStockMessage>
                                * ACOMPANHAR ESTOQUE DESTE PRODUTO
                            </TrackStockMessage>
                        </TrackStock>
                        <TrackStock>
                            <Checkbox
                                onChange={e => handleFormChange(e, 'checked')}
                                name="active"
                                checked={form.active}
                            />
                            <TrackStockMessage>
                                PRODUTO DESABILITADO
                            </TrackStockMessage>
                            <Tooltip
                                label={
                                    <TooltipLabel>
                                        Sinaliza que este produto não está
                                        disponível para venda por questões de
                                        logística, falta de matéria-prima,
                                        produção ou até mesmo indisponibilidade
                                        no estoque da loja.
                                    </TooltipLabel>
                                }
                            >
                                <InfoIcon />
                            </Tooltip>
                        </TrackStock>
                    </Row>
                    <SaveContainer>
                        <RoundButton
                            type="submit"
                            loading={buttonLoading}
                            onClick={handleOnPressSave}
                        >
                            SALVAR <MdDone />
                        </RoundButton>
                    </SaveContainer>
                </>
            )}
        </Container>
    )
}

const Container = styled.div`
    flex: 1;
`

const Form = styled.div`
    border-radius: 3px;
    border: solid 1px #eceeee;
    box-sizing: border-box;
    display: grid;
    background-color: ${Colors.white};
    padding: 0 1.36vw 2.87vh 1.36vw;
`

const ProductField = styled.span``

const UpperForm = styled.div`
    display: grid;
    grid-template-columns: 20% 38% 38%;
    grid-gap: 2%;
    justify-content: stretch;
    margin-top: 20px;
    margin-bottom: 20px;
`

const BottomForm = styled.div`
    display: grid;
    grid-template-columns: 20% 25% 15% 15% 17%;
    grid-gap: 2%;
    justify-content: stretch;
    margin-bottom: 20px;
`

const LabelProductName = styled(Label)`
    color: ${Colors.orange};
`

const TrackStock = styled.div`
    margin-top: 13px;
    margin-left: 19px;
    display: flex;
    align-items: center;
`

const TrackStockMessage = styled.span`
    padding: 17px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.09px;
    color: ${Colors.grey};
`

const TooltipLabel = styled.p`
    max-width: 310px;
    padding: 0 10px;
    font-size: 12px;
`

const SaveContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2.16vh;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
`

export default ProductForm
