import React from 'react'
import styled from 'styled-components'

import Colors from '../commons/Colors'
import { dateFormat } from '../commons/DateUtils'
import { getPriceString } from '../price/PriceUtils'
import { formatDecimalPlace } from '../commons/NumberUtils'
import { isCharges, isFreight, isCustomDeadline } from './OrderFormScreenUtils'

const OrderOverview = ({ order }) => {
    const deadlineToRender = () => {
        const isCustomDeadlineOrder = isCustomDeadline(order.paymentDeadline)

        if (!isCustomDeadlineOrder) return order.paymentDeadline
        if (isCustomDeadlineOrder && order.paymentDate) {
            return dateFormat(order.paymentDate)
        }
        return ''
    }

    return (
        <Content>
            <ColumnLeft>
                <Title>RESUMO DO PEDIDO</Title>
                <PaymentInformation>
                    <PaymentLabel>Forma de Pagamento: </PaymentLabel>
                    <PaymentValue>{order.paymentForm}</PaymentValue>
                </PaymentInformation>

                <PaymentInformation>
                    <PaymentLabel>Prazo de Pagamento: </PaymentLabel>
                    <PaymentValue>{deadlineToRender()}</PaymentValue>
                </PaymentInformation>

                {isFreight(order) && (
                    <PaymentInformation>
                        <PaymentLabel>Frete: </PaymentLabel>
                        <PaymentValue>{order.freightType}</PaymentValue>
                    </PaymentInformation>
                )}
            </ColumnLeft>

            <ColumnRight>
                <Payment>
                    <PaymentLabel>PESO TOTAL</PaymentLabel>
                    <PaymentValue>{`${formatDecimalPlace(
                        order.weightInTonnes
                    )} ton`}</PaymentValue>
                </Payment>

                <Payment>
                    <PaymentLabel>SUBTOTAL</PaymentLabel>
                    <PaymentValue>
                        {getPriceString(order.subtotal)}
                    </PaymentValue>
                </Payment>

                {isFreight(order) && (
                    <Payment>
                        <PaymentLabel>FRETE</PaymentLabel>
                        <PaymentValue>
                            {getPriceString(order.freightPrice)}
                        </PaymentValue>
                    </Payment>
                )}

                {isCharges(order) && (
                    <Payment>
                        <PaymentLabel>ENCARGOS</PaymentLabel>
                        <PaymentValue>
                            {getPriceString(order.chargesPrice)}
                        </PaymentValue>
                    </Payment>
                )}

                <Payment>
                    <TotalLabel>TOTAL DO PEDIDO</TotalLabel>
                    <TotalValue>{getPriceString(order.price)}</TotalValue>
                </Payment>
            </ColumnRight>
        </Content>
    )
}

const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
`

const ColumnLeft = styled.div`
    margin-left: 30px;
    border-right: 1px solid #eceeee;
    width: 90%;
`

const ColumnRight = styled.div``

const Title = styled.span`
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.11px;
    color: ${Colors.brownishGrey};
`

const PaymentInformation = styled.div`
    display: flex;
    justify-content: 'flex-start';
    width: 90%;
`

const Payment = styled.div`
    display: flex;
    justify-content: space-between;
    width: 90%;
`

const PaymentLabel = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 2.5;
    letter-spacing: 0.11px;
    color: ${Colors.blueGrey};
    margin-right: 5px;
`

const PaymentValue = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 2.5;
    letter-spacing: 0.11px;
    color: ${Colors.brownishGrey};
`

const TotalLabel = styled.span`
    font-size: 18px;
    font-weight: bold;
    line-height: 1.11;
    letter-spacing: 0.13px;
    color: ${Colors.brownishGrey};
    margin-top: 20px;
`

const TotalValue = styled(TotalLabel)`
    color: ${Colors.grass};
`

export default OrderOverview
