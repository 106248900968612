import React from 'react'
import styled from 'styled-components'
import { Checkbox as PrimeCheckbox } from 'primereact/checkbox'

import Colors from '../Colors'

export default function Checkbox({
    data_testid,
    className,
    onChange,
    inputId,
    name,
    checked,
    checkedColor = Colors.orange,
    borderColor = Colors.lightBlueGreyThree,
}) {
    return (
        <Container
            data-testid={data_testid}
            className={className}
            checkedColor={checkedColor}
            borderColor={borderColor}
        >
            <PrimeCheckbox
                inputId={inputId}
                onChange={onChange}
                name={name}
                checked={checked}
            />
        </Container>
    )
}

const Container = styled.span`
    .p-checkbox .p-checkbox-box {
        background-color: ${Colors.white} !important;
        border-color: ${props => props.borderColor} !important;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
        background-color: ${props => props.checkedColor} !important;
    }
`
