import { STATUS_TEXT } from '../commons/Status'
import { getFreightTypeId } from '../environment'
import { parseNumber, formatDecimalPlace } from '../commons/NumberUtils'

export const gcpToSydleOrder = gcpOrder => {
    return {
        seller: {
            _id: gcpOrder.seller.sellerId,
            login: gcpOrder.seller.sellerLogin,
            gcpId: gcpOrder.seller.sellerId,
            name: gcpOrder.seller.sellerName,
        },
        note: gcpOrder.note,
        needsChapa: gcpOrder.needsChapa,
        paymentForm: gcpOrder.payment.paymentFormName,
        source: gcpOrder.source?.name,
        weightInTonnes: gcpOrder.weightInTonnes,
        freightType: getFreightType(gcpOrder.freightTypeId),
        price: gcpOrder.priceInfo.price,
        requestDate: gcpOrder.requestDate,
        farm: {
            note: gcpOrder.client.clientNote,
            corporateName: gcpOrder.client.clientCompanyName,
            gcpId: gcpOrder.client.clientId,
            legacyCode: gcpOrder.client.clientLegacyCode,
            debtor: gcpOrder.client.debtor,
            owner: {
                name: gcpOrder.client.clientOwnerName,
                phone: gcpOrder.client.phone,
            },
            address: gcpOrder.client.clientAddress,
            mill: {
                _id: gcpOrder.millSydleId,
            },
            tributes: {
                _id: gcpOrder.freightTypeId,
                freightTributes: gcpOrder.freightType?.name,
            },
            deleted: false,
            fancyName: gcpOrder.client.clientTradeName,
            _id: gcpOrder.client.clientId,
        },
        deliveryDate: gcpOrder.deliveryDate,
        freightPrice: gcpOrder.priceInfo.freightPrice,
        paymentFormComplete: {
            paymentForm: gcpOrder.payment.paymentFormName,
            paymentDeadlines: [
                {
                    _id: gcpOrder.payment.paymentDeadlineId,
                    name: gcpOrder.payment.paymentDeadlineName,
                    legacyCode: gcpOrder.payment.paymentDeadlineLegacyCode,
                    increasePercentage:
                        gcpOrder.payment.paymentDeadlineIncreasePercentage,
                },
            ],
            _id: gcpOrder.payment.paymentFormId,
            mill: {
                _id: gcpOrder.millSydleId,
            },
            legacyCode: gcpOrder.payment.paymentFormLegacyCode,
            dailyPercentage: gcpOrder.payment.paymentDailyPercentage,
        },
        tributesPrice: gcpOrder.priceInfo.tributesPrice,
        needsPallet: gcpOrder.needsPallet,
        deleted: gcpOrder.deletedAt ? true : false,
        subtotal: gcpOrder.priceInfo.subtotal,
        paymentDeadline: gcpOrder.payment.paymentDeadlineName,
        _id: gcpOrder.id,
        gcpId: gcpOrder.id,
        chargesPrice: gcpOrder.priceInfo.chargesPrice,
        category: {
            seller: {
                _id: gcpOrder.seller.sellerId,
                gcpId: gcpOrder.seller.sellerId,
            },
            _id: gcpOrder.clientPortfolio.clientPortfolioId,
            name: gcpOrder.clientPortfolio.clientPortfolioName,
        },
        paymentDate: gcpOrder.payment.paymentDate,

        items: gcpOrder.orderItems?.map(orderItem => ({
            _id: orderItem.id,
            quantity: orderItem.quantity,
            unitPrice: orderItem.unitNegociatedPrice,
            price: orderItem.priceInfo.subtotal,
            unQuantity: `${formatDecimalPlace(orderItem.quantity)} ${
                orderItem.measure
            }(${orderItem.weight}${orderItem.unit})`,
            freightPrice: orderItem.priceInfo.freightPrice,
            chargesPrice: orderItem.priceInfo.chargesPrice,
            tributesPrice: orderItem.priceInfo.tributesPrice,
            product: {
                _id: orderItem.productId,
                legacyCode: orderItem.legacyCode,
                active: orderItem.deletedAt ? false : true,
                mill: {
                    _id: gcpOrder.millSydleId,
                },
                name: orderItem.name,
                gcpId: orderItem.productId,
                price: orderItem.unitPrice,
                discountMaxPercentage: orderItem.discountMaxPercentage,
                increaseMaxPercentage: orderItem.increaseMaxPercentage,
                isOutOfPricePolicy: orderItem.isOutOfPricePolicy,
                measurementUnit: {
                    quantity: orderItem.weight,
                    weight: orderItem.unit,
                    unitType: orderItem.unitType,
                    measure: orderItem.measure,
                },
            },
            discountMaxPercentage: orderItem.discountMaxPercentage,
            increaseMaxPercentage: orderItem.increaseMaxPercentage,
        })),
        hash: gcpOrder.hash,
        paymentDeadlineComplete: {
            name: gcpOrder.payment.paymentDeadlineName,
            _id: gcpOrder.payment.paymentDeadlineId,
            legacyCode: gcpOrder.payment.paymentDeadlineLegacyCode,
            increasePercentage:
                gcpOrder.payment.paymentDeadlineIncreasePercentage || 0,
        },
        orderNumber: gcpOrder.number,
        status: gcpOrder.status?.name,
        statusText: gcpOrder.status ? STATUS_TEXT[gcpOrder.status.name] : null,
        reasonCancellation: gcpOrder.cancellationReason,
        reasonEdition: gcpOrder.editionReason,
        isEdited: gcpOrder.isEdited,
        isUrgent: gcpOrder.isUrgent,
        isUseShipment: gcpOrder.isUseShipment || false,
        isUseFragmentOrder: gcpOrder.isUseFragmentOrder || false,
        isOutOfPricePolicy: gcpOrder.isOutOfPricePolicy,
    }
}

export function sydleToGcpOrder(sydleOrder) {
    return {
        millId: sydleOrder.farm.mill.gcpId,
        millSydleId: sydleOrder.farm.mill._id,
        weightInTonnes: sydleOrder.weightInTonnes,
        requestDate: sydleOrder.requestDate,
        deliveryDate: sydleOrder.deliveryDate,
        needsPallet: sydleOrder.needsPallet,
        needsChapa: sydleOrder.needsChapa,
        freightTypeId: getFreightTypeId()[sydleOrder.freightType],
        sourceName: sydleOrder.source,
        orderItems: sydleOrder.items.map(item => {
            const productTotal =
                Number(item.price) +
                Number(item.freightPrice || 0) +
                Number(item.chargesPrice || 0)
            return {
                quantity: parseNumber(item.quantity).toFixed(3),
                productId: item.product.gcpId,
                productSydleId: item.product.sydleId,
                name: item.product.name,
                legacyCode: item.product.legacyCode,
                priceInfo: {
                    price: productTotal,
                    subtotal: item.price,
                    freightPrice: item.freightPrice,
                    chargesPrice: item.chargesPrice,
                    tributesPrice: item.tributesPrice,
                },
                unitNegociatedPrice: parseNumber(item.unitPrice).toFixed(2),
                unitPrice: item.product.price,
                discountMaxPercentage: item.discountMaxPercentage,
                increaseMaxPercentage: item.increaseMaxPercentage,
                weight: item.product.measurementUnit.quantity,
                measure: item.product.measurementUnit.measure,
                unit: item.product.measurementUnit.weight,
                unitType: item.product.measurementUnit.unitType,
            }
        }),
        note: sydleOrder.note,
        hash: sydleOrder.hash,
        seller: {
            sellerId: sydleOrder.seller.gcpId,
            sellerName: sydleOrder.seller.name,
            sellerLogin: sydleOrder.seller.sellerLogin,
        },
        client: {
            clientSydleId: sydleOrder.farm.sydleId,
            clientId: sydleOrder.farm.gcpId,
            clientLegacyCode: sydleOrder.farm.legacyCode,
            clientTradeName: sydleOrder.farm.fancyName,
            clientCompanyName: sydleOrder.farm.corporateName,
            clientOwnerName: sydleOrder.farm.owner.name,
            clientAddress: sydleOrder.farm.address,
            clientNote: sydleOrder.farm.note,
        },
        payment: {
            paymentFormId: sydleOrder.paymentFormComplete._id,
            paymentFormLegacyCode: sydleOrder.paymentFormComplete.legacyCode,
            paymentFormName: sydleOrder.paymentFormComplete.paymentForm,
            paymentDailyPercentage:
                sydleOrder.paymentFormComplete.dailyPercentage,
            paymentDeadlineId: sydleOrder.paymentDeadlineComplete._id,
            paymentDeadlineLegacyCode:
                sydleOrder.paymentDeadlineComplete.legacyCode,
            paymentDeadlineName: sydleOrder.paymentDeadlineComplete.name,
            paymentDeadlineIncreasePercentage:
                sydleOrder.paymentDeadlineComplete.increasePercentage,
            paymentDate: sydleOrder.paymentDate,
        },
        priceInfo: {
            price: sydleOrder.price,
            subtotal: sydleOrder.subtotal,
            freightPrice: sydleOrder.freightPrice,
            chargesPrice: sydleOrder.chargesPrice,
            tributesPrice: sydleOrder.tributesPrice,
        },
        clientPortfolio: {
            clientPortfolioId: sydleOrder.category?.gcpId,
            clientPortfolioName: sydleOrder.category?.name,
        },
        editionReason: sydleOrder.reasonEdition,
        cancellationReason: sydleOrder.reasonCancellation,
        isEdited: sydleOrder.isEdited,
    }
}

export function getFreightType(freightTypeId) {
    const freightTypeIds = getFreightTypeId()
    return Object.keys(freightTypeIds).find(
        freightType => freightTypeIds[freightType] === freightTypeId
    )
}
