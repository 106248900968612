import React from 'react'
import styled from 'styled-components'
import Tooltip from './Tooltip'
import Colors from '../Colors'
import { MdErrorOutline } from 'react-icons/md'

/**
 *
 * @param {Boolean} debtor - diz se o cliente é inadimplente ou não
 * @param {Boolean} tooltip - se o componente deve ser renderizado apenas com tooltip.
 *
 */

export function DebtorClientIndicator({ debtor, tooltip = false }) {
    const debtorIcon = () =>
        tooltip ? (
            <Tooltip label={'Cliente inadimplente'}>
                <DebtorIcon data-testid="debtor-icon" />
            </Tooltip>
        ) : (
            <SectionDebtor className="debtor-container">
                <DebtorIcon data-testid="debtor-icon" />
                <DebtorClientLabel>CLIENTE INADIMPLENTE</DebtorClientLabel>
            </SectionDebtor>
        )

    return <>{debtor ? debtorIcon() : <></>}</>
}

const SectionDebtor = styled.div`
    display: flex;
    flex-direction: row;
`

const DebtorIcon = styled(MdErrorOutline)`
    font-size: 24px;
    color: ${Colors.strawberry};
`

const DebtorClientLabel = styled.label`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    color: ${Colors.strawberry};
    text-transform: uppercase;
`

export default DebtorClientIndicator
