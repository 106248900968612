import * as DateUtils from '../../commons/DateUtils'

export const KEYS = {
    NEWS_KEY: 'NEWS',
    NOTIFY_KEY: 'NOTIFY',
}

export const getNewsCount = (newsRepository, features) => {
    const repository = newsRepository()
    const oldNews = repository.getOldNews()
    const featuresCount = features.length
    if (!featuresCount) return 0
    if (!oldNews) return featuresCount
    const oldNewsCount = oldNews.reduce((acc, curr) => {
        if (!curr.isRemoved) return (acc += 1)
        return acc
    }, 0)
    return Math.abs(featuresCount - oldNewsCount)
}

export const isFeatureToDisplay = releaseDate => {
    if (!releaseDate) return false
    const daysDiff = DateUtils.getDaysDiff(new Date(releaseDate))
    return daysDiff <= 30
}

export const setNotfied = (notified, notificationDate = null) => {
    localStorage.setItem(
        KEYS.NOTIFY_KEY,
        JSON.stringify({
            notified,
            notificationDate,
        })
    )
}

const getNotificationData = () => {
    return JSON.parse(localStorage.getItem(KEYS.NOTIFY_KEY))
}

export const getNotificationStorageData = () => {
    const storageData = getNotificationData()
    if (!storageData || !Object.keys(storageData).length) setNotfied(false)
    return getNotificationData()
}

export const isToNotify = (storage, features) => {
    return features.some(
        feature =>
            new Date(feature.releaseDate).getTime() >
            new Date(storage.notificationDate).getTime()
    )
}

export const mountFeaturesToRender = (versions, news) => {
    const featuresToRender = []
    versions?.forEach(version => {
        version?.features?.forEach(feature => {
            if (isFeatureToDisplay(version.releaseDate)) {
                featuresToRender.push({
                    version: version.name,
                    releaseDate: version.releaseDate,
                    source: version.source.name,
                    name: feature.name,
                    description: feature.description,
                    fileURL: feature?.fileURL,
                    featureId: feature.id,
                })
            }
        })
    })
    if (news?.length && featuresToRender.length) {
        const updatedNews = featuresToRender.filter(
            feature =>
                !news.some(
                    news =>
                        news.featureId === feature.featureId && news.isRemoved
                )
        )
        return updatedNews
    }
    return featuresToRender || []
}
