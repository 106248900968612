import React, { useState, useReducer, useEffect } from 'react'
import * as ProductUsageAPI from '../ProductUsageAPI'
import * as ProductUsageUtils from '../ProductUsageUtils'
import * as PromiseUtils from '../../commons/PromiseUtils'
import { addMessage, MessageType } from '../../commons/MessageUtils'
import { EVENT_KEYS, analyticsEvent } from '../../commons/AnalyticsUtils'
import ProductUsageView from './ProductUsageView'

// FAZER NO FUTURO
// TODO - Migrar paginação para o back quando produtos forem migrados para GCP

// PENDÊNCIAS
// ORDENAÇÃO

function ProductUsageContainer({ onBack, clientData, millData }) {
    const [productsUsage, setProductsUsage] = useState([])
    const [totalProductsUsage, setTotalProductsUsage] = useState(0)
    const [tableValues, setTableValues] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [isNoRecordsFound, setIsNoRecordsFound] = useState(false)
    const [indexOfFirstTableItem, setIndexOfFirstTableItem] = useState(0)
    const [deletionModalConfig, setDeletionModalConfig] = useState({
        isVisible: false,
        productUsage: { productId: '', name: '' },
    })
    const [isDeletingProductUsage, setIsDeletingProductUsage] = useState(false)
    const [isNotSavedModalVisible, setIsNotSavedModalVisible] = useState(false)
    const [editionModalConfig, updateEditionModalConfig] = useReducer(
        (prev, action) => {
            const { type, carouselIndex } = action

            switch (type) {
                case 'CLOSE':
                    return { isVisible: false, carouselIndex: null }
                case 'OPEN':
                    return {
                        isVisible: true,
                        carouselIndex: 0, //carouselIndex,
                        // FIXME: a renderização da página inicial do carrossel está com problema. Aparenta ser corrigido na versão 4.3.0
                        // https://github.com/primefaces/primereact/issues/1387
                    }
                case 'NAVIGATE':
                    return { ...prev, carouselIndex }
                default:
                    return prev
            }
        },
        {
            isVisible: false,
            productsUsage: {},
        }
    )
    const [isAddProductModalVisible, setIsAddProductModalVisibile] =
        useState(false)
    const [addedProducts, setAddedProducts] = useState([])
    const [selectedDateRange, setSelectedDateRange] = useState({
        firstDate: undefined,
        secondDate: undefined,
    })
    const PAGINATOR_ROWS = 8

    const fetchProductUsage = async (clientId, firstDate, secondDate) => {
        const response = await ProductUsageAPI.getProductsUsage(
            clientId,
            firstDate,
            secondDate
        )

        if (!response.data?.length) {
            setIsNoRecordsFound(true)
            setIsLoading(false)
            return
        }

        if (response.data) {
            // TODO - ORDENAÇÃO
            const allProductsUsage = ProductUsageUtils.handleProductUsageData(
                response.data
            )
            setProductsUsage(allProductsUsage)
            setIsNoRecordsFound(false)
            setIsError(false)
            setIsLoading(false)

            return
        }
    }

    useEffect(() => {
        try {
            const { firstDate, secondDate } = ProductUsageUtils.getDateRange()

            setSelectedDateRange({
                firstDate,
                secondDate,
            })

            const { clientId } = clientData

            fetchProductUsage(clientId, firstDate, secondDate)
        } catch (error) {
            setIsError(true)
            console.error(error)
        }
    }, [clientData])

    useEffect(() => {
        const isNotRegistred =
            ProductUsageUtils.isNotSavedProductUsage(productsUsage)

        setIsNotSavedModalVisible(isNotRegistred)
        setTotalProductsUsage(productsUsage.length)
        setTableValues(
            ProductUsageUtils.filterItemsToPaginate(
                0,
                PAGINATOR_ROWS,
                productsUsage
            )
        )
    }, [productsUsage])

    const handleClientsTablePagination = event => {
        const from = event.first
        const to = event.first + PAGINATOR_ROWS

        const tableValues = ProductUsageUtils.filterItemsToPaginate(
            from,
            to,
            productsUsage
        )

        setTableValues(tableValues)
        setIndexOfFirstTableItem(event.first)
    }

    const handleAddProductModal = () => {
        setIsAddProductModalVisibile(!isAddProductModalVisible)
    }

    const handleDeletionModal = productUsage => {
        setDeletionModalConfig(deletionModalConfig => ({
            isVisible: !deletionModalConfig.isVisible,
            productUsage,
        }))
    }

    const handleEditProductUsageModal = () => {
        updateEditionModalConfig({ type: 'OPEN' })
    }

    const onSaveProductsUsageEdition = async productsUsage => {
        analyticsEvent(EVENT_KEYS.saveProductUsage)

        updateEditionModalConfig({
            type: 'CLOSE',
        })
        setIsLoading(true)

        const handledProductsUsage = Object.values(productsUsage)
            .map(productUsage => Object.values(productUsage))
            .flat()

        const { UPDATE, CREATE } =
            ProductUsageUtils.handleProductsUsageToSave(handledProductsUsage)

        const { firstDate, secondDate } = ProductUsageUtils.getDateRange()

        const createProductUsage = async () => {
            return Boolean(CREATE.length)
                ? await PromiseUtils.GCPWrapper(
                      await ProductUsageAPI.createProductUsage(CREATE)
                  )
                : []
        }

        const updateProductUsage = async () => {
            return Boolean(UPDATE.length)
                ? await PromiseUtils.GCPWrapper(
                      await ProductUsageAPI.updateProductUsage(UPDATE)
                  )
                : []
        }

        await PromiseUtils.WrapAll({
            promises: [createProductUsage(), updateProductUsage()],
            onFulfilled: () => {
                addMessage(MessageType.SUCCESS, 'Produtos salvos com sucesso!')
            },
            onRejected: () =>
                addMessage(
                    MessageType.ERROR,
                    'Ocorreu um erro ao salvar as alterações. Tente novamente.'
                ),
            callback: async () => {
                await fetchProductUsage(
                    clientData.clientId,
                    firstDate,
                    secondDate
                )
            },
        })
    }

    const onSaveAddProducts = products => {
        analyticsEvent(EVENT_KEYS.addProductUsage)
        setIsAddProductModalVisibile(!isAddProductModalVisible)

        const standardizedProducts = ProductUsageUtils.standardizeProducts(
            products,
            clientData,
            millData
        )

        setAddedProducts([])
        setProductsUsage(productsUsage => [
            ...standardizedProducts,
            ...productsUsage,
        ])

        const message =
            products.length > 1
                ? 'Produtos adicionados à lista.'
                : 'Produto adicionado à lista.'

        addMessage(MessageType.SUCCESS, message)

        if (isNoRecordsFound) setIsNoRecordsFound(false)
    }

    const onDeleteAddedProduct = productId => {
        setAddedProducts(addedProducts =>
            addedProducts.filter(item => item.value.id !== productId)
        )
        setTableValues(tableValues =>
            tableValues.filter(item => item.productId !== productId)
        )
    }

    const onDeleteProductUsage = async productUsage => {
        setIsDeletingProductUsage(true)

        const productUsageKeys = Object.keys(productUsage)
        const notCreatedProductUsageKeys = [
            'name',
            'productId',
            'productSydleId',
            'clientId',
            'millId',
            'millSydleId',
        ]
        const createdProductUsageKeys = productUsageKeys.filter(
            key => !notCreatedProductUsageKeys.includes(key)
        )

        if (!Boolean(createdProductUsageKeys.length)) {
            setProductsUsage(productsUsage =>
                productsUsage.filter(
                    item => item.productId !== productUsage.productId
                )
            )
            addMessage(MessageType.SUCCESS, 'Produto deletado da lista.')
        } else {
            const itemsToDelete = createdProductUsageKeys.map(
                key => productUsage[key].id
            )

            PromiseUtils.PromiseWrapper({
                promise: ProductUsageAPI.deleteProductsUsage(itemsToDelete),
                onFulfilled: () => {
                    addMessage(
                        MessageType.SUCCESS,
                        'Produto deletado da lista.'
                    )
                },
                onRejected: () =>
                    addMessage(
                        MessageType.ERROR,
                        'Ocorreu um erro ao deletar o produto. Tente novamente mais tarde.'
                    ),
                callback: async () => {
                    setIsLoading(true)
                    setProductsUsage([])
                    const { firstDate, secondDate } = selectedDateRange
                    await fetchProductUsage(
                        clientData.clientId,
                        firstDate,
                        secondDate
                    )
                },
            })
        }

        setIsDeletingProductUsage(false)
        setDeletionModalConfig({
            productUsage: { productId: '', name: '' },
            isVisible: false,
        })
    }

    return (
        <ProductUsageView
            onBack={onBack}
            clientData={clientData}
            tableValues={tableValues}
            isLoading={isLoading}
            isNoRecordsFound={isNoRecordsFound}
            isError={isError}
            selectedDateRange={selectedDateRange}
            handlePagination={handleClientsTablePagination}
            indexOfFirstTableItem={indexOfFirstTableItem}
            totalProductsUsage={totalProductsUsage}
            handleAddProductModal={handleAddProductModal}
            onSaveAddProducts={onSaveAddProducts}
            isAddProductModalVisible={isAddProductModalVisible}
            addedProducts={addedProducts}
            setAddedProducts={setAddedProducts}
            onDeleteAddedProduct={onDeleteAddedProduct}
            onDeleteProductUsage={onDeleteProductUsage}
            isDeletingProductUsage={isDeletingProductUsage}
            setIsDeletingProductUsage={setIsDeletingProductUsage}
            handleDeletionModal={handleDeletionModal}
            setDeletionModalConfig={setDeletionModalConfig}
            deletionModalConfig={deletionModalConfig}
            isNotSavedModalVisible={isNotSavedModalVisible}
            handleEditProductUsageModal={handleEditProductUsageModal}
            updateEditionModalConfig={updateEditionModalConfig}
            editionModalConfig={editionModalConfig}
            onSaveProductsUsageEdition={onSaveProductsUsageEdition}
            PAGINATOR_ROWS={PAGINATOR_ROWS}
            productsUsage={productsUsage}
        />
    )
}

export default ProductUsageContainer
