import React from 'react'
import styled from 'styled-components'

import { getPriceString } from '../../price/PriceUtils'
import {
    formatsDecimalNumber,
    formatDecimalPlace,
} from '../../commons/NumberUtils'
import * as MeasurementUnitUtils from '../../commons/MeasurementUnitUtils'
import Colors from '../../commons/Colors'

import BasicRoundButton from '../../commons/components/RoundButton'
import BasicInputWithMessage from '../../commons/components/InputWithMessage'
import ExtraSimpleBillingInfoComponent from './ExtraSimpleBillingInfoComponent'
import ShipmentDeliveryDateComponent from './ShipmentDeliveryDateComponent'

export default function SimpleBillingTableComponent({
    simpleBilling,
    orderItems,
    setSimpleBillingItems,
    hasNotRemainingQuantity,
    shipmentToCreate,
    updateShipmentToCreate,
    createShipment,
}) {
    const handleInputOnChange = (value, itemId) => {
        const regex = /^\d*(\.\d+)?$/g
        const isValid = regex.test(value)

        if (isValid) {
            setSimpleBillingItems(currentItems => {
                const itemToUpdate = currentItems.find(
                    item => item.id === itemId
                )

                itemToUpdate.shipmentQuantity =
                    MeasurementUnitUtils.isDecimalUnitType(
                        itemToUpdate.measurementUnit
                    )
                        ? formatsDecimalNumber(value)
                        : value.replace(/\D/g, '')

                itemToUpdate.shipmentSubtotal =
                    itemToUpdate.calculatesShipmentSubtotal()

                itemToUpdate.shipmentWeight =
                    itemToUpdate.getShipmentWeightInTonnes()

                const updatedItems = [
                    ...currentItems.filter(item => item.id !== itemId),
                    itemToUpdate,
                ].sort((prev, next) => prev.id.localeCompare(next.id))

                updateShipmentToCreate({
                    shipmentItems: updatedItems.filter(item =>
                        Boolean(parseFloat(item.shipmentQuantity))
                    ),
                })
                return updatedItems
            })
        }
    }

    const renderOrderItems = () => {
        return orderItems.map(orderItem => (
            <TableRow>
                <TableCell>
                    <ProductNameItemText>
                        {orderItem.productName}
                    </ProductNameItemText>
                </TableCell>
                <TableCell className="total-amount">
                    <TableItemText>
                        {formatDecimalPlace(orderItem.totalQuantity)}
                    </TableItemText>
                </TableCell>
                <TableCell
                    className="remaining-quantity"
                    hasAnyRemainingQuantity={orderItems.some(orderItem =>
                        Boolean(orderItem.remainingQuantity)
                    )}
                >
                    <RemainingQuantityText
                        hasRemainingQuantity={Boolean(
                            orderItem.remainingQuantity
                        )}
                    >
                        {formatDecimalPlace(orderItem.remainingQuantity)}
                    </RemainingQuantityText>
                </TableCell>
                <TableCell>
                    <TableItemText>
                        {MeasurementUnitUtils.getMeasurementUnitText(
                            orderItem.measurementUnit
                        )}
                    </TableItemText>
                </TableCell>
                <TableCell>
                    <TableItemText>
                        {getPriceString(orderItem.price)}
                    </TableItemText>
                </TableCell>
                <TableCell className="shipment-quantity">
                    <InputContainer>
                        <InputWithMessage
                            placeholder="0"
                            invalidMessage="Valor excede saldo"
                            id={orderItem.id}
                            value={orderItem.shipmentQuantity}
                            onChange={e =>
                                handleInputOnChange(
                                    e.target.value,
                                    orderItem.id
                                )
                            }
                            type="number"
                            isInvalid={
                                orderItem.shipmentQuantity >
                                orderItem.remainingQuantity
                            }
                            readOnly={
                                parseFloat(orderItem.remainingQuantity) === 0
                            }
                        />
                    </InputContainer>
                </TableCell>
                <TableCell>
                    <TableItemText>
                        {getPriceString(orderItem.shipmentSubtotal)}
                    </TableItemText>
                </TableCell>
            </TableRow>
        ))
    }

    return (
        <SimpleBillingContainer>
            <SimpleBillingHeader>
                <div>
                    <CustomerNameText>
                        {simpleBilling.farm.owner.name}
                    </CustomerNameText>
                </div>
                <div>
                    <OrderNumberText>
                        Pedido Nº {simpleBilling.orderNumber}
                    </OrderNumberText>
                </div>
            </SimpleBillingHeader>
            <SimpleBillingTable>
                <TableHeader>
                    <TableCell>
                        <TableTitleItemText>Produto</TableTitleItemText>
                    </TableCell>
                    <TableCell className="total-amount">
                        <TableTitleItemText>Qtid. TOTAL</TableTitleItemText>
                    </TableCell>
                    <TableCell>
                        <TableTitleItemText>
                            Qtid. DISPONÍVEL (Saldo)
                        </TableTitleItemText>
                    </TableCell>
                    <TableCell>
                        <TableTitleItemText>Unidade</TableTitleItemText>
                    </TableCell>
                    <TableCell>
                        <TableTitleItemText>Preço (R$)</TableTitleItemText>
                    </TableCell>
                    <TableCell className="shipment-quantity">
                        <TableTitleItemText>Qtid. REMESSA</TableTitleItemText>
                    </TableCell>
                    <TableCell>
                        <TableTitleItemText>
                            Subtotal REMESSA
                        </TableTitleItemText>
                    </TableCell>
                </TableHeader>
                {renderOrderItems(orderItems)}
                <TableFooter>
                    <FooterCell>
                        <TableTitleItemText>TOTAL DO PEDIDO</TableTitleItemText>
                    </FooterCell>
                    <FooterCell />
                    <FooterCell />
                    <FooterCell />
                    <FooterCell className="total-price footer-price">
                        <TotalPriceText>
                            {getPriceString(simpleBilling.price)}
                        </TotalPriceText>
                    </FooterCell>
                    <FooterCell />
                    <FooterCell className="shipment-price footer-price">
                        <TotalPriceText>
                            {getPriceString(shipmentToCreate.subtotal)}
                        </TotalPriceText>
                    </FooterCell>
                </TableFooter>
            </SimpleBillingTable>

            <ExtraSimpleBillingInfoComponent
                simpleBilling={simpleBilling}
                shipmentToCreate={shipmentToCreate}
            />

            <ShipmentDeliveryDateComponent
                value={shipmentToCreate.deliveryDate}
                setValue={deliveryDate =>
                    updateShipmentToCreate({ deliveryDate })
                }
                hasNotRemainingQuantity={hasNotRemainingQuantity}
            />

            <CreateShipmentButtonContainer>
                <CreateShipmentButton
                    disabled={!shipmentToCreate.validate()}
                    onClick={createShipment}
                >
                    <CreateShipmentButtonText>
                        CRIAR REMESSA
                    </CreateShipmentButtonText>
                </CreateShipmentButton>
            </CreateShipmentButtonContainer>
        </SimpleBillingContainer>
    )
}

const SimpleBillingContainer = styled.div`
    flex-grow: 0;
    padding: 9px 20px 28px 20px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: ${Colors.lightBlueGreyFour};

    display: flex;
    flex-direction: column;
    align-items: stretch;
`

const SimpleBillingHeader = styled.div`
    padding: 0px 5px 0px 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`

const OrderNumberText = styled.p`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: ${Colors.deepSeaBlue};
    justify-self: center;
`

const CustomerNameText = styled(OrderNumberText)`
    color: ${Colors.black};
    text-align: left;
`

const SimpleBillingTable = styled.div`
    display: grid;
`

const TableRow = styled.div`
    display: grid;
    column-gap: 10px;
    grid-template-columns: 23% 10% 12% repeat(3, 11%) 14%;
    padding: 0px 11px;
`

const TableHeader = styled(TableRow)`
    background-color: ${Colors.white};
    border-radius: 5px;
`

const TableCell = styled.div`
    height: 68px;
    padding: 10px;
    display: flex;
    align-items: center;

    &.total-amount {
        background-color: rgba(229, 117, 9, 0.1);

        div:first-of-type {
            background-color: rgba(230, 72, 88, 0.1);
        }
    }

    &.shipment-quantity {
        background-color: rgba(0, 81, 134, 0.1);
    }
`

const FooterCell = styled(TableCell)`
    height: 38px;

    &.total-price {
        background-color: rgba(0, 81, 134, 0.1);
        border-radius: 4px;
    }

    &.footer-price {
        justify-content: flex-start;
    }

    &.shipment-price {
        background-color: rgba(77, 163, 47, 0.15);
        border-radius: 4px;
    }
`

const TableItemText = styled.p`
    margin: 0;
    font-family: Roboto;
    color: ${Colors.brownishGrey};
    font-size: 12px;
`

const ProductNameItemText = styled(TableItemText)`
    font-weight: bold;
    color: ${Colors.black};
`

const TableTitleItemText = styled(TableItemText)`
    font-weight: bold;
    line-height: 1.6;
`

const RemainingQuantityText = styled(TableItemText)`
    font-weight: ${props => (props.hasRemainingQuantity ? 'bold' : 'normal')};
    color: ${props =>
        props.hasRemainingQuantity ? Colors.black : Colors.brownishGrey};
`

const TableFooter = styled(TableHeader)`
    padding: 2px 11px;
`

const TotalPriceText = styled(TableItemText)`
    font-weight: bold;
    color: ${Colors.black};
`

const CreateShipmentButtonContainer = styled.div`
    margin-top: 28px;
    display: flex;
    justify-content: center;
`

const CreateShipmentButton = styled(BasicRoundButton)`
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
`

const CreateShipmentButtonText = styled.p`
    color: ${Colors.white};
`

const InputWithMessage = styled(BasicInputWithMessage)`
    width: 58px;
    font-size: 12px;
`

const InputContainer = styled.div`
    height: 54px;
    position: relative;
    top: 7px;

    .error-message {
        margin-left: -8px;
        font-size: 10px;
        padding-top: 3px;
        width: max-content;
    }
`
