import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { validatesToken } from '../commons/Interceptor'
import { keyStorage } from '../commons/LocalStorage'

const PrivateRoute = ({ children, ...props }) => {
    const userToken = localStorage.getItem(keyStorage.userToken)
    const location = useLocation()

    const isValidToken = validatesToken(userToken)
    if (isValidToken) {
        return children ? children : props.render()
    } else {
        localStorage.removeItem(keyStorage.userToken)
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: location },
                }}
            />
        )
    }
}

export default PrivateRoute
