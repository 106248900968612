import React from 'react'
import styled from 'styled-components'
import Tooltip from './Tooltip'
import Colors from '../Colors'

import outOfPricePolicyIcon from '../../assets/dashboard/outOfPricePolicyIcon.png'

function OutOfPricePolicyIndicator({ isOutOfPricePolicy, tooltip = false }) {
    const outOfPricePolicyIcon = () => {
        return tooltip ? (
            <Tooltip label={'Pedido fora da política'}>
                <OutOfPricePolicyIcon
                    size="20px"
                    data-testid="outOfPricePolicy-icon"
                />
            </Tooltip>
        ) : (
            <SectionEdition>
                <OutOfPricePolicyIcon
                    size="15px"
                    data-testid="outOfPricePolicy-icon"
                />
                <OutOfPricePolicyText>Fora da Política</OutOfPricePolicyText>
            </SectionEdition>
        )
    }

    return <>{isOutOfPricePolicy ? outOfPricePolicyIcon() : <></>}</>
}

const OutOfPricePolicyIcon = styled.img`
    width: ${props => props.size};
    height: ${props => props.size};
`
OutOfPricePolicyIcon.defaultProps = {
    src: outOfPricePolicyIcon,
    alt: 'Icone para pedidos fora da política de preços',
}

const SectionEdition = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    height: 20px;
`

const OutOfPricePolicyText = styled.p`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    color: ${Colors.strawberry};
    margin-left: 3px;
`

export default OutOfPricePolicyIndicator
