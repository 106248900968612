import React from 'react'
import styled from 'styled-components'
import { DataTable } from 'primereact/datatable'

import Colors from '../Colors'

/**
 *
 * @param {React.FunctionComponent} children - componente a ser renderizado
 * @param {array} value - um array com os objetos para mostrar
 * @param {any} globalFilter - valor do filtro global para usar na filtragem
 * @param {boolean} loading - renderiza componente de loading na tela com o texto Carregando...
 * @param {number} rows - numero de linhas a ser mostrado por pagina
 * @param {number} first - index da primeira linha a ser mostrada
 * @param {event} onPage - callback a ser chamado na paginação
 * @param {number} totalRecords - numero do total de registros, default é o tamanho do valor quando não definido
 * @param {string} selectionMode - especifica o modo de seleção, valores validos são "single" e "multiple"
 * @param {any} selection - linha selecionada no modo "single" ou array de valores do modo "multiple"
 * @param {event} onSelectionChange - callback a ser chamada quando o selection muda
 * @param {string} dataKey - propriedade para identificar unicamente um registro nos dados
 * @param {boolean} rowHover - propriedade para definir efeito de hover em uma linha da tabela
 * @param {event} onRowClick - callback a ser chamada quando uma linha é clicada
 * @param {string} className - className do componente
 */

export const Table = ({
    children,
    value,
    globalFilter,
    loading,
    rows,
    first,
    onPage,
    totalRecords,
    totalRecordsText = '{totalRecords} Registros Encontrados',
    selectionMode,
    selection,
    onSelectionChange,
    dataKey,
    rowHover = false,
    onRowClick = () => {},
    className,
}) => {
    const renderDataTable = () => {
        return (
            <>
                <DataTable
                    dataKey={dataKey}
                    value={loading ? [] : value}
                    globalFilter={globalFilter}
                    paginator={loading || !onPage ? false : true}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
                    currentPageReportTemplate={totalRecordsText.replace(
                        '{totalRecords}',
                        totalRecords
                    )}
                    lazy={onPage ? true : false}
                    first={first}
                    rows={rows}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    selectionMode={selectionMode}
                    selection={selection}
                    onSelectionChange={onSelectionChange}
                    onRowClick={onRowClick}
                    rowHover={rowHover}
                    className={className}
                >
                    {children}
                </DataTable>

                {loading ? (
                    <LoadingContainer>
                        <Loading>Carregando...</Loading>
                    </LoadingContainer>
                ) : null}
            </>
        )
    }

    if (rowHover) {
        return (
            <TableContainerWithHover>
                {renderDataTable()}
            </TableContainerWithHover>
        )
    }

    return <TableContainer>{renderDataTable()}</TableContainer>
}

const TableContainer = styled.div`
    width: 100%;

    table {
        width: 100%;
        border-collapse: separate !important;
        border-spacing: 0 1px !important;
    }

    table th {
        position: -webkit-sticky;
        position: sticky;
        top: 60px;
        z-index: 4;
    }

    thead tr th {
        border: none !important;
        background-color: #f1f1f1 !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #000000;
    }

    thead tr th:focus {
        border-style: none !important;
        box-shadow: none !important;
        border: none !important;
        outline-style: none !important;
    }

    tbody tr td {
        border-style: none none solid none !important;
        border-color: #edecec !important;
        border-width: 1px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        font-size: 14px !important;
        color: black !important;
    }

    .p-datatable .p-datatable-tbody > tr {
        background-color: #ffffff;
    }

    .p-datatable .p-datatable-tbody > tr:nth-child(even) {
        background-color: #ffffff;
    }

    .p-datatable-wrapper {
        display: flex;
        justify-content: center;
    }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background-color: rgba(241, 141, 58, 0.5);
        color: black;
    }

    .p-datatable .p-paginator-bottom {
        border-style: none none solid none !important;
        border-color: #edecec !important;
        border-width: 1px !important;
    }

    .p-datatable .p-paginator-current {
        color: ${Colors.brownishGrey};
    }

    /* Linha selecionada */
    tr.p-highlight {
        background-color: transparent !important;
    }

    /* Linha selecionada */
    tr.p-highlight > td {
        background-color: rgba(241, 141, 58, 0.1) !important;
    }

    /* Checkbox da linha selecionada */
    .p-checkbox .p-checkbox-box.p-highlight {
        background-color: ${Colors.orange} !important;
        border-color: ${Colors.lightBlueGreyThree} !important;
    }
`

const TableContainerWithHover = styled(TableContainer)`
    .p-datatable .p-datatable-tbody > tr {
        :hover {
            background-color: rgb(226, 226, 226);
        }
        cursor: pointer;
    }

    .p-datatable .p-datatable-tbody > tr:nth-child(even) {
        :hover {
            background-color: rgb(226, 226, 226);
        }
        cursor: pointer;
    }
`

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50vh;
`

const Loading = styled.span`
    font-family: Roboto;
    font-size: 20px;
    color: ${Colors.orange};
`

export default Table
