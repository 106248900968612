import React, { useState } from 'react'
import styled from 'styled-components'

import { MessageType, addMessage } from '../../commons/MessageUtils'
import * as PromiseUtils from '../../commons/PromiseUtils'
import * as OrderAPI from '../../dashboard/OrderAPI'
import * as ShipmentAPI from '../../dashboard/ShipmentApi'
import Colors from '../../commons/Colors'
import RoundButton from '../../commons/components/RoundButton'

export const CancelContent = ({ order, validation, onCancel }) => {
    const [loading, setLoading] = useState(false)
    const [reason, setReason] = useState()

    const sendOrderCancellation = async (order, reason) => {
        setLoading(true)
        try {
            await OrderAPI.cancelOrder(order._id, reason)
            addMessage(
                MessageType.SUCCESS,
                `Pedido ${order.orderNumber} cancelado com sucesso!`
            )
            onCancel()
        } catch (error) {
            addMessage(MessageType.ERROR, 'Erro ao cancelar pedido.')
        }
        setLoading(false)
    }

    const sendSimpleBillingCancelation = async (order, reason) => {
        try {
            setLoading(true)

            const [shipments, getShipmentsErr] = await PromiseUtils.GCPWrapper(
                ShipmentAPI.getAllShipments({ orderId: order.gcpId })
            )
            PromiseUtils.catchRejection(getShipmentsErr)

            if (shipments.length) {
                const [, deleteShipmentsErr] = await PromiseUtils.GCPWrapper(
                    ShipmentAPI.deleteShipments(
                        shipments.map(shipment => shipment.id)
                    )
                )
                PromiseUtils.catchRejection(deleteShipmentsErr)
            }

            await OrderAPI.cancelOrder(order._id, reason)

            addMessage(
                MessageType.SUCCESS,
                `Simples faturamento ${order.orderNumber} cancelado com sucesso!`
            )
            onCancel()
        } catch (err) {
            addMessage(
                MessageType.ERROR,
                'Erro ao cancelar simples faturamento, verifique se existem remessas em produção.'
            )
        } finally {
            setLoading(false)
        }
    }

    if (validation.message === 'PERTENCE_AGRUPAMENTO') {
        return (
            <>
                <Title>
                    Este pedido não pode ser cancelado pois pertence ao
                    agrupamento {validation.orderGroup.orderGroupNumber}.
                </Title>
                <Content>
                    O pedido <strong>{order.orderNumber}</strong> feito por{' '}
                    <strong>{order.seller.name}</strong> referente ao cliente{' '}
                    <strong>{order.farm.owner.name}</strong> não pode ser
                    cancelado pois pertence a um agrupamento de pedidos.
                    <br /> Caso queria realizar o cancelamento remova-o deste
                    agrupamento.
                </Content>
            </>
        )
    }

    if (order.isUseFragmentOrder) {
        return (
            <>
                <Title>
                    Este pedido não pode ser cancelado pois foi fracionado.
                </Title>
                <Content>
                    O pedido <strong>{order.orderNumber}</strong> feito por{' '}
                    <strong>{order.seller.name}</strong> referente ao cliente{' '}
                    <strong>{order.farm.owner.name}</strong> não pode ser
                    cancelado pois foi fracionado.
                    <br /> Caso queria realizar o cancelamento exclua o
                    fracionamento.
                </Content>
            </>
        )
    }

    return (
        <>
            {order.isUseShipment ? (
                <>
                    <Title>
                        Cancelar Simples Faturamento{' '}
                        <strong>Nº {order.orderNumber}</strong>?
                    </Title>

                    <Content>
                        Tem certeza de que deseja excluir o Simples Faturamento
                        e todas as Remessas Futuras criadas a partir deste?
                        <br /> <br />
                        <i>
                            *Nesta ação, todas as remessas geradas, com status
                            Em Análise, serão excluídas, e o Simples Faturamento
                            será eliminado da base.
                        </i>
                    </Content>
                </>
            ) : (
                <>
                    <Title>
                        Cancelar pedido de{' '}
                        <strong>{order.farm.owner.name}</strong>?
                    </Title>

                    <Content>
                        Tem certeza que deseja cancelar o pedido{' '}
                        <strong>{order.orderNumber}</strong> feito por{' '}
                        <strong>{order.seller.name}</strong> referente ao
                        cliente <strong>{order.farm.owner.name}</strong>
                        {Boolean(validation.orderGroup) ? (
                            <>
                                {' '}
                                que pertence ao agrupamento{' '}
                                <strong>
                                    {validation.orderGroup.orderGroupNumber}
                                </strong>
                                ?{' '}
                            </>
                        ) : (
                            <>{' ?'}</>
                        )}
                        <br />
                        Para confirmar o cancelamento preencha o motivo e
                        selecione <strong>Cancelar Pedido.</strong>
                    </Content>
                </>
            )}

            <WarnMessage>* Motivo do Cancelamento</WarnMessage>

            <ReasonInput
                data-testid="reasonInput"
                rows={8}
                onChange={e => setReason(e.target.value)}
            />

            {order.isUseShipment ? (
                <CancelButton
                    loading={loading}
                    onClick={() => sendSimpleBillingCancelation(order, reason)}
                    disabled={!reason}
                >
                    Cancelar Simples Faturamento
                </CancelButton>
            ) : (
                <>
                    <WarnMessage>
                        * Após o cancelamento o pedido estará disponível para
                        visualização na listagem de pedidos.
                    </WarnMessage>

                    <CancelButton
                        loading={loading}
                        onClick={() => sendOrderCancellation(order, reason)}
                        disabled={!reason}
                    >
                        Cancelar Pedido
                    </CancelButton>
                </>
            )}
        </>
    )
}

const Title = styled.span`
    font-family: Roboto;
    font-size: 16px;
`

const Content = styled.span`
    font-family: Roboto;
    line-height: 2;
    font-size: 14px;
    max-width: 35vw;
`

const WarnMessage = styled.span`
    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    color: ${Colors.blueGrey};
`

const ReasonInput = styled.textarea`
    border-radius: 3px;
    border: solid 1px ${Colors.paleLilac};
    background-color: ${Colors.veryLightPink};
    outline: none;
    resize: none;
    font-family: 'Roboto';
`

const CancelButton = styled(RoundButton)`
    display: flex;
    align-items: center;
    background-color: ${Colors.heavyRed};
    border-color: ${Colors.heavyRed};
    line-height: 1.71;
    width: fit-content;
    align-self: flex-end;
`

export default CancelContent
