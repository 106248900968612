import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Colors from '../commons/Colors'
import { Modal } from '../commons/components/modal'
import { Label } from '../commons/commonStyles'
import InputTextArea from '../commons/components/InputTextarea'
import OrderCardOnGroupDetail from './OrderCardOnGroupDetail'
import * as OrderGroupAPI from './OrderGroupAPI'
import { MessageType, addMessage } from '../commons/MessageUtils'
import Loading from '../commons/components/Loading'
import {
    generateOrderGroupHash,
    filterDropdownOptions,
    separatesSelectedOrders,
    sumTotalWeight,
} from './OrderGroupUtils'
import RoundButton from '../commons/components/RoundButton'
import { useDashboardContext } from '../context/DashboardContext'
import { useMillContext } from '../context/MillContext'
import OrderSearchAutoComplete from '../commons/components/OrderSearchAutoComplete'
import PrintOrderGroup from './PrintOrderGroup'
import ConfirmService from '../commons/ConfirmService'
import OutlineButton from '../commons/components/OutlineButton'
import { MdDelete } from 'react-icons/md'
import { formatDecimalPlace } from '../commons/NumberUtils'
import { allowsEdition } from '../dashboard/DashboardScreenUtils'
import { STATUS_TEXT } from '../commons/Status'
import * as PromiseUtils from '../commons/PromiseUtils'

const OrderGroupModal = ({ onClose }) => {
    const {
        selectedOrders,
        orders,
        orderGroups,
        selectedOrderGroup,
        clearSelectedOrders,
        fetchOrderGroups,
    } = useDashboardContext()

    const { mill, millUser } = useMillContext()

    const EMPTY_ORDER_GROUP = {
        orders: [],
        note: '',
        source: 'portal',
        isEditable: true,
    }

    const [agroupButtonLoading, setAgroupButtonLoading] = useState(false)
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false)
    const [loadingGroup, setLoadingGroup] = useState(false)
    const [orderGroup, setOrderGroup] = useState(EMPTY_ORDER_GROUP)

    const [addedOrders, setAddedOrders] = useState([])
    const [orderOptions, setOrderOptions] = useState([])

    useEffect(() => {
        if (selectedOrders && selectedOrders.length > 0) {
            setOrderGroup({
                orders: selectedOrders,
                note: '',
                isEditable: true,
                source: 'portal',
            })
        }
    }, [selectedOrders])

    useEffect(() => {
        if (selectedOrderGroup && selectedOrderGroup._id) {
            const fetchOrderGroup = async id => {
                setLoadingGroup(true)
                await PromiseUtils.PromiseWrapper({
                    promise: OrderGroupAPI.getOrderGroup(id),
                    onRejected: err => {
                        console.error(err)
                        addMessage(
                            MessageType.ERROR,
                            'Erro ao carregar o agrupamento, tente novamente.'
                        )
                        handleOnClose()
                    },
                    onFulfilled: ({ data }) => {
                        const orderGroup = {
                            ...data,
                            isEditable: selectedOrderGroup.isEditable,
                        }

                        setOrderGroup(orderGroup)
                    },
                    callback: () => setLoadingGroup(false),
                })
            }

            fetchOrderGroup(selectedOrderGroup._id)
        }
    }, [selectedOrderGroup])

    useEffect(() => {
        const options = filterDropdownOptions(orders, orderGroups, orderGroup)

        setOrderOptions(options)
    }, [orderGroup])

    const onChangeStatus = (orderId, status) => {
        setOrderGroup(orderGroup => {
            const alteredOrderGroup = { ...orderGroup }

            alteredOrderGroup.orders = alteredOrderGroup.orders.map(order => {
                if (order._id === orderId) {
                    return {
                        ...order,
                        status: status,
                        statusText: STATUS_TEXT[status],
                    }
                }

                return order
            })

            return {
                ...alteredOrderGroup,
                isEditable: allowsEdition(alteredOrderGroup),
            }
        })
    }

    const showConfirmMessage = (orderGroupNumber, id) =>
        ConfirmService.addConfirmMessage({
            title: 'Deseja excluir este agrupamento?',
            actionButtonText: 'Excluir',
            onClose: () => {},
            actionButtonFunction: async () => await handleDeleteOrderGroup(id),
            renderMessage: () => (
                <TextConfirmContainer>
                    <TextQuestion>
                        Tem certeza de que deseja excluir{' '}
                        <strong>a carga agrupada Nº {orderGroupNumber}</strong>{' '}
                        do painel de gestão de Pedidos?
                    </TextQuestion>

                    <TextInformationItalic>
                        *Nesta ação, os pedidos serão reorganizados de forma
                        individual dentro do Painel, conforme suas respectivas
                        situações.
                    </TextInformationItalic>
                </TextConfirmContainer>
            ),
        })

    const saveOrderGroup = async (orderGroup, addedOrders) => {
        setAgroupButtonLoading(true)

        const orderGroupToSave = Object.assign({}, orderGroup)
        orderGroupToSave.orders = orderGroupToSave.orders.concat(addedOrders)

        const isCreation = !orderGroupToSave._id
        if (isCreation) {
            orderGroupToSave.requestDate = new Date()
            orderGroupToSave.hash = generateOrderGroupHash(orderGroupToSave)
            orderGroupToSave.seller = {
                _id: millUser.user._id,
                name: millUser.user.name,
                login: millUser.user.login,
            }
        }

        if (!isCreation) orderGroupToSave.edited = true

        orderGroupToSave.millId = mill._id

        await PromiseUtils.PromiseWrapper({
            promise: isCreation
                ? OrderGroupAPI.createOrderGroup(orderGroupToSave)
                : OrderGroupAPI.editOrderGroup(orderGroupToSave),
            onRejected: err => {
                console.error(err)
                addMessage(MessageType.ERROR, 'Erro ao salvar o agrupamento.')
            },
            onFulfilled: () => {
                addMessage(
                    MessageType.SUCCESS,
                    'Agrupamento de carga realizado com sucesso!'
                )

                handleOnClose()
                clearSelectedOrders()
                fetchOrderGroups()
            },
            callback: () => setAgroupButtonLoading(false),
        })
    }

    const handleDeleteOrderGroup = async id => {
        await PromiseUtils.PromiseWrapper({
            promise: OrderGroupAPI.deleteOrderGroup(id),
            onFulfilled: () => {
                addMessage(
                    MessageType.SUCCESS,
                    'Agrupamento excluído com sucesso!'
                )
                handleOnClose()
                fetchOrderGroups()
            },
            onRejected: () =>
                addMessage(MessageType.ERROR, 'Erro ao excluir o agrupamento.'),
            callback: () => setDeleteButtonLoading(false),
        })
    }

    const onNoteChange = value => {
        setOrderGroup(orderGroup => ({
            ...orderGroup,
            note: value,
        }))
    }

    const onDeleteOrderOnGroup = id => {
        setOrderGroup(orderGroup => ({
            ...orderGroup,
            orders: orderGroup.orders.filter(order => order._id !== id),
        }))
    }

    const onDeleteAddedOrder = id => {
        setAddedOrders(addedOrders => {
            return addedOrders.filter(order => order._id !== id)
        })
    }

    const handleSelectedOrders = selectedOrders => {
        if (orderGroup._id) {
            const { orders, outsideOrders } = separatesSelectedOrders(
                selectedOrders,
                orderGroups,
                orderGroup
            )

            setAddedOrders(outsideOrders)

            if (orders.length > 0) {
                setOrderGroup(orderGroup => ({
                    ...orderGroup,
                    orders: orderGroup.orders.concat(orders),
                }))
            }

            return
        }

        setAddedOrders(selectedOrders)
    }

    const isShowPrintIcon = orderGroup => {
        return orderGroup._id
    }

    const isShowDeleteButton = orderGroup => {
        return orderGroup._id && orderGroup.isEditable
    }

    const handleOnClose = () => {
        setOrderGroup(EMPTY_ORDER_GROUP)
        setLoadingGroup(false)
        setAgroupButtonLoading(false)
        setAddedOrders([])

        onClose()
    }

    const calculatesTotalWeight = (orderGroup, addedOrders) => {
        const tempOrderGroup = {
            orders: orderGroup.orders.concat(addedOrders),
        }

        return `Peso total (toneladas): ${formatDecimalPlace(
            sumTotalWeight(tempOrderGroup)
        )} ton.`
    }

    const renderInformation = () => {
        return (
            <Information>
                * Esta carga não poderá sofrer alterações pois existem pedidos
                que já foram expedidos.
            </Information>
        )
    }

    const renderDeleteGroupButton = () => {
        return (
            <DeleteOrderGroupButton
                deleteButtonLoading={deleteButtonLoading}
                disabled={!orderGroup.isEditable}
                onClick={() =>
                    showConfirmMessage(
                        orderGroup.orderGroupNumber,
                        orderGroup._id
                    )
                }
                loading={deleteButtonLoading}
                color={Colors.orange}
            >
                <IconDelete /> Excluir
            </DeleteOrderGroupButton>
        )
    }

    const renderAgroupButton = () => {
        return (
            // TODO usar props loading do AgroupButton para fazer o loading
            <AgroupButtonContainer>
                <AgroupButton
                    loading={agroupButtonLoading}
                    disabled={
                        orderGroup.orders.length + addedOrders.length <= 1
                    }
                    onClick={() => saveOrderGroup(orderGroup, addedOrders)}
                    data-testId="agroup-button"
                >
                    AGRUPAR
                </AgroupButton>
            </AgroupButtonContainer>
        )
    }

    if (loadingGroup) {
        return (
            <Modal visible={true} onClose={handleOnClose}>
                <Container>
                    <ModalLoading />
                </Container>
            </Modal>
        )
    }

    return (
        <Modal visible={true} onClose={handleOnClose}>
            <Container>
                <ModalInfo>
                    <TitleContainer>
                        <Title>Agrupamento de pedidos por carga</Title>

                        <ButtonContainer>
                            {isShowPrintIcon(orderGroup) && (
                                <PrintOrderGroup
                                    orderGroup={orderGroup}
                                    singleButton={
                                        !isShowDeleteButton(orderGroup)
                                    }
                                />
                            )}
                            {isShowDeleteButton(orderGroup) &&
                                renderDeleteGroupButton()}
                        </ButtonContainer>
                    </TitleContainer>

                    <SpaceBetween>
                        <Text>
                            {`carga agrupada Nº ${
                                orderGroup.orderGroupNumber || ''
                            }`}
                        </Text>

                        <Text>
                            {calculatesTotalWeight(orderGroup, addedOrders)}
                        </Text>
                    </SpaceBetween>

                    {orderGroup.isEditable === false ? (
                        renderInformation()
                    ) : (
                        <>
                            <Dropdown>
                                <OrderSearchAutoComplete
                                    value={addedOrders}
                                    options={orderOptions}
                                    onChange={event =>
                                        handleSelectedOrders(event.value)
                                    }
                                />
                            </Dropdown>
                            <Information>
                                * Para efetivar o processo de Agrupamento de
                                pedidos por carga deve-se clicar no botão
                                AGRUPAR, independente das demais alterações
                                realizadas: Mudanças na Situação do Pedido ou
                                Exclusão de pedidos do agrupamento.
                            </Information>
                        </>
                    )}

                    <OrdersCardsContainer data-testid="orders-card-container">
                        {orderGroup.orders.map(order => (
                            <OrderCardOnGroupDetail
                                order={order}
                                key={order._id}
                                onDeleteOrderGroup={onDeleteOrderOnGroup}
                                renderRemoveIcon={orderGroup.isEditable}
                                onChangeStatus={onChangeStatus}
                            />
                        ))}

                        {addedOrders.map(order => (
                            <OrderCardOnGroupDetail
                                order={order}
                                key={order._id}
                                onDeleteOrderGroup={onDeleteAddedOrder}
                                renderRemoveIcon={orderGroup.isEditable}
                                isOutsideGroup={orderGroup._id}
                                onChangeStatus={onChangeStatus}
                            />
                        ))}
                    </OrdersCardsContainer>

                    <NoteContainer>
                        <Label htmlFor="note">Observação</Label>
                        <InputTextArea
                            id="note"
                            name="note"
                            placeholder="Escreva alguma observação sobre este agrupamento."
                            value={orderGroup.note}
                            rows={2}
                            onChange={e => onNoteChange(e.target.value)}
                        />
                    </NoteContainer>

                    {orderGroup.isEditable === true && renderAgroupButton()}
                </ModalInfo>
            </Container>
        </Modal>
    )
}

const Container = styled.div`
    min-width: 1060px;
    min-height: 300px;
    box-sizing: content-box;
    max-height: 90vh;
    overflow: auto;
`

const ModalInfo = styled.div`
    padding: 35px;
    display: flex;
    flex-direction: column;
`

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
`

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Title = styled.span`
    font-size: 16px;
    color: ${Colors.brownishGrey};
    margin-bottom: 17px;
`

const Text = styled.span`
    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    line-height: 1.83;
    color: ${Colors.brownishGrey};
    margin-top: 8px;
`

const OrdersCardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 15px;
    grid-template-rows: auto;
    row-gap: 10px;
    margin-top: 12px;
`

const NoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
`

const AgroupButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 12px;
    padding-bottom: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 1px 4px 0 #c7c7cc;
    background-color: ${Colors.white};
`

const AgroupButton = styled(RoundButton)`
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
    width: 126px;
`

const DeleteOrderGroupButton = styled(OutlineButton)`
    display: flex;
    justify-content: ${props =>
        props.deleteButtonLoading ? 'center' : 'space-between'};
    align-items: center;
    width: 98px;
    margin-left: 16px;
    padding-right: 14px;
`

const IconDelete = styled(MdDelete)`
    width: 24px;
    height: 24px;
`
const ModalLoading = styled(Loading)`
    height: 300px;
`

const Information = styled.span`
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    line-height: 1.57;
    color: ${Colors.brownishGrey};
`

const Dropdown = styled.div`
    width: 100%;
    margin-top: 4px;
    margin-bottom: 10px;
`

const TextConfirmContainer = styled.div``

const TextInformationItalic = styled.div`
    font-style: italic;
    margin-top: 15px;
`

const TextQuestion = styled.div`
    margin-top: 15px;
`

export default OrderGroupModal
