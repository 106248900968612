import React from 'react'
import styled from 'styled-components'
import Colors from '../commons/Colors'
import * as DateUtils from '../commons/DateUtils'
import * as NumberUtils from '../commons/NumberUtils'
import { Label } from '../commons/commonStyles'
import { getUnQuantity } from './FragmentedOrderModal/FragmentedOrderUtils'

const OrderToPrint = React.forwardRef(
    ({ selectedCard, fragmentedOrders }, ref) => {
        const isUseFragmentedOrder = () =>
            Array.isArray(fragmentedOrders) && fragmentedOrders.length

        const renderFragments = () => {
            if (isUseFragmentedOrder()) {
                return (
                    <>
                        <Title>Remessas ({fragmentedOrders.length})</Title>
                        {fragmentedOrders.map(fragment => (
                            <FragmentedOrderContainer key={fragment.id}>
                                <FragmentedOrderCardTitleContainer>
                                    <Title>
                                        Remessa{' '}
                                        {fragment.number < 10
                                            ? `0${fragment.number}`
                                            : fragment.number}
                                    </Title>

                                    <Title>
                                        {`Pedido Nº ${selectedCard.orderNumber} / ${fragment.number}`}
                                    </Title>
                                </FragmentedOrderCardTitleContainer>

                                {fragment.fragmentedOrderItems.map(
                                    fragmentedOrderItem =>
                                        renderFragmentedOrderItem(
                                            fragmentedOrderItem
                                        )
                                )}
                            </FragmentedOrderContainer>
                        ))}
                    </>
                )
            }
        }

        const renderFragmentedOrderItem = fragmentedOrderItem => {
            return (
                <FragmentedOrderCardItemContainer>
                    <ContainerThreeColumns key={fragmentedOrderItem.id}>
                        <InfoContainer>
                            <FragmentedOrderItemInfo>
                                {fragmentedOrderItem?.legacyCode}
                            </FragmentedOrderItemInfo>
                        </InfoContainer>

                        <InfoContainer>
                            <FragmentedOrderItemInfo>
                                {fragmentedOrderItem.productName}
                            </FragmentedOrderItemInfo>
                        </InfoContainer>

                        <InfoContainer>
                            <ProductInfo>
                                {getUnQuantity(
                                    fragmentedOrderItem.unQuantity,
                                    fragmentedOrderItem.quantity
                                )}
                            </ProductInfo>
                        </InfoContainer>
                    </ContainerThreeColumns>
                </FragmentedOrderCardItemContainer>
            )
        }

        return (
            <Container ref={ref}>
                <HeaderContainer>
                    <Title>Detalhes do Pedido</Title>
                    <OrderNumber>
                        Pedido Nº {selectedCard.orderNumber}
                    </OrderNumber>
                </HeaderContainer>

                <CreatedByContainer>
                    <CreatedByInfo>
                        Feito por {selectedCard.seller.name} | Data:{' '}
                        {DateUtils.dateFormat(selectedCard.requestDate)} | Hora:{' '}
                        {DateUtils.timeFormat(selectedCard.requestDate)}
                    </CreatedByInfo>
                </CreatedByContainer>

                <ContainerThreeColumns>
                    <LabelWithAnswer>
                        <Label>CLIENTE</Label>
                        <Value>{selectedCard.farm.owner.name}</Value>
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label>ROTA</Label>
                        <Value>{selectedCard.category?.name}</Value>
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label>DATA DE ENTREGA</Label>
                        <DeliveryDateValue>
                            {DateUtils.dateFormat(selectedCard.deliveryDate)}
                        </DeliveryDateValue>
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label>FAZENDA</Label>
                        <Value>{selectedCard.farm.fancyName}</Value>
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label>RAZÃO SOCIAL</Label>
                        <Value>
                            {selectedCard.farm.corporateName || ' - '}
                        </Value>
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label>CÓD. DO CLIENTE</Label>
                        <Value>{selectedCard.farm.legacyCode || ' - '}</Value>
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label>TELEFONE</Label>
                        <Value>{selectedCard.farm.owner.phone || ' - '}</Value>
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label>ENDEREÇO DE ENTREGA</Label>
                        <Value>{selectedCard.farm.address || ' - '}</Value>
                    </LabelWithAnswer>
                </ContainerThreeColumns>

                <LabelWithAnswer>
                    <Label>OBSERVAÇÃO DO PEDIDO</Label>
                    <Value>{selectedCard.note || ' - '}</Value>
                </LabelWithAnswer>

                {Boolean(selectedCard.farm.note) && (
                    <>
                        <Divider />

                        <LabelWithAnswer>
                            <Title>Observação do cliente</Title>
                            <Value>{selectedCard.farm.note}</Value>
                        </LabelWithAnswer>
                    </>
                )}

                <Divider />

                <Title>Pagamento</Title>

                <ContainerThreeColumnsNonSpaced>
                    <LabelWithAnswer>
                        <Label>FORMA DE PAGAMENTO</Label>
                        <Value>{selectedCard.paymentForm}</Value>
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label
                            htmlFor="payment-deadline"
                            id="paymentDeadlineLabel"
                        >
                            PRAZO DE PAGAMENTO
                        </Label>
                        <Value
                            id="payment-deadline"
                            aria-labelledby="paymentDeadlineLabel"
                        >
                            {selectedCard.paymentDate
                                ? DateUtils.dateFormat(selectedCard.paymentDate)
                                : selectedCard.paymentDeadline}
                        </Value>
                    </LabelWithAnswer>
                </ContainerThreeColumnsNonSpaced>

                <Divider />

                <Title>Produtos ({selectedCard.items.length})</Title>

                {selectedCard.items.map(item => (
                    <ProductContainer key={item._id}>
                        <ContainerThreeColumns>
                            <ProductInfoContainer>
                                <Title>{item.product.legacyCode}</Title>
                            </ProductInfoContainer>
                            <InfoContainer>
                                <ProductInfoBold>
                                    {item.product.name.toLowerCase()} -{' '}
                                    {item.product.measurementUnit.weight}
                                </ProductInfoBold>
                                <ProductInfo>
                                    {item.unQuantity} /{' '}
                                    {NumberUtils.numberFormat(
                                        item.unitPrice,
                                        2,
                                        'R$ '
                                    )}{' '}
                                    por {item.product.measurementUnit.measure}
                                </ProductInfo>
                            </InfoContainer>
                            <ProductInfoContainer>
                                <Title>
                                    {NumberUtils.numberFormat(
                                        item.price,
                                        2,
                                        'R$ '
                                    )}
                                </Title>
                            </ProductInfoContainer>
                        </ContainerThreeColumns>
                    </ProductContainer>
                ))}
                <TotalWeightContainer>
                    <TotalWeight>
                        <TotalWeightLabel>
                            {'Peso total (toneladas): '}
                        </TotalWeightLabel>
                        {`${NumberUtils.formatDecimalPlace(
                            selectedCard.weightInTonnes
                        )} ton.`}
                    </TotalWeight>
                </TotalWeightContainer>

                <Divider marginTop={'16px'} />

                {renderFragments()}

                <ContainerTwoColumns>
                    <OrderTotal>TOTAL DO PEDIDO</OrderTotal>
                    <FinalValue>
                        {NumberUtils.numberFormat(selectedCard.price, 2, 'R$ ')}
                    </FinalValue>
                </ContainerTwoColumns>
                <Divider marginTop="9px" />
            </Container>
        )
    }
)

const Container = styled.div`
    background-color: ${Colors.white};

    @media print {
        div {
            break-inside: avoid;
        }
    }

    @page {
        margin: 70px !important;
    }
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const Title = styled.span`
    font-family: Roboto;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.18px;
    color: ${Colors.black};
    text-transform: capitalize;
`

const OrderNumber = styled(Title)`
    font-size: 25px;
    color: ${Colors.deepSeaBlue};
    line-height: 1.71;
    font-weight: 500;
`

const CreatedByContainer = styled.div`
    display: flex;
    height: 24px;
    justify-content: flex-end;
    margin-top: 5px;
`

const CreatedByInfo = styled.span`
    font-size: 15px;
    font-style: italic;
    color: ${Colors.brownishGrey};
    text-transform: capitalize;
`

const ContainerThreeColumns = styled.div`
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;
`

const ContainerThreeColumnsNonSpaced = styled.div`
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(3, auto);
`

const LabelWithAnswer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`

const Value = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.18px;
    color: ${Colors.black};
`

const DeliveryDateValue = styled(Value)`
    color: ${Colors.bluishGreen};
`

const Divider = styled.div`
    width: 100%;
    height: ${props => (props.height ? props.height : '5px')};
    background-color: ${Colors.veryLightPink};
    margin-bottom: 15px;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`

const ContainerTwoColumns = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
`

const ProductContainer = styled.div`
    padding-top: 10px;
    margin-left: 20px;
    padding-bottom: 0px;
    border-bottom: solid 1px ${Colors.lightBlueGreyTwo};
    justify-content: space-between;
`

const ProductInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const FinalValue = styled.span`
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.75;
    letter-spacing: 0.29px;
    text-align: right;
    color: ${Colors.bluishGreen};
`

const TotalWeightContainer = styled.div`
    padding-top: 20px;
    padding-left: 20px;
`

const TotalWeight = styled.span`
    font-family: Roboto;
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: ${Colors.brownishGrey};
`

const TotalWeightLabel = styled.span`
    font-family: Roboto;
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    font-weight: bold;
    color: ${Colors.black};
`

const OrderTotal = styled.span`
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.44px;
    color: ${Colors.brownishGrey};
`

const ProductInfo = styled.span`
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.4px;
    color: ${Colors.brownishGrey};
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
`

const FragmentedOrderItemInfo = styled(ProductInfo)`
    color: ${Colors.black};
    font-weight: bold;
`

const ProductInfoBold = styled(FragmentedOrderItemInfo)`
    margin-top: 5px;
    margin-bottom: 0px;
`

const FragmentedOrderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${Colors.whiteGray};
    padding: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
`

const FragmentedOrderCardTitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`

const FragmentedOrderCardItemContainer = styled.div`
    justify-content: space-between;
`
export default OrderToPrint
