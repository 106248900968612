import React from 'react'
import { useHistory } from 'react-router-dom'

import HomeContainer from './HomeContainer'

export default function HomeScreen() {
    const history = useHistory()
    const path = history.location.pathname

    const AVAILABLE_ROUTES = [
        '/home/gestao-pedidos',
        '/home/simples-faturamentos',
    ]

    const changeTab = route => {
        history.push(route)
    }

    return (
        <HomeContainer
            path={path}
            changeTab={changeTab}
            AVAILABLE_ROUTES={AVAILABLE_ROUTES}
        />
    )
}
