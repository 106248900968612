import React from 'react'
import styled from 'styled-components'

import Colors from '../../Colors'
import LoadingStaticIcon from '../../../assets/customIcons/LoadingStaticIcon'

const LoadingErrorComponent = () => {
    return (
        <Container>
            <ImageContainer>
                <LoadingStaticIcon />
            </ImageContainer>
            <TextContainer>
                <Text>
                    Houve um problema no carregamento de seus dados. Por favor
                    atualize sua página ou tente novanete mais tarde.
                </Text>
            </TextContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const ImageContainer = styled.div`
    padding-top: 80px;
`
const TextContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    width: 25%;
`
const Text = styled.p`
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.88;
    letter-spacing: 0.09px;
    text-align: center;
    color: ${Colors.strawberry};
`

export default LoadingErrorComponent
