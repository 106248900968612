import React from 'react'

export default function RightArrow() {
    return (
        <svg
            width="24"
            height="72"
            viewBox="0 0 24 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="60" r="12" fill="#005186" />
            <path
                d="m12 54-1.057 1.057 4.184 4.193H6v1.5h9.127l-4.184 4.192L12 66l6-6-6-6z"
                fill="#fff"
            />
        </svg>
    )
}
