import React from 'react'
import styled from 'styled-components'

/**
 *
 * @param {string} message - mensagem a ser mostrada
 * @param {React.FunctionComponent} Icon - icone a ser renderizado
 */

export const Message = ({ message, Icon }) => {
    return (
        <MessageBody>
            <IconContainer>
                <Icon size={20} />
            </IconContainer>
            {message}
        </MessageBody>
    )
}

const MessageBody = styled.div`
    display: flex;
`

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 3px;
`

export default Message
