import React from 'react'
import styled from 'styled-components'
import { IoMdClose } from 'react-icons/io'

const Modal = ({ children, visible, onClose }) => {
    return (
        visible && (
            <>
                <OutsideModal />
                <ModalWindow>
                    <CloseIcon
                        data-testid="modal-close-icon"
                        onClick={() => {
                            onClose && onClose()
                        }}
                    />
                    {children}
                </ModalWindow>
            </>
        )
    )
}

const OutsideModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(5, 45, 62, 0.7);
    z-index: 9997;
    display: block;
`

const ModalWindow = styled.div`
    background-color: #fff;
    position: fixed;
    background: white;
    height: auto;
    max-height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    z-index: 9998;
`

const CloseIcon = styled(IoMdClose)`
    margin: 8px;
    position: fixed;
    right: 0;
    height: 18px;
    cursor: pointer;
`

export default Modal
