import fetchIntercept from 'fetch-intercept'
import { createBrowserHistory } from 'history'
import moment from 'moment'

import { keyStorage } from './LocalStorage'
import * as UserAPI from '../login/UserAPI'

export const interceptor = fetchIntercept.register({
    response: function (response) {
        const userToken = localStorage.getItem(keyStorage.userToken)
        const isValidToken = validatesToken(userToken)

        if (userToken && (response.status === 401 || !isValidToken)) {
            if (userToken) localStorage.removeItem(keyStorage.userToken)

            createBrowserHistory().push('/login')
            window.location.reload()
            return response
        }

        return response
    },
})

export const validatesToken = userToken => {
    try {
        const HOURS_TO_REFRESH_TOKEN = 96
        userToken = JSON.parse(userToken)

        if (
            userToken &&
            userToken.accessToken &&
            userToken.accessToken.payload
        ) {
            const currentDate = Date.now().valueOf() / 1000
            const tokenExpirationDate = userToken.accessToken.payload.exp

            const hoursToExpirate = moment
                .duration(
                    (tokenExpirationDate - currentDate) * 1000,
                    'milliseconds'
                )
                .asHours()

            const isTokenExpirated = hoursToExpirate < 0
            if (isTokenExpirated) {
                return false
            }
            if (hoursToExpirate < HOURS_TO_REFRESH_TOKEN) {
                refreshToken(userToken.accessToken.token)
            }
            return true
        }
        return false
    } catch (err) {
        return false
    }
}

async function refreshToken(token) {
    const response = await UserAPI.renewToken(token)

    if (response.accessToken) {
        localStorage.setItem(keyStorage.userToken, JSON.stringify(response))
    }
}
