import React, { useState, useEffect } from 'react'

import HomeView from './HomeView'

export default function HomeContainer({ path, changeTab, AVAILABLE_ROUTES }) {
    const [activeIndex, setActiveIndex] = useState(
        AVAILABLE_ROUTES.indexOf(path)
    )

    useEffect(() => {
        setActiveIndex(AVAILABLE_ROUTES.indexOf(path))
    }, [path, AVAILABLE_ROUTES])

    const handleTabChange = e => {
        changeTab(AVAILABLE_ROUTES[e.index])
    }

    return (
        <HomeView activeIndex={activeIndex} handleTabChange={handleTabChange} />
    )
}
