export const mountShipmentItems = (orderItems, shipmentItems) => {
    return shipmentItems.map(shipmentItem => {
        const originalOrderItem = orderItems.find(
            item =>
                item.product._id === shipmentItem.productId ||
                item.product._id === shipmentItem.productSydleId
        )

        return {
            ...shipmentItem,
            measurementUnit: originalOrderItem.product.measurementUnit,
            productName: originalOrderItem.product.name,
            unQuantity: originalOrderItem.unQuantity,
            legacyCode: originalOrderItem.product.legacyCode,
        }
    })
}

export const mountShipments = (order, shipments) => {
    const mountedShipments = shipments
        .sort((a, b) => a.number - b.number)
        .map(shipment => {
            const mountedItems = mountShipmentItems(
                order.items,
                shipment.shipmentItems
            )

            return {
                ...shipment,
                shipmentItems: mountedItems,
            }
        })

    return mountedShipments
}
