import React from 'react'

const CalendarIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
            data-testid="calendar-icon"
        >
            <g clipPath="url(#a)">
                <path
                    d="M21.403 16.821c.372-.618.365-1.288.138-1.978a4.723 4.723 0 0 1-4.027-3.727c-.37-1.729.32-3.706 1.97-4.826 2.688-1.826 6.373-.462 7.237 2.625l1.95-.413c-1.128-4.368-6.37-6.525-10.312-3.847a6.658 6.658 0 0 0-1.759 9.296 6.722 6.722 0 0 0 4.803 2.87zM37.474 13.39c.31-.543.345-1.202.175-1.763a3.63 3.63 0 0 0-.068-.218 4.728 4.728 0 0 1-3.31-2.011 4.688 4.688 0 0 1 1.238-6.544c2.699-1.833 6.407-.452 7.25 2.667.117-.025.181-.05.27-.01l1.87.814A6.695 6.695 0 0 0 39.426.168a6.717 6.717 0 0 0-5.041 1.051C31.32 3.3 30.53 7.47 32.626 10.515a6.712 6.712 0 0 0 4.848 2.874zM34.368 27.068c-.358-.01-.7.117-.961.361l-7.729 7.21a.25.25 0 0 1-.346-.007l-1.991-2.01c-.83-.838-2.29-.27-2.299.926a1.32 1.32 0 0 0 .386.946c3.248 3.278 3.08 3.097 3.08 3.11a1.357 1.357 0 0 0 1.876.036l8.86-8.266c.865-.807.322-2.268-.876-2.306z"
                    fill="#E57109"
                />
                <path
                    d="M51.09 16.195 49.52 8.878l-6.617-2.884-8.813 1.865a4.228 4.228 0 0 0 3.267 3.016c-.328-.663-.744-1.125-.75-1.131a.245.245 0 0 1 .018-.348.25.25 0 0 1 .35.017c.034.037.84.93 1.15 2.08.182.601.159 1.27-.068 1.83-.269.671-.834 1.188-1.474 1.561a.248.248 0 0 1-.125.034c-.252 0-.344-.332-.126-.46.32-.186.588-.392.8-.614a7.199 7.199 0 0 1-5.963-5.367l-13.114 2.776a4.225 4.225 0 0 0 3.267 3.056 5.166 5.166 0 0 0-.768-1.168.245.245 0 0 1 .017-.348.25.25 0 0 1 .35.017 5.683 5.683 0 0 1 1.042 1.742c.581 1.585.016 2.887-1.434 3.73a.249.249 0 0 1-.34-.088.245.245 0 0 1 .09-.337c.306-.178.562-.373.77-.585a7.22 7.22 0 0 1-4.113-2.128l-.14 4.648 27.746-5.872-.9-3.943a.248.248 0 0 1 .483-.109l.924 4.048 3.363 1.055a.246.246 0 1 1-.15.47l-3.114-.977c1.343 8.23-.237 17.077-1.818 23.091-1.739 6.616-3.851 11.329-3.872 11.376a.248.248 0 0 1-.227.145c-.021 0 .935.107-27.983-3.147l-3.641 3.438 34.586 3.144c4.96-10.073 5.509-21.634 5.093-29.577a.248.248 0 0 1 .495-.026c.421 8.042-.142 19.769-5.218 29.973a.248.248 0 0 1-.245.136l-31.129-2.83-2.137 2.782 37.513 3.719c3.963-11.14 3.946-24.076 3.229-32.988a.247.247 0 0 1 .227-.265.247.247 0 0 1 .267.226c.687 8.536.731 20.747-2.719 31.587l2.791.2c2.28-10.212 2.364-20.301 2.03-26.986-.362-7.223-1.274-12.122-1.301-12.267z"
                    fill="#E57109"
                />
                <path
                    d="M42.854 37.422c1.576-6 3.153-14.837 1.783-23.018L16.77 20.3c-.093.873-.577 4.804-2.127 9.416-1.57 4.67-4.577 10.988-10.045 14.968l34.482 3.88c.384-.885 2.228-5.255 3.775-11.143zM27.19 42.634c-5.416 0-9.387-4.187-8.976-9.714.206-2.778 1.487-5.468 3.607-7.574 4.386-4.358 11.043-4.835 14.839-1.064 3.796 3.772 3.316 10.387-1.07 14.745-2.4 2.384-5.48 3.607-8.4 3.607z"
                    fill="#E57109"
                />
                <path
                    d="M36.31 24.63c-1.623-1.612-3.802-2.401-6.09-2.401-5.727 0-11.077 4.88-11.512 10.727-.407 5.476 3.71 9.567 9.223 9.164 2.677-.197 5.272-1.419 7.308-3.442 4.193-4.166 4.673-10.467 1.07-14.047zm-.727 5.103L26.724 38a1.857 1.857 0 0 1-2.568-.05c0 .012.006.006-3.08-3.11a1.809 1.809 0 0 1-.529-1.295A1.832 1.832 0 0 1 22.4 31.73c.49.004.95.198 1.295.546l1.821 1.838 7.552-7.045c.358-.334.828-.51 1.316-.494a1.83 1.83 0 0 1 1.78 1.883 1.81 1.81 0 0 1-.58 1.274z"
                    fill="#fff"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path
                        fill="currentColor"
                        transform="translate(0 .051)"
                        d="M0 0h57v56.637H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CalendarIcon
