import React, { useState } from 'react'
import styled from 'styled-components'
import { MdClear, MdWarning } from 'react-icons/md'

import Colors from '../commons/Colors'
import * as DateUtils from '../commons/DateUtils'
import * as OrderAPI from '../dashboard/OrderAPI'
import iconAlert from '../assets/dashboard/icon-alert.png'
import Dropdown from '../commons/components/Dropdown'
import OutlineButton from '../commons/components/OutlineButton'
import Tooltip from '../commons/components/Tooltip'
import { Label } from '../commons/commonStyles'
import { addMessage, MessageType } from '../commons/MessageUtils'
import { useDashboardContext } from '../context/DashboardContext'
import ConfirmService from '../commons/ConfirmService'
import { STATUS, STATUS_TEXT } from '../commons/Status'
import {
    optionsOrderOnGroup,
    optionsOrderOutsideGroup,
} from './OrderGroupUtils'

import { PromiseWrapper } from '../commons/PromiseUtils'

const OrderCardOnGroupDetail = ({
    order,
    onDeleteOrderGroup,
    isOutsideGroup = false,
    renderRemoveIcon,
    onChangeStatus,
}) => {
    const { changeOrderStatus } = useDashboardContext()

    const [status, setStatus] = useState(order.status)
    const [disabled, setDisabled] = useState(true)
    const [isChangingStatus, setIsChangingStatus] = useState(false)

    const handleDropdownChange = value => {
        if (value !== status) {
            setDisabled(false)
            setStatus(value)
        }
    }

    const showConfirmMessage = (order, status) =>
        ConfirmService.addConfirmMessage({
            title: 'Deseja alterar esta carga para Em Expedição?',
            actionButtonText: 'Alterar',
            onClose: () => {},
            actionButtonFunction: async () =>
                await sendChangeStatus(order, status),
            renderMessage:
                () => `Ao mover um pedido ou carga para a situação Em Expedição, não será mais 
                                possível alterar o Agrupamento de Carga ao qual é pertencente, impedindo 
                                a possibilidade de alterações dentro deste grupo.`,
        })

    const handleChangeStatus = async (order, status) => {
        if (status === STATUS.EM_EXPEDICAO_TRANSITO) {
            showConfirmMessage(order, status)
            return
        }
        await sendChangeStatus(order, status)
    }

    const sendChangeStatus = async (order, status) => {
        setIsChangingStatus(true)
        changeOrderStatus(order._id, status)
        onChangeStatus(order._id, status)
        setDisabled(true)
        PromiseWrapper({
            promise: OrderAPI.updateOrderStatus(order._id, status),
            onRejected: () => {
                changeOrderStatus(order._id, order.status)
                onChangeStatus(order._id, order.status)
                setStatus(order.status)
                addMessage(
                    MessageType.ERROR,
                    `Erro ao movimentar pedido ${order.orderNumber} para a situação ${STATUS_TEXT[status]}.`
                )
            },
            callback: () => setIsChangingStatus(false),
        })
    }

    const isNotDeliveredOrder = order => order.status !== STATUS.ENTREGUE

    const getOptions = () => {
        return isOutsideGroup ? optionsOrderOutsideGroup : optionsOrderOnGroup
    }

    return (
        <Container>
            <CardContainer>
                <Card isOutsideGroup={isOutsideGroup}>
                    <SpaceBetween>
                        <Owner>{order.farm.owner.name}</Owner>

                        {isOutsideGroup && (
                            <Tooltip label="* Este pedido ainda não pertence ao agrupamento.">
                                <TooltipIcon data-testid="orderOutsideGroupIcon" />
                            </Tooltip>
                        )}

                        {renderRemoveIcon && (
                            <RemoveIcon
                                onClick={() => onDeleteOrderGroup(order._id)}
                                data-testid="removeButton"
                            ></RemoveIcon>
                        )}
                    </SpaceBetween>

                    <Column>
                        <OrderNumber>{`Pedido Nº ${order.orderNumber}`}</OrderNumber>
                        <BasicText>{order.farm.fancyName}</BasicText>

                        <SpaceBetween>
                            <BasicText>{order.category.name}</BasicText>
                        </SpaceBetween>

                        <BasicText>
                            Feito por <strong>{order.seller.name}</strong> em{' '}
                            <strong>
                                {DateUtils.dateFormat(order.requestDate)}
                            </strong>
                        </BasicText>
                    </Column>

                    <Delivery>
                        <DeliveryLabel>Data de Entrega:</DeliveryLabel>
                        <DeliveryDate>
                            {DateUtils.dateFormat(order.deliveryDate)}
                        </DeliveryDate>
                    </Delivery>
                </Card>
                {order.isUrgent && (
                    <UrgentOrder>
                        <Icon src={iconAlert} alt="!" />
                        Pedido Urgente
                    </UrgentOrder>
                )}
            </CardContainer>

            {isNotDeliveredOrder(order) && (
                <StatusContainer>
                    <Label>* SITUAÇÃO DO PEDIDO</Label>

                    <StatusSelect>
                        <Dropdown
                            options={getOptions()}
                            value={status}
                            onChange={e => handleDropdownChange(e.value)}
                        />
                        <OutlineButton
                            loading={isChangingStatus}
                            disabled={disabled}
                            color={Colors.orange}
                            onClick={() => handleChangeStatus(order, status)}
                        >
                            Alterar
                        </OutlineButton>
                    </StatusSelect>
                </StatusContainer>
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${Colors.veryLightPink};
    padding: 10px;
    border-radius: 4px;
`

const CardContainer = styled.div``

const Card = styled.div`
    box-shadow: 0 1px 4px 0 #c7c7cc;
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    width: 300px;
    border: ${props => props.isOutsideGroup && 'solid 3px rgba(235,57,75,0.3)'};
`

const BasicText = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.21px;
    color: #8e8e93;
    white-space: break-spaces;
`

const Owner = styled(BasicText)`
    font-size: 16px;
    font-weight: bold;
    color: ${Colors.black};
`

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const RemoveIcon = styled(MdClear)`
    border: solid 2px #c7c7c7;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    width: 24px;
`

const OrderNumber = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.black};
`

const Delivery = styled.div`
    display: flex;
`

const DeliveryLabel = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.black};
    margin-right: 5px;
`

const DeliveryDate = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: #eb394b;
`

const UrgentOrder = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9f1111;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: white;
    border-radius: 0 0 4px 4px;
`

const Icon = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 5px;
`

const StatusContainer = styled.div`
    margin-top: 1.3vh;
`

const StatusSelect = styled.div`
    display: grid;
    grid-template-columns: 65% 33%;
    grid-column-gap: 2%;
    align-items: center;
`

const TooltipIcon = styled(MdWarning)`
    color: ${Colors.strawberry};
    margin-right: 10px;
    width: 24px;
    height: 24px;
`

export default OrderCardOnGroupDetail
