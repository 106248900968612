import React from 'react'

export default function IndicatorsIcon() {
    return (
        <svg
            width="25"
            height="19"
            viewBox="0 0 21 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 0C6.14 0 3 3.364 3 7.5S6.14 15 10 15s7-3.364 7-7.5S13.86 0 10 0zm.585 11.464H9.389v-1.369h1.197v1.369zm-1.197-2.68h1.197V3.535H9.389v5.247z"
                fill="#EB394B"
            />
            <path
                d="M4 .886 3.112 0A10.527 10.527 0 0 0 0 7.5c0 2.833 1.105 5.497 3.112 7.5L4 14.114C.346 10.467.346 4.533 4 .886zM17.887 0 17 .886c3.654 3.647 3.654 9.581 0 13.228l.887.886A10.527 10.527 0 0 0 21 7.5c0-2.833-1.105-5.497-3.113-7.5z"
                fill="#EB394B"
            />
        </svg>
    )
}
