import styled from 'styled-components'
import Colors from './Colors'
import { MdModeEdit, MdDelete } from 'react-icons/md'

export const Label = styled.label`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.09px;
    text-transform: uppercase;
    color: ${Colors.brownishGrey};
`

export const EditIcon = styled(MdModeEdit)`
    font-size: 24px;
    color: ${Colors.brownishGrey};
    cursor: pointer;
`

export const DisabledDeleteIcon = styled(MdDelete)`
    font-size: 24px;
    color: ${Colors.lightBlueGreyTwo};
`

export const DeleteIcon = styled(DisabledDeleteIcon)`
    color: ${Colors.brownishGrey};
    cursor: pointer;
`
