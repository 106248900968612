import { useState, useEffect } from 'react'

/**
 *
 * @param {value} value - valor do componente
 * @param {number} delay - tempo de delay
 */

export default function useDebounce(value, delay = 500) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const debounce = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(debounce)
        }
    }, [value, delay])

    return debouncedValue
}

let timeoutRef = null
export function debounce(fn, delay = 800) {
    clearTimeout(timeoutRef)
    timeoutRef = setTimeout(fn, delay)
}
