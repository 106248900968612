import React from 'react'
import { useHistory } from 'react-router-dom'

import ClientContainer from './ClientContainer'

function ClientScreen() {
    const history = useHistory()

    const goToClientProductUsage = (clientId, clientName) =>
        history.push({
            pathname: `/estoque-virtual/${clientId}`,
            state: { clientId, clientName },
        })

    return <ClientContainer goToProductUsage={goToClientProductUsage} />
}

export default ClientScreen
