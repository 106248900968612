import React from 'react'
import { MdDone, MdHighlightOff } from 'react-icons/md'
import { toast } from 'react-toastify'
import Message from './components/Message'

export const MessageType = {
    SUCCESS: 'success',
    ERROR: 'error',
}

const messagesConfig = {
    [MessageType.SUCCESS]: { method: toast.success, icon: MdDone },
    [MessageType.ERROR]: { method: toast.error, icon: MdHighlightOff },
}

export const addMessage = (type, message) => {
    const toastMethod = getToastMethod(type)
    const Icon = getIconMessage(type)

    if (toastMethod) {
        toastMethod(<Message message={message} Icon={Icon} />, {
            containerId: 'App',
        })
    }
}

export const addMessageLogin = message => {
    toast(message, { containerId: 'LoginScreen' })
}

const getToastMethod = type => {
    const messageConfig = messagesConfig[type]
    if (messageConfig) {
        return messageConfig.method
    }
    return null
}

const getIconMessage = type => {
    const messageConfig = messagesConfig[type]
    if (messageConfig) {
        return messageConfig.icon
    }
    return null
}
