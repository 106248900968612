import { OverlayPanel } from 'primereact/overlaypanel'
import React, { useRef } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'

import OrderGroupIcon from '../../assets/customIcons/OrderGroupIcon'
import ProductSummaryIcon from '../../assets/customIcons/ProductSummaryIcon'
import Colors from '../../commons/Colors'
import BasicOutlineButton from '../../commons/components/OutlineButton'
import OrderGroupPrintTemplate from './OrderGroupPrintTemplate'
import ProductSummaryPrintTemplate from './ProductSummaryPrintTemplate'

const PrintOrderGroup = ({ orderGroup, singleButton }) => {
    const orderGroupRef = useRef()
    const productSummaryRef = useRef()
    const overlayPanelRef = useRef()

    const printProductSummary = useReactToPrint({
        content: () => productSummaryRef.current,
    })

    const printOrderGroup = useReactToPrint({
        content: () => orderGroupRef.current,
    })
    return (
        <>
            <OutlineButton
                color={Colors.orange}
                onClick={e => {
                    overlayPanelRef.current.toggle(e)
                }}
            >
                <PrintIcon />
            </OutlineButton>

            <PrintOptions
                ref={overlayPanelRef}
                showCloseIcon
                dismissable={false}
                singleButton={singleButton}
            >
                <PrintOption onClick={printProductSummary}>
                    <ProductSummaryIcon />
                    <PrintOptionText>Resumo Por Produto</PrintOptionText>
                </PrintOption>
                <PrintOption onClick={printOrderGroup}>
                    <OrderGroupIcon />
                    <PrintOptionText>Agrupamento Completo</PrintOptionText>
                </PrintOption>
            </PrintOptions>

            <DisplayNone>
                <ProductSummaryPrintTemplate
                    ref={productSummaryRef}
                    orderGroup={orderGroup}
                />
                <OrderGroupPrintTemplate
                    ref={orderGroupRef}
                    orderGroup={orderGroup}
                />
            </DisplayNone>
        </>
    )
}

const PrintOptions = styled(OverlayPanel)`
    left: ${props => (props.singleButton ? `93.3%` : `82%`)} !important;
    top: 75px !important;
`

const PrintOption = styled.button`
    display: flex;
    align-items: center;
    font-size: 12px;
    min-width: 180px;

    color: ${Colors.brownishGrey};
    background-color: ${Colors.white};
    border: none;

    &:hover {
        cursor: pointer;
    }
`

const PrintOptionText = styled.p`
    margin-left: 6px;
`

const OutlineButton = styled(BasicOutlineButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 2px;
`

const PrintIcon = styled(AiFillPrinter)`
    height: 24px;
    width: 24px;
`

const DisplayNone = styled.div`
    display: none;
`

export default PrintOrderGroup
