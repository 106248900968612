import { getBaseURL, getToken } from '../environment'

const baseURL = getBaseURL()

export const getAllSellersAndStc = () => {
    return fetch(`${baseURL}/clients/users`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response =>
            response.json().then(({ data }) => ({
                data: data.map(user => ({
                    name: user.name,
                    _id: user.id,
                    discountMaxPercentage: user.discountMaxPercentage,
                    increaseMaxPercentage: user.increaseMaxPercentage,
                    gcpId: user.id,
                    login: user.login,
                    email: user.email,
                })),
            }))
        )
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getAllSellers = async millId => {
    return fetch(`${baseURL}/clients/mills/${millId}/sellers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response =>
            response.json().then(({ data }) => ({
                sellers: data.map(user => ({
                    name: user.name,
                    gcpId: user.id,
                })),
            }))
        )
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getAllStcUsers = millId => {
    return fetch(`${baseURL}/clients/mills/${millId}/stcs`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response =>
            response.json().then(({ data }) => ({
                sellers: data.map(user => ({
                    name: user.name,
                    _id: user.id,
                    discountMaxPercentage: user.discountMaxPercentage,
                    increaseMaxPercentage: user.increaseMaxPercentage,
                    gcpId: user.id,
                    login: user.login,
                    email: user.email,
                })),
            }))
        )
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const signIn = (login, password) => {
    return fetch(`${baseURL}/auth/login`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            user: login,
            password,
        }),
    })
        .then(response => response.json())
        .catch(error => {
            console.error('Erro: ' + JSON.stringify(error))
            throw error
        })
}

export const renewToken = token => {
    return fetch(`${baseURL}/auth/refreshToken`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({ token }),
    })
        .then(response => response.json())
        .catch(error => {
            console.error('Erro: ' + JSON.stringify(error))
            throw error
        })
}

export const generateZendeskToken = () => {
    return fetch(`${baseURL}/auth/login/zendesk`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(error => {
            console.error('Erro: ' + JSON.stringify(error))
            throw error
        })
}

export const getUser = async id => {
    try {
        const params = new URLSearchParams({ id })
        const response = await fetch(
            `${baseURL}/clients/users?${params.toString()}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getToken()}`,
                },
            }
        )
        if (!response.ok) {
            throw new Error(await response.text())
        }

        const body = await response.json()
        const [user] = body.data
        return {
            user: {
                name: user.name,
                _id: user.id,
                discountMaxPercentage: user.discountMaxPercentage,
                increaseMaxPercentage: user.increaseMaxPercentage,
                gcpId: user.id,
                login: user.login,
                email: user.email,
                mill: {
                    _id: user.mill.id,
                    gcpId: user.mill.id,
                    nome: user.mill.name,
                    legacyCode: user.mill.legacyCode,
                },
            },
            aclUserGroups: parseUserGroups(user),
        }
    } catch (err) {
        console.error('Erro: ', JSON.stringify(err))
        throw err
    }
}

function parseUserGroups(user) {
    return user.userGroups.map(userGroup => {
        switch (userGroup) {
            case 'seller':
                return 'prodapFabsVendedor'
            case 'stc':
                return 'prodapFabsSuporteComercial'
            default:
                return undefined
        }
    })
}
