import React from 'react'

const OrderIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.997 8.998h4.001V4.997H4.997v4.001zm6.002 12.005h4.002v-4.001h-4.002v4.002zm-2 0H4.996v-4.001h4.001v4.002zm-4.002-6.002h4.001v-4.002H4.997v4.002zm10.004 0h-4.002v-4.002h4.002v4.002zm2-10.004v4.001h4.002V4.997h-4.001zm-2 4.001h-4.002V4.997h4.002v4.001zm2 6.003h4.002v-4.002h-4.001v4.002zm4.002 6.002h-4.001v-4.001h4.002v4.002z"
                fill="currentColor"
            />
        </svg>
    )
}

export default OrderIcon
