import React from 'react'
import ReactDOMServer from 'react-dom/server'
import styled from 'styled-components'
import { Button } from 'primereact/button'

/**
 *
 * @param {React.FunctionComponent} children - componente a ser renderizado
 * @param {React.ReactElement} label - conteúdo do tooltip
 * @param {string} position - posição do tooltip
 * @param {string} className - Classe do componente
 *
 */

export const Tooltip = ({
    children,
    label,
    position = 'bottom',
    className,
}) => {
    return (
        <TooltipContainer className={className}>
            <TooltipComponent
                tooltip={ReactDOMServer.renderToString(label)}
                tooltipOptions={{ position }}
                type="button"
            >
                {children}
            </TooltipComponent>
        </TooltipContainer>
    )
}

const TooltipComponent = styled(Button)``

const TooltipContainer = styled.div`
    display: flex;
    justify-content: center;

    .p-button,
    .p-button:enabled:active,
    .p-button:enabled:hover {
        background-color: transparent;
        border: none;
        font-size: 0px;
        cursor: default;
    }

    .p-button:enabled:focus {
        box-shadow: none;
    }
`

export default Tooltip
