import React from 'react'
import styled from 'styled-components'
import Colors from '../Colors'

/**
 *
 * @param {string} id - identificação unica do elemento
 * @param {string} name - nome do elemento
 * @param {string} placeholder - texto a ser motrado quando o elemento não tem valor
 * @param {string} className - className do elemento
 * @param {value} value - valor do elemento
 * @param {event} onChange - chamado quando o valor é alterado
 * @param {number} cols - especifica a largura visivel da area de texto
 * @param {number} rows - especifica o numero de linhas visivel na area de texto
 */

export const InputTextarea = ({
    id,
    name,
    placeholder,
    className,
    value,
    onChange,
    cols,
    rows = 4,
}) => {
    return (
        <TextArea
            id={id}
            name={name}
            className={className}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            cols={cols}
            rows={rows}
        />
    )
}

const TextArea = styled.textarea`
    outline: 0;
    padding: 0.429em;

    border-color: #dadada;
    border-radius: 2px;

    font-family: 'Open Sans', 'Helvetica Neue';
    font-size: 14px;
    color: #666666;

    transition: box-shadow 0.2s;
    resize: none;

    :focus {
        box-shadow: 0 0 0 0.2em #e4e9ec;
    }

    &::placeholder {
        font-family: 'Roboto';
        color: ${Colors.lightBlueGreyTwo};
        font-size: 14px !important;
    }
`

export default InputTextarea
