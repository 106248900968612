import React, { useState, useEffect } from 'react'

import { MessageType, addMessage } from '../../commons/MessageUtils'
import { debounce } from '../../commons/components/Debounce'
import ClientListView from './ClientListView'
import * as ClientAPI from '../ClientAPI'

export default function ClientListContainer() {
    const [filter, setFilter] = useState('')
    const [clients, setClients] = useState([])
    const [showConfirm, setShowConfirm] = useState(false)
    const [clientToDelete, setClientToDelete] = useState(null)
    const [isLoadingClients, setIsLoadingClients] = useState(true)
    const [isDeletingClient, setIsDeletingClient] = useState(false)

    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0)
    const rows = 10

    useEffect(() => {
        fetchClients(rows, first) // TODO validar esse disable eslint
        // eslint-disable-next-line
    }, [])

    async function fetchClients(size, from, term) {
        try {
            setIsLoadingClients(true)
            const response = await ClientAPI.getAllClients(size, from, term)

            if (response?.data?.length > 0) {
                setClients(response.data)
                setTotalRecords(response.total)
            }

            setIsLoadingClients(false)
        } catch (err) {
            console.error(err.message)
            setIsLoadingClients(false)
        }
    }

    const openConfirmationModal = client => {
        setShowConfirm(true)
        setClientToDelete(client)
    }

    const onConfirmDeleteClient = async clientToDelete => {
        try {
            setIsDeletingClient(true)
            const response = await ClientAPI.deleteClient(clientToDelete.id)
            if (response._messages && response._messages.length > 0) {
                if (
                    response._messages.find(msg =>
                        msg.text.includes(
                            'Não pode ser removido devido à dependencia'
                        )
                    )
                ) {
                    addMessage(
                        MessageType.ERROR,
                        `O cliente ${clientToDelete.owner?.name} possui pedidos vinculados à ele e não pode ser excluído.`
                    )
                } else {
                    addMessage(
                        MessageType.ERROR,
                        `Erro ao tentar exluir o cliente: ${clientToDelete.owner?.name}`
                    )
                }
            } else {
                addMessage(MessageType.SUCCESS, 'Cliente excluído com sucesso!')
            }
            fetchClients(rows, first)
            setShowConfirm(false)
            setClientToDelete(null)
        } catch (err) {
            addMessage(
                MessageType.ERROR,
                `Erro ao tentar excluir o cliente: ${clientToDelete.owner?.name}`
            )
            console.error(err.message)
        } finally {
            setIsDeletingClient(false)
        }
    }

    const handlePagination = event => {
        setFirst(event.first)
        fetchClients(event.rows, event.page + 1, filter)
    }

    const handleChangeFilter = e => {
        const filter = e.target.value
        setFilter(filter)
        debounce(() => {
            setFirst(0)
            fetchClients(rows, first, filter)
        })
    }

    return (
        <ClientListView
            filter={filter}
            clients={clients}
            showConfirm={showConfirm}
            setShowConfirm={setShowConfirm}
            clientToDelete={clientToDelete}
            setClientToDelete={setClientToDelete}
            isLoadingClients={isLoadingClients}
            isDeletingClient={isDeletingClient}
            totalRecords={totalRecords}
            first={first}
            rows={rows}
            openConfirmationModal={openConfirmationModal}
            onConfirmDeleteClient={onConfirmDeleteClient}
            handlePagination={handlePagination}
            handleChangeFilter={handleChangeFilter}
        />
    )
}
