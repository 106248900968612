import React from 'react'
import styled from 'styled-components'

import Colors from '../commons/Colors'

import ModalResponsive from '../commons/components/ModalResponsive'
import RoundButton from '../commons/components/RoundButton'

/**
 * @param {string} title - titulo do componente
 * @param {boolean} isVisible - se o componente está visivel ou não
 * @param {boolean} isLoading - se está tendo algum carregamento
 * @param {React.FunctionComponent} renderDeletionText - componente a ser renderizado como mensagem de confirmação
 * @param {function} onClose - ação a ser feita ao fechar o componente
 * @param {function} onConfirm - ação a ser feita ao excluir um item especifico
 */

const DeletionAlertModalComponent = ({
    title,
    isVisible,
    isLoading,
    onConfirm,
    onClose,
    renderDeletionText,
}) => {
    return (
        <ModalResponsive title={title} visible={isVisible} onClose={onClose}>
            <ModalContainer>
                <BodyContainer>{renderDeletionText()}</BodyContainer>
                <FooterContainer>
                    <CancelButton onClick={onClose}>Cancelar</CancelButton>
                    <ConfirmButton onClick={onConfirm} loading={isLoading}>
                        Sim, excluir
                    </ConfirmButton>
                </FooterContainer>
            </ModalContainer>
        </ModalResponsive>
    )
}

const ModalContainer = styled.div`
    width: 500px;
    padding: 24px;
`

const BodyContainer = styled.div``

const FooterContainer = styled.div`
    display: flex;
    padding-top: 35px;
    justify-content: flex-end;
`

const CancelButton = styled(RoundButton)`
    margin-right: 12px;
    background-color: ${Colors.white};
    color: #f44336;
    border-color: #f44336;
`

const ConfirmButton = styled(RoundButton)`
    background-color: #f44336;
    color: ${Colors.white};
    border-color: #f44336;
`

export default DeletionAlertModalComponent
