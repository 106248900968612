import React from 'react'
import Styled from 'styled-components'
import FeatureCardComponent from './FeatureCardComponent'

const FeatureCardListComponent = ({
    features = [],
    handleClick,
    isNewFeature,
}) => {
    return (
        <ListContainer>
            {features.map(feature => (
                <FeatureCardComponent
                    title={feature.name}
                    description={feature.description}
                    source={feature.source}
                    fileURL={feature?.fileURL}
                    releaseDate={feature.releaseDate}
                    version={feature.version}
                    featureId={feature.featureId}
                    handleClick={handleClick}
                    isNewFeature={isNewFeature}
                />
            ))}
        </ListContainer>
    )
}

const ListContainer = Styled.div`
    width: 100%;
`
export default FeatureCardListComponent
