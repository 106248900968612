import React from 'react'
import styled from 'styled-components'

import FragmentOrderIcon from '../../assets/customIcons/FragmentOrderIcon'
import { useDashboardContext } from '../../context/DashboardContext'

export default function FragmentOrderIconButton({
    fragmentedOrder,
    color = '#333',
    backgroundColor = '#C6CBD4',
}) {
    const { setSelectedFragmentedOrder } = useDashboardContext()

    const handleOnClick = e => {
        e.stopPropagation()
        setSelectedFragmentedOrder(fragmentedOrder)
    }

    return (
        <Button onClick={e => handleOnClick(e)}>
            <FragmentOrderIcon
                color={color}
                backgroundColor={backgroundColor}
            />
        </Button>
    )
}

const Button = styled.button`
    cursor: pointer;
    width: 17px;
    height: 17px;
    padding: 0;
    border: none;
`
