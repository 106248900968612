import React from 'react'
import styled from 'styled-components'

import Colors from '../commons/Colors'
import FieldErrorMessageComponent from '../commons/components/error/FieldErrorMessageComponent'
import RadioButton from '../commons/components/RadioButton'
import { Label } from '../commons/commonStyles'

const FreightOptionsComponent = ({
    setFreightType,
    freightType: selectedFreightOption,
    invalidFieldsHandler,
}) => {
    const freightOptions = [
        { labelText: 'CIF (com frete)', value: 'CIF' },
        { labelText: 'FOB (sem frete)', value: 'FOB' },
    ]

    const { invalidFields, getInvalidField, setValidField } =
        invalidFieldsHandler

    const handleChange = value => {
        setFreightType('freightType', value)
        setValidField('freightType')
    }

    return (
        <Content>
            <FreightOptionsContainer>
                <Label>* Escolher Frete</Label>

                <Options>
                    {freightOptions.map(freightOption => (
                        <Option key={`${freightOption.value}-freight`}>
                            <RadioButton
                                id="freightOption"
                                name="freightOption"
                                value={freightOption.value}
                                checked={
                                    freightOption.value ===
                                    selectedFreightOption
                                }
                                onChange={e => handleChange(e.value)}
                                isInvalid={getInvalidField(
                                    'freightType',
                                    invalidFields
                                )}
                            />
                            <OptionLabel htmlFor="freightOption">
                                {freightOption.labelText}
                            </OptionLabel>
                        </Option>
                    ))}
                </Options>
            </FreightOptionsContainer>
            <FieldErrorMessageComponent
                isInvalid={getInvalidField('freightType', invalidFields)}
            />
        </Content>
    )
}

export default FreightOptionsComponent

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    background-color: ${Colors.white};
`

const Options = styled.div`
    display: grid;
    grid-row-gap: 2.8vh;
    margin-top: 1.68vh;
`

const Option = styled.div`
    display: flex;
    flex-direction: row;
`

const OptionLabel = styled.label`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: ${Colors.brownishGrey};
`

const FreightOptionsContainer = styled.div``
