import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { TabPanel } from 'primereact/tabview'

import ProductLabelFormScreen from './ProductLabelFormScreen'
import WarrantyLevelScreen from './WarrantyLevelScreen'
import ProductFormScreen from './ProductFormScreen'
import TabView from '../commons/components/TabView'
import * as ProductAPI from '../price/ProductAPI'
import BasicPage from '../commons/components/BasicPage'
import { useMillContext } from '../context/MillContext'

export const ProductManagementScreen = () => {
    const location = useLocation()
    const [isEdition, setIsEdition] = useState(false)
    const { mill } = useMillContext()

    const [initialFormValue, setInitialFormValue] = useState({
        active: false,
        legacyCode: '',
        name: '',
        description: '',
        category: '',
        classificationId: '',
        price: '',
        weight: '',
        measurementUnitId: '',
        trackStock: true,
        millId: mill.gcpId,
    })

    useEffect(() => {
        const fetchData = async id => {
            const response = await ProductAPI.findById(id)
            const product = response.data
            if (response && product) {
                delete product.classification
                delete product.measurementUnit
                product.active = !product.active
                setInitialFormValue(product)
            }
        }
        if (location.state && location.state.productId) {
            setIsEdition(true)
            fetchData(location.state.productId)
        }
    }, [location.state])

    return (
        <BasicPage title={`Gestão de Produto`}>
            <TabView>
                <TabPanel header="Cadastro de Produto">
                    <ProductFormScreen
                        initialFormValue={initialFormValue}
                        setInitialFormValue={setInitialFormValue}
                        isEdition={isEdition}
                    />
                </TabPanel>

                <TabPanel header="Informações do Rótulo">
                    <ProductLabelFormScreen product={initialFormValue} />
                </TabPanel>

                <TabPanel header="Níveis de Garantia">
                    <WarrantyLevelScreen product={initialFormValue} />
                </TabPanel>
            </TabView>
        </BasicPage>
    )
}

export default ProductManagementScreen
