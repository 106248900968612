import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { MdSearch } from 'react-icons/md'
import { AiFillCaretDown } from 'react-icons/ai'
import InputWithMessage from './InputWithMessage'
import OrderGroupIcon from '../../assets/customIcons/OrderGroupIcon'
import NewOrderIcon from '../../assets/customIcons/NewOrderIcon'
import Colors from '../Colors'
import { FILTER_TYPE } from '../../dashboard/DashboardScreenUtils'

/**
 * @param {string} name - nome do elemento
 * @param {string} value - valor do elemento
 * @param {event} onChangeFilter - chamado quando o valor é alterado
 * @param {event} onChangeFilterType - chamado quando o tipo do filtro é alterado
 */
export const SearchInputWithOptions = ({
    name,
    value,
    onChangeFilter,
    onChangeFilterType,
}) => {
    const containerRef = useRef()
    const [optionsIsVisible, setOptionsIsVisible] = useState(false)
    const [options] = useState([
        {
            icon: <NewOrderIcon />,
            label: 'Pesquise Nº do Pedido, Cliente, Fazenda ou Rota',
            value: FILTER_TYPE.DEFAULT,
        },
        {
            icon: <OrderGroupIcon />,
            label: 'Pesquise por Nº do Agrupamento',
            value: FILTER_TYPE.ORDERGROUP,
        },
    ])
    const [placeholder, setPlaceholder] = useState(options[0].label)

    useEffect(() => {
        window.addEventListener('click', callback)

        function callback(event) {
            if (isClickFromFilterInput(event)) return
            setOptionsIsVisible(false)
        }

        function isClickFromFilterInput(event) {
            const path = event.composedPath()
            return path.some(piece => piece.id === 'container-search-input')
        }

        return () => window.removeEventListener('click', callback)
    }, [])

    function selectOption(option) {
        setOptionsIsVisible(false)
        setPlaceholder(option.label)
        onChangeFilterType(option.value)
    }

    function getOptionsContainerWidth() {
        if (!containerRef.current) return 0
        const { width } = getComputedStyle(containerRef.current)
        return width
    }

    return (
        <Container
            id="container-search-input"
            ref={containerRef}
            data-testid="container-search-input"
        >
            <SearchIcon />
            <Input
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={e => onChangeFilter(e)}
            />

            <MenuIcon
                onClick={() => setOptionsIsVisible(visible => !visible)}
            />

            <OptionsContainer
                visible={optionsIsVisible}
                width={getOptionsContainerWidth()}
            >
                {options.map(option => (
                    <Option onClick={() => selectOption(option)}>
                        <OptionIcon>{option.icon}</OptionIcon>
                        <OptionLabel>{option.label}</OptionLabel>
                    </Option>
                ))}
            </OptionsContainer>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #dadada;
    border-radius: 4px;
    height: 40px;
    transition: box-shadow 0.2s;
    background-color: ${Colors.white};

    input:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }
`

const Input = styled(InputWithMessage).attrs(() => ({ type: 'text' }))`
    width: 400px;
    border: none !important;
    height: 38px;
`

const SearchIcon = styled(MdSearch)`
    font-size: 23px;
    margin-left: 4px;
    color: #8e8e93;
`

const MenuIcon = styled(AiFillCaretDown)`
    cursor: pointer;
    font-size: 16px;
    margin-right: 8px;
    color: #8e8e93;
`

const OptionsContainer = styled.div`
    display: ${props => (props.visible ? 'initial' : 'none')};
    position: absolute;
    top: 0;
    margin-top: 45px;
    width: ${props => props.width};
    z-index: 10;

    background-color: ${Colors.white};
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 4px 5px 0 rgba(0, 0, 0, 0.14);
`

const Option = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: Roboto;
    padding: 8px;
    font-size: 12px;
    color: #666;

    &:hover {
        background-color: #f1f1f1;
        color: ${Colors.orange};
    }
`

const OptionIcon = styled.span`
    margin-right: 4px;
`

const OptionLabel = styled.span``

export default SearchInputWithOptions
