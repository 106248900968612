import { keyStorage } from './commons/LocalStorage'
import * as AnalyticsUtils from './commons/AnalyticsUtils'

const BASE_URL = {
    PRD: 'https://api.prodapfabs.com.br',
    HOM: 'https://fabs-qa-d2d2.ue.r.appspot.com',
    DEV: 'https://fabs-dev-c61d.ue.r.appspot.com',
}

const TRACK_SALE_KEYS = {
    PRD: {
        trackSaleKey: 'f4710dabf96b4671',
        trackSaleCampaign: '8370c53f',
    },
    DEV: {
        trackSaleKey: '1c4b8066f2d0c09d',
        trackSaleCampaign: '31b86f1d',
    },
}

const TABLEAU_URL = {
    PRD: {
        BASE_URL: 'https://api.prodapfabs.com.br/query_view_pdf',
        workbookId: '06c05648-ad3b-4037-ad6b-9d59ffc0b9a0',
        viewId: [
            'dd737d81-955b-4423-a79d-65700013bbc9',
            '3b2a45eb-8675-4d08-9b02-731a9e88adab',
            'e7d00314-f42a-4e12-971c-bf5e1e6a738d',
            'add20875-3647-410a-9062-3d9b6cf09883',
            '68cafe74-0961-44bc-acc8-7a69565ccb89',
        ],
    },
    DEV: {
        BASE_URL:
            'https://dev-fabs-dot-prodap-fabs.rj.r.appspot.com/query_view_pdf',
        workbookId: '9d250b07-52aa-4e8a-ab77-968490312881',
        viewId: [
            '039100eb-d61e-445b-b70f-478079c15047',
            '35738bd0-d9a5-48b3-85f3-670dd98772ff',
        ],
    },
}

export const getBaseURL = () => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
        AnalyticsUtils.enable()
        return BASE_URL.PRD
    } else if (process.env.REACT_APP_NODE_ENV === 'qa') {
        AnalyticsUtils.disable()
        return BASE_URL.HOM
    } else {
        AnalyticsUtils.disable()
        return BASE_URL.DEV
    }
}

export const getFreightTypeId = () => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
        return {
            FOB: '06b0da6d-d9dc-488f-aadf-8b90cc2a98f3',
            CIF: '471e5dac-a348-4fed-b71f-b089bfc265d1',
        }
    } else if (process.env.REACT_APP_NODE_ENV === 'qa') {
        return {
            FOB: '06b0da6d-d9dc-488f-aadf-8b90cc2a98f3',
            CIF: '471e5dac-a348-4fed-b71f-b089bfc265d1',
        }
    } else {
        return {
            FOB: '104693cd-dbe1-4dca-9104-150f83eeaf86',
            CIF: 'b0965d05-8675-4f8b-9f13-c4aad2325616',
        }
    }
}

export const getStatusId = () => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
        return {
            EM_ANALISE: 'c88f40b0-071e-4d5d-b718-ee6e9f933344',
            EM_ESTOQUE: '39e6ff0d-4715-4862-aa90-086a04f700e8',
            EM_PRODUCAO: '6d8aa29d-dd9a-403d-ab34-9b9d600cae82',
            EM_EXPEDICAO_TRANSITO: '4eba1ff4-0fd2-404f-abc2-6cb856ad82f0',
            ENTREGUE: '02527cdc-943b-458f-bca2-22223c4a8101',
            CANCELADO: '076820e4-7cdf-47cb-9139-3579f87101d3',
        }
    } else if (process.env.REACT_APP_NODE_ENV === 'qa') {
        return {
            EM_ANALISE: 'c88f40b0-071e-4d5d-b718-ee6e9f933344',
            EM_ESTOQUE: '39e6ff0d-4715-4862-aa90-086a04f700e8',
            EM_PRODUCAO: '6d8aa29d-dd9a-403d-ab34-9b9d600cae82',
            EM_EXPEDICAO_TRANSITO: '4eba1ff4-0fd2-404f-abc2-6cb856ad82f0',
            ENTREGUE: '02527cdc-943b-458f-bca2-22223c4a8101',
            CANCELADO: '076820e4-7cdf-47cb-9139-3579f87101d3',
        }
    } else {
        return {
            EM_ANALISE: '86a15fc3-583a-4791-928a-56e451bae4ca',
            EM_ESTOQUE: '18ff466a-8035-4af5-9644-c1a359ae4d5c',
            EM_PRODUCAO: 'fa65d2b8-8e14-4005-bcc3-e6be54d5db68',
            EM_EXPEDICAO_TRANSITO: '9a719174-b0a5-4569-b4d0-642ed9347a20',
            ENTREGUE: '267ecbde-599f-4e26-b52e-bcc41cca9283',
            CANCELADO: 'dd0bec53-6c91-4788-a630-12bcaca2299f',
        }
    }
}

export const getTrackSaleKeys = () => {
    if (process.env.REACT_APP_NODE_ENV === 'production')
        return TRACK_SALE_KEYS.PRD
    else return TRACK_SALE_KEYS.DEV
}

export const getTableauInfo = () => {
    if (process.env.REACT_APP_NODE_ENV === 'production') return TABLEAU_URL.PRD
    else return TABLEAU_URL.DEV
}

export const getToken = ({ withPayload } = { withPayload: false }) => {
    let userToken = localStorage.getItem(keyStorage.userToken)

    if (userToken) {
        userToken = JSON.parse(userToken)
        if (userToken.accessToken) {
            return withPayload ? userToken : userToken.accessToken.token
        }
        return null
    }
    return null
}

export const getAPIKey = () => 'b8144ce8-d395-4d2f-928e-e8070d828f16'
