import React, { useState, useEffect } from 'react'

import { MessageType, addMessage } from '../../commons/MessageUtils'
import * as ProductAPI from '../../price/ProductAPI'
import { sortProducts } from '../../price/PriceUtils'
import { debounce } from '../../commons/components/Debounce'
import ProductListView from './ProductListView'

export default function ProductListContainer() {
    const [filter, setFilter] = useState('')
    const [products, setProducts] = useState([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [productToDelete, setProductToDelete] = useState({})
    const [isLoadingProducts, setIsLoadingProducts] = useState(true)
    const [isDeletingProduct, setIsDeletingProduct] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0)

    const rows = 10
    const firstPage = 1

    useEffect(() => {
        loadProducts(rows, firstPage)
    }, [])

    const loadProducts = async (size, from, term) => {
        setIsLoadingProducts(true)
        const response = await ProductAPI.searchByLoggedUser(size, from, term)
        if (response && response.data && response.data instanceof Array) {
            setTotalRecords(response.total)
            setProducts(sortProducts(response.data))
        }
        setIsLoadingProducts(false)
    }

    const handleOnClickDeleteIcon = selectedProduct => {
        setShowConfirmationModal(true)
        setProductToDelete(selectedProduct)
    }

    const deleteProduct = async product => {
        try {
            setIsDeletingProduct(true)
            await ProductAPI.deleteAndDeactivate(product.id)
            addMessage(
                MessageType.SUCCESS,
                <span>
                    Produto <strong>{product.name}</strong> deletado com
                    sucesso!
                </span>
            )
            setFirst(0)
        } catch (err) {
            addMessage(
                MessageType.ERROR,
                <span>
                    Erro ao tentar exluir o produto:{' '}
                    <strong>{product.name}</strong>.
                </span>
            )
            console.error(err.message)
        } finally {
            setIsDeletingProduct(false)
            setShowConfirmationModal(false)
            loadProducts()
        }
    }

    const handlePagination = event => {
        setFirst(event.first)
        loadProducts(event.rows, event.page + 1, filter)
    }

    const handleOnFilterChange = e => {
        const filter = e.target.value
        setFilter(filter)
        debounce(() => {
            setFirst(0)
            loadProducts(rows, 0, filter)
        })
    }

    return (
        <ProductListView
            filter={filter}
            products={products}
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
            productToDelete={productToDelete}
            isLoadingProducts={isLoadingProducts}
            isDeletingProduct={isDeletingProduct}
            totalRecords={totalRecords}
            first={first}
            rows={rows}
            handleOnClickDeleteIcon={handleOnClickDeleteIcon}
            handleOnFilterChange={handleOnFilterChange}
            handlePagination={handlePagination}
            deleteProduct={deleteProduct}
        />
    )
}
