import React from 'react'
import styled from 'styled-components'
import { Carousel as pCarousel } from 'primereact/carousel'

import Colors from '../Colors'

export default function Carousel({
    id,
    value,
    page,
    className,
    itemTemplate,
    circular,
    numVisible,
    numScroll,
    onPageChange,
}) {
    return (
        <StyledCarousel
            id={id}
            value={value}
            page={page}
            className={className}
            itemTemplate={itemTemplate}
            circular={circular}
            numVisible={numVisible}
            numScroll={numScroll}
            onPageChange={onPageChange}
        />
    )
}

const StyledCarousel = styled(pCarousel)`
    background-color: ${Colors.veryLightPink};

    .p-carousel-dot-icon {
        width: 30px !important;
        height: 3px !important;
        background-color: ${Colors.darkGray} !important;
        border-radius: 5px;

        &.pi-circle-on {
            background-color: ${Colors.brownishGrey} !important;
        }
    }

    .p-carousel-next {
        color: ${Colors.black} !important;
        background-color: transparent !important;
        border: none !important;
    }

    .p-carousel-prev {
        color: ${Colors.black} !important;
        background-color: transparent !important;
        border: none !important;
    }
`
