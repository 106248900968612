import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import InputWithMessage from '../commons/components/InputWithMessage'
import * as NumberUtils from '../commons/NumberUtils'

export const CellInput = ({ isInvalid, invalidMessage, value, onBlur }) => {
    const [displayValue, setDisplayValue] = useState(value)

    const changeDisplayValue = newValue => {
        if (NumberUtils.isValid(NumberUtils.parseNumber(newValue))) {
            const updatedValue = NumberUtils.formatsDecimalNumber(
                newValue,
                2
            ).replace(/\./g, ',')

            setDisplayValue(updatedValue)
        }
    }

    const changeValue = newValue => {
        if (Number(newValue) !== Number(value)) {
            onBlur(
                NumberUtils.formatsDecimalNumber(newValue, 2).replace(
                    /\./g,
                    ','
                )
            )
        }
    }

    useEffect(() => {
        setDisplayValue(
            NumberUtils.numberFormat(NumberUtils.parseNumber(value), 2).replace(
                /\./g,
                ''
            )
        )
    }, [value])

    return (
        <Input
            value={displayValue}
            invalidMessage={invalidMessage}
            isInvalid={isInvalid}
            onChange={e => changeDisplayValue(e.target.value)}
            onBlur={e => changeValue(e.target.value)}
        />
    )
}

const Input = styled(InputWithMessage).attrs(() => ({
    type: 'text',
}))`
    width: 5.3vw;
    margin: 0 3px;
`

export default CellInput
