import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { MdDelete, MdCheck } from 'react-icons/md'

import * as OrderAPI from '../OrderAPI'
import * as FragmentedOrderAPI from '../FragmentedOrderApi'
import { useMillContext } from '../../context/MillContext'
import Colors from '../../commons/Colors'
import BasicLoading from '../../commons/components/Loading'
import OutlineButton from '../../commons/components/OutlineButton'
import BasicRoundButton from '../../commons/components/RoundButton'
import { Modal } from '../../commons/components/modal'
import { addMessage, MessageType } from '../../commons/MessageUtils'
import { isDecimalUnitType } from '../../commons/MeasurementUnitUtils'
import OriginalOrderInfoComponent from './OriginalOrderInfoComponent'
import FragmentCardComponent from './FragmentCardComponent'
import { mountFragments, mountOrderItems } from './FragmentedOrderUtils'
import { PromiseWrapper } from '../../commons/PromiseUtils'
import DeletionAlertModalComponent from '../../productUsage/DeletionAlertModalComponent'
import * as Analytics from '../../commons/AnalyticsUtils'

export default function FragmentedOrderModalComponent({
    clearSelectedOrderId,
    selectedFragment,
    reloadDashboardData,
}) {
    const [originalOrder, setOriginalOrder] = useState()
    const [originalOrderItems, setOriginalOrderItems] = useState([])
    const [fragments, setFragments] = useState([])
    const [fragmentsToCreate, setFragmentsToCreate] = useState([])
    const [fragmentsToDelete, setFragmentsToDelete] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(true)
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
        useState(false)
    const [isSavingChanges, setIsSavingChanges] = useState(false)
    const { mill } = useMillContext()

    useEffect(() => {
        const fetchOrder = async id => {
            PromiseWrapper({
                promise: OrderAPI.getOrder(id),
                onFulfilled: ({ data }) => {
                    setOriginalOrder(data)
                    setOriginalOrderItems(mountOrderItems(data.items))
                },
                onRejected: () => {
                    addMessage(
                        MessageType.ERROR,
                        'Ocorreu um erro ao buscar o pedido.'
                    )
                    clearSelectedOrderId()
                },
            })
        }
        if (selectedFragment && selectedFragment.orderId)
            fetchOrder(selectedFragment.orderId)
    }, [selectedFragment, clearSelectedOrderId])

    const changeOriginalOrder = useCallback(
        currentFragments => {
            const remainingQuantityByProduct = {}

            originalOrder.items.forEach(item => {
                remainingQuantityByProduct[item.product._id] = parseFloat(
                    item.quantity
                )
            })

            currentFragments.forEach(fragment => {
                fragment.fragmentedOrderItems.forEach(item => {
                    if (isDecimalUnitType(item.measurementUnit)) {
                        const result = (
                            remainingQuantityByProduct[item.productId] -
                            parseFloat(item.quantity)
                        ).toFixed(3)
                        remainingQuantityByProduct[item.productId] =
                            parseFloat(result)

                        return
                    }

                    remainingQuantityByProduct[item.productId] -= parseInt(
                        item.quantity,
                        10
                    )
                })
            })

            setOriginalOrderItems(currentItems =>
                currentItems.map(item => ({
                    ...item,
                    remainingQuantity:
                        remainingQuantityByProduct[item.productId],
                    fragmentQuantity: '',
                }))
            )
        },
        [originalOrder]
    )

    useEffect(() => {
        const fetchFragmentedOrders = async args => {
            try {
                const response =
                    await FragmentedOrderAPI.getAllFragmentedOrders(args)

                if (response && response.data && Array.isArray(response.data)) {
                    const mountedFragments = mountFragments(
                        originalOrder,
                        response.data
                    )
                    changeOriginalOrder(mountedFragments)
                    setFragments(mountedFragments)
                }
            } catch (err) {
                console.error(err)
                addMessage(
                    MessageType.ERROR,
                    'Ocorreu um erro ao carregar os fracionamentos de pedido, tente novamente.'
                )
            } finally {
                setIsLoading(false)
            }
        }
        if (mill && originalOrder)
            fetchFragmentedOrders({
                millId: mill._id,
                orderId: originalOrder.gcpId,
            })
    }, [mill, originalOrder, changeOriginalOrder])

    const createFragment = orderItems => {
        const fragmentToCreate = {
            orderId: originalOrder._id,
            millId: mill._id,
            status: {
                name: 'EM_ANALISE',
            },
            fragmentedOrderItems: orderItems.map(orderItem => ({
                productName: orderItem.productName,
                productId: orderItem.productId,
                measurementUnit: orderItem.measurementUnit,
                quantity: parseFloat(orderItem.fragmentQuantity),
                unitPrice: orderItem.unitPrice,
            })),
        }

        setFragmentsToCreate(currentFragments => {
            const newFragments = [...currentFragments, fragmentToCreate].map(
                (item, index) => ({ ...item, index })
            )

            changeOriginalOrder([...fragments, ...newFragments])
            return newFragments
        })
    }

    const deleteFragment = (index, id) => {
        if (id) {
            setFragmentsToDelete(currentFragmentsToDelete => [
                ...currentFragmentsToDelete,
                id,
            ])

            setFragments(currentFragments => {
                const newFragments = currentFragments
                    .filter(fragment => fragment.id !== id)
                    .map((item, index) => ({ ...item, index }))

                changeOriginalOrder([...newFragments])
                return newFragments
            })
            return
        }

        setFragmentsToCreate(currentFragments => {
            const newFragments = currentFragments
                .filter(fragment => fragment.index !== index)
                .map((item, index) => ({ ...item, index }))

            changeOriginalOrder([...fragments, ...newFragments])
            return newFragments
        })
    }

    const handleOnClose = () => {
        clearSelectedOrderId()
        setIsModalVisible(false)
    }

    const setIsUseFragmentOrder = async isUseFragmentOrder => {
        try {
            const response = await OrderAPI.setIsUseFragmentOrder(
                selectedFragment.orderId,
                isUseFragmentOrder
            )
            return response
        } catch (err) {
            throw err
        }
    }

    const handleOnClickSave = async () => {
        setIsSavingChanges(true)
        let success = true

        const createFragments = async () => {
            try {
                if (fragmentsToCreate.length) {
                    const response =
                        await FragmentedOrderAPI.createFragmentedOrders(
                            fragmentsToCreate
                        )

                    if (response && response.message) {
                        console.error(response.message)
                        success = false
                        return
                    }
                    Analytics.analyticsEvent(
                        Analytics.EVENT_KEYS.createFragmentOrder
                    )
                }
            } catch (err) {
                throw err
            }
        }

        const deleteFragments = async () => {
            try {
                if (fragmentsToDelete.length) {
                    const response =
                        await FragmentedOrderAPI.deleteFragmentedOrders(
                            fragmentsToDelete
                        )

                    if (
                        response &&
                        response.message !==
                            'Fracionamentos deletados com sucesso.'
                    ) {
                        console.error(response.message)
                        success = false
                        return
                    }
                    Analytics.analyticsEvent(
                        Analytics.EVENT_KEYS.deleteFragmentOrder
                    )
                }
            } catch (err) {
                throw err
            }
        }

        const message = await setIsUseFragmentOrder(true).catch(err => {
            console.error(err)
            addMessage(MessageType.ERROR, 'Erro ao salvar os fracionamentos.')
            setIsSavingChanges(false)
        })

        if (message) {
            await Promise.all([createFragments(), deleteFragments()])
                .then(() => {
                    if (success) {
                        addMessage(
                            MessageType.SUCCESS,
                            'Pedido fracionado com sucesso!'
                        )
                        handleOnClose()
                        return
                    }

                    addMessage(
                        MessageType.ERROR,
                        'Erro ao salvar os fracionamentos.'
                    )
                    setIsSavingChanges(false)
                })
                .catch(err => {
                    console.error(err)
                    addMessage(
                        MessageType.ERROR,
                        'Erro ao salvar os fracionamentos.'
                    )
                    setIsSavingChanges(false)
                })

            await reloadDashboardData(mill.gcpId)
        }
    }

    const handleOnClickDeleteAll = async () => {
        setIsSavingChanges(true)
        setIsConfirmationModalVisible(false)

        const deleteAllFragments = async () => {
            try {
                const itemsToDelete = fragments.map(fragment => fragment.id)

                const response =
                    await FragmentedOrderAPI.deleteFragmentedOrders(
                        itemsToDelete
                    )

                if (
                    response &&
                    response.message !== 'Fracionamentos deletados com sucesso.'
                ) {
                    throw response.message
                }
            } catch (err) {
                throw err
            }
        }

        await Promise.all([deleteAllFragments(), setIsUseFragmentOrder(false)])
            .then(() => {
                addMessage(
                    MessageType.SUCCESS,
                    'Fracionamento excluído com sucesso!'
                )
                handleOnClose()
                setIsSavingChanges(false)
            })
            .catch(err => {
                addMessage(
                    MessageType.ERROR,
                    'Ocorreu um erro ao excluir o fracionamento'
                )
                console.error(err)
                setIsSavingChanges(false)
            })

        await reloadDashboardData(mill.gcpId)
    }

    const renderFragmentCards = () => {
        return [...fragments, ...fragmentsToCreate].map(fragment => {
            return (
                <FragmentCardComponent
                    selected={
                        fragment.id === selectedFragment.fragmentedOrderId
                    }
                    fragment={{
                        ...fragment,
                        orderNumber: originalOrder.orderNumber,
                    }}
                    onDelete={deleteFragment}
                />
            )
        })
    }

    const isSaveButtonDisabled = () => {
        const hasAnyChanges = Boolean(
            fragmentsToCreate.length || fragmentsToDelete.length
        )
        const hasEnoughFragments =
            [...fragments, ...fragmentsToCreate].length >= 2
        const hasNotRemainingQuantity = originalOrderItems.every(
            item => parseFloat(item.remainingQuantity) === 0
        )

        const isEnabled =
            hasAnyChanges && hasEnoughFragments && hasNotRemainingQuantity
        return !isEnabled
    }

    const isDeleteFragmentsButtonDisabled = () =>
        !fragments.length ||
        fragments.some(fragment => fragment.status.name === 'ENTREGUE')

    const renderDeletionText = () => {
        return (
            <ConfirmationModalText>
                Tem certeza de que deseja excluir todas as remessas deste
                fracionamento?
                <br />
                <br />
                <i>
                    *Nesta ação, todas as remessas geradas serão canceladas,
                    sendo restaurado o pedido original (posicionado na coluna Em
                    Análise).
                </i>
            </ConfirmationModalText>
        )
    }
    return (
        <Modal visible={isModalVisible} onClose={handleOnClose}>
            <ModalHeader>
                <ModalTitle>Fracionamento de Pedidos</ModalTitle>
                {!isLoading && (
                    <DeleteFragmentsButton
                        disabled={isDeleteFragmentsButtonDisabled()}
                        color={Colors.orange}
                        onClick={() => setIsConfirmationModalVisible(true)}
                        loading={isSavingChanges}
                    >
                        <DeleteIcon /> Excluir
                    </DeleteFragmentsButton>
                )}
            </ModalHeader>
            <ModalBody>
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <OriginalOrderInfoComponent
                            originalOrder={originalOrder}
                            createFragment={createFragment}
                            orderItems={originalOrderItems}
                            setOrderItems={setOriginalOrderItems}
                        />
                        {Boolean(
                            fragmentsToCreate.length || fragments.length
                        ) && (
                            <FragmentCardsContainer>
                                <FragmentsText>Remessas</FragmentsText>
                                <FragmentCardsGrid>
                                    {renderFragmentCards()}
                                </FragmentCardsGrid>
                            </FragmentCardsContainer>
                        )}
                    </>
                )}
            </ModalBody>
            {Boolean([...fragments, ...fragmentsToCreate].length) && (
                <ModalFooter>
                    <CancelButton onClick={handleOnClose}>
                        Cancelar
                    </CancelButton>
                    <SaveButton
                        disabled={isSaveButtonDisabled()}
                        onClick={handleOnClickSave}
                        loading={isSavingChanges}
                    >
                        SALVAR <SaveIcon />
                    </SaveButton>
                </ModalFooter>
            )}
            <DeletionAlertModalComponent
                title="Excluir remessas do fracionamento"
                isVisible={isConfirmationModalVisible}
                renderDeletionText={renderDeletionText}
                onClose={() => setIsConfirmationModalVisible(false)}
                onConfirm={handleOnClickDeleteAll}
            ></DeletionAlertModalComponent>
        </Modal>
    )
}

const ModalBody = styled.div`
    width: 45vw;
    min-width: 700px;
    min-height: 300px;
    box-sizing: content-box;
    max-height: 70vh;
    overflow: auto;
    padding: 30px;
`

const Loading = styled(BasicLoading)`
    height: 300px;
`

const ModalHeader = styled.div`
    padding: 30px 30px 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ModalFooter = styled.div`
    padding: 15px 30px 30px 30px;
    gap: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
`

const ModalTitle = styled.p`
    font-family: Roboto;
    font-size: 16px;
    color: ${Colors.brownishGrey};
`

const DeleteFragmentsButton = styled(OutlineButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98px;
    margin-left: 16px;
    padding-right: 14px;
`

const DeleteIcon = styled(MdDelete)`
    width: 24px;
    height: 24px;
`

const FragmentCardsContainer = styled.div`
    padding: 10px;
    margin-top: 25px;

    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
`

const FragmentCardsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
`

const FragmentsText = styled(ModalTitle)`
    font-weight: 600;
`

const CancelButton = styled(BasicRoundButton)`
    background-color: ${Colors.white};
    color: ${Colors.orange};
    border-color: ${Colors.orange};
`

const SaveButton = styled(BasicRoundButton)`
    min-width: 100px;
    color: ${Colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`

const SaveIcon = styled(MdCheck)`
    width: 20px;
    height: 20px;
`

const ConfirmationModalText = styled.p`
    color: ${Colors.brownishGrey};
`
