import React from 'react'
import styled, { css } from 'styled-components'
import Colors from '../Colors'
import Loading from './Loading'

/**
 * @param {string} type - Tipo do botão. Ex: button, submit. Default: button
 * @param {JSX.Element} children - Conteúdo dentro do botão
 * @param {string} color - Cor da borda e conteúdo
 * @param {function} onClick - Função a ser chamada no evento de click
 * @param {boolean} disabled - Indica se o botão deve ficar desabilitado
 * @param {boolean} loading - Indica se o botão deve apresentar loading
 * @param {string} testId - data-testId
 */
const OutlineButton = ({
    className,
    type = 'button',
    children,
    color,
    onClick,
    disabled = false,
    loading = false,
    testId,
}) => {
    return loading ? (
        <ContainerLoading className={className} color={color}>
            <Loading />
        </ContainerLoading>
    ) : (
        <Button
            className={className}
            type={type}
            disabled={disabled}
            color={color}
            onClick={onClick}
            data-testid={testId}
        >
            {children}
        </Button>
    )
}

const baseButton = css`
    height: 32px;
    border: ${props => `solid 2px ${props.color}`};
    border-radius: 50px;
    color: ${props => props.color};
    background-color: ${Colors.white};
    padding: 5px 10px;
`

const ContainerLoading = styled.div`
    ${baseButton}
    display: flex;
    justify-content: center;
`

const Button = styled.button`
    ${baseButton}
    cursor: pointer;

    :focus {
        outline-style: none;
    }

    :active {
        opacity: 0.8;
    }

    :disabled {
        cursor: default;
        border-color: #c7c7cc;
        color: #c7c7cc;
    }
`

export default OutlineButton
