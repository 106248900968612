import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './index.css'
import 'primereact/resources/themes/rhea/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
    <BrowserRouter getUserConfirmation={() => {}}>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
)
