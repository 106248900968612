import React, { useState, useEffect } from 'react'

import {
    getPaymentDeadlines,
    getIncreasePercentage,
    getPaymentDeadlineLegacyCode,
    isCustomDeadline,
    getPaymentDeadlineId,
} from '../OrderFormScreenUtils'

import * as PaymentAPI from '../PaymentAPI'
import PaymentFormsView from './PaymentFormsView'

import { useMillContext } from '../../context/MillContext'

const PaymentFormsContainer = ({
    order,
    setPaymentForms,
    invalidFieldsHandler,
}) => {
    const [availablePaymentForms, setAvailablePaymentForms] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { setValidField, invalidFields, getInvalidField } =
        invalidFieldsHandler
    const { millConfig } = useMillContext()

    useEffect(() => {
        const fetchPaymentForms = async () => {
            const response = await PaymentAPI.getAllPaymentForms()
            if (response && response.data && Array.isArray(response.data)) {
                setAvailablePaymentForms(response.data)
            }
            setIsLoading(false)
        }

        try {
            fetchPaymentForms()
        } catch (error) {
            console.error(error)
        }
    }, [])
    const setSelectedPaymentForm = (
        selectedPaymentForm,
        availablePaymentForms
    ) => {
        const paymentFormComplete = availablePaymentForms.find(
            form => form.paymentForm === selectedPaymentForm
        )
        setPaymentForms('paymentForm', selectedPaymentForm)
        setPaymentForms('paymentFormComplete', paymentFormComplete)
        setPaymentForms('paymentDeadline', null)
        setPaymentForms('paymentDeadlineComplete', null)
        setPaymentForms('paymentDate', null)
        setValidField('paymentForm')
    }

    const setSelectedPaymentDeadline = (
        paymentDeadline,
        availablePaymentDeadlines
    ) => {
        const increasePercentage = getIncreasePercentage(
            paymentDeadline,
            availablePaymentDeadlines
        )
        const paymentDeadlineComplete = {
            increasePercentage: increasePercentage || 0,
            name: paymentDeadline,
            legacyCode: getPaymentDeadlineLegacyCode(
                order.paymentFormComplete,
                paymentDeadline
            ),
            _id: getPaymentDeadlineId(
                order.paymentFormComplete,
                paymentDeadline
            ),
        }

        if (!isCustomDeadline(paymentDeadline)) {
            setPaymentForms('paymentDate', '')
        }

        setPaymentForms('paymentDeadline', paymentDeadline)
        setPaymentForms('paymentDeadlineComplete', paymentDeadlineComplete)

        invalidFieldsHandler.setValidField('paymentDeadline')
        invalidFieldsHandler.setValidField('paymentDate')
    }

    const setPaymentDate = paymentDate => {
        const isInvalidField = getInvalidField('paymentDate', invalidFields)

        if (isInvalidField) setValidField('paymentDate')

        setPaymentForms('paymentDate', paymentDate)
    }
    return (
        <PaymentFormsView
            setPaymentDate={setPaymentDate}
            setPaymentForm={setSelectedPaymentForm}
            setPaymentDeadline={setSelectedPaymentDeadline}
            availablePaymentForms={availablePaymentForms}
            availablePaymentDeadlines={getPaymentDeadlines(
                availablePaymentForms,
                order.paymentForm,
                millConfig?.allowsCustomDeadline
            )}
            selectedPaymentForm={order.paymentForm}
            selectedPaymentDeadline={order.paymentDeadline}
            selectedPaymentDate={order.paymentDate}
            isLoading={isLoading}
            invalidFieldsHandler={invalidFieldsHandler}
        />
    )
}

export default PaymentFormsContainer
