import React from 'react'
import styled from 'styled-components'

import Colors from '../commons/Colors'
import RadioButton from '../commons/components/RadioButton'
import { Label } from '../commons/commonStyles'

export default function PalletOptionsComponent({
    setNeedsPallet,
    needsPallet,
}) {
    const palletOptions = [
        { labelText: 'Sim', value: true },
        { labelText: 'Não', value: false },
    ]

    const handleOnChange = value => {
        setNeedsPallet('needsPallet', value)
    }

    return (
        <Content>
            <Label>Incluir Pallet</Label>

            <Options>
                {palletOptions.map(palletOption => (
                    <Option key={`${palletOption.value}-pallet`}>
                        <RadioButton
                            id="palletOption"
                            name="palletOption"
                            value={palletOption.value}
                            checked={needsPallet === palletOption.value}
                            onChange={e => handleOnChange(e.value)}
                            ariaLabelledBy={`palletOption${palletOption.labelText}Label`}
                        />
                        <OptionLabel
                            htmlFor="palletOption"
                            id={`palletOption${palletOption.labelText}Label`}
                        >
                            {palletOption.labelText}
                        </OptionLabel>
                    </Option>
                ))}
            </Options>
        </Content>
    )
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    background-color: ${Colors.white};
`

const Options = styled.div`
    display: grid;
    grid-row-gap: 2.8vh;
    margin-top: 1.68vh;
`

const Option = styled.div`
    display: flex;
    flex-direction: row;
`

const OptionLabel = styled.label`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: ${Colors.brownishGrey};
`
