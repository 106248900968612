import React from 'react'
import styled from 'styled-components'
import Colors from '../../Colors'
import farmTellMillsLogoPath from '../../../assets/logo/FarmTellMills.png'
import { Link } from 'react-router-dom'
import { keyStorage } from '../../LocalStorage'
import SideMenu from './SideMenu'
import NewsSideMenuComponent from '../../../news/NewsSideMenuComponent'

import { useMillContext } from '../../../context/MillContext'
import HelpCenter from './HelpCenter'

export const ScreenHeader = ({ children }) => {
    const { mill } = useMillContext()

    const userToken = JSON.parse(localStorage.getItem(keyStorage.userToken))

    return (
        <>
            <Container data-testid="container">
                <Link to="/">
                    <Logo src={farmTellMillsLogoPath} />
                </Link>
                {children}
                <UserArea>
                    <HelpCenter />
                    <Hello>Olá, </Hello>
                    <User>{userToken?.name}</User>
                    <NotificationMenu>
                        <NewsSideMenuComponent />
                    </NotificationMenu>
                    <SideMenu
                        userLogin={userToken?.login}
                        userMill={mill?.nome}
                    />
                </UserArea>
            </Container>
        </>
    )
}

const Container = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: ${Colors.white};
    box-shadow: 0 1px 4px 0 ${Colors.lightBlueGreyTwo};
    border-bottom: 0.1px solid ${Colors.lightBlueGreyTwo};
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
`

const Logo = styled.img`
    width: 100px;
    height: auto;
    margin-left: 1vw;
    align-self: center;
`

const UserArea = styled.div`
    width: auto;
    height: auto;
    align-self: center;
    margin-right: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
`

const Hello = styled.label`
    letter-spacing: 0.4px;
    color: ${Colors.black};
    font-size: 14px;
    text-align: right;
`

const User = styled.label`
    letter-spacing: 0.4px;
    color: ${Colors.black};
    font-size: 14px;
    font-weight: bold;
    margin-left: 0.1vw;
    margin-right: 0.3vw;
`

const NotificationMenu = styled.label`
    margin-right: 0.3vw;
`

export default ScreenHeader
