export const GCPWrapper = async promise => {
    try {
        const response = await promise

        if (response && response.message) {
            throw new Error(response.message)
        }

        if (response && response.data !== undefined) {
            return [
                {
                    data: response.data,
                    total: response?.total ? response.total : 0,
                },
                undefined,
            ]
        }
    } catch (err) {
        return [undefined, err]
    }
}

export const WrapAll = async ({
    promises,
    onFulfilled,
    onRejected,
    callback,
}) => {
    return Promise.all(promises)
        .then(responses => {
            responses.map(([, err]) => catchRejection(err))

            if (typeof onFulfilled === 'function') onFulfilled(responses)
            return responses
        })
        .catch(err => {
            if (typeof onRejected === 'function') onRejected(err)
        })
        .finally(() => {
            if (typeof callback === 'function') callback()
        })
}

export const PromiseWrapper = async ({
    promise,
    onFulfilled,
    onRejected,
    callback,
}) => {
    return GCPWrapper(promise)
        .then(([res, err]) => {
            catchRejection(err)
            if (typeof onFulfilled !== 'function') return res

            const { data, total } = res
            return onFulfilled({ data, total })
        })
        .catch(err => {
            console.error(err)
            if (typeof onRejected === 'function') onRejected(err)
        })
        .finally(() => {
            if (typeof callback === 'function') callback()
        })
}

// TODO : Refatorar catchRejection para chamar como propriedade do wrapper
export const catchRejection = err => {
    if (Boolean(err)) throw err
}
