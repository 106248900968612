import React from 'react'

const OrderIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.008 3.001h-4.182c-.42-1.16-1.52-2-2.821-2-1.3 0-2.401.84-2.821 2H5.002c-1.1 0-2 .9-2 2.001v14.006c0 1.1.9 2 2 2h14.006c1.1 0 2-.9 2-2V5.002c0-1.1-.9-2-2-2zm-7.003 0c.55 0 1 .45 1 1s-.45 1.001-1 1.001-1-.45-1-1 .45-1 1-1zM7.003 17.007h7.003v-2H7.003v2zm10.004-4.002H7.003v-2h10.004v2zM7.003 9.004h10.004V7.003H7.003v2z"
                fill="currentColor"
            />
        </svg>
    )
}

export default OrderIcon
