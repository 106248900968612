import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { MdAddCircleOutline } from 'react-icons/md'

import Colors from '../commons/Colors'
import RoundButton from '../commons/components/RoundButton'
import Tooltip from '../commons/components/Tooltip'

import OrderGroupIcon from '../assets/customIcons/OrderGroupIcon'
import SearchInputWithOptions from '../commons/components/SearchInputWithOptions'

const KanbanTopBar = ({
    buttonDisabled,
    buttonOnClick,
    filterValue,
    setFilterValue,
    setFilterType,
}) => {
    return (
        <KanbanTopBarContainer>
            <InteractionsContainer>
                <SearchInputWithOptions
                    value={filterValue}
                    onChangeFilter={e => setFilterValue(e.target.value)}
                    onChangeFilterType={filterType => setFilterType(filterType)}
                />
                <ButtonsContainer>
                    <ButtonWithTooltip>
                        <AgroupButton
                            disabled={buttonDisabled}
                            onClick={buttonOnClick}
                            dataTestId={'agroup-button'}
                        >
                            <Tooltip
                                label="* selecione o(s) pedido(s) que deseja
                                    agrupar numa nova carga."
                                position="left"
                            >
                                <TooltipIcon />
                            </Tooltip>
                            <ButtonText>Agrupar Carga</ButtonText>
                        </AgroupButton>
                    </ButtonWithTooltip>

                    <Link to={'/pedidos/novo'}>
                        <NewOrderButton>
                            <AddIcon />
                            Novo Pedido
                        </NewOrderButton>
                    </Link>
                </ButtonsContainer>
            </InteractionsContainer>
            <InformationsContainer>
                <InfoMessage>
                    * Selecione os pedidos para o qual deseja criar ou editar um
                    agrupamento por carga. Arraste o agrupamento para mudança de
                    situação.
                </InfoMessage>
            </InformationsContainer>
        </KanbanTopBarContainer>
    )
}

const KanbanTopBarContainer = styled.div`
    padding-right: 32px;
    padding-left: 10px;
    margin-top: 15px;
`

const InteractionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const ButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
`

const InformationsContainer = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
`

const NewOrderButton = styled(RoundButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
`

const AddIcon = styled(MdAddCircleOutline)`
    font-size: 16px;
    align-self: center;
    margin-right: 3px;
`

const AgroupButton = styled(RoundButton)`
    background-color: ${Colors.peacockBlue};
    border-color: ${Colors.peacockBlue};
    min-width: 174px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    :disabled {
        background-color: ${Colors.blueGrey};
        border-color: ${Colors.blueGrey};
        color: ${Colors.white};
    }
`

const ButtonText = styled.span`
    padding: 5px;
`

const InfoMessage = styled.span`
    opacity: 0.5;
    font-family: Roboto;
    font-size: 14px;
    color: ${Colors.black};
`

const ButtonWithTooltip = styled.div`
    display: flex;
    align-items: center;
`

const TooltipIcon = styled(OrderGroupIcon)`
    color: ${Colors.white};
    width: 20px;
    height: 20px;
    cursor: pointer;
`

export default KanbanTopBar
