import { getBaseURL, getAPIKey, getToken } from '../environment'

const endpoint = 'news'
const apiKey = getAPIKey()

export const getAllVersions = () => {
    const url = `${getBaseURL()}/${endpoint}/versions/`
    const queryParams = new URLSearchParams({ deleted: false, published: true })

    return fetch(`${url}?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            'x-api-key': apiKey,
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
