import React from 'react'
import styled from 'styled-components'

import Colors from '../../commons/Colors'

import NoRecordsFoundComponent from '../../commons/components/feedback/NoRecordsFoundComponent'
import LoadingErrorComponent from '../../commons/components/error/LoadingErrorComponent'
import BasicPage from '../../commons/components/BasicPage'
import OutlineButton from '../../commons/components/OutlineButton'
import RoundButton from '../../commons/components/RoundButton'
import AddProductModalComponent from '../AddProductModalComponent'
import DeletionAlertModalComponent from '../DeletionAlertModalComponent'
import NotSavedAlertModalComponent from '../NotSavedAlertModalComponent'
import EditProductUsageModalComponent from '../EditProductUsageModalComponent'
import OpenLetterIcon from '../../assets/customIcons/OpenLetterIcon'
import ProductUsageTable from './ProductUsageTable'

function ProductUsageView({
    clientData,
    onBack,
    tableValues,
    isLoading,
    isNoRecordsFound,
    handlePagination,
    indexOfFirstTableItem,
    totalProductsUsage,
    isError,
    handleAddProductModal,
    onSaveAddProducts,
    onDeleteAddedProduct,
    onDeleteProductUsage,
    isDeletingProductUsage,
    setIsDeletingProductUsage,
    isAddProductModalVisible,
    addedProducts,
    setAddedProducts,
    handleDeletionModal,
    deletionModalConfig,
    setDeletionModalConfig,
    isNotSavedModalVisible,
    handleEditProductUsageModal,
    updateEditionModalConfig,
    editionModalConfig,
    onSaveProductsUsageEdition,
    PAGINATOR_ROWS,
    productsUsage,
}) {
    const renderRecordsNotFoundFeedback = () => {
        const renderText = () => {
            return (
                <>
                    Você ainda não possui nenhum produto cadastrado no sistema
                    clique no botão <strong>“Adicionar novo produto” </strong>
                    para começar!
                </>
            )
        }

        const renderIcon = () => {
            return (
                <>
                    <OpenLetterIcon />
                </>
            )
        }

        if (isNoRecordsFound) {
            return (
                <NoRecordsFoundComponent
                    renderText={renderText}
                    renderIcon={renderIcon}
                />
            )
        }
    }

    const renderErrorComponent = () => {
        if (isError && !isLoading) return <LoadingErrorComponent />
    }

    const renderProductUsageTable = () => {
        if (!isNoRecordsFound)
            return (
                <ProductUsageTable
                    tableValues={tableValues}
                    handleDeletionModal={handleDeletionModal}
                    indexOfFirstTableItem={indexOfFirstTableItem}
                    handlePagination={handlePagination}
                    isLoading={isLoading}
                    totalRecords={totalProductsUsage}
                    PAGINATOR_ROWS={PAGINATOR_ROWS}
                />
            )
    }

    const renderDeletitionText = () => {
        return (
            <span>
                Tem certeza que deseja excluir o produto
                <strong> {deletionModalConfig.productUsage.name}</strong> ? Você
                poderá adiciona-lo novamente quando quiser.
            </span>
        )
    }

    return (
        <BasicPage
            title={clientData.clientName}
            editionTitle={null}
            isEdition={false}
            isBackButton
            onBack={onBack}
        >
            <HeaderContainer>
                <Subtitle>
                    Planejamento mensal de atividade dos produtos
                </Subtitle>
                {!isLoading && (
                    <ButtonsContainer>
                        <OutlineButton
                            color={Colors.orange}
                            onClick={() => handleEditProductUsageModal()}
                            testId="edit-button"
                        >
                            Editar produtos
                        </OutlineButton>
                        <AddButton onClick={handleAddProductModal}>
                            Adicionar novo produto
                        </AddButton>
                    </ButtonsContainer>
                )}
            </HeaderContainer>
            {renderProductUsageTable()}
            {renderErrorComponent()}
            {renderRecordsNotFoundFeedback()}
            {isAddProductModalVisible && (
                <AddProductModalComponent
                    onSave={onSaveAddProducts}
                    setIsVisible={handleAddProductModal}
                    isVisible={true}
                    addedProducts={addedProducts}
                    setAddedProducts={setAddedProducts}
                    tableValues={tableValues}
                    onDelete={onDeleteAddedProduct}
                />
            )}
            {deletionModalConfig.isVisible && (
                <DeletionAlertModalComponent
                    title={'Excluir produto'}
                    isVisible={deletionModalConfig.isVisible}
                    isLoading={isDeletingProductUsage}
                    renderDeletionText={renderDeletitionText}
                    onConfirm={() => {
                        onDeleteProductUsage(deletionModalConfig.productUsage)
                    }}
                    onClose={() => {
                        setDeletionModalConfig({
                            productUsage: { productId: '', name: '' },
                            isVisible: false,
                        })
                    }}
                />
            )}
            {editionModalConfig.isVisible && (
                <EditProductUsageModalComponent
                    isVisible={editionModalConfig.isVisible}
                    onClose={() => updateEditionModalConfig({ type: 'CLOSE' })}
                    onSave={onSaveProductsUsageEdition}
                    productUsage={editionModalConfig.productUsage}
                    pageIndex={editionModalConfig.carouselIndex}
                    onChangePage={index =>
                        updateEditionModalConfig({
                            type: 'NAVIGATE',
                            carouselIndex: index,
                        })
                    }
                    productsUsage={productsUsage}
                />
            )}
            <NotSavedAlertModalComponent isVisible={isNotSavedModalVisible} />
        </BasicPage>
    )
}

const HeaderContainer = styled.div`
    padding: 48.5px 0 32px 0;
    display: flex;
    justify-content: space-between;
`

const Subtitle = styled.span`
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: ${Colors.black};
`

const AddButton = styled(RoundButton)`
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
`

const ButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
`

export default ProductUsageView
