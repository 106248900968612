import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import BasicModal from '../commons/components/ModalResponsive'
import Colors from '../commons/Colors'
import RoundButton from '../commons/components/RoundButton'

const StockSafetyDaysModal = ({ visibilityStatus, onClose, farm }) => {
    const history = useHistory()

    function onEdit() {
        history.push(`/clientes/editar/${farm._id}`)
    }

    function closeModal() {
        onClose({ isVisible: false, rendered: true })
    }

    if (visibilityStatus.isVisible && !visibilityStatus.rendered) {
        return (
            <Modal
                visible={
                    visibilityStatus.isVisible && !visibilityStatus.rendered
                }
                onClose={closeModal}
            >
                <Container>
                    <Content>
                        O cliente {farm.corporateName} possui estoque de
                        segurança inválido.
                        <strong>
                            {' '}
                            Favor editar o campo "estoque segurança (em dias)"
                        </strong>{' '}
                        para um valor maior que zero, para realizar o pedido.
                    </Content>
                    <ButtonContainer>
                        <CancelButton
                            onClick={() =>
                                onClose({ isVisible: false, rendered: true })
                            }
                        >
                            Cancelar
                        </CancelButton>
                        <EditButton onClick={onEdit}>Editar</EditButton>
                    </ButtonContainer>
                </Container>
            </Modal>
        )
    }

    return null
}

const Modal = styled(BasicModal)`
    align-items: center;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 32px 21px;
`

const Content = styled.span`
    font-family: Roboto;
    line-height: 2;
    font-size: 14px;
    max-width: 35vw;
`

const ButtonContainer = styled.div`
    align-self: center;
    display: flex;
    justify-content: center;
    width: 100%;
`

const CancelButton = styled(RoundButton)`
    background-color: ${Colors.white};
    border-color: ${Colors.orange};
    color: ${Colors.orange};
    display: flex;
    justify-content: center;
    margin: 15px;
    width: 106px;
`

const EditButton = styled(RoundButton)`
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
    margin: 15px;
    width: 106px;
    display: flex;
    justify-content: center;
`

export default StockSafetyDaysModal
