import React, { useState, useEffect } from 'react'
import { debounce } from '../../commons/components/Debounce'

import * as OrderAPI from '../../dashboard/OrderAPI'
import SimpleBillingView from './SimpleBillingView'
import { STATUS } from '../../commons/Status'

export default function SimpleBillingContainer() {
    const [isLoadingData, setIsLoadingData] = useState(true)
    const [data, setData] = useState([])
    const [totalRecords, setTotalRecords] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [filter, setFilter] = useState('')
    const [selectedSimpleBilling, setSelectedSimpleBilling] = useState(null)
    const [simpleBillingToManage, setSimpleBillingToManage] = useState(null)
    const [simpleBillingToDelete, setSimpleBillingToDelete] = useState(null)
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false)

    const rows = 10
    const firstPage = 1

    const fetchData = async (from, size, filter) => {
        try {
            setIsLoadingData(true)

            const response = await OrderAPI.searchSimpleBillings({
                from,
                size,
                filter,
            })

            if (response && response.orders && Array.isArray(response.orders)) {
                setData(response.orders)
                setTotalRecords(response.total || 0)
            }
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoadingData(false)
        }
    }

    useEffect(() => {
        fetchData(firstPage, rows, filter)
    }, []) // eslint-disable-line

    const handleOnChangeFilter = e => {
        const filterValue = e.target.value
        setFilter(filterValue)
        debounce(() => fetchData(0, rows, filterValue))
    }

    const handlePagination = e => {
        setCurrentIndex(e.first)
        fetchData(e.page + 1, e.rows, filter)
    }

    const isOrderDelivered = order => order?.status === STATUS.ENTREGUE

    const handleOnClickDelete = order => {
        setIsCancelModalVisible(true)
        setSimpleBillingToDelete(order)
    }

    const handleOnCloseCancelModal = () => {
        setIsCancelModalVisible(false)
        setSimpleBillingToDelete()
    }

    const onCancelOrder = () => {
        handleOnChangeFilter({
            target: { value: '' },
        })
        handleOnCloseCancelModal()
    }

    return (
        <SimpleBillingView
            isLoadingData={isLoadingData}
            data={data}
            filterValue={filter}
            handleOnChangeFilter={handleOnChangeFilter}
            rows={rows}
            currentIndex={currentIndex}
            totalRecords={totalRecords}
            handlePagination={handlePagination}
            selectedSimpleBilling={selectedSimpleBilling}
            setSelectedSimpleBilling={setSelectedSimpleBilling}
            isOrderDelivered={isOrderDelivered}
            handleOnClickDelete={handleOnClickDelete}
            handleOnCloseCancelModal={handleOnCloseCancelModal}
            onCancelOrder={onCancelOrder}
            simpleBillingToDelete={simpleBillingToDelete}
            isCancelModalVisible={isCancelModalVisible}
            simpleBillingToManage={simpleBillingToManage}
            setSimpleBillingToManage={setSimpleBillingToManage}
        />
    )
}
