import { getBaseURL, getToken } from '../environment'
import { gcpToSydleOrder, sydleToGcpOrder } from './OrderUtils'

const baseURL = `${getBaseURL()}/orders/pendingOrders`

export const searchPendingMillOrders = async () => {
    const response = await fetch(`${baseURL}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
    return {
        data: response.data.map(pendingOrder => gcpToSydleOrder(pendingOrder)),
    }
}

export const createPendingOrder = async sydleOrder => {
    const order = sydleToGcpOrder(sydleOrder)
    const response = await fetch(`${baseURL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(order),
    })

    if (!response.ok) throw new Error(await response.text())

    return response.json()
}

export const updatePendingOrder = async (orderId, sydleOrder) => {
    const order = sydleToGcpOrder(sydleOrder)
    const response = await fetch(`${baseURL}/${orderId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(order),
    })

    if (!response.ok) throw new Error(await response.text())

    return response.json()
}

export const deletePendingOrder = async orderId => {
    return await fetch(`${baseURL}/${orderId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => (response.ok ? { data: null } : response.json()))
        .catch(err => {
            console.error('Erro: ', err)
            throw err
        })
}

export const getById = async orderId => {
    const response = await fetch(`${baseURL}/${orderId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
    return { order: gcpToSydleOrder(response.data) }
}

export const sendOrders = async orders => {
    return await fetch(`${baseURL}/confirmed`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ orders }),
    })
        .then(response => (response.ok ? { data: null } : response.json()))
        .catch(err => {
            console.error('Erro: ', err)
            throw err
        })
}
