import React from 'react'

const VersionIcon = () => {
    return (
        <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="evenodd" fill="none">
                <path
                    d="M5.386 11.213V8L0 13.5 5.386 19v-3.213H11L11 11.213H5.386zM13.614 0 19 5.5 13.614 11V7.787H8l.001-4.574h5.613V0z"
                    fill="#666"
                ></path>
            </g>
        </svg>
    )
}

export default VersionIcon
