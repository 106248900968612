import React from 'react'
import styled from 'styled-components'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'

import Table from '../../commons/components/Table'
import Colors from '../../commons/Colors'
import { EditIcon, DeleteIcon } from '../../commons/commonStyles'
import BasicListScreen from '../../commons/components/BasicListScreen'
import BasicPage from '../../commons/components/BasicPage'
import DeletionAlertModalComponent from '../../productUsage/DeletionAlertModalComponent'

export default function ClientListView({
    filter,
    portfolios,
    showConfirm,
    setShowConfirm,
    portfolioToDelete,
    setPortfolioToDelete,
    isLoadingPortfolios,
    isDeletingPortfolio,
    totalRecords,
    first,
    rows,
    openConfirmationModal,
    onConfirmDeletePortfolio,
    handlePagination,
    handleChangeFilter,
}) {
    const renderActionsColumn = portfolio => {
        return (
            <ActionsContent>
                <Link to={{ pathname: `/carteiras/editar/${portfolio.id}` }}>
                    <EditIcon />
                </Link>

                <DeleteIcon
                    data-testid={`delete-icon-${portfolio.id}`}
                    onClick={() => openConfirmationModal(portfolio)}
                />
            </ActionsContent>
        )
    }

    const renderNameColumn = portfolio => {
        return <NameCell>{portfolio.name}</NameCell>
    }

    const renderDeletionText = () => {
        return (
            <Subtitle>
                Tem certeza de que deseja excluir a carteira{' '}
                <strong>
                    {portfolioToDelete ? portfolioToDelete.name : null}
                </strong>{' '}
                da lista de carteiras?
            </Subtitle>
        )
    }

    return (
        <BasicPage title={'Cadastro de Carteira'}>
            <BasicListScreen
                searchValue={filter}
                searchOnChange={handleChangeFilter}
                searchPlaceholder={'Pesquise por carteira ou vendedor(a)'}
                linkTo={'/carteiras/novo'}
                buttonText={'Cadastrar Carteira'}
                buttonWithAddIcon={true}
            >
                <Table
                    value={portfolios}
                    loading={isLoadingPortfolios}
                    onPage={handlePagination}
                    rows={rows}
                    first={first}
                    totalRecords={totalRecords}
                    totalRecordsText="{totalRecords} Carteiras Encontradas"
                >
                    <Column
                        field="portfolio.name"
                        header="Nome"
                        body={renderNameColumn}
                        style={ColumnName}
                    />

                    <Column
                        header="Qte Vendedores"
                        body={rowData => rowData.users.length}
                        style={ColumnNameCenter}
                    />
                    <Column
                        header="Qte Clientes"
                        body={rowData => rowData.clients.length}
                        style={ColumnNameCenter}
                    />
                    <Column body={renderActionsColumn} style={ActionColumn} />
                </Table>
            </BasicListScreen>

            <DeletionAlertModalComponent
                onClose={() => {
                    setShowConfirm(false)
                    setPortfolioToDelete(null)
                }}
                isVisible={showConfirm}
                title="Deseja excluir este cadastro?"
                renderDeletionText={renderDeletionText}
                onConfirm={() => onConfirmDeletePortfolio(portfolioToDelete)}
                isLoading={isDeletingPortfolio}
            />
        </BasicPage>
    )
}

const ColumnName = {
    textAlign: 'start',
    width: '30%',
}

const ColumnNameCenter = {
    textAlign: 'center',
    width: '30%',
}

const ActionColumn = {
    textAlign: 'center',
    width: '10%',
}

const ActionsContent = styled.div`
    display: grid;
    grid-template-columns: repeat(3, min-content);
    column-gap: 8px;
`

const NameCell = styled.span`
    color: ${Colors.peacockBlue};
`

const Subtitle = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #6c6e6f;
`
