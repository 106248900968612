import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import OrderToPrint from './OrderToPrint'
import { MdPrint } from 'react-icons/md'
import Colors from '../commons/Colors'
import styled from 'styled-components'

const PrintOrder = ({ screenProps, isFragmentedOrder, fragmentedOrders }) => {
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <>
            <div style={{ display: 'none' }}>
                <OrderToPrint
                    ref={componentRef}
                    selectedCard={screenProps}
                    fragmentedOrders={fragmentedOrders}
                />
            </div>

            <PrintIcon
                onClick={handlePrint}
                isFragmentedOrder={isFragmentedOrder}
            />
        </>
    )
}

const PrintIcon = styled(MdPrint)`
    cursor: pointer;
    font-size: 24px;
    color: ${({ isFragmentedOrder }) =>
        isFragmentedOrder ? Colors.deepSeaBlue : Colors.black};

    margin-right: ${({ isFragmentedOrder }) =>
        isFragmentedOrder ? 0 : '17px'};
`

export default PrintOrder
