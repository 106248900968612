export const NutrientClassification = {
    GENERAL: 'Geral',
    MACRO_MINERALS: 'Macro Minerais',
    MICRO_MINERALS: 'Micro Minerais',
    VITAMINS: 'Vitaminas',
    ADDITIONS_AND_OTHER: 'Aditivos/Outros',
}

export const filterWarrantyLevelsByClassification = (
    warrantyLevels,
    classification
) => {
    return warrantyLevels.filter(
        warrantyLevel =>
            warrantyLevel.nutrient.nutrientClassification.name ===
            classification
    )
}

export const formatWarrantyLevels = (warrantyLevels, setWarantyLevels) => {
    const formatedWarrantyLevels = []
    warrantyLevels.forEach(warrantyLevel => {
        if (typeof warrantyLevel.quantity !== 'number') {
            warrantyLevel.quantity = Number(
                warrantyLevel.quantity.replace(',', '.')
            )
        }

        if (
            warrantyLevel.quantity <= 0 ||
            !warrantyLevel.nutrient ||
            !warrantyLevel.nutrient.name ||
            !warrantyLevel.nutrient.name === ''
        ) {
            return
        }

        formatedWarrantyLevels.push(warrantyLevel)
    })

    setWarantyLevels(formatedWarrantyLevels)
    return formatedWarrantyLevels
}

export const createEmptyWarrantyLevel = () => {
    return {
        quantity: 0,
        nutrient: {
            name: '',
            id: '',
            nutrientClassification: {
                measurementUnit: '',
                name: '',
            },
        },
    }
}

export const isProductValid = form => {
    if (
        form.legacyCode.length !== 0 &&
        form.name.length !== 0 &&
        form.price !== '' &&
        form.weight > 0 &&
        form.measurementUnitId !== ''
    ) {
        return true
    }
    return false
}
