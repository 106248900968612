import React from 'react'
import styled from 'styled-components'

import Colors from '../../commons/Colors'
import { getPriceString } from '../../price/PriceUtils'
import * as NumberUtils from '../../commons/NumberUtils'

export default function ExtraSimpleBillingInfoComponent({
    simpleBilling,
    shipmentToCreate,
}) {
    const needsPallet = simpleBilling =>
        simpleBilling?.needsPallet ? 'Sim' : 'Não'
    const needsChapa = simpleBilling =>
        simpleBilling?.needsChapa ? 'Sim' : 'Não'

    const getWeightInTonnes = simpleBilling => {
        return `${NumberUtils.formatDecimalPlace(
            simpleBilling.weightInTonnes
        )} ton.`
    }

    return (
        <Container>
            <Title>Informações adicionais sobre Pedido e Remessa</Title>
            <Table>
                <TableHeader>
                    <TableCell>Forma de Pagamento</TableCell>
                    <TableCell>Prazo de Pagamento</TableCell>
                    <TableCell>Chapa</TableCell>
                    <TableCell>Pallet</TableCell>
                    <TableCell>Tipo de Frete</TableCell>
                    <TableCell>Peso Total</TableCell>
                    <TableCell>Frete</TableCell>
                    <TableCell>Encargos</TableCell>
                    <TableCell
                        className="shipment"
                        disabled={shipmentToCreate.freightPrice === 0}
                    >
                        Frete da Remessa
                    </TableCell>
                    <TableCell
                        className="shipment"
                        disabled={shipmentToCreate.chargesPrice === 0}
                    >
                        Encargos da Remessa
                    </TableCell>
                </TableHeader>
                <TableRow>
                    <TableCell>{simpleBilling.paymentForm}</TableCell>
                    <TableCell>{simpleBilling.paymentDeadline}</TableCell>
                    <TableCell>{needsChapa(simpleBilling)}</TableCell>
                    <TableCell>{needsPallet(simpleBilling)}</TableCell>
                    <TableCell>{simpleBilling.freightType}</TableCell>
                    <TableCell>{getWeightInTonnes(simpleBilling)}</TableCell>
                    <TableCell>
                        {simpleBilling.freightPrice === 0
                            ? '-'
                            : getPriceString(simpleBilling.freightPrice)}
                    </TableCell>
                    <TableCell>
                        {simpleBilling.chargesPrice === 0
                            ? '-'
                            : getPriceString(simpleBilling.chargesPrice)}
                    </TableCell>
                    <TableCell
                        className="shipment"
                        disabled={shipmentToCreate.freightPrice === 0}
                    >
                        {shipmentToCreate.freightPrice === 0
                            ? '-'
                            : getPriceString(shipmentToCreate.freightPrice)}
                    </TableCell>
                    <TableCell
                        className="shipment"
                        disabled={shipmentToCreate.chargesPrice === 0}
                    >
                        {shipmentToCreate.chargesPrice === 0
                            ? '-'
                            : getPriceString(shipmentToCreate.chargesPrice)}
                    </TableCell>
                </TableRow>
            </Table>
        </Container>
    )
}

const Container = styled.div`
    background-color: ${Colors.white};
    border-radius: 4px;
    padding: 15px;
    margin-top: 16px;
`

const Title = styled.p`
    font-size: 14px;
    font-weight: 550;
    line-height: 1.5;
    letter-spacing: 0.15px;
    text-align: left;
    color: ${Colors.brownishGrey};
    margin: 0px;
`

const Table = styled.div`
    display: grid;
    margin-top: 20px;
`

const TableRow = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 10%);
    padding-left: 5px;
`

const TableHeader = styled(TableRow)`
    background-color: ${Colors.veryLightPink};
`

const TableCell = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px;

    &.shipment {
        background-color: ${props =>
            props.disabled
                ? 'rgba(238, 242, 237, 0.35)'
                : 'rgba(77, 163, 47, 0.1)'};
        color: ${props => (props.disabled ? Colors.opacityGrey : null)};
        transition: background-color 0.2s, color 0.2s;
    }
`
