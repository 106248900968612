import React from 'react'
import styled from 'styled-components'
import { IoMdClose } from 'react-icons/io'

import Colors from '../Colors'

const ModalResponsive = ({ className, children, visible, onClose, title }) => {
    if (title && visible) {
        return (
            <OverlayPanel className={className}>
                <ModalWindow>
                    <ModalHeaderContainer>
                        <Title>{title}</Title>
                        <CloseIcon
                            data-testid="modal-close-icon"
                            onClick={() => {
                                onClose && onClose()
                            }}
                        />
                    </ModalHeaderContainer>
                    {children}
                </ModalWindow>
            </OverlayPanel>
        )
    }

    return (
        visible && (
            <OverlayPanel className={className}>
                <ModalWindow>
                    <CloseIcon
                        data-testid="modal-close-icon"
                        onClick={() => {
                            onClose && onClose()
                        }}
                    />
                    {children}
                </ModalWindow>
            </OverlayPanel>
        )
    )
}

const OverlayPanel = styled.div`
    position: fixed;
    z-index: 9997;
    display: flex;
    justify-content: center;
    background: rgba(5, 45, 62, 0.7);
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    overflow-y: auto;
    overflow-x: hidden;
`

const ModalHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 0 24px;
`

const Title = styled.span`
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.75;
    letter-spacing: 0.44px;
    text-align: left;
    color: #333;
`

const ModalWindow = styled.div`
    background-color: ${Colors.white};
    border-radius: 4px;

    height: fit-content;

    margin: 5vh;
`

const CloseIcon = styled(IoMdClose)`
    cursor: pointer;
    margin: 8px;
    float: right;
`

export default ModalResponsive
