import OutlineButton from '../OutlineButton'

import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Colors from '../../Colors'
import * as UserAPI from '../../../login/UserAPI'
import HeadsetIcon from '../../../assets/customIcons/HeadsetIcon'

export const HelpCenter = () => {
    const returnTo = encodeURI(`https://farmtellmillsdsm.zendesk.com/hc/pt-br`)
    const formRef = useRef(null)
    const inputRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)

    const fetchZendeskToken = async () => {
        const response = await UserAPI.generateZendeskToken()
        return response.token
    }

    const handleButton = async () => {
        setIsLoading(true)
        try {
            const token = await fetchZendeskToken()
            setIsLoading(false)
            inputRef.current.value = token
            formRef.current.submit()
        } catch (err) {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <HelpCenterButton
                color={Colors.black}
                onClick={handleButton}
                loading={isLoading}
                testId={'help-center-button-testid'}
            >
                <HeadsetIcon />
                <Text>Central de Ajuda</Text>
            </HelpCenterButton>
            <form
                data-testId={'help-center-form-testid'}
                ref={formRef}
                action={`https://prodapsuporte.zendesk.com/access/jwt?&return_to=${returnTo}`}
                method="post"
                target="_blank"
            >
                <input ref={inputRef} type="hidden" name="jwt"></input>
            </form>
        </div>
    )
}

export default HelpCenter

const HelpCenterButton = styled(OutlineButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    margin-right: 22px;
    border-radius: 0px;
    border: none;
`
const Text = styled.label`
    display: flex;
    letter-spacing: 0.4px;
    color: ${Colors.black};
    font-size: 14px;
    text-align: right;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`
