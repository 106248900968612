import React from 'react'
import styled from 'styled-components'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'

import { removeDuplicatedCategories } from '../utils/ClientsUtils'

import Table from '../../commons/components/Table'
import Colors from '../../commons/Colors'
import DebtorClientIndicator from '../../commons/components/DebtorClientIndicator'
import { EditIcon, DeleteIcon } from '../../commons/commonStyles'
import BasicListScreen from '../../commons/components/BasicListScreen'
import BasicPage from '../../commons/components/BasicPage'
import DeletionAlertModalComponent from '../../productUsage/DeletionAlertModalComponent'

export default function ClientListView({
    filter,
    clients,
    showConfirm,
    setShowConfirm,
    clientToDelete,
    setClientToDelete,
    isLoadingClients,
    isDeletingClient,
    totalRecords,
    first,
    rows,
    openConfirmationModal,
    onConfirmDeleteClient,
    handlePagination,
    handleChangeFilter,
}) {
    const renderActionsColumn = client => {
        return (
            <ActionsContent>
                <Link to={{ pathname: `/clientes/editar/${client.id}` }}>
                    <EditIcon />
                </Link>

                <DeleteIcon
                    data-testid={`delete-icon-${client.id}`}
                    onClick={() => openConfirmationModal(client)}
                />
            </ActionsContent>
        )
    }

    const renderNameColumn = client => {
        return <NameCell>{client.owner?.name}</NameCell>
    }

    const renderNoBreakLineColumn = (client, field) => {
        let columnContent = []
        switch (field) {
            case 'seller':
                columnContent = [
                    ...client.portfolios.reduce((acc, cur) => {
                        for (const user of cur.users) {
                            acc.add(user.name)
                        }
                        return acc
                    }, new Set()),
                ]
                if (!columnContent.length) columnContent = ['-']
                break
            case 'category':
                columnContent = removeDuplicatedCategories(client.portfolios)
                if (!columnContent.length) columnContent = ['-']
                break
            default:
                break
        }

        return (
            <NoBreakLineContent>{columnContent.join(', ')}</NoBreakLineContent>
        )
    }

    const handleDebtorIcon = client => {
        return <DebtorClientIndicator debtor={client.debtor} tooltip />
    }
    const renderDeletitionText = () => {
        return (
            <Subtitle>
                Tem certeza de que deseja excluir o cliente{' '}
                <strong>
                    {clientToDelete && clientToDelete.owner
                        ? clientToDelete.owner.name
                        : null}
                </strong>{' '}
                da lista de clientes?
            </Subtitle>
        )
    }

    return (
        <BasicPage title="Clientes">
            <BasicListScreen
                searchValue={filter}
                searchOnChange={handleChangeFilter}
                searchPlaceholder="Pesquise por Código, Nome, Nome Fantasia"
                linkTo="/clientes/novo"
                buttonText="Cadastrar Cliente"
                buttonWithAddIcon
            >
                <Table
                    value={clients}
                    loading={isLoadingClients}
                    onPage={handlePagination}
                    rows={rows}
                    first={first}
                    totalRecords={totalRecords}
                    totalRecordsText="{totalRecords} Clientes Encontrados"
                >
                    <Column
                        field="legacyCode"
                        header="Código"
                        style={ColumnCode}
                    />

                    <Column
                        body={rowData => handleDebtorIcon(rowData)}
                        style={DebtorColumn}
                    />

                    <Column
                        field="owner.name"
                        header="Nome do Cliente"
                        body={renderNameColumn}
                        style={ColumnName}
                    />
                    <Column
                        field="tradeName"
                        header="Nome Fantasia "
                        style={ColumnName}
                    />
                    <Column
                        header="Vendedor Associado"
                        body={rowData =>
                            renderNoBreakLineColumn(rowData, 'seller')
                        }
                        style={ColumnNameCenter}
                    />
                    <Column
                        header="Carteira/Rota"
                        body={rowData =>
                            renderNoBreakLineColumn(rowData, 'category')
                        }
                        style={ColumnNameCenter}
                    />
                    <Column body={renderActionsColumn} style={ActionColumn} />
                </Table>
            </BasicListScreen>

            <DeletionAlertModalComponent
                title="Deseja excluir este cadastro?"
                isVisible={showConfirm}
                isLoading={isDeletingClient}
                renderDeletionText={renderDeletitionText}
                onClose={() => {
                    setShowConfirm(false)
                    setClientToDelete(null)
                }}
                onConfirm={() => onConfirmDeleteClient(clientToDelete)}
            ></DeletionAlertModalComponent>
        </BasicPage>
    )
}

const ColumnCode = {
    width: '10%',
    textAlign: 'start',
}

const ColumnName = {
    textAlign: 'start',
    width: '25%',
}

const ColumnNameCenter = {
    textAlign: 'center',
    width: '25%',
}

const ActionColumn = {
    textAlign: 'center',
    width: '10%',
}

const ActionsContent = styled.div`
    display: grid;
    grid-template-columns: repeat(3, min-content);
    column-gap: 8px;
`

const NameCell = styled.span`
    color: ${Colors.peacockBlue};
`

const NoBreakLineContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const DebtorColumn = {
    textAlign: 'center',
    width: '6%',
}

const Subtitle = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #6c6e6f;
`
