import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import Colors from '../commons/Colors'

import { newsRepository } from './repository/NewsRepository'
import * as NewsUtils from './utils/NewsUtils'
import * as AnalyticsUtils from '../commons/AnalyticsUtils'

import { Sidebar as Menu } from 'primereact/sidebar'
import CalendarIcon from '../assets/customIcons/CalendarIcon'
import NotificationComponent from '../commons/components/screenHeader/NotificationComponent'
import FeatureCardListComponent from './FeatureCardListComponent'
import NewsModalComponent from './NewsModalComponent'

const NewsSideMenuComponent = () => {
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [features, setFeatures] = useState([])

    useEffect(() => {
        const fetchFeatures = async () => {
            const repository = newsRepository()
            const features = await repository.getAll()
            setFeatures(features)
        }
        try {
            fetchFeatures()
        } catch (err) {
            console.error(err)
        }
    }, [])

    const isEmptyNewsMenu = () => !Boolean(features.length)

    const renderCalendarIcon = () => {
        const noNotificationsText =
            'Você já viu todas suas notificações dos últimos 30 dias'
        const notificationsText =
            'Estas são todas as suas notificações dos últimos 30 dias'

        return (
            <>
                <CalendarIconContainer>
                    <CalendarIcon />
                </CalendarIconContainer>
                <CalendarTextContainer>
                    <CalendarText>
                        {isEmptyNewsMenu()
                            ? noNotificationsText
                            : notificationsText}
                    </CalendarText>
                </CalendarTextContainer>
            </>
        )
    }

    const handleNotificationOnClick = async (featureId, isRemoved = true) => {
        const repository = newsRepository()
        const onRemoveEvent = AnalyticsUtils.EVENT_KEYS.removeNewsNotification
        const onViewEvent = AnalyticsUtils.EVENT_KEYS.openNewsDocument

        if (isRemoved && isNewFeature(featureId)) {
            AnalyticsUtils.analyticsEvent(onRemoveEvent)
        }

        if (!isRemoved) {
            AnalyticsUtils.analyticsEvent(onViewEvent)
        }

        repository.update(featureId, isRemoved)
        const updatedFeatures = await repository.getAll()

        setFeatures(updatedFeatures)
    }

    const isNewFeature = featureId => {
        const repository = newsRepository()
        return !Boolean(repository.findById(featureId))
    }

    const handleNewsModalOnConfirm = event => {
        event.preventDefault()
        setIsMenuVisible(!isMenuVisible)
        setIsModalVisible(false)
        NewsUtils.setNotfied(true, new Date())
    }

    const handleNewsModalOnClose = () => {
        setIsMenuVisible(!isMenuVisible)
        setIsModalVisible(false)
        NewsUtils.setNotfied(true, new Date())
    }

    useEffect(() => {
        const handleNewsModal = () => {
            const newsCount = NewsUtils.getNewsCount(newsRepository, features)
            const data = NewsUtils.getNotificationStorageData()
            const isToNotify = NewsUtils.isToNotify(data, features)
            if (isToNotify && newsCount > 0) {
                NewsUtils.setNotfied(false)
                setIsModalVisible(true)
            }
        }
        if (features.length < 1) return
        handleNewsModal()
    }, [features])

    return (
        <Container>
            <NotificationComponent
                onClick={() =>
                    !isModalVisible && setIsMenuVisible(!isMenuVisible)
                }
                counter={NewsUtils.getNewsCount(newsRepository, features)}
            />
            <Sidebar
                dismissable={false}
                showCloseIcon={false}
                modal={false}
                closeOnEscape={true}
                position="right"
                visible={isMenuVisible}
                onHide={() => setIsMenuVisible(false)}
            >
                <SideBarContent>
                    <TitleContainer>
                        <Title>Notificações de Novidades</Title>
                    </TitleContainer>
                    {isEmptyNewsMenu() && <Divider />}
                    <CardsContainer>
                        <FeatureCardListComponent
                            features={features}
                            handleClick={handleNotificationOnClick}
                            isNewFeature={isNewFeature}
                        />
                    </CardsContainer>
                    <CalendarInformationContainer isEmpty={isEmptyNewsMenu()}>
                        {renderCalendarIcon()}
                    </CalendarInformationContainer>
                </SideBarContent>
            </Sidebar>
            <NewsModalComponent
                isVisible={isModalVisible}
                onConfirm={handleNewsModalOnConfirm}
                onClose={handleNewsModalOnClose}
            />
        </Container>
    )
}

const Container = styled.div``

const Sidebar = styled(Menu)`
    margin-top: 65px;
    height: calc(100vh - 60px);
    padding: 0 !important;
    width: 400px;
    overflow: auto;
`

const SideBarContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const TitleContainer = styled.div`
    padding: 15px 0 15px 0;
    text-align: center;
`

const Title = styled.span`
    color: ${Colors.orangeTwo};
`

const CardsContainer = styled.div`
    display: flex;
    width: 100%
    align-items: center;
`

const Divider = styled.div`
    height: 1px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(199, 199, 204, 0.5);
`

const CalendarInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: ${({ isEmpty }) => (isEmpty ? '100%' : 'auto')};
    padding-top: ${({ isEmpty }) => (isEmpty ? 0 : '35px')};
    padding-bottom: ${({ isEmpty }) => (isEmpty ? 0 : '35px')};
`
const CalendarIconContainer = styled.div``

const CalendarTextContainer = styled.div`
    width: 55%;
    padding-top: 20px;
`
const CalendarText = styled.span`
    font-family: Roboto;
    font-size: 11px;
    font-weight: 600;
    color: ${Colors.brownishGrey};
`
export default NewsSideMenuComponent
