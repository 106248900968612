import React, { useState, useEffect } from 'react'
import * as PendingOrderAPI from '../PendingOrderAPI'
import * as orderAPI from '../../dashboard/OrderAPI'
import * as FreightTableAPI from '../FreightTableAPI'
import * as ClientAPI from '../../client/ClientAPI'
import OrderFormView from './OrderFormView'
import { useMillContext } from '../../context/MillContext'
import { addMessage, MessageType } from '../../commons/MessageUtils'
import {
    isInvalidOrder,
    getFreightTable,
    recalculatesOrder,
    getOrderStructure,
    mountOrder,
    isOrderInvalidFields,
    convertGcpClientToSydle,
    handleOnSaveOrder,
} from '../OrderFormScreenUtils'
import { analyticsEvent, EVENT_KEYS } from '../../commons/AnalyticsUtils'

const OrderFormContainer = ({
    goToOrders,
    goToHistory,
    goToSimpleBillings,
    orderFrom,
    orderId,
    path,
}) => {
    const { millConfig, mill } = useMillContext()
    const [order, setOrder] = useState(getOrderStructure())
    const [isSavingOrder, setIsSavingOrder] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isMillHasFreight, setIsMillHasFreight] = useState(false)
    const [freightTable, setFreightTable] = useState({})
    const [invalidFields, setInvalidFields] = useState([
        { field: 'farm', isValid: true },
        { field: 'paymentForm', isValid: true },
        { field: 'paymentDeadline', isValid: true },
        { field: 'deliveryDate', isValid: true },
        { field: 'items', isValid: true },
    ])
    useEffect(() => {
        const fetchFreightTable = async () => {
            const response = await FreightTableAPI.getFreightTablesV1()
            if (response?.data && response?.data.length) {
                setIsMillHasFreight(true)
                const freightTable = getFreightTable(order.farm, response.data)
                setFreightTable(freightTable)
            }
        }
        try {
            if (order.farm) fetchFreightTable()
        } catch (err) {
            console.error(err)
        }
    }, [order.farm])

    useEffect(() => {
        const getPendingOrder = async orderId => {
            const response = await PendingOrderAPI.getById(orderId)

            if (response && response.order) {
                const orderFarm = response.order?.farm
                const { data } = await ClientAPI.getClientTemp(
                    orderFarm?.gcpId || orderFarm._id
                )
                const [sydleFarm] = convertGcpClientToSydle(data)
                const orderToMount = {
                    ...response.order,
                    farm: sydleFarm,
                    orderFrom: {
                        isConfirmedOrder: false,
                        isPendingOrder: true,
                    },
                    isOrderEdition: true,
                }

                setOrder(
                    order => mountOrder({ ...order, ...orderToMount }),
                    isMillHasFreight
                )
            }
        }

        const getConfimedOrder = async orderId => {
            const response = await orderAPI.getOrderById(orderId)
            if (response && response.order) {
                const orderFarm = response.order?.farm
                const { data } = await ClientAPI.getClientTemp(
                    orderFarm?.gcpId || orderFarm._id
                )
                const [sydleFarm] = convertGcpClientToSydle(data)
                const orderToMount = {
                    ...response.order,
                    farm: sydleFarm,
                    orderFrom: {
                        isConfirmedOrder: true,
                        isPendingOrder: false,
                    },
                    isOrderEdition: true,
                }

                setOrder(order =>
                    mountOrder({ ...order, ...orderToMount }, isMillHasFreight)
                )
            }
        }

        if (orderId) {
            setIsLoading(true)
            try {
                const isConfirmedOrder = orderFrom?.isConfirmedOrder
                if (isConfirmedOrder && millConfig?.allowsOrderEdition) {
                    getConfimedOrder(orderId)
                }

                if (!isConfirmedOrder) getPendingOrder(orderId)
            } catch (err) {
                addMessage(MessageType.ERROR, 'Erro ao buscar o pedido.')
            } finally {
                setIsLoading(false)
            }
        }
    }, [orderId, orderFrom, millConfig, isMillHasFreight])

    const isNewSimpleBilling = () => path === '/simples-faturamento/novo'

    const onSaveOrder = async e => {
        e.preventDefault()

        if (order.isInvalidOrder) return handleInvalidFieldsOnSaveOrder()

        const isSimpleBilling = isNewSimpleBilling()
        const isConfirmedOrder = order.orderFrom.isConfirmedOrder
        const messageText = isSimpleBilling ? 'simples faturamento' : 'pedido'

        try {
            setIsSavingOrder(true)

            await handleOnSaveOrder({
                isSimpleBilling,
                isConfirmedOrder,
                orderId,
                order,
            })

            addMessage(
                MessageType.SUCCESS,
                `O ${messageText} foi registrado com sucesso.`
            )

            if (isSimpleBilling) {
                analyticsEvent(EVENT_KEYS.createSimpleBilling)
                return goToSimpleBillings()
            }
            if (isConfirmedOrder) return goToHistory()
            if (!isConfirmedOrder) return goToOrders()
        } catch (err) {
            console.error(err.message)
            addMessage(MessageType.ERROR, `Erro ao salvar o ${messageText}.`)
        } finally {
            setIsSavingOrder(false)
        }
    }

    const handleInvalidFieldsOnSaveOrder = () => {
        const { invalidFields } = isOrderInvalidFields(
            order,
            isMillHasFreight,
            isNewSimpleBilling()
        )
        const isInvalidPaymentForm = invalidFields.some(
            field => field.field === 'paymentForm'
        )

        addMessage(
            MessageType.ERROR,
            'Erro, existem campos obrigatórios sem preenchimento ou preenchidos de forma incorreta.'
        )

        if (isInvalidPaymentForm) {
            return setInvalidFields(
                invalidFields.filter(field => field.field !== 'paymentDeadline')
            )
        }

        return setInvalidFields(invalidFields)
    }

    const onChangeOrder = (field, value) => {
        setOrder(order => {
            const updatedOrder = recalculatesOrder(
                { ...order, [field]: value },
                freightTable,
                millConfig
            )
            const { isInvalid } = isInvalidOrder(
                updatedOrder,
                isMillHasFreight,
                isNewSimpleBilling(),
                millConfig?.allowsOrderOutOfPricePolicy
            )
            return {
                ...updatedOrder,
                isInvalidOrder: isInvalid,
            }
        })
    }

    const getInvalidFieldValue = (key, invalidFields) => {
        const field = invalidFields.find(field => field.field === key)

        return field ? !field.isValid : false
    }

    const setValidField = fieldToSet => {
        setInvalidFields(currentInvalidFields => [
            ...currentInvalidFields.filter(field => field.field !== fieldToSet),
            { field: fieldToSet, isValid: true },
        ])
    }

    return (
        <OrderFormView
            order={order}
            setOrder={onChangeOrder}
            isSavingOrder={isSavingOrder}
            onSaveOrder={onSaveOrder}
            isMillHasFreight={isMillHasFreight}
            millConfig={millConfig}
            mill={mill}
            freightTable={freightTable}
            isLoading={isLoading}
            invalidFieldsHandler={{
                invalidFields,
                setValidField: setValidField,
                getInvalidField: getInvalidFieldValue,
            }}
            isNewSimpleBilling={isNewSimpleBilling}
        />
    )
}

export default OrderFormContainer
