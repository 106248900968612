import React from 'react'

const ProductSummaryIcon = () => {
    return (
        <svg width="23" height="21" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="nonzero" fill="none">
                <path d="M1 0h20.168v20.168H1z" />
                <path
                    d="M16.967 2.521h-3.513a2.53 2.53 0 0 0-2.37-1.68 2.53 2.53 0 0 0-2.37 1.68H5.202c-.925 0-1.681.756-1.681 1.68v11.766c0 .924.756 1.68 1.68 1.68h11.766c.924 0 1.68-.756 1.68-1.68V4.202c0-.925-.756-1.681-1.68-1.681zm-5.883 0c.462 0 .84.378.84.84 0 .463-.378.84-.84.84a.843.843 0 0 1-.84-.84c0-.462.378-.84.84-.84zm1.68 11.765H6.883v-1.68h5.883v1.68zm2.522-3.361H6.882V9.244h8.404v1.68zm0-3.362H6.882v-1.68h8.404v1.68z"
                    fill="#666"
                />
            </g>
        </svg>
    )
}

export default ProductSummaryIcon
