import React from 'react'
import styled from 'styled-components'
import { Paginator } from 'primereact/paginator'

import Colors from '../../../commons/Colors'
import { DeleteIcon } from '../../../commons/commonStyles'
import ProductsCarouselComponent from './ProductsCarouselComponent'
import bLoading from '../../../commons/components/Loading'

export default function ProductUsageTable({
    tableValues,
    handleDeletionModal,
    indexOfFirstTableItem,
    handlePagination,
    isLoading,
    totalRecords,
    PAGINATOR_ROWS,
}) {
    const renderProductsColumn = () => {
        return (
            <Column>
                <HeaderCell>
                    <ColumnTitle>Produto</ColumnTitle>
                </HeaderCell>
                {tableValues.map(product => (
                    <Cell key={product.name}>
                        <ProductNameText>{product.name}</ProductNameText>
                    </Cell>
                ))}
            </Column>
        )
    }

    const renderTableIcons = () => {
        return (
            <Column>
                <HeaderCell className="actions-cell">
                    <ColumnTitle>Ações</ColumnTitle>
                </HeaderCell>
                {tableValues.map((productUsage, index) => {
                    return (
                        <Cell
                            className="actions-cell"
                            key={`${productUsage.name}-${index}`}
                        >
                            <IconsContainer>
                                <DeleteIcon
                                    onClick={() =>
                                        handleDeletionModal(productUsage)
                                    }
                                    data-testid="delete-icon"
                                />
                            </IconsContainer>
                        </Cell>
                    )
                })}
            </Column>
        )
    }

    return isLoading ? (
        <Loading />
    ) : (
        <Container>
            <Grid>
                {renderProductsColumn()}
                <ProductsCarouselComponent tableValues={tableValues} />
                {renderTableIcons()}
            </Grid>
            <StyledPaginator
                rows={PAGINATOR_ROWS}
                first={indexOfFirstTableItem}
                onPageChange={handlePagination}
                totalRecords={totalRecords}
            />
        </Container>
    )
}

const Container = styled.div`
    background-color: ${Colors.veryLightPink};
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 24% 70% 6%;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`

const Cell = styled.div`
    background-color: ${Colors.white};
    display: flex;
    align-content: center;
    align-items: center;

    padding: 0px 15px;
    height: 65px;
    margin-bottom: 1px;

    &.actions-cell {
        justify-content: center;
        box-shadow: -2px 1px 3px 0 rgba(0, 0, 0, 0.05);
    }
`
const HeaderCell = styled(Cell)`
    height: 48px;

    background-color: ${Colors.blueGrey};
`

const ColumnTitle = styled.h3`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: ${Colors.white};
    margin: 0;
`

const IconsContainer = styled.div`
    display: flex;
    justify-content: center;
`

const ProductNameText = styled.p`
    font-size: 14px;
    margin: 0;
    color: ${Colors.black};
`

const StyledPaginator = styled(Paginator)`
    .p-highlight {
        background-color: rgba(241, 141, 58, 0.5) !important;
    }

    border: none !important;
`

const Loading = styled(bLoading)`
    position: relative;
    top: 25vh;
`
