import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Colors from '../Colors'

/**
 *
 * @param {React.FunctionComponent} children - conteudo do accordion
 * @param {string} header - texto para o header do accordion
 * @param {React.FunctionComponent} icon - icone a ser renderizado no accordion
 * @param {function} onClickIcon - o que deve ocorrer ao clicar no icone
 * @param {boolean} opened - diz se o accordion esta visivel ou não
 */

export const Accordion = ({
    children,
    header,
    icon,
    onClickIcon,
    opened = false,
}) => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setVisible(opened)
    }, [opened])

    return (
        <AccordionContainer>
            <AccordionButton
                visible={visible}
                onClick={() => setVisible(!visible)}
            >
                {header}
                <IconContainer
                    onClick={e => {
                        onClickIcon && onClickIcon()
                        setVisible(true)
                        e.stopPropagation()
                    }}
                >
                    {icon}
                </IconContainer>
            </AccordionButton>
            <AccordionContent data-testid="accordion-content" visible={visible}>
                {children}
            </AccordionContent>
        </AccordionContainer>
    )
}

const AccordionContainer = styled.div``

const AccordionButton = styled.button`
    width: 100%;
    height: 40px;
    border: none;

    background-color: rgba(241, 141, 58, 0.1) !important;

    color: ${Colors.orange};
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    margin-bottom: 1px;
    border-bottom: 1px solid #c6cbd475;

    :focus {
        outline: none;
    }
`

const IconContainer = styled.div`
    cursor: pointer;
    float: right;
    margin-right: 3.25vw;
`

const AccordionContent = styled.div`
    max-height: ${props => (props.visible ? '1000px' : '0')};
    opacity: ${props => (props.visible ? '1' : '0')};
    overflow: ${props => (props.visible ? 'visible' : 'hidden')};

    padding: 0;
    background-color: ${Colors.white};

    transition: all 0.3s ease;
`

export default Accordion
