import { getBaseURL, getAPIKey, getToken } from '../environment'

const service = 'orders'
const endpoint = 'fragmentedOrders'
const apiKey = getAPIKey()

export const getAllFragmentedOrders = args => {
    const url = `${getBaseURL()}/${service}/${endpoint}/`
    const queryParams = new URLSearchParams(args)

    return fetch(`${url}?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            'x-api-key': apiKey,
            Authorization: `${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const createFragmentedOrders = fragmentedOrders => {
    const body = { fragmentedOrders: fragmentedOrders }
    const url = `${getBaseURL()}/${service}/${endpoint}`

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const updateFragmentedOrder = (id, statusName) => {
    const url = `${getBaseURL()}/${service}/${endpoint}`

    return fetch(`${url}/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
            Authorization: `${getToken()}`,
        },
        body: JSON.stringify({
            status: {
                name: statusName,
            },
        }),
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const deleteFragmentedOrders = fragmentedOrdersIds => {
    const url = `${getBaseURL()}/${service}/${endpoint}`
    const queryParams = new URLSearchParams({})

    fragmentedOrdersIds.forEach(fragmentId =>
        queryParams.append('id', fragmentId)
    )

    return fetch(`${url}?${queryParams.toString()}`, {
        method: 'DELETE',
        headers: {
            'x-api-key': apiKey,
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
