import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MdDelete } from 'react-icons/md'

import InputStyled from '../commons/components/Input'
import Dropdown from '../commons/components/Dropdown'
import * as NumberUtils from '../commons/NumberUtils'

export const WarrantyLevelForm = ({
    warrantyLevels,
    setWarrantyLevels,
    dropdownOptions,
}) => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        const options = dropdownOptions.map(option => ({
            label: option.name,
            value: option,
        }))
        setOptions(options)
    }, [dropdownOptions])

    const handleChangeLine = (field, newValue, warrantyLevelChanged) => {
        const newWarrantyLevels = warrantyLevels.map(warrantyLevel => {
            if (warrantyLevel.nutrient === warrantyLevelChanged.nutrient) {
                warrantyLevel[field] = newValue
                return warrantyLevel
            }
            return warrantyLevel
        })

        setWarrantyLevels(newWarrantyLevels)
    }

    const isNutrientNotSelected = (nutrientSelected, warrantyLevels) => {
        const valueAlreadySelected = warrantyLevels.find(
            warrantyLevel =>
                warrantyLevel.nutrient.name === nutrientSelected.name
        )
        return valueAlreadySelected ? false : true
    }

    const deleteWarrantyLevel = warrantyLevel => {
        const newWarrantyLevels = warrantyLevels.filter(
            wl => wl !== warrantyLevel
        )
        setWarrantyLevels(newWarrantyLevels)
    }

    return (
        <>
            {warrantyLevels.map(warrantyLevel => (
                <WarrantyLevelFormContainer key={warrantyLevel._id}>
                    <NutrientDropdownContainer>
                        <NutrientDropdown
                            placeholder={'Escolha um nutriente'}
                            value={warrantyLevel.nutrient}
                            options={options}
                            onChange={e => {
                                if (
                                    isNutrientNotSelected(
                                        e.value,
                                        warrantyLevels
                                    )
                                ) {
                                    handleChangeLine(
                                        'nutrient',
                                        e.value,
                                        warrantyLevel
                                    )
                                }
                            }}
                        />
                    </NutrientDropdownContainer>

                    <CellContainer>
                        <Input
                            value={warrantyLevel.quantity}
                            onChange={e => {
                                if (NumberUtils.isValid(e.target.value)) {
                                    handleChangeLine(
                                        'quantity',
                                        e.target.value,
                                        warrantyLevel
                                    )
                                }
                            }}
                        />
                    </CellContainer>

                    <CellContainer>
                        <Input
                            value={
                                warrantyLevel.nutrient.nutrientClassification
                                    .measurementUnit
                            }
                            readOnly
                        />
                    </CellContainer>

                    <CellContainer>
                        <DeleteIcon
                            data-testid="delete-nutrient-icon"
                            onClick={() => deleteWarrantyLevel(warrantyLevel)}
                        />
                    </CellContainer>
                </WarrantyLevelFormContainer>
            ))}
        </>
    )
}

const WarrantyLevelFormContainer = styled.div`
    display: grid;
    grid-template-columns: 60% 15% 15% 10%;

    padding: 10px 0 10px 40px;
    border-bottom: 1px solid #c6cbd44d;
`

const NutrientDropdownContainer = styled.div`
    display: block;
`

const CellContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const NutrientDropdown = styled(Dropdown)`
    width: 100%;
`

const Input = styled(InputStyled)`
    width: 50%;
`

const DeleteIcon = styled(MdDelete)`
    font-size: 23px;
    cursor: pointer;
`

export default WarrantyLevelForm
