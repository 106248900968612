import {
    getAnalytics,
    logEvent,
    setAnalyticsCollectionEnabled,
    setUserId,
    setUserProperties,
} from '@firebase/analytics'
import { initializeApp } from '@firebase/app'
import { firebaseConfig } from '../firebaseConfig'

const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)

export const EVENT_KEYS = {
    login: 'login',
    addProductUsage: 'product_usage_add',
    saveProductUsage: 'product_usage_save',
    openNewsDocument: 'news_document_open',
    removeNewsNotification: 'news_notification_remove',
    logout: 'logout',
    createFragmentOrder: 'create_fragment_order',
    deleteFragmentOrder: 'delete_fragment_order',
    createShipments: 'create_shipments',
    deleteShipments: 'delete_shipments',
    createSimpleBilling: 'create_simple_billing',
    deliveredSimpleBilling: 'delivered_simple_billing',
}

export function disable() {
    setAnalyticsCollectionEnabled(analytics, false)
}

export function enable() {
    setAnalyticsCollectionEnabled(analytics, true)
}

export function analyticsEvent(event, params) {
    return logEvent(analytics, event, params)
}

export function analyticsSetUserId(userId) {
    return setUserId(analytics, userId)
}

export function analyticsSetUserProperties(userProperties) {
    return setUserProperties(analytics, userProperties)
}
