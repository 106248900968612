import React from 'react'

import PaymentFormsContainer from './PaymentFormsContainer'

const PaymentFormsComponent = ({
    order,
    setPaymentForms,
    invalidFieldsHandler,
}) => {
    return (
        <PaymentFormsContainer
            order={order}
            setPaymentForms={setPaymentForms}
            invalidFieldsHandler={invalidFieldsHandler}
        />
    )
}

export default PaymentFormsComponent
