import { InputSwitch } from 'primereact/inputswitch'
import React from 'react'
import styled from 'styled-components'

import Colors from '../Colors'

const SwitchButton = ({ checked, name, onChange, disabled }) => {
    if (checked === undefined) {
        return (
            <ButtonWithoutValueContainer disabled={disabled}>
                <InputSwitch
                    checked={checked}
                    onChange={event => onChange(event)}
                    name={name}
                    disabled={disabled}
                />
            </ButtonWithoutValueContainer>
        )
    }

    return (
        <ButtonWithValueContainer>
            <InputSwitch
                checked={checked}
                onChange={event => onChange(event)}
                name={name}
                disabled={disabled}
            />
        </ButtonWithValueContainer>
    )
}

const ButtonWithValueContainer = styled.div`
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background-color: ${Colors.softGreen};
    }

    .p-inputswitch .p-inputswitch-slider {
        background-color: ${Colors.heavyRed};
    }

    .p-inputswitch .p-inputswitch-slider:before {
        background-color: ${Colors.white};
    }
`

const ButtonWithoutValueContainer = styled.div`
    .p-inputswitch .p-inputswitch-slider {
        background-color: #e5e5e5;
        cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    }

    .p-inputswitch .p-inputswitch-slider:before {
        background-color: ${Colors.white};
        cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    }
`
export default SwitchButton
