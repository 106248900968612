import React, { useEffect, useState } from 'react'
import * as PromiseUtils from '../../commons/PromiseUtils'

import { debounce } from '../../commons/components/Debounce'

import ClientView from './ClientView'
import * as ClientAPI from '../../client/ClientAPI'
import { keyStorage } from '../../commons/LocalStorage'

const ClientContainer = ({ goToProductUsage }) => {
    const [clients, setClients] = useState([])
    const [clientsSearchTerm, setclientsSearchTerm] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [totalClients, setTotalClients] = useState(0)
    const [indexOfFirstTableItem, setIndexOfFirstTableItem] = useState(0)
    const userToken = JSON.parse(localStorage.getItem(keyStorage.userToken))

    const fetchClients = async (size, from, term) => {
        PromiseUtils.PromiseWrapper({
            promise: ClientAPI.getAllClients(
                size,
                from,
                term,
                userToken?.accessToken.payload.id
            ),
            onFulfilled: ({ data, total }) => {
                setIsError(false)
                setIsLoading(false)
                setClients(data)
                setTotalClients(total)
            },
            onRejected: () => {
                setIsError(true)
                setIsLoading(false)
            },
        })
    }

    useEffect(() => {
        try {
            fetchClients()
        } catch (err) {
            console.error(err)
        }
        // eslint-disable-next-line
    }, [])

    const handleClientsFilter = event => {
        const numberOfRows = 10
        const firstIndex = 0
        const searchTerm = event.target.value
        setclientsSearchTerm(searchTerm)
        setIsLoading(true)
        debounce(
            async () => await fetchClients(numberOfRows, firstIndex, searchTerm)
        )
    }

    const handleClientsTablePagination = async event => {
        setIndexOfFirstTableItem(event.first)
        const page = event.page + 1
        await fetchClients(event.rows, page, clientsSearchTerm)
    }

    const handleClientsRowOnClick = event => {
        goToProductUsage(event.data.id, event.data.owner.name)
    }

    return (
        <ClientView
            clients={clients}
            clientsSearchTerm={clientsSearchTerm}
            handleClientsFilter={handleClientsFilter}
            isLoading={isLoading}
            isError={isError}
            totalClients={totalClients}
            indexOfFirstTableItem={indexOfFirstTableItem}
            handleClientsTablePagination={handleClientsTablePagination}
            handleClientsRowOnClick={handleClientsRowOnClick}
        />
    )
}

export default ClientContainer
