import React, { useState, useCallback } from 'react'
import * as OrderAPI from '../dashboard/OrderAPI'
import * as FragmentedOrderApi from '../dashboard/FragmentedOrderApi'
import * as ShipmentsApi from '../dashboard/ShipmentApi'
import * as OrderGroupAPI from '../orderGroup/OrderGroupAPI'
import { MessageType, addMessage } from '../commons/MessageUtils'
import { STATUS_TEXT } from '../commons/Status'
import * as PromiseUtils from '../commons/PromiseUtils'
import { getUserPreferences, keyStorage } from '../commons/LocalStorage'

const EMPTY_SELECTED_ORDER_GROUP = {
    _id: null,
    isEditable: true,
}

export const DashboardContext = React.createContext({
    loading: true,
    orders: [],
    ordersWithFragments: [],
    fragmentedOrders: [],
    shipments: [],
    simpleBillings: [],
    orderGroups: [],
    selectedOrders: [],
    selectedFragmentedOrder: null,
    selectedOrderGroup: null,
    selectedOrderCard: null,
    showOrderGroupModal: false,
    fetchOrders: () => {},
    fetchOrdersWithFragments: () => {},
    fetchFragmentedOrders: () => {},
    fetchOrderGroups: () => {},
    handleSelectedOrders: () => {},
    handleOrderGroupModal: () => {},
    setSelectedOrderCard: () => {},
    setSelectedFragmentedOrder: () => {},
    clearSelectedOrders: () => {},
    changeOrderStatus: () => {},
    changeFragmentedOrderStatus: () => {},
    changeShipmentStatus: () => {},
    fetchDashboardData: () => {},
    setLoading: () => {},
})

export const useDashboardContext = () => React.useContext(DashboardContext)

const DashboardContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])
    const [orderGroups, setOrderGroups] = useState([])
    const [selectedOrders, setSelectedOrders] = useState([])
    const [showOrderGroupModal, setShowOrderGroupModal] = useState(false)
    const [selectedOrderGroup, setSelectedOrderGroup] = useState(null)
    const [selectedOrderCard, setSelectedOrderCard] = useState(null)
    const [selectedFragmentedOrder, setSelectedFragmentedOrder] = useState(null)
    const [fragmentedOrders, setFragmentedOrders] = useState([])
    const [ordersWithFragments, setOrdersWithFragments] = useState([])
    const [simpleBillings, setSimpleBillings] = useState([])
    const [shipments, setShipments] = useState([])

    const fetchOrders = async () => {
        await PromiseUtils.PromiseWrapper({
            promise: OrderAPI.searchMillOrders(),
            onRejected: err => {
                addMessage(
                    MessageType.ERROR,
                    'Ocorreu um erro ao carregar os pedidos, tente novamente.'
                )
            },
            onFulfilled: ({ data }) => {
                unifiesResponsesAndSetOrders([data])
            },
        })
    }

    const unifiesResponsesAndSetOrders = responses => {
        const orders = responses.reduce((orders, response) => {
            if (response && response.orders && Array.isArray(response.orders)) {
                return orders.concat(response.orders)
            }
            return response
        }, [])

        setOrders(orders)
    }

    const fetchOrderGroups = async () => {
        await PromiseUtils.PromiseWrapper({
            promise: OrderGroupAPI.getAllOrderGroups(),
            onRejected: err => {
                console.error(err)
                addMessage(
                    MessageType.ERROR,
                    'Ocorreu um erro ao carregar os pedidos, tente novamente.'
                )
            },
            onFulfilled: ({ data }) => {
                setOrderGroups(data)
            },
        })
    }

    const fetchFragmentedOrders = async args => {
        try {
            const response = await FragmentedOrderApi.getAllFragmentedOrders(
                args
            )

            if (response && response.data && Array.isArray(response.data)) {
                setFragmentedOrders(response.data)
            }
        } catch (err) {
            console.error(err)
            addMessage(
                MessageType.ERROR,
                'Ocorreu um erro ao carregar os fracionamentos de pedido, tente novamente.'
            )
        }
    }

    const fetchOrdersWithFragments = async () => {
        try {
            const response = await OrderAPI.getOrdersWithFragments()
            if (response && response.orders && Array.isArray(response.orders)) {
                setOrdersWithFragments(response.orders)
            }
        } catch (err) {
            console.error(err)
            addMessage(
                MessageType.ERROR,
                'Ocorreu um erro ao carregar os fracionamentos de pedido, tente novamente.'
            )
        }
    }

    const fetchSimpleBillings = async ({ view }) => {
        try {
            const response = await OrderAPI.searchSimpleBillings({
                view,
            })

            if (response && response.orders && Array.isArray(response.orders)) {
                setSimpleBillings(response.orders)
            }
        } catch (err) {
            console.error(err)
            addMessage(
                MessageType.ERROR,
                'Ocorreu um erro ao carregar os simples faturamentos, tente novamente.'
            )
        }
    }

    const fetchShipments = async args => {
        try {
            const response = await ShipmentsApi.getAllShipments(args)

            if (response && response.data && Array.isArray(response.data)) {
                setShipments(response.data)
            }
        } catch (err) {
            console.error(err)
            addMessage(
                MessageType.ERROR,
                'Ocorreu um erro ao carregar os fracionamentos de pedido, tente novamente.'
            )
        }
    }

    const fetchDashboardData = async ({ millId }) => {
        setLoading(true)
        try {
            const view = 'dashboard'
            await Promise.all([
                fetchOrders(),
                fetchOrderGroups(),
                fetchFragmentedOrders({ millId, view }),
                fetchOrdersWithFragments(),
                fetchSimpleBillings({ view }),
                fetchShipments({ millId, view }),
            ])
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    const changeOrderStatus = (orderId, targetLaneId) => {
        setOrders(orders =>
            orders.map(order => {
                if (order._id === orderId) {
                    order.status = targetLaneId
                    order.statusText = STATUS_TEXT[targetLaneId]
                }
                return order
            })
        )
    }

    const changeFragmentedOrderStatus = (fragmentId, targetLaneId) => {
        setFragmentedOrders(fragmentedOrders => {
            return fragmentedOrders.map(fragment => {
                if (fragment.id === fragmentId) {
                    fragment.status.name = targetLaneId
                }
                return fragment
            })
        })
    }

    const changeShipmentStatus = (shipmentId, targetLaneId) => {
        setShipments(shipments => {
            return shipments.map(shipment => {
                if (shipment.id === shipmentId) {
                    shipment.status.name = targetLaneId
                }
                return shipment
            })
        })
    }

    const handleSelectedOrders = (order, selected) => {
        selected
            ? setSelectedOrders(selectedOrders => selectedOrders.concat(order))
            : setSelectedOrders(selectedOrders =>
                  selectedOrders.filter(o => o._id !== order._id)
              )
    }

    const handleOrderGroupModal = useCallback((visible, selectedOrderGroup) => {
        if (!selectedOrderGroup) {
            selectedOrderGroup = EMPTY_SELECTED_ORDER_GROUP
        }
        setShowOrderGroupModal(visible)
        setSelectedOrderGroup(selectedOrderGroup)
    }, [])

    const clearSelectedOrders = () => {
        setSelectedOrders([])
    }

    function createOrderGroupCardOnPreferences() {
        const userPreferences = getUserPreferences()
        if (userPreferences && !userPreferences[keyStorage.orderGroupCard]) {
            localStorage.setItem(
                keyStorage.userPreferences,
                JSON.stringify({
                    ...userPreferences,
                    [keyStorage.orderGroupCard]: {},
                })
            )
        }
    }

    function removeOrderGroupCardOnPreferences(groupNumber) {
        const userPreferences = getUserPreferences()
        const orderGroupOnPreferences =
            userPreferences[keyStorage.orderGroupCard]
        delete orderGroupOnPreferences[groupNumber]
        localStorage.setItem(
            keyStorage.userPreferences,
            JSON.stringify({
                ...userPreferences,
                [keyStorage.orderGroupCard]: orderGroupOnPreferences,
            })
        )
    }

    return (
        <DashboardContext.Provider
            value={{
                loading,
                orders,
                ordersWithFragments,
                fragmentedOrders,
                shipments,
                simpleBillings,
                orderGroups,
                selectedOrders,
                showOrderGroupModal,
                selectedOrderCard,
                selectedOrderGroup,
                selectedFragmentedOrder,
                fetchOrders,
                fetchOrdersWithFragments,
                fetchFragmentedOrders,
                fetchOrderGroups,
                handleSelectedOrders,
                handleOrderGroupModal,
                setSelectedOrderCard,
                clearSelectedOrders,
                changeOrderStatus,
                changeFragmentedOrderStatus,
                setSelectedFragmentedOrder,
                changeShipmentStatus,
                fetchDashboardData,
                setLoading,
                createOrderGroupCardOnPreferences,
                removeOrderGroupCardOnPreferences,
            }}
        >
            {children}
        </DashboardContext.Provider>
    )
}

export default DashboardContextProvider
