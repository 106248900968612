import React from 'react'
import styled from 'styled-components'
import { MdAccountCircle } from 'react-icons/md'
import MillIcon from '../../../assets/customIcons/MillIcon'
import Colors from '../../Colors'

export const UserProfileComponent = ({ userLogin, userMill }) => {
    return (
        <Container>
            <MdAccountCircle size={60} />
            <UserLogin>{userLogin}</UserLogin>
            <UserMillContainer>
                <MillIcon />
                <UserMill>{userMill}</UserMill>
            </UserMillContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;
    border-bottom: 1px solid ${Colors.lightBlueGreyTwo};
`
const UserLogin = styled.span``
const UserMillContainer = styled.span`
    margin-top: 5px;
`
const UserMill = styled.span`
    margin-left: 5px;
`

export default UserProfileComponent
