import React, { useState } from 'react'
import styled from 'styled-components'
import { Sidebar as Menu } from 'primereact/sidebar'
import Colors from '../../Colors'
import ItemMenu from './ItemMenu'
import UserProfileComponent from './UserProfileComponent'

import { keyStorage } from '../../LocalStorage'

import { VERSION } from '../../../version'
import {
    MdHome,
    MdAttachMoney,
    MdPowerSettingsNew,
    MdAssignmentInd,
    MdAssessment,
    MdMenu,
} from 'react-icons/md'
import ProductIcon from '../../../assets/customIcons/ProductIcon'
import OrderIcon from '../../../assets/customIcons/OrderIcon'
import OrderListIcon from '../../../assets/customIcons/OrderListIcon'
import VersionIcon from '../../../assets/customIcons/VersionIcon'
import ReportsModal from '../../../reports/ReportsModal'

import { useMillContext } from '../../../context/MillContext'
import WalletIcon from '../../../assets/customIcons/WalletIcon'
import { EVENT_KEYS, analyticsEvent } from '../../AnalyticsUtils'

export const SideMenu = () => {
    const { millUser, mill } = useMillContext()
    const [showReportsModal, setShowReportsModal] = useState(false)
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(false)

    const handleMenuVisibility = () => {
        setIsSideMenuVisible(isSideMenuVisible => !isSideMenuVisible)
    }

    const onLogout = () => {
        analyticsEvent(EVENT_KEYS.logout)
        localStorage.removeItem('userToken')
    }

    const isStcUser = millUser =>
        millUser.aclUserGroups.includes(keyStorage.stcUser)

    if (millUser && isStcUser(millUser)) {
        return (
            <>
                <MenuButtonContainer>
                    <MenuButton
                        onClick={handleMenuVisibility}
                        isVisible={isSideMenuVisible}
                    >
                        <MdMenu />
                    </MenuButton>
                </MenuButtonContainer>
                <SideBar
                    dismissable={false}
                    showCloseIcon={false}
                    modal={false}
                    closeOnEscape={false}
                    position="right"
                    visible={isSideMenuVisible}
                    onHide={handleMenuVisibility}
                >
                    <SideBarContent>
                        <TopContainer>
                            <UserProfileComponent
                                userLogin={millUser?.login}
                                userMill={mill?.nome}
                            />
                            <ItemsContainer>
                                <ItemMenu
                                    label="Início"
                                    icon={<MdHome size={25} />}
                                    linkTo={'/home/gestao-pedidos'}
                                />

                                <ItemMenu
                                    label="Listagem de Pedidos"
                                    icon={<OrderListIcon />}
                                    linkTo={'/pedidos/historico'}
                                />

                                <ItemMenu
                                    label="Alteração de Preços"
                                    icon={<MdAttachMoney size={25} />}
                                    linkTo={'/precos/alteracao'}
                                />

                                <ItemMenu
                                    label="Cadastro de Carteiras"
                                    icon={<WalletIcon size={25} />}
                                    linkTo={'/carteiras'}
                                />

                                <ItemMenu
                                    label="Cadastro de Clientes"
                                    icon={<MdAssignmentInd size={25} />}
                                    linkTo={'/clientes'}
                                />

                                <ItemMenu
                                    label="Pedidos"
                                    icon={<OrderIcon />}
                                    linkTo={'/pedidos'}
                                />

                                <ItemMenu
                                    label="Produtos"
                                    icon={<ProductIcon />}
                                    linkTo={'/produtos'}
                                />

                                <ItemMenu
                                    label="Relatórios"
                                    icon={<MdAssessment size={25} />}
                                    onClick={() => {
                                        setShowReportsModal(true)
                                        setIsSideMenuVisible(false)
                                    }}
                                />
                            </ItemsContainer>
                        </TopContainer>

                        <BottomContainer>
                            <ItemMenu
                                label={`Versão ${VERSION}`}
                                icon={<VersionIcon />}
                                isClickable={false}
                            />
                            <ItemMenu
                                label={'Sair'}
                                icon={<MdPowerSettingsNew size={25} />}
                                linkTo={'/login'}
                                onClick={() => onLogout()}
                            />
                        </BottomContainer>
                    </SideBarContent>
                </SideBar>
                {showReportsModal && (
                    <ReportsModal
                        onCloseReportsModal={() => setShowReportsModal(false)}
                    />
                )}
            </>
        )
    }

    return (
        <>
            <MenuButtonContainer>
                <MenuButton
                    onClick={handleMenuVisibility}
                    isVisible={isSideMenuVisible}
                >
                    <MdMenu />
                </MenuButton>
            </MenuButtonContainer>
            <SideBar
                dismissable={false}
                showCloseIcon={false}
                modal={false}
                closeOnEscape={false}
                position="right"
                visible={isSideMenuVisible}
                onHide={handleMenuVisibility}
            >
                <SideBarContent>
                    <TopContainer>
                        <UserProfileComponent
                            userLogin={millUser?.login}
                            userMill={mill?.nome}
                        />
                        <ItemsContainer>
                            <ItemMenu
                                label="Início"
                                icon={<MdHome size={25} />}
                                linkTo={'/'}
                            />
                        </ItemsContainer>
                    </TopContainer>

                    <BottomContainer>
                        <ItemMenu
                            label={`Versão ${VERSION}`}
                            icon={<VersionIcon />}
                            isClickable={false}
                        />
                        <ItemMenu
                            label={'Sair'}
                            icon={<MdPowerSettingsNew size={25} />}
                            linkTo={'/login'}
                            onClick={() => onLogout()}
                        />
                    </BottomContainer>
                </SideBarContent>
            </SideBar>
            {showReportsModal && (
                <ReportsModal
                    onCloseReportsModal={() => setShowReportsModal(false)}
                />
            )}
        </>
    )
}

const MenuButtonContainer = styled.div`
    &:hover {
        background-color: ${Colors.veryLightPink};
    }
    border-radius: 50%;
    height: 40px;
`

const MenuButton = styled.button`
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    color: ${props =>
        props.isVisible ? `${Colors.orange}` : `${Colors.black}`};
    border: none;
    display: flex;
    align-content: center;
    font-size: 30px;
    margin-top: 4px;
`

const SideBar = styled(Menu)`
    margin-top: 65px;
    height: calc(100vh - 60px);
    padding: 0 !important;
`

const SideBarContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

const TopContainer = styled.div``

const ItemsContainer = styled.div``

const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${Colors.lightBlueGreyTwo};
`

export default SideMenu
