import React from 'react'

const AddClientIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="124"
            height="90"
            viewBox="0 0 124 90"
            fill="none"
        >
            <path
                d="M44.5218 78.6039L44.3244 79.25H45H112.5H113.16L112.981 78.6144C112.804 77.9862 112.332 77.3603 111.693 76.7566C111.045 76.1442 110.182 75.5127 109.149 74.8808C107.081 73.6163 104.289 72.3214 101.068 71.1571C94.629 68.829 86.4146 67 78.75 67C71.1138 67 62.9276 68.8149 56.4951 71.1359C53.2782 72.2967 50.484 73.5898 48.4073 74.8568C47.3695 75.4899 46.4993 76.1235 45.8428 76.7391C45.1951 77.3466 44.7141 77.9745 44.5218 78.6039ZM28.125 44.5H27.625V45V61.375H17.375V45V44.5H16.875H0.5V34.25H16.875H17.375V33.75V17.375H27.625V33.75V34.25H28.125H44.5V44.5H28.125ZM100.75 22.5C100.75 34.6551 90.9051 44.5 78.75 44.5C66.5949 44.5 56.75 34.6551 56.75 22.5C56.75 10.3449 66.5949 0.5 78.75 0.5C90.9051 0.5 100.75 10.3449 100.75 22.5ZM90.5 22.5C90.5 16.0364 85.2136 10.75 78.75 10.75C72.2864 10.75 67 16.0364 67 22.5C67 28.9636 72.2864 34.25 78.75 34.25C85.2136 34.25 90.5 28.9636 90.5 22.5ZM34.25 78.75C34.25 75.193 36.0279 72.0403 39.0084 69.2895C41.9922 66.5355 46.1481 64.2183 50.8039 62.3532C60.1163 58.6226 71.3031 56.75 78.75 56.75C86.1969 56.75 97.3837 58.6226 106.696 62.3532C111.352 64.2183 115.508 66.5355 118.492 69.2895C121.472 72.0403 123.25 75.193 123.25 78.75V89.5H34.25V78.75Z"
                fill="currentColor"
                stroke="white"
            />
        </svg>
    )
}

export default AddClientIcon
