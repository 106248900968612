import React from 'react'
import styled from 'styled-components'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import ScreenHeader from './screenHeader/ScreenHeader'
import Colors from '../Colors'

/**
 * @param {string} title - titulo da pagina
 * @param {string} editionTitle - titulo da pagina de edição
 * @param {boolean} isEdition - seta que é um formulario de edição
 * @param {boolean} isBackButton - seta que contém um botão de retorno
 * @param {Fucntion} onBack - função que o botão de retorno irá executar
 * @param {React.FunctionComponent} children - componente a ser renderizado
 */

export const BasicPage = ({
    title,
    editionTitle,
    isEdition,
    isBackButton = false,
    onBack,
    children,
}) => {
    return (
        <Container>
            <ScreenHeader />
            <ContainerScreen>
                <TitleContainer>
                    {isBackButton && (
                        <BackButton onClick={() => onBack()}>
                            <AiOutlineArrowLeft />
                        </BackButton>
                    )}
                    <Title>{isEdition ? editionTitle : title}</Title>
                </TitleContainer>
                {children}
            </ContainerScreen>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
`

const ContainerScreen = styled.div`
    display: block;
    padding: 32px;
    padding-bottom: 22px;
`

const TitleContainer = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
`

const BackButton = styled.button`
    background-color: transparent;
    border: none;
    margin: 0;
    margin-right: 20px;
    padding: 0;
    font-size: 21px;
    color: ${Colors.brownishGrey};
    display: flex;
    align-content: center;
    cursor: pointer;
`

const Title = styled.span`
    margin: 0;
    color: ${Colors.orange};
    font-family: Roboto;
    font-size: 22px;
`

export default BasicPage
