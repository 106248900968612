import { getBaseURL, getToken } from '../environment'

const gcpBaseURL = `${getBaseURL()}/clients`

export const searchPortfoliosByFilter = async (size, page, filter, query) => {
    try {
        const queryParams = new URLSearchParams({
            size,
            page,
        })
        if (filter) queryParams.append('filter', filter)

        if (query) {
            Object.keys(query).forEach(key => {
                queryParams.append(key, query[key])
            })
        }

        const response = await fetch(
            `${gcpBaseURL}/portfolios/?${queryParams}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getToken()}`,
                },
            }
        )
        return await response.json()
    } catch (err) {
        console.error('Erro: ' + JSON.stringify(err))
        throw err
    }
}

export const deletePortfolio = async id => {
    try {
        const response = await fetch(`${gcpBaseURL}/portfolios/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
        })

        if (response.status > 299 || response.status < 200)
            throw new Error(await response.text())

        return await Promise.resolve()
    } catch (err) {
        console.error('Erro: ' + JSON.stringify(err))
        throw err
    }
}

export const createPortfolio = async portfolio => {
    try {
        const response = await fetch(`${gcpBaseURL}/portfolios`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(portfolio),
        })

        return await response.json()
    } catch (err) {
        console.error('Erro: ' + JSON.stringify(err))
        throw err
    }
}

export const findDuplicatedRelation = async portfolio => {
    try {
        const response = await fetch(
            `${gcpBaseURL}/portfolios/findDuplicateRelation`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(portfolio),
            }
        )
        if (response.ok) return { data: {} }

        return await response.json()
    } catch (err) {
        console.error('Erro: ' + JSON.stringify(err))
        throw err
    }
}

export const updatePortfolio = async portfolios => {
    try {
        const response = await fetch(`${gcpBaseURL}/portfolios/bulk`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(portfolios),
        })

        return await response.json()
    } catch (err) {
        console.error('Erro: ' + JSON.stringify(err))
        throw err
    }
}
