import React from 'react'
import styled from 'styled-components'
import { IoMdClose } from 'react-icons/io'
import Colors from '../commons/Colors'

import * as DateUtils from '../commons/DateUtils'

import RoundTagComponent from '../commons/components/RoundTagComponent'

const FeatureCardComponent = ({
    title,
    description,
    source,
    fileURL,
    releaseDate,
    version,
    featureId,
    handleClick,
    isNewFeature,
}) => {
    const isRemoved = true

    const getTagColor = source => {
        if (source) {
            if (source === 'Portal') {
                return Colors.lightRed
            }
            return Colors.lightishBlue
        }

        return Colors.grass
    }

    const handleDocumentUrl = () => {
        const test = new RegExp(/https:\/\//)

        if (!test.test(fileURL)) return `https://${fileURL}`
        return fileURL
    }

    return (
        <CardContainer>
            <CardHeader>
                <CloseButtonContainer>
                    <CloseButton
                        name="remove-notification"
                        onClick={() => handleClick(featureId, isRemoved)}
                    >
                        <CloseIcon />
                    </CloseButton>
                </CloseButtonContainer>
                <TitleContainer>
                    <TitleText>{title}</TitleText>
                </TitleContainer>
                <TagsContainer>
                    {isNewFeature(featureId) && (
                        <NewTagFeatureTag>
                            <RoundTagComponent
                                text={'Novo'}
                                color={getTagColor()}
                            />
                        </NewTagFeatureTag>
                    )}

                    <RoundTagComponent
                        text={source}
                        color={getTagColor(source)}
                    />
                </TagsContainer>
            </CardHeader>
            <CardBody>
                <DescriptionContainer>
                    <DescriptionText>{description}</DescriptionText>
                </DescriptionContainer>
                {fileURL && (
                    <FileURLContainer>
                        <FileURLText
                            href={handleDocumentUrl()}
                            target="_blank"
                            onClick={() => handleClick(featureId, !isRemoved)}
                        >
                            Visualizar PDF
                        </FileURLText>
                    </FileURLContainer>
                )}
            </CardBody>
            <CardFooter>
                <RelaseInformationContainer>
                    <ReleaseInformationText>
                        {`Disponível na versão ${version} dia ${DateUtils.dateFormat(
                            releaseDate
                        )}`}
                    </ReleaseInformationText>
                </RelaseInformationContainer>
            </CardFooter>
            <Divider />
        </CardContainer>
    )
}

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const CardHeader = styled.div`
    display: flex;
    padding: 10px 0 0 20px;
`
const CloseButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 2px;
`
const CloseButton = styled.button`
    background-color: ${Colors.lightBlueGreyThree};
    border-radius: 50%;
    text-align: center;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    height: 15px;
    width: 15px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
`

const CloseIcon = styled(IoMdClose)`
    text-align: center;
    color: white;
    background-color: transparent;
    height: 13px;
    width: 13px;
`

const TitleContainer = styled.div`
    padding: 0 10px 0 10px;
    max-width: 60%;
`
const TitleText = styled.h1`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    color: #666;
`

const TagsContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 2px;
`
const NewTagFeatureTag = styled.div`
    padding-right: 6px;
`

const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 8px 0 35px;
`
const DescriptionContainer = styled.div`
    padding-bottom: 5px;
`
const DescriptionText = styled.span`
    font-family: Roboto;
    font-size: 12px;
    color: ${Colors.brownishGrey};
`

const FileURLContainer = styled.div``
const FileURLText = styled.a`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    color: ${Colors.orange};
    text-decoration: none;
    :hover {
        color: ${Colors.orange};
    }
`
const CardFooter = styled.div`
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
`
const RelaseInformationContainer = styled.div`
    padding-bottom: 8px;
    padding-right: 25px;
`
const ReleaseInformationText = styled.span`
    font-family: Roboto;
    font-size: 9px;
    color: ${Colors.lightBlueGreyTwo};
`

const Divider = styled.div`
    height: 1px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(199, 199, 204, 0.5);
`

export default FeatureCardComponent
