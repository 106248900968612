import * as yup from 'yup'

// TODO - Avaliar possibilidade de tirar o YUP e implementar validações próprias
export function createFieldValidator() {
    return {
        getAvailableValidators: () => ({
            STRING: 0,
            POSITIVE_NUMBER: 1,
            ARRAY: 2,
            DATE: 3,
            EXISTS: '',
        }),
        isInvalidFields: schema => schema.some(field => !field.isValid),
        getInvalidFields: schema => schema.filter(field => !field.isValid),
        getValue: (object, keys) => {
            return keys.reduce((value, key) => {
                return (object = value[key])
            }, object)
        },
        getField: keys => keys[0],
        stringValidator: value => yup.string().required().isValidSync(value),
        positiveNumberValidator: value =>
            yup.number().positive().required().isValidSync(value),
        arrayValidator: value => yup.array().required().isValidSync(value),
        dateValidator: value => yup.date().required().isValidSync(value),
        valueExistsValidator: value => Boolean(value),
        createFieldValidation: function (object, keys, type) {
            switch (type) {
                case 0:
                    return {
                        field: this.getField(keys),
                        isValid: this.stringValidator(
                            this.getValue(object, keys)
                        ),
                    }
                case 1:
                    return {
                        field: this.getField(keys),
                        isValid: this.positiveNumberValidator(
                            this.getValue(object, keys)
                        ),
                    }
                case 2:
                    return {
                        field: this.getField(keys),
                        isValid: this.arrayValidator(
                            this.getValue(object, keys)
                        ),
                    }
                case 3:
                    return {
                        field: this.getField(keys),
                        isValid: this.dateValidator(
                            this.getValue(object, keys)
                        ),
                    }
                default:
                    return {
                        field: this.getField(keys),
                        isValid: this.valueExistsValidator(
                            this.getValue(object, keys)
                        ),
                    }
            }
        },
        composeValidationSchema: function (requiredFields, object) {
            return requiredFields.map(field =>
                this.createFieldValidation(object, field.keys, field?.type)
            )
        },
    }
}
