import React from 'react'

import styled from 'styled-components'
import { MdErrorOutline } from 'react-icons/md'

import IndicatorsIcon from '../assets/customIcons/IndicatorsIcon'
import DollarCoinIcon from '../assets/customIcons/DollarCoinIcon'
import Colors from '../commons/Colors'
import Tooltip from '../commons/components/Tooltip'

export default function OrderCardIndicatorsComponent({
    isDebtorClient,
    isOutOfPricePolicy,
}) {
    const isIndicatorVisible = () => isDebtorClient || isOutOfPricePolicy
    const indicatorsQuantity =
        Boolean(isDebtorClient) + Boolean(isOutOfPricePolicy)

    const tooltipBody = (
        <TooltipContainer>
            {isDebtorClient && (
                <Indicator>
                    <DebtorIcon /> Cliente Inadimplente
                </Indicator>
            )}
            {isOutOfPricePolicy && (
                <Indicator>
                    <DollarCoinIconContainer>
                        <DollarCoinIcon />
                    </DollarCoinIconContainer>
                    Produto Fora da Política
                </Indicator>
            )}
        </TooltipContainer>
    )

    const indicatorsQuantityPin = () => (
        <PinContainer>
            <PinText>{indicatorsQuantity}</PinText>
        </PinContainer>
    )

    return (
        <>
            {isIndicatorVisible() ? (
                <Container data-testid="order-indicator">
                    <Tooltip label={tooltipBody}>
                        <IndicatorsIcon />
                        {indicatorsQuantityPin()}
                    </Tooltip>
                </Container>
            ) : (
                <></>
            )}
        </>
    )
}

const Container = styled.div`
    padding-top: 5px;
`

const DebtorIcon = styled(MdErrorOutline)`
    font-size: 24px;
    color: ${Colors.white};
    padding-right: 3px;
`

const DollarCoinIconContainer = styled.div`
    padding-right: 3px;
    width: 24px;
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
`

const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const Indicator = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const PinContainer = styled.div`
    width: 11px;
    height: 11px;
    border-radius: 5px;
    background-color: ${Colors.brownishGrey};

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;

    top: -5px;
    left: 13px;
`

const PinText = styled.p`
    color: ${Colors.white};
    font-size: 8px;
    margin: 0px;
`
