import React from 'react'
import styled from 'styled-components'

import Colors from '../../../commons/Colors'

export function Card({ index, style, association, items, name, onClick }) {
    const clientData = Object.entries(items)[index]
    if (!clientData) return null

    const [id, client] = clientData
    return (
        <ClientCard
            value={id}
            name={name}
            style={{
                ...style,
                backgroundColor: client.isSelected && 'rgba(217,217,217, 0.3)',
                width: '100%',
            }}
            onClick={() =>
                onClick({
                    clientId: id,
                    association: association,
                    card: name,
                })
            }
        >
            <ClientCardText>
                {client.tradeName}
                {client.companyName ? ` - ${client.companyName}` : ''}
            </ClientCardText>
        </ClientCard>
    )
}

const ClientCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.43;
    height: 40px;
    width: 100%;
    background-color: ${Colors.white};
`

const ClientCardText = styled.span`
    margin-left: 0.7vw;
    align-items: center;
    font-family: Roboto;
    letter-spacing: 0.25px;
    color: #666666;
    font-size: 14px;
`
