import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import * as PromiseUtils from '../commons/PromiseUtils'
import Colors from '../commons/Colors'
import RoundButton from '../commons/components/RoundButton'
import * as ClientAPI from '../client/ClientAPI'
import Input from '../commons/components/Input'
import DebtorClientIndicator from '../commons/components/DebtorClientIndicator'
import {
    sortFarmsByName,
    getTributes,
    convertGcpClientToSydle,
    mountFarmDropdownOptions,
    mountFarmDropdownOption,
} from './OrderFormScreenUtils'
import { Label } from '../commons/commonStyles'
import { MessageType, addMessage } from '../commons/MessageUtils'
import AutoCompleteAsync from '../commons/components/AutoCompleteAsync'

const ClientInformation = ({
    setClientInformation,
    farm,
    isConfirmedOrder,
    invalidFieldsHandler,
}) => {
    const { invalidFields, getInvalidField, setValidField } =
        invalidFieldsHandler

    const fetchFarms = async term => {
        return PromiseUtils.PromiseWrapper({
            promise: ClientAPI.getAllClients(10, 0, term),
            onFulfilled: ({ data }) => {
                const clientSydle = convertGcpClientToSydle(data)
                const sortedFarms = sortFarmsByName(clientSydle)
                return mountFarmDropdownOptions(sortedFarms)
            },
            onRejected: () => {
                addMessage(
                    MessageType.ERROR,
                    `Erro ao tentar carregar os clientes.`
                )
            },
        })
    }

    const resetSeller = () => {
        setClientInformation('seller', {})
    }

    const onSelectClient = selectedOption => {
        const { value } = selectedOption

        resetSeller()
        setValidField('farm', invalidFields)
        setClientInformation('farm', value)
        setClientInformation('tributes', getTributes(value))
    }

    const getAutoCompleteValue = () => {
        return Object.keys(farm).length && mountFarmDropdownOption(farm)
    }

    return (
        <>
            <Panel>
                <LabelWithAutoComplete data-testid="client-input-container">
                    <Label htmlFor="clientInput">* BUSCAR FAZENDA</Label>
                    <AutoCompleteAsync
                        hideSelectedOptions
                        placeholder="Pesquise a fazenda para fazer o pedido"
                        fetchSuggestions={fetchFarms}
                        onChange={onSelectClient}
                        value={getAutoCompleteValue()}
                        disabled={isConfirmedOrder}
                        isInvalid={getInvalidField('farm', invalidFields)}
                        getCustomStyle={{
                            container: styles => ({
                                ...styles,
                                width: '400px',
                            }),
                        }}
                    />
                </LabelWithAutoComplete>

                <NewClientMessage>
                    * O Cliente é NOVO e a fazenda não está cadastrada?
                    Cadastre-o para realizar pedidos.
                </NewClientMessage>
                <Link to="/clientes/novo">
                    <NewClientButton>
                        Ir para o Cadastro de Clientes
                    </NewClientButton>
                </Link>
            </Panel>

            <ClientContainer>
                <ClientInfoContainerTop>
                    <LabelWithAnswer>
                        <Label htmlFor="legacyCode">CÓDIGO DO CLIENTE</Label>
                        <ClientData
                            id="legacyCode"
                            value={farm.legacyCode}
                            readOnly={true}
                        />
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label htmlFor="clientName">
                            NOME DO CLIENTE / PROPRIETÁRIO
                        </Label>
                        <ClientData
                            id="clientName"
                            value={farm.owner?.name}
                            readOnly={true}
                        />
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label htmlFor="fancyName">NOME FANTASIA</Label>
                        <ClientData
                            id="fancyName"
                            value={farm.fancyName}
                            readOnly={true}
                        />
                    </LabelWithAnswer>
                </ClientInfoContainerTop>

                <ClientInfoContainerBottom>
                    <LabelWithAnswer>
                        <Label htmlFor="corporateName">RAZÃO SOCIAL</Label>
                        <ClientData
                            id="corporateName"
                            value={farm.corporateName}
                            readOnly={true}
                        />
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label htmlFor="address">ENDEREÇO / RODOVIA</Label>
                        <ClientData
                            id="address"
                            value={farm.address}
                            readOnly={true}
                        />
                    </LabelWithAnswer>

                    <LabelWithAnswer>
                        <Label htmlFor="addressReference">
                            OBSERVAÇÃO DE ENDEREÇO
                        </Label>
                        <ClientData
                            id="addressReference"
                            value={farm.addressReference}
                            readOnly={true}
                        />
                    </LabelWithAnswer>
                </ClientInfoContainerBottom>

                <DebtorClientIndicator debtor={farm.debtor} />
            </ClientContainer>
        </>
    )
}

const Panel = styled.div`
    display: flex;
    width: 100%;
    border-radius: 3px;
    border: solid 1px #eceeee;
    background-color: ${Colors.white};
    padding: 21px;
    align-items: center;
`

const NewClientMessage = styled.span`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: normal;
    color: ${Colors.brownishGrey};
    margin-right: 20px;
    margin-left: 15px;
    margin-top: 30px;
`

const LabelWithAutoComplete = styled.div`
    display: flex;
    flex-direction: column;

    .p-inputtext {
        width: 409px;
        height: 40px;
        border-radius: 4px;
        background-color: ${Colors.white};
        border-color: ${Colors.lightBlueGreyTwo};
    }
`

const LabelWithAnswer = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
`

const NewClientButton = styled(RoundButton)`
    background-color: ${Colors.peacockBlue};
    border-color: ${Colors.peacockBlue};
    margin-top: 28px;
    width: 232px;
    height: 32px;
`

const ClientContainer = styled.div`
    border-radius: 3px;
    border: solid 1px #eceeee;
    box-sizing: border-box;
    display: grid;
    background-color: ${Colors.white};
    padding: 21px;
`

const ClientInfoContainerTop = styled.div`
    display: grid;
    grid-template-columns: 12% 42% 42%;
    grid-gap: 2%;
    justify-content: stretch;
    margin-bottom: 16px;
`

const ClientInfoContainerBottom = styled.div`
    display: grid;
    grid-template-columns: 35% 35% 26%;
    grid-gap: 2%;
    justify-content: stretch;
    margin-bottom: 16px;
`

const ClientData = styled(Input)`
    border-radius: 4px;
    border-color: ${Colors.lightBlueGreyTwo};
    color: ${Colors.black};
`

export default ClientInformation
