import React from 'react'

const NewOrderIcon = () => {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.967 2.521h-3.513a2.53 2.53 0 0 0-2.37-1.68 2.53 2.53 0 0 0-2.37 1.68H4.202c-.925 0-1.681.756-1.681 1.68v11.766c0 .924.756 1.68 1.68 1.68h11.766c.924 0 1.68-.756 1.68-1.68V4.202c0-.925-.756-1.68-1.68-1.68zm-5.883 0c.462 0 .84.378.84.84 0 .463-.378.84-.84.84a.843.843 0 0 1-.84-.84c0-.462.378-.84.84-.84zM5.882 14.286h5.883v-1.68H5.882v1.68zm8.404-3.361H5.882V9.244h8.404v1.68zM5.882 7.563h8.404v-1.68H5.882v1.68z"
                fill="currentColor"
            />
        </svg>
    )
}

export default NewOrderIcon
