import React from 'react'
import styled from 'styled-components'
import Loading from './Loading'

/**
 *
 * @param {string} className - className do componente
 * @param {React.FunctionComponent} children - componente a ser renderizado
 * @param {boolean} disabled - se o componente está desabilitado, default false
 * @param {event} onClick - ação a ser feita ao clicar
 * @param {string} type - tipo do elemento
 * @param {string} dataTestId - id de teste do componente
 * @param {boolean} loading - indica se deve apresentar loading no botão
 */

export const RoundButton = ({
    className,
    children,
    disabled = false,
    onClick,
    type,
    dataTestId = 'round-button',
    loading = false,
}) => {
    return loading ? (
        <LoadingContainer className={className}>
            <Loading />
        </LoadingContainer>
    ) : (
        <Button
            data-testid={dataTestId}
            type={type}
            className={className}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </Button>
    )
}

const LoadingContainer = styled.div`
    height: 32px;
    padding: 0.7vh 0.9vw;
    border-radius: 25px;
    border-style: solid;
    color: transparent;
    display: flex;
    justify-content: center;
`

const Button = styled.button`
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    height: 32px;
    padding: 0.7vh 0.9vw;
    border-radius: 25px;
    background-color: #4da32f;
    border-color: #4da32f;
    border-style: solid;
    color: #ffffff;

    transition: all 0.5s;

    :focus {
        outline-style: none;
    }

    :active {
        opacity: 0.8;
    }

    :disabled {
        background-color: #c7c7cc;
        border-color: #c7c7cc;
        color: #666666;
        opacity: 1;
    }

    :hover {
        box-shadow: #e4e9ec 0px 0px 0px 0.25em;
    }
`

export default RoundButton
