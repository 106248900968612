import React from 'react'

const HeadsetIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="headset-icon"
        >
            <path
                d="M10 .83a7.504 7.504 0 0 0-7.503 7.502v5.836a2.498 2.498 0 0 0 2.501 2.501h2.501v-6.67H4.164V8.333A5.831 5.831 0 0 1 10 2.496a5.831 5.831 0 0 1 5.836 5.836V10H12.5v6.669h3.335v.834H10v1.667h5.002a2.498 2.498 0 0 0 2.501-2.501V8.332A7.504 7.504 0 0 0 10 .83z"
                fill="#F08B1F"
            />
        </svg>
    )
}

export default HeadsetIcon
