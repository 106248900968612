import { STATUS_TEXT } from '../commons/Status'
import { getBaseURL, getToken } from '../environment'

const baseURL = `${getBaseURL()}/orders`
const dashboard = '/boards'
const endpoint = '/orderGroups'

export const createOrderGroup = orderGroup => {
    const DTO = DTOhelpers.upload(orderGroup)

    return fetch(`${baseURL}${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(DTO),
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getAllOrderGroups = () =>
    fetch(`${baseURL}${dashboard}${endpoint}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response =>
            response.json().then(({ data }) => ({
                data: data.map(orderGroup => DTOhelpers.getAll(orderGroup)),
            }))
        )
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })

export const editOrderGroup = orderGroup => {
    const DTO = DTOhelpers.upload(orderGroup)

    return fetch(`${baseURL}${endpoint}/${DTO.id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(DTO),
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getOrderGroup = id =>
    fetch(`${baseURL}${endpoint}/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response =>
            response.json().then(({ data }) => ({
                data: DTOhelpers.getById(data),
            }))
        )
        .catch(err => {
            console.error('Erro: ', err)
            throw err
        })

export const deleteOrderGroup = id =>
    fetch(`${baseURL}${endpoint}/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => (response.ok ? { data: null } : response.json()))
        .catch(err => {
            console.error('Erro: ', err)
            throw err
        })

export const DTOhelpers = {
    upload: sydleOrderGroup => ({
        id: sydleOrderGroup._id,
        hash: sydleOrderGroup.hash,
        note: sydleOrderGroup.note,
        requestDate: sydleOrderGroup.requestDate || new Date(),
        edited: Boolean(sydleOrderGroup.edited),
        millId: sydleOrderGroup.millId,
        millSydleId: sydleOrderGroup.millSydleId,
        orders: sydleOrderGroup.orders.map(order => ({ id: order.gcpId })),
        seller: {
            sellerId: sydleOrderGroup.seller._id,
            sellerSydleId: sydleOrderGroup.seller?.sellerSydleId,
            sellerName: sydleOrderGroup.seller.name,
            sellerLogin: sydleOrderGroup.seller.login,
        },
        sourceName: sydleOrderGroup.source,
    }),
    getAll: gcpOrderGroup => ({
        _id: gcpOrderGroup.id,
        orderGroupNumber: gcpOrderGroup.number,
        orders: gcpOrderGroup.orders.map(({ id }) => ({ _id: id })),
    }),
    getById: gcpOrderGroup => ({
        _id: gcpOrderGroup.id,
        hash: gcpOrderGroup.hash,
        note: gcpOrderGroup.note,
        requestDate: gcpOrderGroup.requestDate,
        seller: {
            _id: gcpOrderGroup.seller.sellerId,
            sydleId: gcpOrderGroup.seller?.sellerSydleId,
        },
        edited: gcpOrderGroup.edited,
        orderGroupNumber: gcpOrderGroup.number,
        source: gcpOrderGroup.source?.name,
        orders: gcpOrderGroup.orders.map(order => ({
            _id: order.id,
            gcpId: order.id,
            note: order.note,
            needsChapa: order.needsChapa,
            isEdited: order.isEdited,
            weightInTonnes: order.weightInTonnes,
            isUseFragmentOrder: order.isUseFragmentOrder,
            requestDate: order.requestDate,
            deliveryDate: order.deliveryDate,
            legacyCode: order.legacyCode,
            reasonCancellation: order.cancellationReason,
            reasonEdition: order.editionReason,
            needsPallet: order.needsPallet,
            isUseShipment: order.isUseShipment,
            isOutOfPricePolicy: order.isOutOfPricePolicy,
            orderNumber: order.number.value,
            status: order.status.name,
            statusText: STATUS_TEXT[order.status.name],
            paymentForm: order.payment.paymentFormName,
            paymentDeadline: order.payment.paymentDeadlineName,
            paymentDate: order.payment.paymentDate,
            price: parseFloat(order.priceInfo.price),
            freightPrice: order.priceInfo.freightPrice,
            subtotal: order.priceInfo.subtotal,
            chargesPrice: order.priceInfo.chargesPrice,
            freightType: {
                name: order.freightType?.name,
            },
            farm: {
                _id: order.client.clientId,
                legacyCode: order.client.clientLegacyCode,
                fancyName: order.client.clientTradeName,
                corporateName: order.client.clientCompanyName,
                note: order.client.clientNote,
                address: order.client.clientAddress,
                owner: {
                    name: order.client.clientOwnerName,
                },
            },
            category: {
                name: order.clientPortfolio.clientPortfolioName,
            },
            seller: {
                _id: order.seller.sellerId,
                name: order.seller.sellerName,
            },
            items: order.orderItems.map(item => ({
                unitPrice: item.unitNegociatedPrice,
                product: {
                    gcpId: item.productId,
                    price: item.unitPrice,
                    name: item.name,
                    _id: item.productSydleId,
                    legacyCode: item.legacyCode,
                    measurementUnit: {
                        quantity: item.weight,
                        measure: item.measure,
                        weight: item.unit,
                    },
                    isOutOfPricePolicy: item.isOutOfPricePolicy,
                },
                quantity: item.quantity,
                price: item.priceInfo.price,
                subtotal: item.priceInfo.subtotal,
                chargesPrice: item.priceInfo.chargesPrice,
                freightPrice: item.priceInfo.freightPrice,
                unQuantity: `${item.quantity.toString().replace('.', ',')} ${
                    item.measure
                }(${item.weight}${item.unit})`,
                _id: item.id,
            })),
        })),
    }),
}
