import React from 'react'
import styled from 'styled-components'
import Colors from '../Colors'
import { STATUS_TEXT } from '../Status'

export const StatusChipComponent = ({ status, left }) => {
    let chipColor

    switch (status) {
        case STATUS_TEXT.EM_PRODUCAO:
            chipColor = Colors.orangeTwo
            break
        case STATUS_TEXT.EM_ESTOQUE:
            chipColor = Colors.macaroniAndCheese
            break
        case STATUS_TEXT.EM_EXPEDICAO_TRANSITO:
            chipColor = Colors.deepSeaBlue
            break
        case STATUS_TEXT.ENTREGUE:
            chipColor = Colors.bluishGreen
            break
        default:
            chipColor = Colors.lightRed
    }

    return (
        <StatusChip backgroundColor={chipColor} left={left}>
            <StatusChipText>{status}</StatusChipText>
        </StatusChip>
    )
}

const StatusChip = styled.div`
    width: max-content;
    height: 20px;
    padding: 0 18px 0 18px;
    border-radius: 4px;
    background-color: ${props => props.backgroundColor};
    display: flex;
    align-items: center;
    position: relative;
    left: ${props => props.left || '0px'};
`

const StatusChipText = styled.p`
    margin: 0;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: ${Colors.white};
`

export default StatusChipComponent
