import React, { useEffect, useState, useCallback } from 'react'

import * as MillConfigAPI from '../dashboard/MillConfigAPI'
import * as UserAPI from '../login/UserAPI'

import * as Environment from '../environment'

export const MillContext = React.createContext({
    millUser: {},
    millConfig: {},
    mill: {},
    loadMill: () => {},
    isUseTributes: () => false,
})

export const useMillContext = () => React.useContext(MillContext)

export const MillContextProvider = ({ children }) => {
    const [millUser, setMillUser] = useState()
    const [mill, setMill] = useState()
    const [millConfig, setMillConfig] = useState()

    const loadMill = useCallback(async userId => {
        await loadMillUserInfo(userId)
        await loadMillConfig()
    }, [])

    useEffect(() => {
        const userToken = Environment.getToken({ withPayload: true })
        if (!millUser && userToken) {
            loadMill(userToken.accessToken.payload.id)
        }
    }, [millUser, loadMill])

    const loadMillConfig = async () => {
        const response = await MillConfigAPI.getMillConfig()

        if (response && response.data) {
            setMillConfig(response.data)
            return
        }

        return
    }

    const loadMillUserInfo = async userId => {
        const response = await UserAPI.getUser(userId)

        if (response && response.user) {
            setMillUser({
                user: response.user,
                aclUserGroups: response.aclUserGroups,
            })
            setMill(response.user.mill)
        }

        return
    }

    const isUseTributes = () => {
        return Boolean(millConfig?.useTributes)
    }

    return (
        <MillContext.Provider
            value={{
                mill,
                millConfig,
                millUser,
                loadMill,
                isUseTributes,
            }}
        >
            {children}
        </MillContext.Provider>
    )
}
