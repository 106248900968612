import React from 'react'

const NotificationBellIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.003 10.5v5.502l2 2v1H2.998v-1l2-2v-5.503A6.5 6.5 0 0 1 10 4.177v-.68c0-.83.67-1.501 1.501-1.501.83 0 1.5.67 1.5 1.5v.68a6.5 6.5 0 0 1 5.003 6.323zM13.5 20.002c0 1.1-.9 2.001-2 2.001-1.101 0-2.002-.9-2.002-2h4.002z"
                fill="currentColor"
            />
        </svg>
    )
}

export default NotificationBellIcon
