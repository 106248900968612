import React from 'react'
import styled from 'styled-components'

import Colors from '../../commons/Colors'
import Loading from '../../commons/components/Loading'
import { Label } from '../../commons/commonStyles'
import RadioButton from '../../commons/components/RadioButton'
import DatePickerCalendar from '../../commons/components/DatePickerCalendar'
import FieldErrorMessageComponent from '../../commons/components/error/FieldErrorMessageComponent'
import { isCustomDeadline } from '../OrderFormScreenUtils'

const PaymentFormsView = ({
    setPaymentDate,
    setPaymentForm,
    setPaymentDeadline,
    availablePaymentForms,
    availablePaymentDeadlines,
    selectedPaymentForm,
    selectedPaymentDeadline,
    selectedPaymentDate,
    isLoading,
    invalidFieldsHandler,
}) => {
    const { invalidFields, getInvalidField, setValidField } =
        invalidFieldsHandler

    if (isLoading) {
        return (
            <Panel>
                <Loading />
            </Panel>
        )
    }

    const renderDatePicker = paymentDeadline => {
        if (isCustomDeadline(paymentDeadline)) {
            return (
                <DatePickerCalendar
                    isInvalidDate={getInvalidField(
                        'paymentDate',
                        invalidFields
                    )}
                    onFocus={() => setValidField('paymentDate')}
                    name="datePicker"
                    value={selectedPaymentDate}
                    onChange={e => setPaymentDate(e.target.value)}
                    minDate={new Date()}
                    disabled={!isCustomDeadline(selectedPaymentDeadline)}
                />
            )
        }
    }

    return (
        <Panel>
            <Content>
                <PaymentFormContainer>
                    <Label htmlFor="paymentForm" id="paymentFormLabel">
                        * Forma de Pagamento
                    </Label>

                    <Options
                        id="paymentForm"
                        aria-labelledby="paymentFormLabel"
                    >
                        {availablePaymentForms.map(paymentForm => (
                            <Option key={paymentForm._id}>
                                <RadioButton
                                    ariaLabelledBy={`${paymentForm._id}Label`}
                                    id={`${paymentForm._id}`}
                                    name="paymentForm"
                                    value={paymentForm.paymentForm}
                                    checked={
                                        selectedPaymentForm ===
                                        paymentForm.paymentForm
                                    }
                                    onChange={e =>
                                        setPaymentForm(
                                            e.value,
                                            availablePaymentForms
                                        )
                                    }
                                    isInvalid={getInvalidField(
                                        'paymentForm',
                                        invalidFields
                                    )}
                                />
                                <OptionLabel
                                    htmlFor={`${paymentForm._id}`}
                                    id={`${paymentForm._id}Label`}
                                >
                                    {paymentForm.paymentForm}
                                </OptionLabel>
                            </Option>
                        ))}
                    </Options>
                    <FieldErrorMessageComponent
                        isInvalid={getInvalidField(
                            'paymentForm',
                            invalidFields
                        )}
                    />
                </PaymentFormContainer>

                {selectedPaymentForm ? (
                    <PaymentDeadlineContainer>
                        <Label
                            htmlFor="paymentDeadlines"
                            id="paymentDeadlinesLabel"
                        >
                            * Prazo de Pagamento
                        </Label>

                        <DeadlineOptions
                            id="paymentDeadlines"
                            aria-labelledby="paymentDeadlinesLabel"
                        >
                            {availablePaymentDeadlines.map(paymentDeadline => (
                                <Option
                                    key={paymentDeadline.name}
                                    data-testid={paymentDeadline.name}
                                >
                                    <RadioButton
                                        id={`id-${paymentDeadline.name}`}
                                        name="paymentDeadline"
                                        value={paymentDeadline.name}
                                        checked={
                                            selectedPaymentDeadline ===
                                            paymentDeadline.name
                                        }
                                        onChange={e =>
                                            setPaymentDeadline(
                                                e.value,
                                                availablePaymentDeadlines
                                            )
                                        }
                                        isInvalid={getInvalidField(
                                            'paymentDeadline',
                                            invalidFields
                                        )}
                                    />
                                    <OptionLabel
                                        htmlFor={`id-${paymentDeadline.name}`}
                                    >
                                        {paymentDeadline.label}
                                    </OptionLabel>
                                    {renderDatePicker(paymentDeadline)}
                                </Option>
                            ))}
                        </DeadlineOptions>
                        <FieldErrorMessageComponent
                            isInvalid={getInvalidField(
                                'paymentDeadline',
                                invalidFields
                            )}
                        />
                    </PaymentDeadlineContainer>
                ) : (
                    <></>
                )}
            </Content>
        </Panel>
    )
}

const Panel = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: ${Colors.white};
`

const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
`

const Options = styled.div`
    display: grid;
    grid-row-gap: 2.8vh;
    margin-top: 1.68vh;
`

const Option = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const OptionLabel = styled.label`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: ${Colors.brownishGrey};
`

const PaymentFormContainer = styled.div``

const PaymentDeadlineContainer = styled.div`
    border-radius: 3px;
    border: solid 1px #c4c4c8;
    padding: 2vh 1vw;
`

const DeadlineOptions = styled(Options)``

export default PaymentFormsView
