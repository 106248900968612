import React from 'react'
import styled from 'styled-components'
import { TabPanel } from 'primereact/tabview'

import Colors from '../commons/Colors'
import ScreenHeader from '../commons/components/screenHeader/ScreenHeader'
import DashboardScreen from '../dashboard/DashboardScreen'
import DashboardContextProvider from '../context/DashboardContext'
import bTabView from '../commons/components/TabView'
import SimpleBillingScreen from '../simpleBilling/SimpleBillingScreen'

export default function HomeView({ activeIndex, handleTabChange }) {
    return (
        <Container data-testid="home">
            <ScreenHeader />
            <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
                <TabPanel header="GESTÃO DE PEDIDOS">
                    <DashboardContextProvider>
                        <DashboardScreen />
                    </DashboardContextProvider>
                </TabPanel>
                <TabPanel header="SIMPLES FATURAMENTO">
                    <SimpleBillingScreen />
                </TabPanel>
            </TabView>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
`

const TabView = styled(bTabView)`
    .p-tabview-nav {
        margin: 18px 0px 32px 0px !important;
    }

    a[id^='pr_id_'] {
        margin: 0px !important;
        padding: 12px !important;
    }

    .p-tabview-selected.p-highlight a {
        border-bottom: 4px solid ${Colors.orange} !important;
    }

    .p-tabview-title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.16px;
        text-align: center;
        color: ${Colors.orange};
        margin: 0;
    }

    .p-tabview-panels {
        border: none !important;
    }
`
