import React, { useState, useEffect, useReducer } from 'react'
import styled from 'styled-components'
import { MdDelete, MdCheck } from 'react-icons/md'

import * as OrderAPI from '../../dashboard/OrderAPI'
import * as ShipmentAPI from '../../dashboard/ShipmentApi'
import * as NumberUtils from '../../commons/NumberUtils'
import * as MeasurementUnitUtils from '../../commons/MeasurementUnitUtils'
import * as PromiseUtils from '../../commons/PromiseUtils'
import { getDaysDiff } from '../../commons/DateUtils'

import Colors from '../../commons/Colors'
import BasicLoading from '../../commons/components/Loading'
import OutlineButton from '../../commons/components/OutlineButton'
import BasicRoundButton from '../../commons/components/RoundButton'
import { Modal } from '../../commons/components/modal'
import Confirm from '../../commons/components/Confirm'
import { addMessage, MessageType } from '../../commons/MessageUtils'
import { STATUS } from '../../commons/Status'
import SimpleBillingTableComponent from './SimpleBillingTableComponent'
import { useMillContext } from '../../context/MillContext'
import ShipmentCardComponent from './ShipmentCardComponent'
import CancelOrderModal from '../../order/CancelOrderModal'
import { analyticsEvent, EVENT_KEYS } from '../../commons/AnalyticsUtils'

export default function SimpleBillingModalComponent({
    clearSelectedSimpleBillingId,
    selectedSimpleBilling,
    onCancelOrder,
}) {
    const { mill } = useMillContext()
    const [simpleBilling, setSimpleBilling] = useState()
    const [simpleBillingItems, setSimpleBillingItems] = useState([])
    const [isLoadingData, setIsLoadingData] = useState(true)
    const [isSavingData, setIsSavingData] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(true)
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false)
    const [data, updateData] = useReducer(
        (state, action) => {
            const mapIndexes = arr =>
                arr.map((item, index) => ({ ...item, index }))

            switch (action.type) {
                case 'POPULATE':
                    return { ...state, shipments: mapIndexes(action.shipments) }
                case 'ADD':
                    return {
                        ...state,
                        shipmentsToSave: mapIndexes([
                            ...state.shipmentsToSave,
                            action.shipment,
                        ]),
                    }
                case 'REMOVE':
                    return action.id
                        ? {
                              ...state,
                              shipments: mapIndexes(
                                  state.shipments.filter(
                                      shipment => shipment.id !== action.id
                                  )
                              ),
                              shipmentIdsToDelete: [
                                  ...state.shipmentIdsToDelete,
                                  action.id,
                              ],
                          }
                        : {
                              ...state,
                              shipmentsToSave: mapIndexes(
                                  state.shipmentsToSave.filter(
                                      shipment =>
                                          shipment.index !== action.index
                                  )
                              ),
                          }
                default:
                    throw new Error('Ação inválida.')
            }
        },
        { shipments: [], shipmentsToSave: [], shipmentIdsToDelete: [] }
    )
    const [shipmentToCreate, updateShipmentToCreate] = useReducer(
        (prev, current) => {
            const shipment = { ...prev, ...current }

            const calculatesSubtotal = shipmentItems =>
                shipmentItems.reduce(
                    (sum, item) => sum + item.shipmentSubtotal,
                    0
                )

            const getTotalWeight = shipmentItems => {
                const totalWeight = shipmentItems.reduce((sum, item) => {
                    return sum + item.getShipmentWeightInTonnes()
                }, 0)

                return NumberUtils.round(totalWeight, 3)
            }
            const calculatesFreight = simpleBilling => {
                const shipmentWeight = shipment.weightInTonnes * 1000
                const simpleBillingWeight = simpleBilling.weightInTonnes * 1000
                const totalFreightValue = simpleBilling.freightPrice

                return (
                    (shipmentWeight / simpleBillingWeight) * totalFreightValue
                )
            }
            const calculatesCharges = simpleBilling => {
                const shipmentWeight = shipment.weightInTonnes * 1000
                const simpleBillingWeight = simpleBilling.weightInTonnes * 1000
                const totalChargesValue = simpleBilling.chargesPrice

                return (
                    (shipmentWeight / simpleBillingWeight) * totalChargesValue
                )
            }
            const calculatesPrice = () => {
                const freightPrice = shipment.freightPrice
                const chargesPrice = shipment.chargesPrice
                const subtotal = shipment.subtotal

                return NumberUtils.round(
                    subtotal + freightPrice + chargesPrice,
                    2
                )
            }
            const isShipmentUrgent = stockSafetyDays => {
                if (!shipment.deliveryDate) return false

                return (
                    getDaysDiff(new Date(shipment.deliveryDate)) <
                    stockSafetyDays
                )
            }

            shipment.subtotal = calculatesSubtotal(shipment.shipmentItems)
            shipment.weightInTonnes = getTotalWeight(shipment.shipmentItems)
            shipment.freightPrice = calculatesFreight(simpleBilling)
            shipment.chargesPrice = calculatesCharges(simpleBilling)
            shipment.price = calculatesPrice()
            shipment.isUrgent = isShipmentUrgent(
                simpleBilling.farm.stockSafetyDays
            )

            return shipment
        },
        {
            deliveryDate: null,
            subtotal: 0,
            weightInTonnes: 0,
            freightPrice: 0,
            chargesPrice: 0,
            price: 0,
            isUrgent: false,
            shipmentItems: [],
            validate: function () {
                if (!this.shipmentItems.length) return false

                if (
                    this.shipmentItems.some(
                        item => item.shipmentQuantity > item.remainingQuantity
                    )
                )
                    return false

                if (!Boolean(this.deliveryDate)) return false

                return true
            },
        }
    )
    const [confirmationModalParams, setConfirmationModalParams] = useState({
        isVisible: false,
        shipmentId: undefined,
        shipmentIndex: undefined,
    })

    useEffect(() => {
        const mountOrderItems = orderItems => {
            const enrichOrderItem = item => {
                return {
                    ...item,
                    calculatesShipmentSubtotal: function () {
                        if (!this.unitPrice || !this.shipmentQuantity) return 0

                        return NumberUtils.round(
                            this.unitPrice * parseFloat(this.shipmentQuantity)
                        )
                    },
                    getShipmentWeightInTonnes: function () {
                        if (!this.shipmentQuantity || !this.isRationItem())
                            return 0

                        return NumberUtils.round(
                            MeasurementUnitUtils.standardizeWeight(
                                this.measurementUnit,
                                this.shipmentQuantity
                            ),
                            3
                        )
                    },
                    isRationItem: function () {
                        return MeasurementUnitUtils.isRationItem(
                            this.measurementUnit
                        )
                    },
                }
            }

            const enrichOrderItems = items =>
                items.map(item => enrichOrderItem(item))

            const items = orderItems
                .map(orderItem => ({
                    productName: orderItem.product.name,
                    productId: orderItem.product._id,
                    totalQuantity: orderItem.quantity,
                    remainingQuantity: orderItem.quantity,
                    measurementUnit: orderItem.product.measurementUnit,
                    price: orderItem.price,
                    id: orderItem._id,
                    unitPrice: orderItem.unitPrice,
                    shipmentQuantity: '',
                    shipmentSubtotal: 0,
                    shipmentWeight: 0,
                }))
                .sort((prev, next) => prev.id.localeCompare(next.id))

            return enrichOrderItems(items)
        }

        const fetchOrder = async orderId => {
            await PromiseUtils.PromiseWrapper({
                promise: OrderAPI.getOrder(orderId),
                onFulfilled: ({ data }) => {
                    setSimpleBilling(data)
                    setSimpleBillingItems(mountOrderItems(data.items))
                },
            })
        }

        const fetchShipments = async orderId => {
            await PromiseUtils.PromiseWrapper({
                promise: ShipmentAPI.getAllShipments({ orderId }),
                onFulfilled: ({ data }) =>
                    updateData({
                        type: 'POPULATE',
                        shipments: data.sort(
                            (prev, next) => prev.number - next.number
                        ),
                    }),
            })
        }

        Promise.all([
            fetchOrder(selectedSimpleBilling.orderId),
            fetchShipments(selectedSimpleBilling.orderId),
        ])
            .then(() => setIsLoadingData(false))
            .catch(() => {
                clearSelectedSimpleBillingId()
                setIsModalVisible(false)
                addMessage(
                    MessageType.ERROR,
                    'Ocorreu um erro ao buscar as informações. tente novamente.'
                )
            })
    }, [selectedSimpleBilling, clearSelectedSimpleBillingId])
    useEffect(() => {
        const recalculatesRemainingQuantity = allShipments => {
            if (!simpleBilling) return

            const remainingQuantityByProduct = {}

            simpleBilling.items.forEach(item => {
                remainingQuantityByProduct[item.product._id] = parseFloat(
                    item.quantity
                )
            })

            allShipments.forEach(shipment => {
                shipment.shipmentItems.forEach(shipmentItem => {
                    if (
                        MeasurementUnitUtils.isDecimalUnitType(
                            simpleBilling.items.find(
                                orderItem =>
                                    orderItem.product?.gcpId ===
                                        shipmentItem.productId ||
                                    orderItem.product._id ===
                                        shipmentItem.productId ||
                                    orderItem.product._id ===
                                        shipmentItem?.productSydleId
                            ).product.measurementUnit
                        )
                    ) {
                        const result = (
                            remainingQuantityByProduct[shipmentItem.productId] -
                            parseFloat(shipmentItem.quantity)
                        ).toFixed(3)
                        remainingQuantityByProduct[shipmentItem.productId] =
                            parseFloat(result)

                        return
                    }

                    remainingQuantityByProduct[shipmentItem.productId] -=
                        parseInt(shipmentItem.quantity, 10)
                })
            })

            setSimpleBillingItems(currentItems =>
                currentItems.map(item => ({
                    ...item,
                    remainingQuantity:
                        remainingQuantityByProduct[item.productId],
                    shipmentQuantity: '',
                    shipmentSubtotal: 0,
                    shipmentWeight: 0,
                }))
            )
        }

        recalculatesRemainingQuantity([
            ...data.shipments,
            ...data.shipmentsToSave,
        ])
    }, [data.shipments, data.shipmentsToSave, simpleBilling])

    const handleOnClose = () => {
        clearSelectedSimpleBillingId()
        setIsModalVisible(false)
    }

    const hasNotRemainingQuantity = () => {
        return simpleBillingItems.every(
            item => parseFloat(item.remainingQuantity) === 0
        )
    }

    const createShipment = () => {
        const isValid = shipmentToCreate.validate()
        if (!isValid) return

        const {
            deliveryDate,
            subtotal,
            weightInTonnes,
            freightPrice,
            chargesPrice,
            price,
            isUrgent,
            shipmentItems,
        } = shipmentToCreate

        const shipment = {
            deliveryDate,
            subtotal,
            weightInTonnes,
            freightPrice,
            chargesPrice,
            price,
            isUrgent,
            shipmentItems: shipmentItems.map(item => ({
                productId: item.productId,
                price: item.shipmentSubtotal,
                weight: item.shipmentWeight,
                quantity: parseFloat(item.shipmentQuantity),
            })),
            status: {
                name: STATUS.EM_ANALISE,
            },
            source: {
                name: 'portal',
            },
            orderId: simpleBilling.gcpId,
            millId: mill.gcpId,
            millSydleId: mill._id,
        }

        updateData({ type: 'ADD', shipment })
        updateShipmentToCreate({ shipmentItems: [], deliveryDate: null })
    }

    const clearConfirmationModalParams = () => {
        setConfirmationModalParams({
            isVisible: false,
            shipmentIndex: undefined,
            shipmentId: undefined,
        })
    }

    const deleteShipment = (index, id) => {
        if (id)
            setConfirmationModalParams({
                isVisible: true,
                shipmentIndex: index,
                shipmentId: id,
            })
        else updateData({ type: 'REMOVE', index })
    }

    const handleOnClickSave = async () => {
        setIsSavingData(true)

        const saveShipments = async () =>
            Boolean(data.shipmentsToSave.length)
                ? await PromiseUtils.GCPWrapper(
                      ShipmentAPI.createShipments(data.shipmentsToSave)
                  )
                : []

        const deleteShipments = async () =>
            Boolean(data.shipmentIdsToDelete.length)
                ? await PromiseUtils.GCPWrapper(
                      ShipmentAPI.deleteShipments(data.shipmentIdsToDelete)
                  )
                : []

        await PromiseUtils.WrapAll({
            promises: [saveShipments(), deleteShipments()],
            onFulfilled: () => {
                if (Boolean(data.shipmentsToSave.length)) {
                    analyticsEvent(EVENT_KEYS.createShipments)
                }
                if (Boolean(data.shipmentIdsToDelete.length)) {
                    analyticsEvent(EVENT_KEYS.deleteShipments)
                }
                addMessage(
                    MessageType.SUCCESS,
                    'Alterações Salvas com sucesso!'
                )
                handleOnClose()
            },
            onRejected: () =>
                addMessage(
                    MessageType.ERROR,
                    'Ocorreu um erro ao salvar as alterações. tente novamente.'
                ),
            callback: () => setIsSavingData(false),
        })
    }

    const isCancelButtonDisabled = () => {
        return (
            simpleBilling?.status === STATUS.ENTREGUE ||
            data.shipments.some(
                shipment => shipment.status.name !== STATUS.EM_ANALISE
            )
        )
    }

    const renderShipments = () => {
        return [...data.shipments, ...data.shipmentsToSave].map(shipment => {
            return (
                <ShipmentCardComponent
                    shipment={shipment}
                    simpleBilling={simpleBilling}
                    onDelete={deleteShipment}
                />
            )
        })
    }

    return (
        <Modal visible={isModalVisible} onClose={handleOnClose}>
            <ModalHeader>
                <ModalTitle>Simples Faturamento</ModalTitle>
                {!isLoadingData && (
                    <DeleteSimpleBillingButton
                        disabled={isCancelButtonDisabled()}
                        color={Colors.orange}
                        onClick={() => setIsCancelModalVisible(true)}
                        loading={isSavingData}
                    >
                        <DeleteIcon /> Excluir
                    </DeleteSimpleBillingButton>
                )}
            </ModalHeader>
            <ModalBody>
                {isLoadingData ? (
                    <Loading />
                ) : (
                    <>
                        <SimpleBillingTableComponent
                            simpleBilling={simpleBilling}
                            orderItems={simpleBillingItems}
                            setSimpleBillingItems={setSimpleBillingItems}
                            hasNotRemainingQuantity={hasNotRemainingQuantity}
                            shipmentToCreate={shipmentToCreate}
                            updateShipmentToCreate={updateShipmentToCreate}
                            createShipment={createShipment}
                        />
                        {Boolean(
                            [...data.shipments, ...data.shipmentsToSave].length
                        ) && (
                            <ShipmentsContainer>
                                <ShipmentsText>Remessas</ShipmentsText>
                                <ShipmentCardsGrid>
                                    {renderShipments()}
                                </ShipmentCardsGrid>
                            </ShipmentsContainer>
                        )}
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <CancelButton onClick={handleOnClose} loading={isSavingData}>
                    Cancelar
                </CancelButton>
                <SaveButton
                    disabled={
                        !Boolean(
                            data.shipmentsToSave.length ||
                                data.shipmentIdsToDelete.length
                        )
                    }
                    onClick={() => handleOnClickSave()}
                    loading={isSavingData}
                >
                    SALVAR <SaveIcon />
                </SaveButton>
            </ModalFooter>
            <Confirm
                visible={confirmationModalParams.isVisible}
                title="Excluir remessa futura"
                onClose={clearConfirmationModalParams}
                actionButtonText="Sim, excluir"
                actionButtonFunction={() => {
                    updateData({
                        type: 'REMOVE',
                        index: confirmationModalParams.shipmentIndex,
                        id: confirmationModalParams.shipmentId,
                    })
                    addMessage(
                        MessageType.SUCCESS,
                        'Remessa excluída com sucesso! Clique em "SALVAR" para registrar as alterações.'
                    )
                    clearConfirmationModalParams()
                }}
            >
                <ConfirmationModalText>
                    Tem certeza de que deseja excluir a remessa{' '}
                    {
                        data.shipments.find(
                            shipment =>
                                shipment.id ===
                                confirmationModalParams.shipmentId
                        )?.number
                    }
                    ?
                    <br />
                    <br />
                    <i>
                        *Nesta ação, os valores do pedido retornarão ao saldo do
                        Simples faturamento.
                    </i>
                </ConfirmationModalText>
            </Confirm>

            {isCancelModalVisible && (
                <CancelOrderModal
                    visible={isCancelModalVisible}
                    onClose={() => setIsCancelModalVisible(false)}
                    onCancel={() => {
                        setIsCancelModalVisible(false)
                        handleOnClose()
                        onCancelOrder()
                    }}
                    order={simpleBilling}
                />
            )}
        </Modal>
    )
}

const ModalBody = styled.div`
    width: 50vw;
    min-width: 700px;
    min-height: 300px;
    box-sizing: content-box;
    max-height: 70vh;
    overflow: auto;
    padding: 30px;
`

const Loading = styled(BasicLoading)`
    height: 300px;
`

const ModalHeader = styled.div`
    padding: 30px 30px 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ModalFooter = styled.div`
    padding: 15px 30px 30px 30px;
    gap: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
`

const ModalTitle = styled.p`
    font-family: Roboto;
    font-size: 16px;
    color: ${Colors.brownishGrey};
`

const DeleteSimpleBillingButton = styled(OutlineButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98px;
    margin-left: 16px;
    padding-right: 14px;
`

const DeleteIcon = styled(MdDelete)`
    width: 24px;
    height: 24px;
`

const CancelButton = styled(BasicRoundButton)`
    background-color: ${Colors.white};
    color: ${Colors.orange};
    border-color: ${Colors.orange};
`

const SaveButton = styled(BasicRoundButton)`
    min-width: 100px;
    color: ${Colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`

const SaveIcon = styled(MdCheck)`
    width: 20px;
    height: 20px;
`

const ShipmentsContainer = styled.div`
    padding: 10px;
    margin-top: 25px;

    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
`

const ShipmentCardsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
`

const ShipmentsText = styled(ModalTitle)`
    font-weight: 600;
`

const ConfirmationModalText = styled.p`
    color: ${Colors.brownishGrey};
`
