import React from 'react'
import styled from 'styled-components'

import Colors from '../Colors'
import { Dropdown as PDropdown } from 'primereact/dropdown'
import FieldErrorMessageComponent from './error/FieldErrorMessageComponent'

/**
 *
 * @param {string} id - identificação unica do elemento
 * @param {string} name - nome do elemento de input
 * @param {string} className - className da opção do dropdown
 * @param {value} value - valor da opção do dropdown
 * @param {array} options - array com as opções a serem mostradas no dropdown
 * @param {event} onChange - chamado quando o valor é alterado
 * @param {string} placeholder - texto a ser mostrado quando nenhuma opção esta selecionada
 * @param {string} dataKey - propriedade para combinar unicamente o valor nas opções para melhor performance
 * @param {string} optionLabel - nome do campo label para um opção quando arbitrariamente objetos são usados como opções invez de SelecItems
 * @param {string} optionValue - nome do campo value para um opção quando arbitrariamente objetos são usados como opções invez de SelecItems
 * @param {boolean} disabled - quando verdadeiro especifica que o componente esta desabilitado
 * @param {any} onBlur - chamado quando o elemento perde o foco
 * @param {any} onFocus - chamado quando o elemento recebe foco
 * @param {boolean} isInvalid - diz se o input é invalido
 * @param {string} invalidMessage - mensagem a ser mostrada quando o input for invalido
 */

export const Dropdown = ({
    id,
    name,
    className,
    value,
    options,
    onChange,
    placeholder,
    dataKey,
    optionLabel,
    optionValue,
    disabled = false,
    getValue,
    filter = false,
    showClear = false,
    filterBy,
    onBlur,
    onFocus,
    isInvalid = false,
    invalidMessage,
    ariaLabelledBy,
}) => {
    return (
        <Container onFocus={onFocus} onBlur={onBlur}>
            <DropdownComponent
                ariaLabelledBy={ariaLabelledBy}
                id={id}
                name={name}
                className={className}
                value={getValue ? getValue(value) : value}
                options={options}
                onChange={onChange}
                placeholder={placeholder}
                dataKey={dataKey}
                optionLabel={optionLabel}
                optionValue={optionValue}
                disabled={disabled}
                filter={filter}
                showClear={showClear}
                filterBy={filterBy}
                isInvalid={isInvalid}
            />
            <FieldErrorMessageComponent
                isInvalid={isInvalid}
                message={invalidMessage}
            />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const DropdownComponent = styled(PDropdown)`
    background-color: ${Colors.white} !important;
    border-color: ${props =>
        props.isInvalid ? Colors.strawberry : Colors.lightGrey} !important;
    line-height: 1;
    min-width: auto;
    height: 40px;
    width: 100%;
    padding-top: 7px;

    .p-dropdown-trigger {
        background-color: ${Colors.white} !important;
    }
    .p-dropdown-label {
        background-color: ${Colors.white} !important;
    }

    .p-dropdown-item.p-highlight {
        background-color: rgba(241, 141, 58, 0.1) !important;
        color: ${Colors.black};
    }

    .p-placeholder {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.12px;
        color: ${Colors.lightBlueGreyTwo};
    }

    .p-dropdown-filter-container {
        display: flex;
        justify-self: stretch;

        input {
            background-color: ${Colors.white};
        }
    }

    &::placeholder {
        font-family: 'Roboto';
        color: ${Colors.lightBlueGreyTwo};
        font-size: 14px !important;
    }
`

export default Dropdown
