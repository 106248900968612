import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as UserAPI from '../../login/UserAPI'
import * as OrderAPI from '../../dashboard/OrderAPI'
import { useMillContext } from '../../context/MillContext'
import { debounce } from '../../commons/components/Debounce'
import { STATUS, STATUS_OPTIONS } from '../../commons/Status'

import * as PromiseUtils from '../../commons/PromiseUtils'

import OrderListView from './OrderListView'

export default function OrderListContainer() {
    const history = useHistory()
    const { millConfig } = useMillContext()
    const [isLoadingOrders, setIsLoadingOrders] = useState(true)
    const [isCancelOrderModalVisible, setIsCancelOrderModalVisible] =
        useState(false)
    const [orderToCancel, setOrderToCancel] = useState(null)
    const [selectedOrderId, setSelectedOrderId] = useState(null)
    const [filter, setFilter] = useState({
        search: '',
        requestDate: '',
        deliveryDate: '',
        status: [],
        sellers: [],
    })
    const [selectedStatus, setSelectedStatus] = useState([])
    const [selectedSellers, setSelectedSellers] = useState([])

    const [orders, setOrders] = useState([])
    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0)
    const [invalidDate, setInvalidDate] = useState(false)

    const rows = 10

    useEffect(() => {
        if (filter.requestDate && filter.deliveryDate) {
            setInvalidDate(filter.requestDate > filter.deliveryDate)
        }
    }, [filter])

    useEffect(() => {
        fetchOrders(first, rows, filter)
        // eslint-disable-next-line
    }, [])

    async function fetchOrders(from, size, filter) {
        setIsLoadingOrders(true)

        await PromiseUtils.PromiseWrapper({
            promise: OrderAPI.searchPaginatedOrders(from, size, filter),
            onFulfilled: ({ data }) => {
                setOrders(data.orders)
                setTotalRecords(data.total || 0)
            },
            callback: () => setIsLoadingOrders(false),
        })
    }

    const fetchSellers = async () => {
        return PromiseUtils.PromiseWrapper({
            promise: UserAPI.getAllSellersAndStc(),
            onFulfilled: ({ data }) => {
                return data.map(seller => ({
                    label: seller.name,
                    value: seller,
                }))
            },
        })
    }

    const fetchStatus = () => {
        return Promise.resolve(
            STATUS_OPTIONS.map(({ value, label }) => ({
                value: { id: value },
                label,
            }))
        )
    }

    const handlePagination = event => {
        setFirst(event.first)
        fetchOrders(event.page + 1, event.rows, filter)
    }

    const handleChangeFilter = e => {
        const { name, value } = e.target

        setFilter(filter => {
            const newFilter = {
                ...filter,
                [name]: value || '',
            }

            if (filter[name] !== newFilter[name]) {
                setFirst(0)
                debounce(() => fetchOrders(0, rows, newFilter))
            }
            return newFilter
        })
    }

    const eventValueExtractor = value => {
        if (value.id) return value.id
        if (value._id) return value._id
        return value
    }

    const mountFilterEvent = (inputId, event) => ({
        target: {
            name: inputId,
            value: event.map(item => eventValueExtractor(item.value)),
        },
    })

    const cancelOrder = order => {
        setIsCancelOrderModalVisible(true)
        setOrderToCancel(order)
    }

    const onEditClick = orderId => {
        history.push({
            pathname: `/pedidos/editar/${orderId}`,
            state: { isConfirmedOrder: true },
        })
    }

    const isEditionAllowed = order => {
        const isDelivered = isOrderDelivered(order)
        const isCanceled = isOrderCanceled(order)
        const isFragmentedOrder = isUseFragmentedOrder(order)

        if (
            millConfig?.allowsOrderEdition &&
            !isDelivered &&
            !isCanceled &&
            !isFragmentedOrder
        ) {
            return true
        }

        return false
    }

    const isOrderCanceled = order => {
        return order.status === STATUS.CANCELADO
    }

    const isOrderDelivered = order => {
        return order.status === STATUS.ENTREGUE
    }

    const isUseFragmentedOrder = order => order.isUseFragmentOrder

    return (
        <OrderListView
            isLoadingOrders={isLoadingOrders}
            isCancelOrderModalVisible={isCancelOrderModalVisible}
            setIsCancelOrderModalVisible={setIsCancelOrderModalVisible}
            orderToCancel={orderToCancel}
            selectedOrderId={selectedOrderId}
            setSelectedOrderId={setSelectedOrderId}
            filter={filter}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            orders={orders}
            totalRecords={totalRecords}
            first={first}
            setFirst={setFirst}
            invalidDate={invalidDate}
            rows={rows}
            fetchOrders={fetchOrders}
            handlePagination={handlePagination}
            handleChangeFilter={handleChangeFilter}
            cancelOrder={cancelOrder}
            onEditClick={onEditClick}
            isEditionAllowed={isEditionAllowed}
            isOrderCanceled={isOrderCanceled}
            isUseFragmentedOrder={isUseFragmentedOrder}
            fetchStatus={fetchStatus}
            fetchSellers={fetchSellers}
            selectedSellers={selectedSellers}
            setSelectedSellers={setSelectedSellers}
            mountFilterEvent={mountFilterEvent}
        />
    )
}
