import React from 'react'

const OpenLetterIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="145"
            height="128"
            viewBox="0 0 145 128"
            fill="none"
        >
            <g clip-path="url(#knwychbs0a)">
                <path
                    d="m121.84 60.731-.004-.01-.004-.01-12.645-33.194a2.582 2.582 0 0 0-2.413-1.662H37.226c-1.07 0-2.03.66-2.413 1.662L22.168 60.71l-.004.01-.004.011a2.591 2.591 0 0 0-.145.721 1.063 1.063 0 0 0-.015.177v37.935a2.58 2.58 0 0 0 2.58 2.581h94.839a2.58 2.58 0 0 0 2.581-2.58V61.628c0-.068-.007-.128-.015-.177a2.62 2.62 0 0 0-.145-.72zm-93.514-1.683 10.678-28.032h65.992l10.679 28.032H94.129a2.58 2.58 0 0 0-2.58 2.581c0 10.796-8.753 19.548-19.549 19.548S52.452 72.425 52.452 61.63a2.58 2.58 0 0 0-2.581-2.58H28.325zM27.16 96.984V64.21h20.264C48.727 76.739 59.303 86.347 72 86.347c12.698 0 23.272-9.608 24.576-22.137h20.263v32.774H27.161z"
                    fill="#E57509"
                    stroke="#E57509"
                    stroke-width="2"
                />
            </g>
            <defs>
                <clipPath id="knwychbs0a">
                    <path
                        fill="#currentColor"
                        transform="translate(23 15)"
                        d="M0 0h98v98H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default OpenLetterIcon
