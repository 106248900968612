import React, { useState, useEffect } from 'react'
import * as OrderAPI from '../OrderAPI'
import * as FragmentedOrderApi from '../FragmentedOrderApi'
import * as ShipmentApi from '../ShipmentApi'
import * as FragmentedOrderUtils from '../FragmentedOrderModal/FragmentedOrderUtils'
import * as SimpleBillingUtils from '../../commons/SimpleBillingUtils'
import { PromiseWrapper } from '../../commons/PromiseUtils'
import { addMessage, MessageType } from '../../commons/MessageUtils'
import OrderModalView from './OrderModalView'

const OrderModalContainer = ({
    selectedOrderId,
    clearSelectedOrderId,
    millConfig,
    mill,
    onEditClick,
}) => {
    const [order, setOrder] = useState(null)
    const [fragments, setFragments] = useState([])
    const [shipments, setShipments] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        if (selectedOrderId) {
            PromiseWrapper({
                promise: OrderAPI.getOrder(selectedOrderId),
                onFulfilled: ({ data }) => setOrder(data),
                onRejected: () => {
                    addMessage(
                        MessageType.ERROR,
                        'Ocorreu um erro ao buscar o pedido.'
                    )
                    clearSelectedOrderId()
                },
            })
        }
    }, [selectedOrderId, clearSelectedOrderId])

    useEffect(() => {
        if (mill && isUseFragmentedOrder(order)) {
            PromiseWrapper({
                promise: FragmentedOrderApi.getAllFragmentedOrders({
                    millId: mill.gcpId,
                    orderId: order.gcpId,
                }),
                onFulfilled: ({ data }) => {
                    const mountedFragments =
                        FragmentedOrderUtils.mountFragments(order, data)
                    setFragments(mountedFragments)
                },
                onRejected: () =>
                    addMessage(
                        MessageType.ERROR,
                        'Ocorreu um erro ao carregar os fracionamentos de pedido, tente novamente.'
                    ),
                callback: () => setIsLoading(false),
            })
            return
        }

        if (mill && isUseShipment(order)) {
            PromiseWrapper({
                promise: ShipmentApi.getAllShipments({
                    millId: mill.gcpId,
                    orderId: order.gcpId,
                }),
                onFulfilled: ({ data }) => {
                    const mountedShipments = SimpleBillingUtils.mountShipments(
                        order,
                        data
                    )

                    setShipments(mountedShipments)
                },
                onRejected: () =>
                    addMessage(
                        MessageType.ERROR,
                        'Ocorreu um erro ao carregar os simples faturamentos, tente novamente.'
                    ),
                callback: () => setIsLoading(false),
            })
            return
        }

        if (!isUseFragmentedOrder(order) && order) setIsLoading(false)
    }, [order, mill])

    const isItemOutOfPricePolicy = (order, item) =>
        item.product.isOutOfPricePolicy && order.status === 'EM_ANALISE'

    const isFreight = order => {
        return (
            Boolean(order?.freightType === 'CIF') ||
            Boolean(order?.freightType === 'FOB')
        )
    }

    const isCharges = order => {
        return Boolean(order?.chargesPrice)
    }

    const isUseFragmentedOrder = order => order?.isUseFragmentOrder

    const isUseShipment = order => order?.isUseShipment

    const havePalletInfo = order => typeof order?.needsPallet === 'boolean'

    const needsPallet = order => (order?.needsPallet ? 'Sim' : 'Não')

    const haveChapaInfo = order => typeof order?.needsChapa === 'boolean'

    const needsChapa = order => (order?.needsChapa ? 'Sim' : 'Não')

    const isOrderDelivered = order => order?.status === 'ENTREGUE'

    const isOrderCanceled = order => order?.status === 'CANCELADO'

    const isEditionAllowed = order => {
        if (
            millConfig?.allowsOrderEdition &&
            !isOrderDelivered(order) &&
            !isOrderCanceled(order) &&
            !isUseFragmentedOrder(order) &&
            !isUseShipment(order)
        ) {
            return true
        }

        return false
    }

    const isDebtorClient = () => order?.farm?.debtor

    return (
        <OrderModalView
            order={order}
            fragments={fragments}
            shipments={shipments}
            isLoading={isLoading}
            onEditClick={onEditClick}
            clearSelectedOrderId={clearSelectedOrderId}
            isItemOutOfPricePolicy={isItemOutOfPricePolicy}
            isUseFragmentedOrder={isUseFragmentedOrder(order)}
            isUseShipment={isUseShipment(order)}
            isOrderCanceled={isOrderCanceled(order)}
            isOrderDelivered={isOrderDelivered(order)}
            isFreight={isFreight(order)}
            isCharges={isCharges(order)}
            havePalletInfo={havePalletInfo(order)}
            needsPallet={needsPallet(order)}
            haveChapaInfo={haveChapaInfo(order)}
            needsChapa={needsChapa(order)}
            isEditionAllowed={isEditionAllowed(order, millConfig)}
            isDebtorClient={isDebtorClient(order)}
        />
    )
}

export default OrderModalContainer
