import React from 'react'
import Tooltip from './Tooltip'

import FragmentOrderIcon from '../../assets/customIcons/FragmentOrderIcon'

function FragmentedOrderIndicator() {
    return (
        <Tooltip label={'Pedidos fracionados não podem ser editados'}>
            <FragmentOrderIcon
                data-testid="fragment-order-icon"
                backgroundColor={'#f7a6a0'}
            />
        </Tooltip>
    )
}

export default FragmentedOrderIndicator
