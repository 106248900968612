import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column'
import { MdSend } from 'react-icons/md'

import Colors from '../commons/Colors'
import Table from '../commons/components/Table'
import DebtorClientIndicator from '../commons/components/DebtorClientIndicator'
import Confirm from '../commons/components/Confirm'
import * as PendingOrderAPI from './PendingOrderAPI'
import * as DateUtils from '../commons/DateUtils'
import { EditIcon, DeleteIcon } from '../commons/commonStyles'
import { MessageType, addMessage } from '../commons/MessageUtils'
import BasicListScreen from '../commons/components/BasicListScreen'
import RoundButton from '../commons/components/RoundButton'
import BasicPage from '../commons/components/BasicPage'
import { PromiseWrapper } from '../commons/PromiseUtils'
import DeletionAlertModalComponent from '../productUsage/DeletionAlertModalComponent'

export const PendingOrderListScreen = () => {
    const [pendingOrders, setPendingOrders] = useState([])
    const [loadingPendingOrders, setLoadingPendingOrders] = useState(true)
    const [globalFilter, setGlobalFilter] = useState('')

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [orderToDelete, setOrderToDelete] = useState({})

    const [isSendConfirmVisible, setIsSendConfirmVisible] = useState(false)
    const [selectedOrders, setSelectedOrders] = useState([])
    const [orderToSend, setOrderToSend] = useState(null)
    const [isSendingOrders, setIsSendingOrders] = useState(false)

    useEffect(() => {
        getPendingOrders()
    }, [])

    const getPendingOrders = async () => {
        PromiseWrapper({
            promise: PendingOrderAPI.searchPendingMillOrders(),
            onFulfilled: ({ data }) => {
                setPendingOrders(data)
            },
            callback: () => {
                setLoadingPendingOrders(false)
            },
        })
    }

    const bodyFormatDate = pendingOrders => {
        return DateUtils.dateFormat(pendingOrders.requestDate)
    }

    const bodyNameColumn = pendingOrders => {
        return (
            <ClientNameColumn>{pendingOrders.farm.owner.name}</ClientNameColumn>
        )
    }

    const bodyActionsColumn = order => {
        return (
            <ActionsContent>
                <Link
                    to={{
                        pathname: `/pedidos/editar/${order._id}`,
                        state: { isPendingOrder: true },
                    }}
                >
                    <EditIcon data-testid="edit-icon" />
                </Link>

                <DeleteIcon
                    data-testid="delete-icon"
                    onClick={() => handleDeleteIcon(order)}
                />

                <GreenSendIcon
                    data-testid="send-icon"
                    onClick={() => {
                        if (!isSendingOrders) {
                            setOrderToSend(order)
                            setIsSendConfirmVisible(true)
                        }
                    }}
                />
            </ActionsContent>
        )
    }

    const handleDeleteIcon = selectedOrder => {
        setShowDeleteConfirm(true)
        setOrderToDelete(selectedOrder)
    }

    const handleDebtorClient = rowData => {
        return (
            <DebtorClientIndicator
                debtor={rowData.farm.debtor}
                tooltip={true}
            />
        )
    }

    const deleteOrder = async order => {
        PromiseWrapper({
            promise: PendingOrderAPI.deletePendingOrder(order._id),
            onFulfilled: () => {
                addMessage(MessageType.SUCCESS, 'Pedido deletado com sucesso!')
            },
            onRejected: () => {
                addMessage(MessageType.ERROR, 'Erro ao tentar exluir o pedido.')
            },
            callback: () => {
                setShowDeleteConfirm(false)
                getPendingOrders()
            },
        })
    }

    const sendOrders = async () => {
        setIsSendingOrders(true)
        let orders
        if (!orderToSend) {
            orders = selectedOrders.map(o => o._id)
        } else {
            orders = [orderToSend._id]
        }
        PromiseWrapper({
            promise: PendingOrderAPI.sendOrders(orders),
            onFulfilled: () => {
                setSelectedOrders([])
                addMessage(
                    MessageType.SUCCESS,
                    'Pedido(s) enviado(s) para a situação: Em Análise'
                )
            },
            onRejected: () => {
                addMessage(MessageType.ERROR, 'Erro ao enviar pedidos.')
            },
            callback: () => {
                setIsSendingOrders(false)
                getPendingOrders()
            },
        })
    }

    const renderSendOrdersButton = () => {
        return (
            <SendButton
                onClick={() => {
                    setOrderToSend(null)
                    setIsSendConfirmVisible(true)
                }}
                loading={isSendingOrders}
                disabled={selectedOrders.length === 0}
            >
                <SendButtonContent>
                    <SendIcon />
                    ENVIAR PEDIDO(S)
                </SendButtonContent>
            </SendButton>
        )
    }

    const renderDeletionText = () => {
        return (
            <Subtitle>
                Tem certeza de que deseja excluir o pedido selecionado da lista
                de pedidos em aberto?
            </Subtitle>
        )
    }

    return (
        <BasicPage title={'Lista de Pedidos via STC'}>
            <BasicListScreen
                searchValue={globalFilter}
                searchPlaceholder={
                    'Pesquise por pedido ou outras informações da venda'
                }
                searchOnChange={e => {
                    setGlobalFilter(e.target.value)
                    setSelectedOrders([])
                }}
                linkTo={'/pedidos/novo'}
                buttonText={'Novo Pedido'}
                buttonWithAddIcon={true}
                renderAnotherButton={renderSendOrdersButton()}
            >
                <Table
                    value={pendingOrders}
                    loading={loadingPendingOrders}
                    globalFilter={globalFilter}
                    selection={selectedOrders}
                    onSelectionChange={e => setSelectedOrders(e.value)}
                    dataKey="_id"
                    totalRecordsText="{totalRecords} Pedidos Encontrados"
                >
                    <Column selectionMode="multiple" style={littleColumn} />
                    <Column
                        header={'Data de Criação'}
                        field={'requestDate'}
                        body={bodyFormatDate}
                        sortable
                        style={centerAlignColumn}
                    />
                    <Column
                        body={rowData => handleDebtorClient(rowData)}
                        style={littleColumn}
                    />
                    <Column
                        header={'Nome do Cliente'}
                        field={'farm.owner.name'}
                        body={bodyNameColumn}
                        sortable
                        style={startAlignColumn}
                    />
                    <Column
                        header={'Fazenda'}
                        field={'farm.fancyName'}
                        sortable
                        style={startAlignColumn}
                    />
                    <Column
                        header={'Carteira/Rota'}
                        field={'category.name'}
                        sortable
                        style={centerAlignColumn}
                    />
                    <Column
                        header={'Situação'}
                        body={() => 'Em Aberto'}
                        style={centerAlignColumn}
                    />
                    <Column body={bodyActionsColumn} style={actionsColumn} />
                </Table>
            </BasicListScreen>

            <DeletionAlertModalComponent
                onClose={() => {
                    setShowDeleteConfirm(false)
                }}
                isVisible={showDeleteConfirm}
                title="Deseja excluir este pedido?"
                onConfirm={() => {
                    deleteOrder(orderToDelete)
                }}
                renderDeletionText={renderDeletionText}
            />

            <Confirm
                onClose={() => setIsSendConfirmVisible(false)}
                visible={isSendConfirmVisible}
                title="Deseja enviar este(s) pedido(s)?"
                actionButtonText="Enviar"
                actionButtonFunction={() => {
                    setIsSendConfirmVisible(false)
                    sendOrders()
                }}
            >
                {
                    'Tem certeza de que deseja enviar o(s) pedido(s) para a fábrica? Essa ação não poderá ser desfeita.'
                }
            </Confirm>
        </BasicPage>
    )
}

const centerAlignColumn = {
    textAlign: 'center',
}

const startAlignColumn = {
    textAlign: 'start',
}

const littleColumn = {
    textAlign: 'center',
    width: '6%',
}

const actionsColumn = {
    textAlign: 'center',
    width: '10%',
}

const ClientNameColumn = styled.span`
    color: ${Colors.brownishGrey};
`

const ActionsContent = styled.div`
    display: grid;
    grid-template-columns: repeat(3, min-content);
    column-gap: 8px;
`

const GreenSendIcon = styled(MdSend)`
    cursor: pointer;
    font-size: 24px;
    color: ${Colors.grass};
`
const SendButtonContent = styled.div`
    display: flex;
    align-items: center;
`

const SendIcon = styled(MdSend)`
    font-size: 15px;
    margin-right: 8px;
`

const SendButton = styled(RoundButton)`
    margin-right: 16px;
`
const Subtitle = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #6c6e6f;
`

export default PendingOrderListScreen
