import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { toast, ToastContainer as Toast } from 'react-toastify'

import InputWithMessage from '../commons/components/InputWithMessage'
import { ProgressSpinner } from 'primereact/progressspinner'
import * as usuarioAPI from './UserAPI'
import { keyStorage, createUserPreferences } from '../commons/LocalStorage'
import RoundButton from '../commons/components/RoundButton'
import farmTellMillsLogoPath from '../assets/logo/FarmTellMills.png'
import sideImagePath from '../assets/login/imagem-lateral@2x.png'
import { addMessageLogin } from '../commons/MessageUtils'
import { useMillContext } from '../context/MillContext'
import Checkbox from '../commons/components/Checkbox'
import {
    analyticsEvent,
    analyticsSetUserId,
    analyticsSetUserProperties,
    EVENT_KEYS,
} from '../commons/AnalyticsUtils'
import { VERSION } from '../version'

export function LoginScreen() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [authenticating, setAuthenticating] = useState(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    const isEnterButtonDisabled = isInvalidLoginPassword(username, password)
    const history = useHistory()
    const { loadMill } = useMillContext()

    return (
        <Container
            data-testid="container"
            onKeyPress={event =>
                enterWithKeyboard(
                    event.key,
                    username,
                    password,
                    history,
                    setAuthenticating,
                    loadMill
                )
            }
        >
            <LoginContainer altura={window.innerHeight}>
                <Logo src={farmTellMillsLogoPath} />

                <LabelInput>USUÁRIO</LabelInput>
                <Input
                    type="text"
                    placeholder="Insira Usuário"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />

                <SpaceBetween></SpaceBetween>

                <LabelInput>SENHA</LabelInput>
                <Input
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Insira Senha"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />

                <PasswordVisibilityContainer>
                    <Checkbox
                        onChange={e => setIsPasswordVisible(e.checked)}
                        checked={isPasswordVisible}
                        data_testid="show-password-checkbox"
                    />
                    <Text>Exibir Senha</Text>
                </PasswordVisibilityContainer>

                <EnterButton
                    disabled={isEnterButtonDisabled}
                    onClick={() =>
                        signIn(
                            username,
                            password,
                            history,
                            setAuthenticating,
                            loadMill
                        )
                    }
                >
                    {authenticating ? (
                        <Loading strokeWidth="5" />
                    ) : (
                        <ButtonText>ENTRAR</ButtonText>
                    )}
                </EnterButton>

                <ToastContainer
                    containerId={'LoginScreen'}
                    enableMultiContainer
                    pauseOnFocusLoss={true}
                    autoClose={2000}
                    position={toast.POSITION.BOTTOM_LEFT}
                    hideProgressBar={true}
                    closeButton={false}
                />
            </LoginContainer>

            <ContainerSideImage>
                <SideImage src={sideImagePath} />
            </ContainerSideImage>
        </Container>
    )
}

const enterWithKeyboard = (
    keyCode,
    username,
    password,
    history,
    setAuthenticating,
    loadMill
) => {
    if (keyCode === 'Enter' && !isInvalidLoginPassword(username, password)) {
        signIn(username, password, history, setAuthenticating, loadMill)
    }
}

const isInvalidLoginPassword = (username, password) => {
    return (
        !username || username.length === 0 || !password || password.length === 0
    )
}

const signIn = async (
    username,
    password,
    history,
    setAuthenticating,
    loadMill
) => {
    setAuthenticating(true)
    try {
        const response = await usuarioAPI.signIn(username, password)

        if (response.accessToken) {
            localStorage.setItem(keyStorage.userToken, JSON.stringify(response))
            createUserPreferences()
            await loadMill(response.accessToken.payload.id)
            analyticsEvent(EVENT_KEYS.login)
            analyticsSetUserId(username)
            analyticsSetUserProperties({
                login: response.login,
                name: response.name,
                organizationId: response.accessToken.payload.id,
                millId: response.mill.id,
                millName: response.mill.name,
                appVersion: VERSION,
            })
            history.push('/home/gestao-pedidos')
        } else {
            addMessageLogin('Usuário e/ou Senha Incorretos.')
            setAuthenticating(false)
        }
    } catch (error) {
        console.error(error.message)
        addMessageLogin(
            'Houve um problema com o login, verifique suas credenciais!'
        )
        setAuthenticating(false)
    }
}

const Container = styled.div`
    display: flex;
    width: 100%;
    background-color: #ffffff;
`

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10vh;
    width: 50%;

    .Toastify__toast-container {
        position: inherit;
        margin-top: 3vh;
        height: 5vh;
    }
`

const Logo = styled.img`
    width: 200px;
    height: auto;
    margin: 40px;
`

const LabelInput = styled.p`
    width: 20.64vw;
    height: 10px;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.09px;
    color: #666666;
`

const SpaceBetween = styled.p``

const Input = styled(InputWithMessage)`
    width: 20.64vw;
`

const EnterButton = styled(RoundButton)`
    margin-top: 8vh !important;
    width: 20.64vw;
    height: 48px;
    border-radius: 47.5px !important;
    background: #e57509;
    border-color: #e57509;
    justify-content: center;
    align-items: center;
    display: flex;
`

const ButtonText = styled.p`
    font-weight: bold;
    letter-spacing: 1px;
`

const ContainerSideImage = styled.div`
    width: 50%;
    height: 100vh;
`

const SideImage = styled.img`
    width: 100%;
    height: 100vh;
`

const ToastContainer = styled(Toast)`
    .Toastify__toast--default {
        background: #333333;
        border-radius: 4.5vh;
        text-align: center;
        color: #ffffff;
        min-height: 8vh;
    }
`
const Loading = styled(ProgressSpinner)`
    width: 40px;
    height: 40px;

    @keyframes p-progress-spinner-color {
        100%,
        0% {
            stroke: #b15717;
        }
    }
`

const PasswordVisibilityContainer = styled.div`
    display: flex;
    gap: 5px;
    margin-top: 10px;
    width: 20.64vw;
`

const Text = styled.p`
    margin: 0px;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.09px;
    color: #666666;
`

export default LoginScreen
