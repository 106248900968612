import React, { useState } from 'react'
import styled from 'styled-components'
import InputWithMessage from './InputWithMessage'
import Colors from '../Colors'

/**
 * @param {string} name - nome do elemento
 * @param {string} value - valor do elemento
 * @param {string} placeholder - texto a ser motrado quando o elemento não tem valor
 * @param {event} onChange - chamado quando o valor é alterado
 * @param {number} total - numero de elementos na lista
 * @param {number} selected - numero de elementos selecionados na lista
 */

export const SearchInputWithCounter = ({
    name,
    value,
    placeholder,
    onChange,
    total,
    selected,
}) => {
    const [containerOnFocus, setContainerOnFocus] = useState(false)

    return (
        <Container
            data-testid="container-search-input"
            focus={containerOnFocus}
            onFocus={() => setContainerOnFocus(true)}
            onBlur={() => setContainerOnFocus(false)}
        >
            <Input
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={e => onChange(e)}
            />
            <Counter>
                {selected}/{total}
            </Counter>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #dadada;
    border-radius: 4px;
    height: 40px;
    box-shadow: ${props => (props.focus ? '0 0 0 0.2em #e4e9ec' : '')};
    transition: box-shadow 0.2s;
    background-color: ${Colors.white};

    input:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }
`

const Input = styled(InputWithMessage).attrs(() => ({
    type: 'text',
}))`
    width: 400px;
    border: none !important;
    height: 38px;
`

const Counter = styled.div`
    isplay: flex;
    flex-grow: 0;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    margin-right: 8px;
    color: #8e8e93;
}
`

export default SearchInputWithCounter
