import React from 'react'
import styled from 'styled-components'

import { getPriceString } from '../../price/PriceUtils'
import FragmentOrderIcon from '../../assets/customIcons/FragmentOrderIcon'
import Colors from '../../commons/Colors'
import BasicRoundButton from '../../commons/components/RoundButton'
import BasicInputWithMessage from '../../commons/components/InputWithMessage'
import StatusChipComponent from '../../commons/components/StatusChipComponent'
import {
    formatsDecimalNumber,
    formatDecimalPlace,
} from '../../commons/NumberUtils'
import {
    getMeasurementUnitText,
    isDecimalUnitType,
} from '../../commons/MeasurementUnitUtils'

export default function OriginalOrderInfoComponent({
    originalOrder,
    createFragment,
    orderItems,
    setOrderItems,
}) {
    const handleInputOnChange = (value, itemId) => {
        const regex = /^\d*(\.\d+)?$/g
        const isValid = regex.test(value)

        if (isValid) {
            setOrderItems(currentItems => {
                const orderItemToUpdate = currentItems.find(
                    item => item.id === itemId
                )

                orderItemToUpdate.fragmentQuantity = isDecimalUnitType(
                    orderItemToUpdate.measurementUnit
                )
                    ? formatsDecimalNumber(value)
                    : value.replace(/\D/g, '')

                const updatedItems = [
                    ...currentItems.filter(item => item.id !== itemId),
                    orderItemToUpdate,
                ].sort((prev, next) => prev.id.localeCompare(next.id))

                return updatedItems
            })
        }
    }

    const renderOrderItems = () => {
        return orderItems.map(orderItem => (
            <TableRow>
                <TableCell>
                    <ProductNameItemText>
                        {orderItem.productName}
                    </ProductNameItemText>
                </TableCell>
                <TableCell className="total-amount">
                    <TableItemText>
                        {formatDecimalPlace(orderItem.totalQuantity)}
                    </TableItemText>
                </TableCell>
                <TableCell
                    className="remaining-quantity"
                    hasAnyRemainingQuantity={orderItems.some(orderItem =>
                        Boolean(orderItem.remainingQuantity)
                    )}
                >
                    <RemainingQuantityText
                        hasRemainingQuantity={Boolean(
                            orderItem.remainingQuantity
                        )}
                    >
                        {formatDecimalPlace(orderItem.remainingQuantity)}
                    </RemainingQuantityText>
                </TableCell>
                <TableCell>
                    <TableItemText>
                        {getMeasurementUnitText(orderItem.measurementUnit)}
                    </TableItemText>
                </TableCell>
                <TableCell>
                    <TableItemText>
                        {getPriceString(orderItem.price)}
                    </TableItemText>
                </TableCell>
                <TableCell className="fragment-quantity">
                    <InputContainer>
                        <InputWithMessage
                            placeholder="0"
                            invalidMessage="Valor excede saldo"
                            id={orderItem.id}
                            value={orderItem.fragmentQuantity}
                            onChange={e =>
                                handleInputOnChange(
                                    e.target.value,
                                    orderItem.id
                                )
                            }
                            type="number"
                            isInvalid={
                                orderItem.fragmentQuantity >
                                orderItem.remainingQuantity
                            }
                            readOnly={
                                parseFloat(orderItem.remainingQuantity) === 0
                            }
                        />
                    </InputContainer>
                </TableCell>
            </TableRow>
        ))
    }

    const isFragmentButtonDisabled = () => {
        return (
            orderItems.some(
                item => item.fragmentQuantity > item.remainingQuantity
            ) ||
            orderItems.every(
                item =>
                    item.fragmentQuantity === '' ||
                    parseFloat(item.fragmentQuantity) === 0
            )
        )
    }

    const handleOnClickCreate = () => {
        const orderItemsToCreate = orderItems.filter(
            item =>
                item.fragmentQuantity !== '' &&
                parseFloat(item.fragmentQuantity) > 0
        )

        createFragment(orderItemsToCreate)
    }

    return (
        <OriginalOrderContainer>
            <OriginalOrderHeader>
                <CustomerNameText>{originalOrder.seller.name}</CustomerNameText>
                <OriginalOrderText>
                    Pedido Nº {originalOrder.orderNumber}
                </OriginalOrderText>
                <StatusChipComponent
                    status={originalOrder.statusText}
                    left={'15px'}
                />
            </OriginalOrderHeader>
            <OrderTable>
                <TableHeader>
                    <TableCell>
                        <TableTitleItemText>Produto</TableTitleItemText>
                    </TableCell>
                    <TableCell className="total-amount">
                        <TableTitleItemText>Qtid. TOTAL</TableTitleItemText>
                    </TableCell>
                    <TableCell
                        className="remaining-quantity"
                        hasAnyRemainingQuantity={orderItems.some(orderItem =>
                            Boolean(orderItem.remainingQuantity)
                        )}
                    >
                        <TableTitleItemText>
                            Qtid. DISPONÍVEL (Saldo)
                        </TableTitleItemText>
                    </TableCell>
                    <TableCell>
                        <TableTitleItemText>Unidade</TableTitleItemText>
                    </TableCell>
                    <TableCell>
                        <TableTitleItemText>Preço (R$)</TableTitleItemText>
                    </TableCell>
                    <TableCell className="fragment-quantity">
                        <TableTitleItemText>Qtid. REMESSA</TableTitleItemText>
                    </TableCell>
                </TableHeader>
                {renderOrderItems(orderItems)}
                <FooterContainer>
                    <TableFooter>
                        {(Boolean(originalOrder.freightPrice) ||
                            Boolean(originalOrder.chargesPrice)) && (
                            <>
                                <FooterCell>
                                    <TableItemText>Subtotal</TableItemText>
                                </FooterCell>
                                <FooterCell />
                                <FooterCell />
                                <FooterCell />
                                <FooterCell className="footer-price">
                                    <TableItemText>
                                        {getPriceString(originalOrder.subtotal)}
                                    </TableItemText>
                                </FooterCell>
                            </>
                        )}
                        {Boolean(originalOrder.freightPrice) && (
                            <>
                                <FooterCell>
                                    <TableItemText>Frete</TableItemText>
                                </FooterCell>
                                <FooterCell />
                                <FooterCell />
                                <FooterCell />
                                <FooterCell className="footer-price">
                                    <TableItemText>
                                        {getPriceString(
                                            originalOrder.freightPrice
                                        )}
                                    </TableItemText>
                                </FooterCell>
                            </>
                        )}
                        {Boolean(originalOrder.chargesPrice) && (
                            <>
                                <FooterCell>
                                    <TableItemText>Encargos</TableItemText>
                                </FooterCell>
                                <FooterCell />
                                <FooterCell />
                                <FooterCell />
                                <FooterCell className="footer-price">
                                    <TableItemText>
                                        {getPriceString(
                                            originalOrder.chargesPrice
                                        )}
                                    </TableItemText>
                                </FooterCell>
                            </>
                        )}
                        <FooterCell>
                            <TableTitleItemText>
                                TOTAL DO PEDIDO
                            </TableTitleItemText>
                        </FooterCell>
                        <FooterCell />
                        <FooterCell />
                        <FooterCell />
                        <FooterCell className="total-price footer-price">
                            <TotalPriceText>
                                {getPriceString(originalOrder.price)}
                            </TotalPriceText>
                        </FooterCell>
                    </TableFooter>
                    <FooterCell />
                </FooterContainer>
            </OrderTable>
            <FragmentOrderButtonContainer>
                <FragmentOrderButton
                    disabled={isFragmentButtonDisabled()}
                    onClick={handleOnClickCreate}
                >
                    <FragmentOrderIcon
                        color={Colors.white}
                        backgroundColor={null}
                    />
                    <FragmentOrderButtonText>
                        FRACIONAR PEDIDO
                    </FragmentOrderButtonText>
                </FragmentOrderButton>
            </FragmentOrderButtonContainer>
        </OriginalOrderContainer>
    )
}

const OriginalOrderContainer = styled.div`
    flex-grow: 0;
    padding: 9px 20px 28px 20px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: ${Colors.lightBlueGreyFour};

    display: flex;
    flex-direction: column;
    align-items: stretch;
`

const OriginalOrderHeader = styled.div`
    padding: 0px 5px 0px 5px;
    display: flex;
    justify-content: space-between;
`

const OriginalOrderText = styled.p`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: ${Colors.deepSeaBlue};
`

const CustomerNameText = styled(OriginalOrderText)`
    color: ${Colors.black};
`

const OrderTable = styled.div`
    display: grid;
`

const TableRow = styled.div`
    display: grid;
    column-gap: 10px;
    grid-template-columns: 25% 10% 16% repeat(3, 14%);
    padding: 0px 11px;
`

const TableHeader = styled(TableRow)`
    background-color: ${Colors.white};
    border-radius: 5px;
`

const TableCell = styled.div`
    height: 68px;
    padding: 10px;
    display: flex;
    align-items: center;

    &.total-amount {
        background-color: rgba(229, 117, 9, 0.1);

        div:first-of-type {
            background-color: rgba(230, 72, 88, 0.1);
        }
    }

    &.remaining-quantity {
        background-color: ${props =>
            props.hasAnyRemainingQuantity ? 'rgba(230, 72, 88, 0.1)' : 'none'};
    }

    &.fragment-quantity {
        background-color: rgba(0, 81, 134, 0.1);
    }
`

const FooterCell = styled(TableCell)`
    height: 38px;

    &.total-price {
        background-color: rgba(0, 81, 134, 0.1);
        border-radius: 4px;
    }

    &.footer-price {
        justify-content: flex-end;
    }
`

const TableItemText = styled.p`
    margin: 0;
    font-family: Roboto;
    color: ${Colors.brownishGrey};
    font-size: 12px;
`

const ProductNameItemText = styled(TableItemText)`
    font-weight: bold;
    color: ${Colors.black};
`

const TableTitleItemText = styled(TableItemText)`
    font-weight: bold;
    line-height: 1.6;
`

const RemainingQuantityText = styled(TableItemText)`
    font-weight: ${props => (props.hasRemainingQuantity ? 'bold' : 'normal')};
    color: ${props =>
        props.hasRemainingQuantity ? Colors.black : Colors.brownishGrey};
`

const TableFooter = styled(TableHeader)`
    grid-template-columns: 23% 14% 20% repeat(2, 18%);
    column-gap: 11px;
    padding: 2px 11px;
`

const FooterContainer = styled.div`
    display: grid;
    grid-template-columns: 83% 17%;
`

const TotalPriceText = styled(TableItemText)`
    font-weight: bold;
    color: ${Colors.black};
`

const FragmentOrderButtonContainer = styled.div`
    margin-top: 28px;
    display: flex;
    justify-content: center;
`

const FragmentOrderButton = styled(BasicRoundButton)`
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
`

const FragmentOrderButtonText = styled.p`
    color: ${Colors.white};
`

const InputWithMessage = styled(BasicInputWithMessage)`
    width: 58px;
    font-size: 12px;
`

const InputContainer = styled.div`
    position: relative;
    top: 7px;

    .error-message {
        margin-left: -8px;
        font-size: 10px;
        padding-top: 3px;
        width: max-content;
    }
`
