import React from 'react'
import styled from 'styled-components'

import Colors from '../commons/Colors'
import * as DateUtils from '../commons/DateUtils'

import Checkbox from '../commons/components/Checkbox'
import { useDashboardContext } from '../context/DashboardContext'
import { STATUS } from '../commons/Status'
import FragmentOrderButton from '../commons/components/FragmentOrderButton'
import OrderCardIndicatorsComponent from './OrderCardIndicatorsComponent'

const OrderCard = props => {
    const { handleSelectedOrders, setSelectedOrderCard } = useDashboardContext()

    const [selected, setSelected] = React.useState(false)

    const isSelectable = () =>
        props?.laneId !== STATUS.EM_EXPEDICAO_TRANSITO &&
        props?.laneId !== STATUS.ENTREGUE &&
        !props.isOrderGroup &&
        !props.isOrdersDropdown

    const onSelectOrder = id => {
        if (!props.isOrdersDropdown) {
            setSelectedOrderCard(id)
        }
    }

    const isOutOfPricePolicyIndicatorRendering = order => {
        return order.isOutOfPricePolicy && order.status === 'EM_ANALISE'
    }

    if (!props.farm) return <></>

    return (
        <Container
            isOrderGroup={props.isOrderGroup}
            data-testid="card-container"
            onClick={() => onSelectOrder(props._id)}
            isUrgent={props.isUrgent}
        >
            <Card>
                <SpaceBetween>
                    <Owner>{props.farm.owner.name}</Owner>
                </SpaceBetween>

                <ActionsContainer>
                    {Boolean(handleSelectedOrders) && isSelectable() && (
                        <Checkbox
                            data_testid="selected-order-card"
                            onChange={e => {
                                setSelected(selected => {
                                    handleSelectedOrders(props, !selected)
                                    return !selected
                                })
                                e.originalEvent.stopPropagation()
                            }}
                            name="selected"
                            checked={selected}
                        />
                    )}
                    <IndicatorsContainer>
                        <OrderCardIndicatorsComponent
                            isDebtorClient={props.farm.debtor}
                            isOutOfPricePolicy={isOutOfPricePolicyIndicatorRendering(
                                props
                            )}
                        />
                    </IndicatorsContainer>

                    {!props.isOrderGroup && (
                        <FragmentOrderButton
                            fragmentedOrder={{
                                orderId: props.id,
                                fragmentedOrderId: null,
                            }}
                        />
                    )}
                </ActionsContainer>

                <SpaceBetween>
                    <OrderNumber>Pedido Nº {props.orderNumber}</OrderNumber>
                </SpaceBetween>

                <BasicText>{props.farm.fancyName}</BasicText>

                <SpaceBetween>
                    <BasicText>{props.category?.name}</BasicText>
                </SpaceBetween>

                <BasicText>
                    Feito por <strong>{props.seller.name}</strong> em{' '}
                    <strong>{DateUtils.dateFormat(props.requestDate)}</strong>
                </BasicText>
                <Delivery>
                    <DeliveryLabel>Data de Entrega:</DeliveryLabel>
                    <DeliveryDate>
                        {DateUtils.dateFormat(props.deliveryDate)}
                    </DeliveryDate>
                </Delivery>
            </Card>
        </Container>
    )
}

const Container = styled.div`
    margin-top: ${props => (props.isOrderGroup ? 0 : '10px')};
    box-shadow: ${props =>
        props.isOrderGroup ? 'none' : `0 1px 4px 0 ${Colors.lightBlueGreyTwo}`};
    border-width: ${props => (props.isUrgent ? '0 0 0 8px' : 'none')};
    border-color: ${props => (props.isUrgent ? Colors.darkRed : Colors.white)};
    border-style: ${props => (props.isUrgent ? 'solid' : 'none')};
    background-color: white;
    border-radius: 4px;
    position: relative;
`

const Card = styled.div`
    width: 260px;
    padding: 10px;
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 8px;
    position: absolute;
    right: 0;
    top: 0;
`

const BasicText = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.21px;
    color: ${Colors.blueGrey};
    white-space: break-spaces;
`

const Owner = styled(BasicText)`
    font-size: 16px;
    font-weight: bold;
    color: ${Colors.black};
    max-width: 220px;
`

const SpaceBetween = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const OrderNumber = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.black};
`

const IndicatorsContainer = styled.div`
    display: flex;
    flex-direction: 'row';
    justify-content: flex-end;
`

const Delivery = styled.div`
    display: flex;
`

const DeliveryLabel = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.black};
    margin-right: 5px;
`

const DeliveryDate = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.strawberry};
`

export default OrderCard
