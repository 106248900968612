import { getAPIKey, getBaseURL, getToken } from '../environment'

const baseURL = `${getBaseURL()}/orders/paymentForms`

export const getAllPaymentForms = () => {
    return fetch(`${baseURL}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': getAPIKey(),
        },
    })
        .then(async response => {
            const paymentForms = await response.json()
            return {
                data: paymentForms.data.map(paymentForm =>
                    mapToSydleStructure(paymentForm)
                ),
            }
        })
        .catch(error => {
            console.error('Erro: ' + JSON.stringify(error))
            throw error
        })
}

export function mapToSydleStructure(entity) {
    const _id = entity.id
    const legacyCode = entity.legacyCode
    const paymentForm = entity.name
    const dailyPercentage = entity.dailyPercentage || 0
    const mill = { _id: entity.millSydleId }
    const paymentDeadlines = entity.paymentDeadlines.map(deadline => ({
        _id: deadline.id,
        legacyCode: deadline.legacyCode,
        name: deadline.name,
        increasePercentage: deadline.increasePercentage || 0,
    }))
    return {
        _id,
        legacyCode,
        paymentForm,
        dailyPercentage,
        mill,
        paymentDeadlines,
    }
}
