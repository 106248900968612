import React from 'react'

const MagnifierGlass = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="98"
            height="98"
            viewBox="0 0 98 98"
            fill="none"
        >
            <path
                d="M76.773 45.226H67.76a22.534 22.534 0 0 0-22.534-22.533v-9.014a31.547 31.547 0 0 1 31.547 31.547zM91.601 98 73.573 79.973a45.067 45.067 0 1 1 6.4-6.4L98 91.602 91.6 98zM45.225 81.28a36.053 36.053 0 1 0 0-72.107 36.053 36.053 0 0 0 0 72.107z"
                fill="currentColor"
            />
        </svg>
    )
}

export default MagnifierGlass
