import React from 'react'
import styled from 'styled-components'
import { MdDelete, MdDone } from 'react-icons/md'

import * as NumberUtils from '../../commons/NumberUtils'
import DatePickerCalendar from '../../commons/components/DatePickerCalendar'
import Colors from '../../commons/Colors'
import Loading from '../../commons/components/Loading'
import InputWithMessage from '../../commons/components/InputWithMessage'
import BasicInputTextarea from '../../commons/components/InputTextarea'
import BasicDropdown from '../../commons/components/Dropdown'
import RoundButton from '../../commons/components/RoundButton'
import BasicCheckbox from '../../commons/components/Checkbox'
import { Label } from '../../commons/commonStyles'
import BasicFormScreen from '../../commons/components/BasicFormScreen'
import BasicPage from '../../commons/components/BasicPage'
import Tooltip from '../../commons/components/Tooltip'
import InfoIcon from '../../assets/customIcons/InfoIcon'

export const ClientForm = ({
    isEdition,
    isLoadingSellers,
    isUseTributes,
    form,
    formCategories,
    sellerDropdownOptions,
    sendingToAPI,
    invalidFields,
    setInvalidFields,
    handleClientChange,
    handleTributesChange,
    handleOwnerChange,
    handleCategoryChange,
    handleOnPressSave,
    getFreigthTable,
    handleSelectedCategories,
    selectedCategory,
    selectedCategories,
    handleDeleteAssociated,
}) => {
    return (
        <BasicPage
            title="Novo Cliente"
            isEdition={isEdition}
            editionTitle="Editar Cliente"
        >
            <BasicFormScreen linkTo="/clientes">
                {Object.keys(form).length === 0 ? (
                    <Loading data-testid="form-loading" />
                ) : (
                    <Container>
                        <form autoComplete="off">
                            <MainInfoContainer>
                                <FormField>
                                    <Label htmlFor="legacyCode">
                                        * Código do Cliente
                                    </Label>
                                    <InputWithMessage
                                        id="legacyCode"
                                        name="legacyCode"
                                        value={form.legacyCode}
                                        onChange={e => handleClientChange(e)}
                                        isInvalid={invalidFields.legacyCode}
                                        onFocus={() =>
                                            setInvalidFields(
                                                currentInvalidFields => ({
                                                    ...currentInvalidFields,
                                                    legacyCode: false,
                                                })
                                            )
                                        }
                                    />
                                </FormField>

                                <FormField>
                                    <Label htmlFor="name">
                                        * Nome do Cliente / Proprietário
                                    </Label>
                                    <InputWithMessage
                                        id="name"
                                        name="name"
                                        value={form.owner.name}
                                        onChange={e => handleOwnerChange(e)}
                                        isInvalid={invalidFields.ownerName}
                                        onFocus={() =>
                                            setInvalidFields(
                                                currentInvalidFields => ({
                                                    ...currentInvalidFields,
                                                    ownerName: false,
                                                })
                                            )
                                        }
                                    />
                                </FormField>

                                <FormField>
                                    <Label htmlFor="birthDate">
                                        Data de Nascimento
                                    </Label>
                                    <DatePickerCalendar
                                        id="birthDate"
                                        name="birthDate"
                                        monthNavigator
                                        yearNavigator
                                        yearRange={`1900:${new Date().getFullYear()}`}
                                        value={form.owner.birthDate}
                                        onChange={e => handleOwnerChange(e)}
                                    />
                                </FormField>

                                <FormField>
                                    <Label htmlFor="phone">Telefone</Label>
                                    <InputWithMessage
                                        id="phone"
                                        type="tel"
                                        name="phone"
                                        value={form.owner.phone}
                                        onChange={e => handleOwnerChange(e)}
                                    />
                                </FormField>
                            </MainInfoContainer>

                            <FormPanel>
                                <DetailsContainer>
                                    <FormField>
                                        <Label htmlFor="tradeName">
                                            * Nome Fantasia da Fazenda
                                        </Label>
                                        <InputWithMessage
                                            id="tradeName"
                                            name="tradeName"
                                            value={form.tradeName}
                                            onChange={e =>
                                                handleClientChange(e)
                                            }
                                            isInvalid={invalidFields.tradeName}
                                            onFocus={() =>
                                                setInvalidFields(
                                                    currentInvalidFields => ({
                                                        ...currentInvalidFields,
                                                        tradeName: false,
                                                    })
                                                )
                                            }
                                        />
                                    </FormField>

                                    <FormField>
                                        <Label htmlFor="companyName">
                                            Razão Social
                                        </Label>
                                        <InputWithMessage
                                            id="companyName"
                                            name="companyName"
                                            value={form.companyName}
                                            onChange={e =>
                                                handleClientChange(e)
                                            }
                                        />
                                    </FormField>
                                </DetailsContainer>

                                <AddressContainer>
                                    <FormField>
                                        <Label htmlFor="address">
                                            Endereço / Rodovia
                                        </Label>
                                        <InputWithMessage
                                            id="address"
                                            name="address"
                                            value={form.address}
                                            onChange={e =>
                                                handleClientChange(e)
                                            }
                                        />
                                    </FormField>

                                    <FormField>
                                        <Label htmlFor="addressReference">
                                            Observação de endereço
                                        </Label>
                                        <InputWithMessage
                                            id="addressReference"
                                            name="addressReference"
                                            value={form.addressReference}
                                            onChange={e =>
                                                handleClientChange(e)
                                            }
                                        />
                                    </FormField>
                                </AddressContainer>

                                <FormField
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '0.665fr',
                                    }}
                                >
                                    <Label htmlFor="note">
                                        Observação do cliente
                                    </Label>
                                    <InputTextarea
                                        id="note"
                                        name="note"
                                        value={form.note || ''}
                                        onChange={e => handleClientChange(e)}
                                    />
                                </FormField>
                            </FormPanel>

                            <AdditionalInfoContainer
                                isUseTributes={isUseTributes()}
                            >
                                <FormField>
                                    <Label htmlFor="stockSafetyDays">
                                        Estoque segurança (Em dias)
                                    </Label>
                                    <InputWithMessage
                                        id="stockSafetyDays"
                                        name="stockSafetyDays"
                                        type="text"
                                        min={0}
                                        value={form.stockSafetyDays}
                                        onChange={e => {
                                            const event = {
                                                target: {
                                                    name: e.target.name,
                                                    value: NumberUtils.isIntegerPositive(
                                                        e.target.value
                                                    )
                                                        ? parseInt(
                                                              e.target.value
                                                          )
                                                        : '',
                                                },
                                            }

                                            handleClientChange(event)
                                        }}
                                    />
                                </FormField>

                                <FormField>
                                    <Label
                                        htmlFor="freightTableId"
                                        id="freightTableIdLabel"
                                    >
                                        REGIÃO DE FRETE
                                    </Label>
                                    <Dropdown
                                        id="freightTableId"
                                        name="freightTableId"
                                        placeholder="Selecione uma região"
                                        value={form.freightTableId}
                                        options={getFreigthTable()}
                                        onChange={e => handleClientChange(e)}
                                        filter
                                        showClear
                                        filterBy="label"
                                        ariaLabelledBy="freightTableIdLabel"
                                    />
                                </FormField>

                                {isUseTributes() && (
                                    <FormField>
                                        <Label htmlFor="freightTributes">
                                            TRIBUTOS DE FRETE
                                        </Label>
                                        <InputWithMessage
                                            id="freightTributes"
                                            name="freightTributes"
                                            type="number"
                                            value={
                                                form.tributes?.freightTributes
                                            }
                                            onChange={e => {
                                                const event = {
                                                    target: {
                                                        name: e.target.name,
                                                        value: NumberUtils.isValid(
                                                            e.target.value
                                                        )
                                                            ? parseFloat(
                                                                  e.target.value
                                                              )
                                                            : '',
                                                    },
                                                }

                                                handleTributesChange(event)
                                            }}
                                        />
                                    </FormField>
                                )}

                                {isUseTributes() && (
                                    <FormField>
                                        <Label htmlFor="icms">ICMS</Label>
                                        <InputWithMessage
                                            id="icms"
                                            name="icms"
                                            type="number"
                                            value={form.tributes?.icms}
                                            onChange={e => {
                                                const event = {
                                                    target: {
                                                        name: e.target.name,
                                                        value: NumberUtils.isValid(
                                                            e.target.value
                                                        )
                                                            ? parseFloat(
                                                                  e.target.value
                                                              )
                                                            : '',
                                                    },
                                                }

                                                handleTributesChange(event)
                                            }}
                                        />
                                    </FormField>
                                )}

                                <FormCheckboxField>
                                    <Checkbox
                                        inputId="debtor"
                                        name="debtor"
                                        checked={form.debtor}
                                        onChange={e =>
                                            handleClientChange(e, 'checked')
                                        }
                                        borderColor={Colors.strawberry}
                                        checkedColor={Colors.strawberry}
                                    />
                                    <DebtorLabel htmlFor="debtor">
                                        CLIENTE INADIMPLENTE
                                    </DebtorLabel>
                                </FormCheckboxField>
                            </AdditionalInfoContainer>

                            <CategoriesHeader>
                                <CategoryHeaderText>
                                    ASSOCIAR CARTEIRAS E VENDEDORES
                                </CategoryHeaderText>
                                <InfoIconContainer>
                                    <Tooltip label="É possível vincular a este cliente vendedores e carteiras que já estejam cadastrados na base.">
                                        <InfoIcon />
                                    </Tooltip>
                                </InfoIconContainer>
                            </CategoriesHeader>

                            <CategoriesContent>
                                <CategoryHeader>
                                    <Label
                                        htmlFor="portfolios"
                                        id="portfoliosLabel"
                                    >
                                        NOME DA CARTEIRA
                                    </Label>
                                    <Label>Vendedor(es) associado(s)</Label>
                                </CategoryHeader>

                                {isLoadingSellers ? (
                                    <Loading data-testid="sellers-loading" />
                                ) : (
                                    <CategoryContent>
                                        <div data-testid="categories-dropdown">
                                            <Dropdown
                                                ariaLabelledBy="portfoliosLabel"
                                                name="portfolios"
                                                id="portfolios"
                                                placeholder="Selecione uma carteira"
                                                options={formCategories}
                                                value={selectedCategory}
                                                onChange={handleCategoryChange}
                                                filter
                                                showClear
                                                filterBy="label"
                                            />
                                        </div>

                                        <AssociatedUsers>
                                            {sellerDropdownOptions.map(
                                                seller => (
                                                    <AssociatedUserCard
                                                        isSingleSeller={
                                                            sellerDropdownOptions.length ===
                                                            1
                                                        }
                                                        key={seller.value}
                                                    >
                                                        <AssociatedUserText>
                                                            {seller.label}
                                                        </AssociatedUserText>
                                                    </AssociatedUserCard>
                                                )
                                            )}
                                        </AssociatedUsers>

                                        <AddPortfolioButton
                                            dataTestId="add-portfolio-button"
                                            onClick={handleSelectedCategories}
                                            disabled={!selectedCategory}
                                            type="button"
                                        >
                                            Adicionar
                                        </AddPortfolioButton>
                                    </CategoryContent>
                                )}
                            </CategoriesContent>

                            {selectedCategories.length > 0 && (
                                <CategoriesContent>
                                    <CategoryHeader>
                                        <Label htmlFor="portfolios">
                                            NOME DA CARTEIRA
                                        </Label>
                                        <Label>Vendedor(es) associado(s)</Label>
                                    </CategoryHeader>

                                    {selectedCategories.map(
                                        (category, index) => (
                                            <CategoryContent key={category.id}>
                                                <AssociatedCategoryCard>
                                                    <AssociatedCategoryText>
                                                        {category.name}
                                                    </AssociatedCategoryText>
                                                </AssociatedCategoryCard>
                                                <AssociatedUsers>
                                                    {category.users.map(
                                                        seller => (
                                                            <AssociatedUserCard
                                                                isSingleSeller={
                                                                    category
                                                                        .users
                                                                        .length ===
                                                                    1
                                                                }
                                                                key={seller.id}
                                                            >
                                                                <AssociatedUserText>
                                                                    {
                                                                        seller.name
                                                                    }
                                                                </AssociatedUserText>
                                                            </AssociatedUserCard>
                                                        )
                                                    )}
                                                </AssociatedUsers>
                                                <DeleteIconContainer>
                                                    <DeleteAssociated
                                                        data-testid="delete-category-button"
                                                        onClick={e =>
                                                            handleDeleteAssociated(
                                                                e,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </DeleteAssociated>
                                                </DeleteIconContainer>
                                            </CategoryContent>
                                        )
                                    )}
                                </CategoriesContent>
                            )}
                            <FooterActions>
                                <RoundButton
                                    loading={sendingToAPI}
                                    onClick={handleOnPressSave}
                                    disabled={sendingToAPI || isLoadingSellers}
                                >
                                    SALVAR <MdDone />
                                </RoundButton>
                            </FooterActions>
                        </form>
                    </Container>
                )}
            </BasicFormScreen>
        </BasicPage>
    )
}

const Container = styled.div``

const FormPanel = styled.div`
    padding: 2.8vh 1.3vw;
    margin-bottom: 2.2vh;

    border-radius: 3px;
    border: solid 1px ${Colors.lightBlueGreyFour};
    background-color: ${Colors.white};
`

const DebtorLabel = styled(Label)`
    color: ${Colors.strawberry};
`

const FormField = styled.div`
    display: flex;
    flex-direction: column;
`

const MainInfoContainer = styled(FormPanel)`
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr;
    grid-gap: 1vw;
`

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1vw;
    margin-bottom: 2.2vh;
`

const AddressContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1vw;

    margin-bottom: 2.2vh;
`

const AdditionalInfoContainer = styled(FormPanel)`
    display: grid;
    grid-template-columns: ${({ isUseTributes }) =>
        isUseTributes ? '23% 23% 15% 15% 20%' : '1fr 1fr 1fr'};
    grid-gap: 1vw;
`

const InputTextarea = styled(BasicInputTextarea)`
    width: 100%;
`

const Checkbox = styled(BasicCheckbox)`
    margin-right: 0.5vw !important;
`

const FormCheckboxField = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
`

const CategoriesHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.6vh 1.7vw;
    background-color: ${Colors.veryLightPink};
`

const CategoriesContent = styled(FormPanel)`
    padding-bottom: 0;
    &.attached {
        margin-top: -2.4vh;
        border-top-left-radius: 0;
        border-top-right-radius: 0
        margin-bottom: 2.2vh;
    }
`

const CategoryHeaderText = styled.span`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: ${Colors.brownishGrey};
    margin-right: 5px;
`

const CategoryHeader = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.1fr;
`

const InfoIconContainer = styled.div`
    padding-bottom: 1.5px;
`

const CategoryContent = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.1fr;
    margin-bottom: 2.8vh;
`

const Dropdown = styled(BasicDropdown)`
    width: 95%;
`

const AssociatedCategoryCard = styled.div`
    display: flex;
    align-items: center;
    width: 95%;
    height: 40px;
    border-radius: 3px;
    border: solid 1px ${Colors.lightBlueGreyFour};
`

const AssociatedCategoryText = styled.span`
    font-family: Roboto;
    font-size: 14px;
    padding-left: 8px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.12px;
    color: ${Colors.black};
`

const AssociatedUsers = styled.div`
    width: 95%;
    min-height: 40px;
    max-height: 15vh;
    border-radius: 3px;
    border: solid 1px ${Colors.lightBlueGreyFour};
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const AssociatedUserCard = styled.div`
    width: fit-content;
    height: 32px;
    border-radius: 4px;
    border: solid 1px ${Colors.lightBlueGreyFour};
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    margin: 5px 0 5px 5px;
    box-shadow: 0 2px 2px ${Colors.lightBlueGreyFour};
`

const AssociatedUserText = styled(AssociatedCategoryText)`
    padding: 0 8px;
    font-weight: 500;
`

const DeleteAssociatedWH = 30
const DeleteAssociated = styled.a`
    border: 1px solid ${Colors.orangeTwo};
    padding: 4px;
    border-radius: 50%;
    width: ${DeleteAssociatedWH}px;
    height: ${DeleteAssociatedWH}px;
    font-size: ${DeleteAssociatedWH}px;
    display: flex;
    align-content: space-around;
    justify-content: space-around;
`

const DeleteIconContainer = styled.div`
    width: 106px;
    display: flex;
    justify-content: flex-start;
`

const DeleteIcon = styled(MdDelete)`
    cursor: pointer;
    color: ${Colors.orangeTwo};
    align-self: center;
`

const AddPortfolioButton = styled(RoundButton)`
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
    color: ${Colors.white};
    display: flex;
    justify-content: center;
    width: 106px;
`

const FooterActions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

export default ClientForm
