import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { ToastContainer as Toast, toast } from 'react-toastify'

import Routes from './Routes'
import Colors from './commons/Colors'
import Confirm from './commons/components/Confirm'
import ConfirmService from './commons/ConfirmService'

const App = () => {
    const [showConfirm, setShowConfirm] = useState(false)
    const [confirmOptions, setConfirmOptions] = useState({
        title: '',
        actionButtonText: '',
        onClose: () => {},
        actionButtonFunction: () => {},
        renderMessage: () => {},
    })

    useEffect(() => {
        const id = ConfirmService.subscribe(addConfirm)
        return () => ConfirmService.unsubscribe(id)
    }, [])

    const addConfirm = options => {
        setConfirmOptions(options)
        setShowConfirm(true)
    }

    return (
        <>
            <Routes />
            <ToastContainer
                enableMultiContainer
                containerId={'App'}
                autoClose={4000}
                hideProgressBar
                closeButton={false}
                position={toast.POSITION.BOTTOM_CENTER}
            />

            <Confirm
                visible={showConfirm}
                title={confirmOptions.title}
                actionButtonText={confirmOptions.actionButtonText}
                onClose={() => {
                    setShowConfirm(false)
                    confirmOptions.onClose && confirmOptions.onClose()
                }}
                actionButtonFunction={() => {
                    setShowConfirm(false)
                    confirmOptions.actionButtonFunction()
                }}
            >
                {confirmOptions.renderMessage()}
            </Confirm>
        </>
    )
}

const ToastContainer = styled(Toast)`
    height: auto;
    width: 100%;
    left: 0;
    margin-left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .Toastify__toast {
        font-family: Roboto;
        border-radius: 32px;
        font-size: 14px;
        line-height: 1.71;

        padding: 2vh 1vw;
        height: fit-content;
        min-height: 30.8px;
        width: 94%;
    }

    .Toastify__toast--success {
        background-color: ${Colors.softGreen};
        color: ${Colors.white};
        min-height: 30.8px;
        align-items: center;
    }

    .Toastify__toast--error {
        background-color: ${Colors.strawberry};
        color: ${Colors.white};
        min-height: 4.22vh;
        align-items: center;
    }

    .Toastify__toast-body {
        margin: 0;
    }
`

export default App
