import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import * as PromiseUtils from '../../commons/PromiseUtils'
import * as ClientAPI from '../../client/ClientAPI'
import * as PortfolioAPI from '../ClientPortfolioAPI'
import * as UserAPI from '../../login/UserAPI'
import PortfolioFormView from './PortfolioFormView'

import { useMillContext } from '../../context/MillContext'
import { MessageType, addMessage } from '../../commons/MessageUtils'
import {
    buildCreatePortfolioRequest,
    buildFindDuplicateRelationRequest,
    buildUpdatePortfolioRequest,
    getNonAssociatedClients,
    getPortfolioClients,
    getPortfolioUsers,
    getUsersDropdownOptions,
    sortByKey,
} from '../PortfolioUtils'
import { debounce } from '../../commons/components/Debounce'

export const PortfolioFormContainer = () => {
    const { id } = useParams()
    const { mill } = useMillContext()
    const [isEdition, setIsEdition] = useState(false)

    const [sellers, setSellers] = useState([])
    const [portfolio, setPortfolio] = useState({})

    const isLoadingSellers = sellers.length === 0
    const isLoadingClients = false // clients.length === 0 Ajustar loading de clientes
    const [isValidatingClient, setIsValidatingClient] = useState(false)

    const [selectedUsers, setSelectedUsers] = useState({})
    const [associatedClients, setAssociatedClients] = useState({})
    const [nonAssociatedClients, setNonAssociatedClients] = useState({})
    const [usersDropdownOptions, setUsersDropdownOptions] = useState([])
    const [form, setForm] = useState({
        name: '',
        users: [],
        clients: [],
    })
    const [sendingToAPI, setSendingToAPI] = useState(false)
    const [invalidFields, setInvalidFields] = useState({
        name: false,
        users: false,
    })

    const [associatedClientsFilter, setAssociatedClientsFilter] = useState('')
    const [clientPagination, setClientPagination] = useState({
        page: 1,
        size: 50,
    })
    const [nonAssociatedClientsTotal, setNonAssociatedClientsTotal] =
        useState(0)
    const [isLoadingNonAssociate, setIsLoadingNonAssociate] = useState(false)

    const history = useHistory()

    useEffect(() => {
        const fetchPortfolio = async id => {
            setIsEdition(true)
            const response = await PortfolioAPI.searchPortfoliosByFilter(
                null,
                null,
                null,
                { id }
            )
            if (response && response.data) {
                const portfolio = response.data[0]
                setPortfolio(portfolio)
                setForm(form => ({ ...form, name: portfolio.name }))
                return portfolio
            }
        }

        const fetchSellers = async () => {
            const response = await UserAPI.getAllSellers(mill.gcpId)

            if (
                response &&
                response.sellers &&
                response.sellers instanceof Array
            ) {
                setSellers(response.sellers)
                if (!id) {
                    const options = getUsersDropdownOptions(response.sellers)
                    setUsersDropdownOptions(options)
                }
            }
        }

        const loadScreenData = async id => {
            let portfolio
            if (id) portfolio = await fetchPortfolio(id)

            if (!Object.keys(sellers).length) await fetchSellers()

            await fetchClients({
                portfolio,
                size: clientPagination.size,
                page: clientPagination.page,
            })
            setIsLoadingNonAssociate(false)
        }

        loadScreenData(id)
    }, [mill, id, clientPagination.size, clientPagination.page])

    useEffect(() => {
        setForm(form => ({
            ...form,
            users: selectedUsers,
            clients: associatedClients,
        }))
    }, [selectedUsers, associatedClients])

    useEffect(() => {
        if (sellers.length && Object.keys(portfolio).length) {
            const { inPortfolioUsers, dropdownOptions } = getPortfolioUsers(
                portfolio,
                sellers
            )
            setSelectedUsers(inPortfolioUsers)
            setUsersDropdownOptions(dropdownOptions)
        }
    }, [sellers, portfolio])

    const fetchClients = async ({ portfolio, size, page, filter }) => {
        const response = await ClientAPI.getAllClients(size, page, filter)
        if (response && response.data.length) {
            const clients = response.data
            const inPortfolioClients = getPortfolioClients(portfolio)
            setAssociatedClients(associatedClients => ({
                ...associatedClients,
                ...inPortfolioClients,
            }))
            const nonAssociatedClientsOption = getNonAssociatedClients(
                clients,
                inPortfolioClients
            )
            setNonAssociatedClientsTotal(
                Object.keys(nonAssociatedClientsOption).length
            )
            setNonAssociatedClients(nonAssociatedClientsOption)
            if (!Object.keys(nonAssociatedClientsOption).length) nextPage()
        }
    }

    const nextPage = () => {
        setIsLoadingNonAssociate(true)
        setClientPagination(pagination => ({
            page: pagination.page + 1,
            size: pagination.size,
        }))
    }

    const handleChange = (e, valueField = 'value') => {
        setForm({
            ...form,
            [e.target.name]: e.target[valueField],
        })
    }

    const handleClientsAssociate = async ({
        toInsert,
        toRemove,
        operation,
    }) => {
        const newToInsert = { ...toInsert }
        const newToRemove = {}

        Object.entries(toRemove).forEach(
            ([id, { isSelected, tradeName, companyName, inPortfolio }]) => {
                if (isSelected) {
                    newToInsert[id] = {
                        isSelected: !isSelected,
                        tradeName,
                        companyName,
                        inPortfolio,
                    }
                    return
                }
                newToRemove[id] = {
                    isSelected,
                    tradeName,
                    inPortfolio,
                    companyName,
                }
            }
        )
        const sortedRemove = sortByKey(newToRemove, 'tradeName')
        const sortedInsert = sortByKey(newToInsert, 'tradeName')

        if (operation === 'associate') {
            const request = buildFindDuplicateRelationRequest(
                sortedInsert,
                mill,
                form,
                portfolio
            )
            setIsValidatingClient(true)
            return await PromiseUtils.PromiseWrapper({
                promise: PortfolioAPI.findDuplicatedRelation(request),
                onFulfilled: () => {
                    setNonAssociatedClients(sortedRemove)
                    setAssociatedClients(sortedInsert)
                    setIsValidatingClient(false)
                    if (Object.keys(sortedRemove).length === 0) nextPage()
                },
                onRejected: err => {
                    handleRejection(err)
                    setNonAssociatedClients(nonAssociatedClients)
                    setAssociatedClients(associatedClients)
                    setIsValidatingClient(false)
                },
            })
        }

        if (operation) {
            setNonAssociatedClients(sortedInsert)
            setAssociatedClients(sortedRemove)
        }
    }

    const handleSelectClient = ({ clientId, association, card }) => {
        const cardToUpdate = {
            ...association,
            [clientId]: {
                isSelected: !association[clientId].isSelected,
                tradeName: association[clientId].tradeName,
                companyName: association[clientId].companyName,
                inPortfolio: association[clientId].inPortfolio,
            },
        }

        card === 'nonAssociatedClientCard'
            ? setNonAssociatedClients(cardToUpdate)
            : setAssociatedClients(cardToUpdate)
    }

    const handleUserChange = target => {
        const selectedOption = usersDropdownOptions.find(
            option => option.value === target.value
        )

        const { value, label, inPortfolio } = selectedOption

        setUsersDropdownOptions(prev =>
            prev.filter(option => option.value !== value)
        )
        setSelectedUsers(prev => ({
            ...prev,
            [value]: { name: label, inPortfolio },
        }))
    }

    const deleteSelectedUser = id => {
        const newSelectedUsers = { ...selectedUsers }
        setUsersDropdownOptions(prev => [
            ...prev,
            {
                label: newSelectedUsers[id].name,
                value: id,
                inPortfolio: newSelectedUsers[id].inPortfolio,
            },
        ])

        setSelectedUsers(prev => {
            delete prev[id]
            return prev
        })
    }

    const handleOnPressSave = () => {
        const { isInvalid, invalidFields } = existsInvalidFields(form)
        if (isInvalid) {
            const errorMessage = `Erro, existem campos obrigatórios sem preenchimento ou preenchidos de forma incorreta: ${invalidFields.join(
                ', '
            )}.`
            addMessage(MessageType.ERROR, errorMessage)
            setInvalidFields({
                name: !Boolean(form.name),
                users: !Boolean(Object.keys(form.users).length !== 0),
            })
        } else {
            onSave(form)
        }
    }

    const handleRejection = err => {
        console.error(err)
        const errorMessages = err.message
            .split('\n')
            .filter(errorMessage => errorMessage)

        for (let errorMessage in errorMessages) {
            addMessage(MessageType.ERROR, errorMessages[errorMessage])
        }
        setSendingToAPI(false)
    }

    const handleSuccess = () => {
        setSendingToAPI(false)
        history.push('/carteiras')
        addMessage(MessageType.SUCCESS, 'Carteira salva com sucesso!')
    }

    const onSave = async form => {
        setSendingToAPI(true)
        if (!id) {
            const request = buildCreatePortfolioRequest(form, mill)
            await PromiseUtils.PromiseWrapper({
                promise: PortfolioAPI.createPortfolio(request),
                onRejected: err => handleRejection(err),
                onFulfilled: handleSuccess,
            })
            return
        }

        const request = buildUpdatePortfolioRequest(portfolio, form)
        await PromiseUtils.PromiseWrapper({
            promise: PortfolioAPI.updatePortfolio(request),
            onRejected: err => handleRejection(err),
            onFulfilled: handleSuccess,
        })
    }

    const existsInvalidFields = form => {
        const invalidFields = []
        if (!form.name) {
            invalidFields.push('Nome da Carteira')
        }

        if (Object.keys(form.users).length === 0) {
            invalidFields.push('Vendedores Associados')
        }

        return {
            isInvalid: invalidFields.length > 0,
            invalidFields,
        }
    }

    const handleFilter = ({ target, association }) => {
        const filter = target.value
        if (association === 'nonAssociatedClient') {
            debounce(() => {
                fetchClients({
                    portfolio,
                    size: clientPagination.size,
                    page: 1,
                    filter,
                })
            })
            return
        }
        setAssociatedClientsFilter(filter)
    }

    return (
        <PortfolioFormView
            isEdition={isEdition}
            isLoadingSellers={isLoadingSellers}
            isLoadingClients={isLoadingClients}
            form={form}
            usersDropdownOptions={usersDropdownOptions}
            sendingToAPI={sendingToAPI}
            invalidFields={invalidFields}
            selectedUsers={selectedUsers}
            setInvalidFields={setInvalidFields}
            handleChange={handleChange}
            handleUserChange={handleUserChange}
            handleSelectClient={handleSelectClient}
            handleClientsAssociate={handleClientsAssociate}
            handleOnPressSave={handleOnPressSave}
            deleteSelectedUser={deleteSelectedUser}
            nonAssociatedClients={nonAssociatedClients}
            associatedClients={associatedClients}
            handleFilter={handleFilter}
            associatedClientsFilter={associatedClientsFilter}
            nonAssociatedClientsTotal={nonAssociatedClientsTotal}
            isValidatingClient={isValidatingClient}
            isLoadingNonAssociate={isLoadingNonAssociate}
        />
    )
}

export default PortfolioFormContainer
