import { getTrackSaleKeys } from '../environment'

export const getNpsParams = (mill, millUser) => {
    const trackSaleKeys = getTrackSaleKeys()

    const npsParams = {
        key: trackSaleKeys.trackSaleKey,
        campaign: trackSaleKeys.trackSaleCampaign,
        name: millUser.name,
        identification: millUser.name,
        email: millUser.email,
        tags: {
            'DECISOR-INFLUENCIADOR': 'USUARIO',
            'CODIGO DO CLIENTE': mill ? mill.legacyCode : '',
            'NOME CLIENTE': mill ? mill.corporateName : '',
            'TIPO DE SOFTWARE': 'PRODAP FABS',
            UF: mill ? mill.uf : '',
        },
        salute: `${millUser.name}, tudo bem?`,
        thanks: `Obrigado, ${millUser.name}`,
    }

    return npsParams
}

export function injectTrackSaleWidget(mill, millUser) {
    const id = 'tracksale-js'

    if (document.getElementById(id)) {
        return
    }

    const params = getNpsParams(mill, millUser)

    const script = document.createElement('script')
    script.id = id
    script.src = 'https://cdn.tracksale.co/tracksale-js/tracksale.js'
    script.type = 'text/javascript'
    script.async = true
    script.onload = script.onreadystatechange = script => {
        var state = script.readyState
        if (state && state !== 'complete' && state !== 'loaded') {
            return
        }
        try {
            var trackSale = new window.Tracksale()
            trackSale.init(params)
        } catch (ex) {}
    }
    document.getElementsByTagName('head')[0].appendChild(script)
}
