import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import CellInput from './CellInput'
import { Column } from 'primereact/column'
import RadioButton from '../commons/components/RadioButton'
import { MdClose, MdDone } from 'react-icons/md'

import * as ProductAPI from './ProductAPI'
import Colors from '../commons/Colors'
import {
    calculatesMinAndMaxPrice,
    recalculateFields,
    validateField,
    unitPricingType,
    validateExistsInvalidFields,
    sortProducts,
    mountPriceChangeRequest,
} from './PriceUtils'
import RoundButton from '../commons/components/RoundButton'
import Table from '../commons/components/Table'
import SearchInput from '../commons/components/SearchInput'
import { MessageType, addMessage } from '../commons/MessageUtils'
import { debounce } from '../commons/components/Debounce'
import BasicPage from '../commons/components/BasicPage'
import * as PromiseUtils from '../commons/PromiseUtils'

export const PriceChangeScreen = () => {
    const [products, setProducts] = useState([])
    const [alteredProducts, setAlteredProducts] = useState([])
    const [unitPricingSelected, setUnitPricingSelected] = useState(
        unitPricingType['reais']
    )
    const [filter, setFilter] = useState('')
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
    const [initialMessageVisible, setInitialMessageVisible] = useState(true)

    const [isSavingProducts, setIsSavingProducts] = useState(false)

    const [isFetchingProducts, setIsFetchingProducts] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [rows] = useState(10)
    const [first, setFirst] = useState(0)

    useEffect(() => {
        fetchData(rows, first)
        // eslint-disable-next-line
    }, [])

    async function fetchData(size, from, term) {
        if (!isFetchingProducts) {
            setIsFetchingProducts(true)
        }

        await PromiseUtils.PromiseWrapper({
            promise: ProductAPI.searchByLoggedUser(size, from, term),
            onFulfilled: ({ data, total }) => {
                const productsWithMinMaxPrice = data.map(product => {
                    return calculatesMinAndMaxPrice(product)
                })

                setTotalRecords(total)
                setProducts(sortProducts(productsWithMinMaxPrice))
            },
            onRejected: () => {
                addMessage(
                    MessageType.ERROR,
                    'Erro ao alterar o preço dos produtos.'
                )
            },
            callback: () => setIsFetchingProducts(false),
        })
    }

    useEffect(() => {
        validateExistsInvalidFields(products, setIsSaveButtonDisabled)
    }, [products])

    const bodyPriceColumn = (product, columnRef) => {
        return (
            <ContainerCellInput>
                R$ {editorColumn(columnRef, product.price, 'price')}
            </ContainerCellInput>
        )
    }

    const minPriceColumn = (product, columnRef) => {
        if (unitPricingSelected === unitPricingType['reais'])
            return (
                <ContainerCellInput>
                    R$ {editorColumn(columnRef, product.minPrice, 'minPrice')}
                </ContainerCellInput>
            )
        else if (unitPricingSelected === unitPricingType['percentage'])
            return (
                <ContainerCellInput>
                    {editorColumn(
                        columnRef,
                        product.discountMaxPercentage,
                        'discountMaxPercentage'
                    )}
                    %
                </ContainerCellInput>
            )
    }

    const maxPriceColumn = (product, columnRef) => {
        if (unitPricingSelected === unitPricingType['reais'])
            return (
                <ContainerCellInput>
                    R$ {editorColumn(columnRef, product.maxPrice, 'maxPrice')}
                </ContainerCellInput>
            )
        else if (unitPricingSelected === unitPricingType['percentage'])
            return (
                <ContainerCellInput>
                    {editorColumn(
                        columnRef,
                        product.increaseMaxPercentage,
                        'increaseMaxPercentage'
                    )}
                    %
                </ContainerCellInput>
            )
    }

    const editorColumn = (columnRef, value, field) => {
        const product = columnRef.rowData
        return (
            <CellInput
                value={value}
                invalidMessage={'Campo Inválido'}
                isInvalid={
                    product[`${field}IsInvalid`] === undefined
                        ? false
                        : product[`${field}IsInvalid`]
                }
                onBlur={newValue => {
                    changeProductValue(columnRef, newValue, field)
                }}
            />
        )
    }

    const includeProductOnAlteredProducts = product => {
        setAlteredProducts(alteredProducts => {
            const newAlteredProducts = alteredProducts.filter(
                p => p.id !== product.id
            )

            return [...newAlteredProducts, product]
        })
    }

    const changeProductValue = (columnRef, newValue, field) => {
        const newProducts = products.map(product => {
            if (product.id === columnRef.rowData.id) {
                product = {
                    ...product,
                    [field]: newValue,
                }
                if (validateField(field, product)) {
                    product = recalculateFields(field, product)
                    includeProductOnAlteredProducts(product)
                }
            }
            return product
        })
        setProducts(newProducts)
    }

    const saveProductsPrice = async () => {
        setIsSavingProducts(true)
        await PromiseUtils.PromiseWrapper({
            promise: ProductAPI.saveAll(
                mountPriceChangeRequest(alteredProducts)
            ),
            onFulfilled: () => {
                setAlteredProducts([])
                addMessage(MessageType.SUCCESS, 'Preços alterados com sucesso.')
            },
            onRejected: () => {
                addMessage(
                    MessageType.ERROR,
                    'Erro ao alterar o preço dos produtos.'
                )
            },
            callback: () => setIsSavingProducts(false),
        })
    }

    const handlePagination = event => {
        setFirst(event.first)
        fetchData(event.rows, event.page + 1, filter)
    }

    const handleFilter = e => {
        const filter = e.target.value
        setFilter(filter)
        debounce(() => {
            setFirst(0)
            fetchData(rows, 0, filter)
        })
    }

    return (
        <BasicPage title={'Atualização de Preços'}>
            <ContainerScreen>
                <Message isVisible={initialMessageVisible}>
                    <CloseIcon
                        onClick={() => setInitialMessageVisible(false)}
                    />
                    <MessageTitle>
                        Bem vindo(a) à Atualização de Preços!
                    </MessageTitle>
                    <MessageContent>
                        Acompanhe a evolução de preços dos produtos no último
                        mês, atualize informações junto à vendedores em tempo
                        real, gerencie a data de início de vigência de novos
                        valores e mantenha um histórico de preços ao longo do
                        tempo.
                    </MessageContent>
                </Message>

                <ActionsContainer>
                    <SearchInput
                        value={filter}
                        onChange={handleFilter}
                        placeholder="Pesquise por Código, Produto ou Valores"
                    />

                    <UnitPricingContainer>
                        <Label>*(Unidade de precificação (Mín e Max):</Label>

                        <RadioButton
                            id="unitPricingReaisButton"
                            name="unitPricing"
                            value={unitPricingType['reais']}
                            checked={
                                unitPricingSelected === unitPricingType['reais']
                            }
                            onChange={e => {
                                if (unitPricingSelected !== e.value) {
                                    setUnitPricingSelected(e.value)
                                }
                            }}
                            ariaLabelledBy="unitPricingReaisButtonLabel"
                        />
                        <UnitPricingLabel
                            id="unitPricingReaisButtonLabel"
                            htmlFor="unitPricingReaisButton"
                            marginRight={1.3}
                        >
                            Valor em R$
                        </UnitPricingLabel>

                        <RadioButton
                            id="unitPricingPercentageButton"
                            name="unitPricing"
                            value={unitPricingType['percentage']}
                            checked={
                                unitPricingSelected ===
                                unitPricingType['percentage']
                            }
                            onChange={e => {
                                if (unitPricingSelected !== e.value) {
                                    setUnitPricingSelected(e.value)
                                }
                            }}
                            ariaLabelledBy={'unitPricingPercentageLabel'}
                        />
                        <UnitPricingLabel
                            id={'unitPricingPercentageLabel'}
                            htmlFor="unitPricingPercentageButton"
                        >
                            Valor em % (Inserir valor base)
                        </UnitPricingLabel>
                    </UnitPricingContainer>
                </ActionsContainer>

                <Table
                    value={products}
                    loading={isFetchingProducts}
                    onPage={handlePagination}
                    rows={rows}
                    first={first}
                    totalRecords={totalRecords}
                    totalRecordsText="{totalRecords} Produtos Encontrados"
                >
                    <Column
                        header={'Código'}
                        field={'legacyCode'}
                        bodyStyle={{ textAlign: 'center' }}
                    />
                    <Column
                        header={'Produto'}
                        field={'name'}
                        style={nameColumn}
                        bodyStyle={{ textAlign: 'start' }}
                    />
                    <Column
                        header={'Valor BASE'}
                        field={'price'}
                        body={bodyPriceColumn}
                        bodyStyle={{ textAlign: 'center' }}
                    />
                    <Column
                        header={'*Valor Mínimo'}
                        body={minPriceColumn}
                        bodyStyle={{ textAlign: 'center' }}
                    />
                    <Column
                        header={'*Valor Máximo'}
                        body={maxPriceColumn}
                        bodyStyle={{ textAlign: 'center' }}
                    />
                </Table>

                <FooterActionsContainer>
                    <RoundButton
                        loading={isSavingProducts}
                        disabled={isSaveButtonDisabled}
                        onClick={saveProductsPrice}
                    >
                        * SALVAR <MdDone />
                    </RoundButton>
                    <SaveButtonInfo>
                        *Todos os vendedores terão o PREÇO atualizado em tempo
                        real
                    </SaveButtonInfo>
                </FooterActionsContainer>
            </ContainerScreen>
        </BasicPage>
    )
}

const ContainerScreen = styled.div`
    flex-direction: row;
    tbody tr td {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
`

const Message = styled.div`
    display: ${props => (props.isVisible ? 'flex' : 'none')};
    flex-direction: column;
    margin-top: 2.1vh;
    background-color: rgba(229, 117, 9, 0.15);
    padding: 10px;
    border-radius: 5px;
    font-family: Roboto;
    font-size: 13px;
    line-height: 1.71;
    letter-spacing: 0.13px;
    box-shadow: 0 1px 4px 0 #c7c7cc;
`

const MessageTitle = styled.p`
    margin: 0;
`

const MessageContent = styled.p`
    margin: 0;
    margin-top: 10px;
`

const CloseIcon = styled(MdClose)`
    align-self: flex-end;
    position: absolute;
    cursor: pointer;
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
`

const UnitPricingContainer = styled.div`
    display: flex;
`

const Label = styled.label`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    color: #666666;
    margin-right: 0.52vw;
`

const UnitPricingLabel = styled.label`
    opacity: 0.5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #333333;
    margin-right: ${props =>
        props.marginRight ? `${props.marginRight}vw` : 0};
`

const nameColumn = {
    color: Colors.orange,
    textAlign: 'start',
    width: '30%',
}

const ContainerCellInput = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    margin-top: 10px;
`

const FooterActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 2.2vh;
`

const SaveButtonInfo = styled.span`
    opacity: 0.5;
    font-family: Roboto;
    font-size: 13px;
    line-height: 1.85;
    color: #333333;
`

export default PriceChangeScreen
