import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MdAddCircleOutline, MdDone } from 'react-icons/md'
import { Link } from 'react-router-dom'

import Colors from '../commons/Colors'
import RoundButton from '../commons/components/RoundButton'
import Accordion from '../commons/components/Accordion'
import WarrantyLevelForm from './WarrantyLevelForm'
import { MessageType, addMessage } from '../commons/MessageUtils'
import * as NutrientAPI from './NutrientAPI'
import * as ProductAPI from '../price/ProductAPI'
import {
    NutrientClassification,
    filterWarrantyLevelsByClassification,
    formatWarrantyLevels,
    createEmptyWarrantyLevel,
} from './ProductUtils'

export const WarrantyLevelScreen = ({ product }) => {
    const warrantyLevels =
        product.label && product.label.warrantyLevels
            ? product.label.warrantyLevels
            : []

    const [loading, setLoading] = useState(false)

    const [general, setGeneral] = useState([])
    const [macroMinerals, setMacroMinerals] = useState([])
    const [microMinerals, setMicroMinerals] = useState([])
    const [vitamins, setVitamins] = useState([])
    const [additionsAndOther, setAdditionsAndOther] = useState([])

    const [allNutrients, setAllNutrients] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const result = await NutrientAPI.searchAll()
            const nutrientes = result.data.entities
            if (result && nutrientes) {
                setAllNutrients(nutrientes)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        setGeneral(
            filterWarrantyLevelsByClassification(
                warrantyLevels,
                NutrientClassification.GENERAL
            )
        )

        setMacroMinerals(
            filterWarrantyLevelsByClassification(
                warrantyLevels,
                NutrientClassification.MACRO_MINERALS
            )
        )

        setMicroMinerals(
            filterWarrantyLevelsByClassification(
                warrantyLevels,
                NutrientClassification.MICRO_MINERALS
            )
        )

        setVitamins(
            filterWarrantyLevelsByClassification(
                warrantyLevels,
                NutrientClassification.VITAMINS
            )
        )

        setAdditionsAndOther(
            filterWarrantyLevelsByClassification(
                warrantyLevels,
                NutrientClassification.ADDITIONS_AND_OTHER
            )
        )
        // eslint-disable-next-line
    }, [])

    const consolidatesAndSaves = async (
        general,
        macroNutrients,
        microNutrients,
        vitamins,
        additionsAndOther
    ) => {
        try {
            setLoading(true)
            const warrantyLevels = [
                ...formatWarrantyLevels(general, setGeneral),
                ...formatWarrantyLevels(macroNutrients, setMacroMinerals),
                ...formatWarrantyLevels(microNutrients, setMicroMinerals),
                ...formatWarrantyLevels(vitamins, setVitamins),
                ...formatWarrantyLevels(
                    additionsAndOther,
                    setAdditionsAndOther
                ),
            ]
            product.label = {
                ...product.label,
                warrantyLevels,
            }

            const request = {
                productId: product.id,
                labelId: product.label.id,
                warrantyLevels: warrantyLevels,
            }
            await ProductAPI.saveWarrantyLevels(request)
            setLoading(false)

            return addMessage(
                MessageType.SUCCESS,
                'Níveis de Garantia salvos com sucesso!'
            )
        } catch (err) {
            setLoading(false)
            console.error(err)
            addMessage(MessageType.ERROR, 'Erro ao salvar Níveis de Garantia.')
        }
    }

    return (
        <Container>
            <Title>Níveis de Garantia</Title>

            <TopElementsContainer>
                <AdditionalInfo>
                    Cadastre os Níveis de Garantia conforme as especificações
                    que constam no RÓTULO deste produto.
                </AdditionalInfo>
                <Link to={'/produtos'}>
                    <CancelButton>Cancelar</CancelButton>
                </Link>
            </TopElementsContainer>

            <InstructionsContainer>
                <AdditionalInfo>Clique no</AdditionalInfo>
                <AddIcon />
                <AdditionalInfo>
                    para associar os nutrientes presentes no Rótulo ao seu
                    respectivo grupo:
                </AdditionalInfo>
            </InstructionsContainer>

            <FormHeaderContainer>
                <NutrientFormHeader>Nutriente</NutrientFormHeader>
                <FormHeader>Quantidade</FormHeader>
                <FormHeader>Unidade</FormHeader>
            </FormHeaderContainer>

            <Accordion
                header="Geral"
                opened={general.length > 0}
                icon={<AddIcon />}
                onClickIcon={() =>
                    setGeneral([...general, createEmptyWarrantyLevel()])
                }
            >
                <WarrantyLevelForm
                    warrantyLevels={general}
                    setWarrantyLevels={setGeneral}
                    dropdownOptions={allNutrients.filter(
                        n =>
                            n.nutrientClassification.name ===
                            NutrientClassification.GENERAL
                    )}
                />
            </Accordion>

            <Accordion
                header="Macro Minerais"
                opened={macroMinerals.length > 0}
                icon={<AddIcon />}
                onClickIcon={() =>
                    setMacroMinerals([
                        ...macroMinerals,
                        createEmptyWarrantyLevel(),
                    ])
                }
            >
                <WarrantyLevelForm
                    warrantyLevels={macroMinerals}
                    setWarrantyLevels={setMacroMinerals}
                    dropdownOptions={allNutrients.filter(
                        n =>
                            n.nutrientClassification.name ===
                            NutrientClassification.MACRO_MINERALS
                    )}
                />
            </Accordion>

            <Accordion
                header="Micro Minerais"
                opened={microMinerals.length > 0}
                icon={<AddIcon />}
                onClickIcon={() =>
                    setMicroMinerals([
                        ...microMinerals,
                        createEmptyWarrantyLevel(),
                    ])
                }
            >
                <WarrantyLevelForm
                    warrantyLevels={microMinerals}
                    setWarrantyLevels={setMicroMinerals}
                    dropdownOptions={allNutrients.filter(
                        n =>
                            n.nutrientClassification.name ===
                            NutrientClassification.MICRO_MINERALS
                    )}
                />
            </Accordion>

            <Accordion
                header="Vitaminas"
                opened={vitamins.length > 0}
                icon={<AddIcon />}
                onClickIcon={() =>
                    setVitamins([...vitamins, createEmptyWarrantyLevel()])
                }
            >
                <WarrantyLevelForm
                    warrantyLevels={vitamins}
                    setWarrantyLevels={setVitamins}
                    dropdownOptions={allNutrients.filter(
                        n =>
                            n.nutrientClassification.name ===
                            NutrientClassification.VITAMINS
                    )}
                />
            </Accordion>

            <Accordion
                header="Aditivos / Outros"
                opened={additionsAndOther.length > 0}
                icon={<AddIcon />}
                onClickIcon={() =>
                    setAdditionsAndOther([
                        ...additionsAndOther,
                        createEmptyWarrantyLevel(),
                    ])
                }
            >
                <WarrantyLevelForm
                    warrantyLevels={additionsAndOther}
                    setWarrantyLevels={setAdditionsAndOther}
                    dropdownOptions={allNutrients.filter(
                        n =>
                            n.nutrientClassification.name ===
                            NutrientClassification.ADDITIONS_AND_OTHER
                    )}
                />
            </Accordion>

            <FooterActionsContainer>
                <RoundButton
                    loading={loading}
                    onClick={() =>
                        consolidatesAndSaves(
                            general,
                            macroMinerals,
                            microMinerals,
                            vitamins,
                            additionsAndOther
                        )
                    }
                >
                    SALVAR <MdDone />
                </RoundButton>
            </FooterActionsContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const Title = styled.span`
    font-family: Roboto;
    font-size: 20px;
    color: ${Colors.orange};
    padding-top: 15px;
`

const TopElementsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
`

const AdditionalInfo = styled.span`
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    color: ${Colors.brownishGrey};
`

const InstructionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3.14vh;
`

const AddIcon = styled(MdAddCircleOutline)`
    color: ${Colors.orange};
    font-size: 21px;
    margin: 0 3px;
`

const CancelButton = styled(RoundButton)`
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
    width: 106px;
    height: 32px;
`

const FormHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 60% 15% 15% 10%;

    background-color: ${Colors.veryLightPink};
    padding: 10px 0 10px 40px;
`

const NutrientFormHeader = styled.span`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: ${Colors.orange};
`

const FormHeader = styled.span`
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
`

const FooterActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-top: 2.16vh;
`

export default WarrantyLevelScreen
