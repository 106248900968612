import React from 'react'
import styled from 'styled-components'
import { Column } from 'primereact/column'
import { IoMdTrash, IoMdEye } from 'react-icons/io'

import Colors from '../../commons/Colors'
import bTable from '../../commons/components/Table'
import { dateFormat } from '../../commons/DateUtils'
import NewShipmentIcon from '../../assets/customIcons/NewShipmentIcon'
import BasicListScreen from '../../commons/components/BasicListScreen'
import OrderModal from '../../dashboard/OrderModal'
import CancelOrderModal from '../../order/CancelOrderModal'
import SimpleBillingModal from '../simpleBillingModal'
import { SIMPLE_BILLING_STATUS_TEXT } from '../../commons/Status'

export default function SimpleBillingView({
    isLoadingData,
    data,
    filterValue,
    handleOnChangeFilter,
    rows,
    currentIndex,
    totalRecords,
    handlePagination,
    selectedSimpleBilling,
    setSelectedSimpleBilling,
    isOrderDelivered,
    handleOnClickDelete,
    handleOnCloseCancelModal,
    onCancelOrder,
    simpleBillingToDelete,
    isCancelModalVisible,
    simpleBillingToManage,
    setSimpleBillingToManage,
}) {
    const renderActions = simpleBilling => {
        return (
            <ActionsContainer>
                <DeleteIcon
                    data-testid={'delete-icon'}
                    size={24}
                    disabled={isOrderDelivered(simpleBilling)}
                    onClick={() => handleOnClickDelete(simpleBilling)}
                />
                <ViewIcon
                    data-testid={'view-icon'}
                    size={24}
                    onClick={() => setSelectedSimpleBilling(simpleBilling._id)}
                />
            </ActionsContainer>
        )
    }

    const renderNewShipmentButton = simpleBilling => {
        return (
            <NewShipmentButton
                onClick={() =>
                    setSimpleBillingToManage({
                        orderId: simpleBilling._id,
                    })
                }
                data-testid="new-shipment-button"
            >
                <NewShipmentIcon />
            </NewShipmentButton>
        )
    }

    const renderStatusText = simpleBilling => {
        return (
            <StatusText>
                {isOrderDelivered(simpleBilling)
                    ? SIMPLE_BILLING_STATUS_TEXT.ENTREGUE
                    : SIMPLE_BILLING_STATUS_TEXT.PENDENTE}
            </StatusText>
        )
    }

    return (
        <Container>
            <BasicListScreen
                searchPlaceholder="Pesquise por Nº do Pedido, Cliente, Fazenda, Rota, Vendedor..."
                searchValue={filterValue}
                searchOnChange={handleOnChangeFilter}
                linkTo="/simples-faturamento/novo"
                buttonText="Novo Simples Faturamento"
                buttonWithAddIcon
            >
                <Table
                    value={data}
                    loading={isLoadingData}
                    rows={rows}
                    first={currentIndex}
                    totalRecords={totalRecords}
                    dataKey="_id"
                    totalRecordsText="{totalRecords} Simples Faturamentos Encontrados"
                    onPage={handlePagination}
                >
                    <Column
                        header={'Nº  S.Fat.'}
                        field={'orderNumber'}
                        style={{ ...basicColumnStyle, ...smallColumnStyle }}
                    />
                    <Column
                        header={'Nome do Cliente/ Prop.'}
                        field={'farm.owner.name'}
                        style={{ ...basicColumnStyle, ...ownerStyle }}
                    />
                    <Column
                        header={'Fazenda'}
                        field={'farm.fancyName'}
                        style={{ ...basicColumnStyle, ...farmNameStyle }}
                    />
                    <Column
                        header={'Carteira / Rota'}
                        field={'category.name'}
                        style={{ ...basicColumnStyle }}
                    />
                    <Column
                        header={'Vendedor'}
                        field={'seller.name'}
                        style={{ ...basicColumnStyle }}
                    />
                    <Column
                        header={'Data / Criação'}
                        field={'requestDate'}
                        body={order => dateFormat(order.requestDate)}
                        style={{ ...basicColumnStyle }}
                    />
                    {/* FIXME: statusText não representa o mesmo status do protótipo */}
                    <Column
                        header={'Status Remessa'}
                        field={'TODOshipmentstatus'}
                        style={{ ...basicColumnStyle, ...statusColumnStyle }}
                        body={renderStatusText}
                    />
                    <Column
                        header={'Lançar Remessa'}
                        style={{ ...basicColumnStyle, ...createShipmentStyle }}
                        body={renderNewShipmentButton}
                    />
                    <Column
                        header={''}
                        style={{ ...basicColumnStyle }}
                        body={renderActions}
                    />
                </Table>
            </BasicListScreen>

            {selectedSimpleBilling && (
                <OrderModal
                    selectedOrderId={selectedSimpleBilling}
                    clearSelectedOrderId={() => setSelectedSimpleBilling(null)}
                />
            )}

            {simpleBillingToManage && (
                <SimpleBillingModal
                    clearSelectedSimpleBillingId={() =>
                        setSimpleBillingToManage(null)
                    }
                    selectedSimpleBilling={simpleBillingToManage}
                    onCancelOrder={onCancelOrder}
                />
            )}

            {isCancelModalVisible && (
                <CancelOrderModal
                    visible={isCancelModalVisible}
                    onClose={handleOnCloseCancelModal}
                    onCancel={onCancelOrder}
                    order={simpleBillingToDelete}
                />
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 32px;
`

const Table = styled(bTable)``

const basicColumnStyle = {
    textAlign: 'start',
    wordBreak: 'break-all',
}

const smallColumnStyle = {
    width: '8%',
}

const ownerStyle = {
    width: '15%',
}

const farmNameStyle = {
    width: '15%',
}

const statusColumnStyle = {
    width: '9%',
    wordBreak: 'normal',
}

const createShipmentStyle = {
    ...smallColumnStyle,
    wordBreak: 'normal',
}

const DeleteIcon = styled(IoMdTrash)`
    color: ${props =>
        props.disabled ? Colors.lightBlueGrey : Colors.brownishGrey};
    cursor: ${props => (props.disabled ? 'normal' : 'pointer')};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

const ViewIcon = styled(IoMdEye)`
    color: ${props =>
        props.disabled ? Colors.lightBlueGrey : Colors.brownishGrey};
    cursor: ${props => (props.disabled ? 'normal' : 'pointer')};
`
const NewShipmentButton = styled.button`
    cursor: ${props => (props.disabled ? 'normal' : 'pointer')};

    background: none;
    outline: none;
    padding: 0;
    border: none;
`

const ActionsContainer = styled.div`
    display: flex;
    gap: 20px;
`

const StatusText = styled.span`
    color: ${props => {
        switch (props.children) {
            case 'Pendente':
                return Colors.heavyRed
            default:
                return Colors.black
        }
    }};
`
