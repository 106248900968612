import React from 'react'
import styled from 'styled-components'

import BasicRoundButton from '../../commons/components/RoundButton'
import Colors from '../../commons/Colors'
import StatusChipComponent from '../../commons/components/StatusChipComponent'
import { getMeasurementUnitText } from '../../commons/MeasurementUnitUtils'
import { getMountedStatus } from './FragmentedOrderUtils'

export default function FragmentCardComponent({
    selected,
    fragment,
    onDelete,
}) {
    const renderItems = () => {
        return fragment.fragmentedOrderItems.map(item => (
            <TableRow>
                <Cell>
                    <ProductNameText>{item.productName}</ProductNameText>
                </Cell>
                <Cell>
                    <Text>{getMeasurementUnitText(item.measurementUnit)}</Text>
                </Cell>
                <Cell>
                    <QuantityText>{item.quantity}</QuantityText>
                </Cell>
            </TableRow>
        ))
    }

    const handleOnDelete = () => {
        onDelete(fragment.index, fragment.id)
    }

    const getStatusChip = () => {
        const mountedStatus = getMountedStatus(fragment.status.name)

        return <StatusChipComponent status={mountedStatus.label} />
    }

    const renderShipmentInfo = () => {
        if (fragment.id) return <>Remessa {fragment.number} </>
    }

    const renderOrderInfo = () => {
        if (fragment.id)
            return (
                <>
                    Pedido Nº {fragment.orderNumber}/{fragment.number}
                </>
            )
    }

    const isFragmentDelivered = () => fragment.status.name === 'ENTREGUE'

    return (
        <Container selected={selected}>
            <div>
                <CardHeader>{getStatusChip()}</CardHeader>
                <CardInfoContainer>
                    <CardShipmentInfo>{renderShipmentInfo()}</CardShipmentInfo>
                    <CardOrderInfo>{renderOrderInfo()}</CardOrderInfo>
                </CardInfoContainer>
                <ItemsTable>
                    <TableHeader>
                        <Cell>
                            <HeaderText>Produto</HeaderText>
                        </Cell>
                        <Cell>
                            <HeaderText>Unidade</HeaderText>
                        </Cell>
                        <Cell>
                            <HeaderText>Qtid.</HeaderText>
                        </Cell>
                    </TableHeader>
                    {renderItems()}
                </ItemsTable>
            </div>
            {!isFragmentDelivered() && (
                <CardFooter>
                    <DeleteButton onClick={handleOnDelete}>
                        Excluir Fracionamento
                    </DeleteButton>
                </CardFooter>
            )}
        </Container>
    )
}

const Container = styled.div`
    border-width: ${props => (props.selected ? '2px' : 'none')};
    border-color: ${props => (props.selected ? Colors.heavyGreen : 'white')};
    border-style: ${props => (props.selected ? 'dashed' : 'none')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: ${Colors.lightBlueGreyFour};
`

const CardHeader = styled.div`
    padding: 10px 0px;
    display: flex;
    justify-content: center;
`
const CardInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
`
const CardShipmentInfo = styled.span``

const CardOrderInfo = styled.span`
    font-family: Roboto;
    font-size: 16px;
    color: ${Colors.deepSeaBlue};
`

const ItemsTable = styled.div`
    margin: 10px;
    display: grid;
    gap: 5px;
`

const TableRow = styled.div`
    display: grid;
    grid-template-columns: 40% 30% 30%;
    gap: 5px;
    padding: 10px 10px 10px 10px;
`

const TableHeader = styled(TableRow)`
    background-color: ${Colors.white};
    border-radius: 4px;
`

const Cell = styled.div`
    display: flex;
    align-items: center;
`

const Text = styled.p`
    font-family: Roboto;
    font-size: 12px;
    color: ${Colors.blueGrey};
    margin: 0;
`

const HeaderText = styled(Text)`
    font-weight: bold;
`

const ProductNameText = styled(Text)`
    font-weight: bold;
    color: ${Colors.black};
`

const QuantityText = styled(Text)`
    font-weight: bold;
    color: ${Colors.black};
`

const DeleteButton = styled(BasicRoundButton)`
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: ${Colors.white};
    border-color: ${Colors.orange};
    color: ${Colors.orange};
`

const CardFooter = styled.div`
    display: flex;
    justify-content: center;
    justify-self: flex-end;
    padding: 10px;
`
