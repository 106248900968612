import React from 'react'

const ProductIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 17.5 25"
            fill="none"
        >
            <path
                d="M8.886 16.1c-1.463 0-2.822-.732-3.554-1.777-.627-.732-.941-1.777-.941-2.718 0-2.51 1.986-4.6 4.6-4.6 1.15 0 2.195.418 3.032 1.149L5.75 14.009l6.586-5.436c.21.21.418.523.628.836l-7.11 5.331 7.214-4.912c.105.313.21.627.21.836l-7.214 4.39 7.213-3.867v.313c0 .21 0 .523-.104.732l-6.378 3.24 6.482-2.717c-.104.523-.418 1.045-.731 1.462l-5.019 1.569 4.6-1.046c-.836.732-1.986 1.36-3.24 1.36zm8.782 5.436c-.313-.627-.523-1.254-.732-1.777H12.65a.45.45 0 0 1-.418-.418c0-.21.209-.419.418-.419h4.077c-.313-1.149-.523-2.299-.523-3.45v-7.84c0-2.092.523-4.183 1.464-6.168.21-.314.105-.732-.104-1.045C17.354.105 17.04 0 16.623 0c-.21 0-.314 0-.523.105-1.568.836-3.24 1.359-5.018 1.359H7.005c-1.882 0-3.66-.418-5.228-1.36C1.464 0 1.15 0 .941 0 .523.105.314.313.105.628 0 .94 0 1.358.209 1.568a6.532 6.532 0 0 1 1.046 2.3H5.75c.21 0 .418.21.418.419 0 .208-.104.522-.418.522H1.568V17.25c0 1.464-.523 2.927-1.359 4.078-.104.208-.209.522-.209.73.105.315.314.629.627.838.314.104.732.104 1.046-.106 1.568-.94 3.345-1.358 5.227-1.358h4.077c1.777 0 3.555.523 5.018 1.358.21.106.314.106.523.106.314 0 .732-.21.941-.418.314-.21.314-.628.21-.942z"
                fill="currentColor"
            />
        </svg>
    )
}

export default ProductIcon
