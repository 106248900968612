import React from 'react'
import styled from 'styled-components'

import Colors from '../../commons/Colors'

const ProductSummaryItem = ({ item }) => {
    return (
        <ProductContainer key={item.product._id}>
            <LegacyCodeContainer>
                <Title>{item.product.legacyCode}</Title>
            </LegacyCodeContainer>
            <InfoContainer>
                <Title>{item.product.name.toLowerCase()}</Title>
                <ProductInfo>{item.unQuantity}</ProductInfo>
            </InfoContainer>
            <ProductAmount>
                {item.totalWeight} {item.product.measurementUnit.weight}
            </ProductAmount>
        </ProductContainer>
    )
}

export const ProductContainer = styled.div`
    border-bottom: solid 1px ${Colors.lightBlueGreyTwo};
    display: flex;
    margin-top: 35px;
    margin-left: 20px;
    margin-bottom: 10px;
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const LegacyCodeContainer = styled.div`
    width: 150px;
`

const Title = styled.span`
    font-family: Roboto;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.18px;
    color: ${Colors.black};
    text-transform: capitalize;
`

const ProductInfo = styled.span`
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.4px;
    color: ${Colors.brownishGrey};
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
`

const ProductAmount = styled.span`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.13px;
    color: ${Colors.black};
    margin-left: auto;
`

export default ProductSummaryItem
