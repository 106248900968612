const Colors = {
    opacityOrange: '#f18d3a1a',
    orange: '#e57509',
    orangeTwo: '#e67508',
    dustyOrange: '#f18d3a',
    sienna: '#b15717',
    deepSeaBlue: '#005186',
    marine: '#0a2658',
    peacockBlue: '#0053a0',
    grass: '#4da32f',
    white: '#ffffff',
    veryLightPink: '#f1f1f1',
    paleLilac: '#e5e5ea',
    lightBlueGrey: '#d1d1d6',
    lightBlueGreyTwo: '#c7c7cc',
    blueGrey: '#8e8e93',
    brownishGrey: '#666666',
    black: '#333333',
    strawberry: '#eb394b',
    lightishGreen: '#68e182',
    lightViolet: '#cec5e6',
    iris: '#775bbc',
    powderBlue: '#c1ddf8',
    softBlue: '#5aa5ef',
    manilla: '#fbe08f',
    butterscotch: '#f7c244',
    hospitalGreen: '#a3e9a4',
    leafyGreen: '#57bd41',
    mediumBlue: '#2e77b8',
    softGreen: '#78c47b',
    bluishGreen: '#16a074',
    lightBlueGreyThree: '#c6cbd4',
    lightBlueGreyFour: '#eceeee',
    lightishBlue: '#4b74ff',
    lipstick: '#ca182a',
    macaroniAndCheese: '#f1b63a',
    orange15: 'rgba(229, 117, 9, 0.15)',
    lightRed: '#e64858',
    opacityRed: 'rgba(248, 128, 120, 0.2)',
    opacityGrey: 'rgba(204, 204, 204, 0.5)',
    lightGreen: '#dff1df',
    heavyGreen: '#4caf50',
    heavyRed: '#f44336',
    lightGrey: '#dadada',
    darkRed: '#9f1111',
    darkGray: '#e3e2e2',
    whiteGray: '#f0f0f0',
}

export default Colors
