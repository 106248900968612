import React from 'react'
import styled from 'styled-components'

import Colors from '../Colors'

const RoundTagComponent = ({ text, color }) => {
    return (
        <RoundTag color={color}>
            <Text>{text}</Text>
        </RoundTag>
    )
}

const RoundTag = styled.button`
    background-color: ${({ color }) => color || Colors.orange};
    min-width: 36px;
    height: 13px;
    border-radius: 24px;
    border-width: none;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Text = styled.span`
    margin: 1px 0 0 0;
    font-family: 'Roboto';
    font-size: 9px;
    color: ${Colors.white};
    text-align: center;
`

export default RoundTagComponent
