import moment from 'moment'

const ONE_DAY = 1000 * 60 * 60 * 24
const ONE_HOUR = 1000 * 60 * 60

export function getHoursDiff(dateStart, dateEnd = new Date()) {
    if (typeof dateStart === 'string') dateStart = new Date(dateStart)

    return Math.abs(dateEnd - dateStart) / ONE_HOUR
}

export function getDaysDiff(date, ceil = false) {
    if (Object.prototype.toString.call(date) !== '[object Date]')
        throw new Error('A data informada deve ser do tipo Date')

    const now = new Date()
    const diffTime = Math.abs(now.getTime() - date.getTime())

    return ceil ? Math.ceil(diffTime / ONE_DAY) : Math.round(diffTime / ONE_DAY)
}

// TODO - validar impactos da substitução da função getDaysDiff
export function getDaysDiffV2(date) {
    if (Object.prototype.toString.call(date) !== '[object Date]')
        throw new Error('A data informada deve ser do tipo Date')

    const now = new Date()
    const handledDate = new Date(
        new Date().setMonth(date.getMonth(), date.getDate())
    )

    const diffTime = Math.abs(now.getTime() - handledDate.getTime())

    return Math.round(diffTime / ONE_DAY)
}

export function dateFormat(date) {
    if (!date) return ''

    if (typeof date === 'string') date = new Date(date)

    const day = date.getDate().toString().padStart(2, '0'),
        month = (date.getMonth() + 1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
        year = date.getFullYear()

    return `${day}/${month}/${year}`
}

export function dateFormatToReport(date) {
    if (!date) return ''

    if (typeof date === 'string') date = new Date(date)

    const day = date.getDate().toString().padStart(2, '0'),
        month = (date.getMonth() + 1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
        year = date.getFullYear()

    return `${year}-${month}-${day}`
}

export function timeFormat(date) {
    if (!date) return ''
    if (typeof date === 'string') date = new Date(date)

    return date.toLocaleTimeString('pt-BR', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    })
}

export function addDays(days, date = new Date()) {
    const result = new Date(date.toISOString())
    result.setDate(result.getDate() + days)

    return result
}

export function toDate(dateString) {
    /** Formato esperado: DD/MM/YYYY */
    try {
        if (!dateString) return null

        const [day, month, year] = dateString.split('/')
        const formatedDate = moment(`${year}-${month}-${day}`)

        return formatedDate.isValid()
            ? formatedDate.toDate().toISOString()
            : null
    } catch (err) {
        return null
    }
}

export const getMonth = date => {
    const dateToGetValue = new Date(date)

    const monthString = dateToGetValue
        .toLocaleString('pt-BR', { month: 'long' })
        .substring(0, 3)

    const shortMonth = `${monthString
        .charAt(0)
        .toUpperCase()}${monthString.slice(1)}`

    let monthNumber = dateToGetValue.getMonth() + 1

    if (monthNumber < 10) monthNumber = `0${monthNumber}`

    return {
        monthNumber,
        shortMonth,
    }
}

export const getYear = date => {
    const fullYear = new Date(date).getFullYear()
    const shortYear = fullYear.toString().substring(2)

    return {
        fullYear,
        shortYear,
    }
}
