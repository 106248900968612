import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Colors from '../Colors'
import RoundButton from '../components/RoundButton'
import SearchInput from '../components/SearchInput'
import { MdAddCircleOutline } from 'react-icons/md'

/**
 *
 * @param {value} searchValue - valor do filtro de pesquisa
 * @param {string} searchPlaceholder - placeholder do filtro de pesquisa
 * @param {React.ChangeEvent} searchOnChange - chamado toda vez que o valor do input é alterado
 * @param {string} linkTo - url para ser redirecionado
 * @param {string} buttonText - texto do botão de redirecionamento
 * @param {boolean} buttonWithAddIcon - se deve adicionar icone de + no botão de redirecionamento
 * @param {React.FunctionComponent} children - componente a ser renderizado
 * @param {React.FunctionComponent} renderAnotherButton - renderiza outro botão ao lado do de redirecionamento caso seja passado
 */

export const BasicListScreen = ({
    searchValue,
    searchPlaceholder,
    searchOnChange,
    linkTo,
    buttonText,
    buttonWithAddIcon = false,
    children,
    renderAnotherButton,
}) => {
    return (
        <>
            <ActionsContainer>
                <SearchInput
                    name="search"
                    value={searchValue}
                    onChange={searchOnChange}
                    placeholder={searchPlaceholder}
                />

                <RightButtons>
                    {renderAnotherButton}
                    {Boolean(linkTo) && (
                        <Link to={linkTo}>
                            <NewRegistrationButton>
                                {buttonWithAddIcon && <AddIcon />}
                                {buttonText}
                            </NewRegistrationButton>
                        </Link>
                    )}
                </RightButtons>
            </ActionsContainer>
            {children}
        </>
    )
}

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 24px;
    padding-top: 20px;
`

const NewRegistrationButton = styled(RoundButton)`
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
    height: 32px;
    display: flex;
    align-items: center;
`

const AddIcon = styled(MdAddCircleOutline)`
    font-size: 15px;
    margin-right: 3px;
`

const RightButtons = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
`

export default BasicListScreen
