import React from 'react'
import styled from 'styled-components'

import Colors from '../../Colors'

const NoRecordsFoundComponent = ({ renderIcon, renderText }) => {
    return (
        <Container>
            <ImageContainer>{renderIcon()}</ImageContainer>
            <TextContainer>
                <Text>{renderText()}</Text>
            </TextContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const ImageContainer = styled.div`
    padding-top: 80px;
    color: ${Colors.orange};
`
const TextContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    width: 35%;
`
const Text = styled.p`
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.88;
    letter-spacing: 0.09px;
    text-align: center;
    color: ${Colors.orange};
`

export default NoRecordsFoundComponent
