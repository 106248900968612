import hash from 'object-hash'
import { removeOrdersInGroups } from '../dashboard/DashboardScreenUtils'
import { STATUS } from '../commons/Status'

export const generateOrderGroupHash = orderGroup => {
    return hash({
        requestDate: orderGroup.requestDate.toISOString(),
        note: orderGroup.note,
    })
}

export const sumTotalWeight = orderGroup => {
    return orderGroup.orders.reduce((totalWeight, order) => {
        return totalWeight + order.weightInTonnes
    }, 0)
}

export const optionsOrderOnGroup = [
    { label: 'Em Análise', value: 'EM_ANALISE' },
    { label: 'Em Produção', value: 'EM_PRODUCAO' },
    { label: 'Em Estoque', value: 'EM_ESTOQUE' },
    { label: 'Em Expedição', value: 'EM_EXPEDICAO_TRANSITO' },
]

export const optionsOrderOutsideGroup = [
    { label: 'Em Análise', value: 'EM_ANALISE' },
    { label: 'Em Produção', value: 'EM_PRODUCAO' },
    { label: 'Em Estoque', value: 'EM_ESTOQUE' },
]

export const removeOrdersAlreadySelected = (
    enabledStatusOrdersToSelect,
    ordersInGroup
) => {
    const removedOrders = enabledStatusOrdersToSelect.filter(
        item => !ordersInGroup.find(order => item._id === order._id)
    )
    return removedOrders
}

export const filterDropdownOptions = (
    orders,
    orderGroups,
    selectedOrderGroup
) => {
    const ordersThatHaveNoGrouping = removeOrdersInGroups(
        orders,
        orderGroups.filter(
            orderGroup => orderGroup._id !== selectedOrderGroup._id
        )
    )

    const ordersFilteredByStatus = ordersThatHaveNoGrouping.filter(order => {
        return (
            order.status === STATUS.EM_ANALISE ||
            order.status === STATUS.EM_ESTOQUE ||
            order.status === STATUS.EM_PRODUCAO
        )
    })

    return removeOrdersAlreadySelected(
        ordersFilteredByStatus,
        selectedOrderGroup.orders
    )
}

export const separatesSelectedOrders = (
    selectedOrders,
    orderGroups,
    orderGroup
) => {
    const selectedOrderGroup = orderGroups.find(
        group => group._id === orderGroup._id
    )

    return selectedOrders.reduce(
        (output, order) => {
            const orderExistsOnGroup = selectedOrderGroup.orders.some(
                orderOnGroup => orderOnGroup._id === order._id
            )

            orderExistsOnGroup
                ? output.orders.push(order)
                : output.outsideOrders.push(order)

            return output
        },
        {
            orders: [],
            outsideOrders: [],
        }
    )
}
