import React from 'react'
import styled from 'styled-components'

import Colors from '../../commons/Colors'
import * as NumberUtils from '../../commons/NumberUtils'
import * as DateUtils from '../../commons/DateUtils'
import { sumTotalWeight } from '../OrderGroupUtils'
import ProductSummaryItem from './ProductSummaryItem'

const ProductSummaryPrintTemplate = React.forwardRef(({ orderGroup }, ref) => {
    const getProductsSummary = () => {
        let summary = {}

        const addProduct = itemToAdd => {
            const productID = itemToAdd.product?.gcpId
                ? itemToAdd.product.gcpId
                : itemToAdd.product?._id

            summary[productID] = {
                product: itemToAdd.product,
                quantity: itemToAdd.quantity,
                totalWeight: calculateWeigth(itemToAdd),
                unQuantity: itemToAdd.unQuantity,
            }

            return
        }

        const updateProduct = itemToAdd => {
            const {
                quantity: orderItemQuantity,
                product: {
                    _id,
                    measurementUnit: { measure, quantity, weight },
                },
            } = itemToAdd
            const productId = itemToAdd.product?.gcpId
                ? itemToAdd.product.gcpId
                : _id

            const newTotalQuantity =
                summary[productId].quantity + orderItemQuantity
            const newTotalWeight =
                summary[productId].totalWeight + calculateWeigth(itemToAdd)

            const newUnQuantity = `${newTotalQuantity} ${measurementUnitToPlural(
                measure
            )} (${quantity} ${weight})`

            summary[productId].quantity = newTotalQuantity
            summary[productId].unQuantity = newUnQuantity
            summary[productId].totalWeight = newTotalWeight

            return
        }

        const measurementUnitToPlural = unit => {
            switch (unit) {
                case 'saco':
                    return 'sacos'
                case 'caixa':
                    return 'caixas'
                case 'KG':
                    return 'quilos'
                case 'metro':
                    return 'metros'
                case 'rolo':
                    return 'rolos'
                case 'pacote':
                    return 'pacotes'
                case 'litro':
                    return 'litros'
                case 'unidade':
                    return 'unidades'
                case 'bag':
                    return 'bags'
                default:
                    return unit
            }
        }

        // TODO: Implementar no back-end a informação do peso total do orderItem e remover a lógica de cálculo no front
        const calculateWeigth = item => {
            const isBag = item => {
                return (
                    (item.product.measurementUnit.weight === 'kg' &&
                        item.product.measurementUnit.measure === 'saco') ||
                    (item.product.measurementUnit.weight === 'kg' &&
                        item.product.measurementUnit.measure === 'bag')
                )
            }

            if (isBag(item))
                return item.quantity * item.product.measurementUnit.quantity
            else return item.quantity
        }

        orderGroup.orders.forEach(order => {
            order.items.forEach(item => {
                summary[
                    item.product?.gcpId ? item.product.gcpId : item.product._id
                ]
                    ? updateProduct(item)
                    : addProduct(item)
            })
        })

        return Object.values(summary)
    }

    const productsSummary = getProductsSummary()

    return (
        <Container ref={ref}>
            <Header>
                <Text>{DateUtils.dateFormat(new Date())}</Text>
                <Text>FarmTell Mills</Text>
            </Header>

            <OrderGroupInfo>
                <HeaderContainer>
                    <Title>Resumo Por Produto</Title>
                    <OrderGroupNumber>
                        Agrupamento Nº {orderGroup.orderGroupNumber}
                    </OrderGroupNumber>
                </HeaderContainer>

                <OrderGroupNote>
                    <SpaceBetween>
                        <Text>Observações: </Text>
                        <Text>
                            {NumberUtils.numberFormat(
                                sumTotalWeight(orderGroup),
                                2,
                                'Carga total: ',
                                ' Ton.'
                            )}
                        </Text>
                    </SpaceBetween>
                    <Text>{orderGroup.note}</Text>
                </OrderGroupNote>

                <>
                    {productsSummary.map(item => (
                        <ProductSummaryItem
                            key={item.product._id}
                            item={item}
                        />
                    ))}
                </>
            </OrderGroupInfo>
        </Container>
    )
})

const Container = styled.div`
    padding: 70px;
    background-color: ${Colors.white};
`

const Header = styled.div`
    display: grid;
    grid-template-columns: 0.8fr 1fr;
`

const Text = styled.span`
    font-size: 10px;
    color: ${props => (props.color ? props.color : '#3c3c3b')};
`

const OrderGroupInfo = styled.div`
    padding: 9px 20px;
`

const Note = styled.div`
    padding: 4px 9px;
    background-color: #e1e1e1;
`

const OrderGroupNote = styled(Note)`
    display: flex;
    flex-direction: column;
`

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
`

const Title = styled.span`
    font-family: Roboto;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.18px;
    color: ${Colors.black};
    text-transform: capitalize;
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const OrderGroupNumber = styled(Title)`
    font-size: 25px;
    color: ${Colors.deepSeaBlue};
    line-height: 1.71;
    font-weight: 500;
`

export default ProductSummaryPrintTemplate
