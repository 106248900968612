export const keyStorage = {
    userToken: 'userToken',
    products: 'products',
    categories: 'categories',
    farms: 'farms',
    stocks: 'stocks',
    payments: 'payments',
    orders: 'orders',
    synchronizationStatus: 'synchronizationStatus',
    pendingMeasurements: 'pendingMeasurements',
    pendingOrders: 'pendingOrders',
    stcUser: 'prodapFabsSuporteComercial',
    sellerUser: 'prodapFabsVendedor',
    aclUserGroups: 'aclUserGroups',
    userPreferences: 'userPreferences',
    orderGroupCard: 'orderGroupCards',
}

export function getUserPreferences() {
    const userPreferences = localStorage.getItem(keyStorage.userPreferences)
    if (userPreferences) return JSON.parse(userPreferences)
    return
}

export function createUserPreferences() {
    const userPreferences = getUserPreferences()
    if (!userPreferences)
        localStorage.setItem(keyStorage.userPreferences, JSON.stringify({}))
}
