import { getBaseURL, getToken } from '../environment'

const gcpBaseURL = `${getBaseURL()}/clients`

export const getAllClients = (size = 10, page = 0, filter, userId) => {
    const params = new URLSearchParams({ size, page })

    if (filter && filter.length > 0) {
        params.append('filter', filter)
    }

    if (userId) {
        params.append('userId', userId)
        params.append('getPortfolios', 'true')
    }

    return fetch(`${gcpBaseURL}/clients?${params.toString()}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getClientTemp = id => {
    const params = new URLSearchParams({ id })
    return fetch(`${gcpBaseURL}/clients?${params.toString()}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const getClient = id => {
    return fetch(`${gcpBaseURL}/clients/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const createClient = async client => {
    try {
        const response = await fetch(`${gcpBaseURL}/clients`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(client),
        })
        if (response.status > 299 || response.status < 200)
            throw new Error(await response.text())

        return await response.json()
    } catch (err) {
        console.error('Erro: ' + err.message)
        throw err
    }
}

export const updateClient = async (client, id) => {
    try {
        const response = await fetch(`${gcpBaseURL}/clients/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(client),
        })
        if (response.status > 299 || response.status < 200)
            throw new Error(await response.text())

        return await response.json()
    } catch (err) {
        console.error('Erro: ' + err.message)
        throw err
    }
}

export const deleteClient = id => {
    return fetch(`${gcpBaseURL}/clients/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(error => {
            console.error('Erro: ' + JSON.stringify(error))
            throw error
        })
}

export const getPortfolios = (millId, userId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
    }
    if (userId) {
        headers['userId'] = userId
    }
    return fetch(`${gcpBaseURL}/portfolios?millId=${millId}`, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
