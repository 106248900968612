import React from 'react'
import styled from 'styled-components'
import Colors from '../commons/Colors'
import * as DateUtils from '../commons/DateUtils'
import OrderCardIndicatorsComponent from './OrderCardIndicatorsComponent'
import NewShipmentIcon from '../assets/customIcons/NewShipmentIcon'
import { isOrderOutOfPricePolicy } from './DashboardScreenUtils'
import { useDashboardContext } from '../context/DashboardContext'

const SimpleBillingCardComponent = props => {
    const { setSelectedOrderCard } = useDashboardContext()

    const onSelectOrder = id => {
        if (!props.isOrdersDropdown) {
            setSelectedOrderCard(id)
        }
    }

    const renderShipmentNumber = () => {
        return `${props.orderNumber}/${props.number}`
    }

    return (
        <Container
            isOrderGroup={props.isOrderGroup}
            data-testid="card-container"
            onClick={() => onSelectOrder(props.orderId)}
            isUrgent={props.isUrgent}
        >
            <Card>
                <SpaceBetween>
                    <Owner>{props.farm.owner.name}</Owner>
                </SpaceBetween>

                <ActionsContainer>
                    <IndicatorsContainer>
                        <OrderCardIndicatorsComponent
                            isDebtorClient={props.farm.debtor}
                            isOutOfPricePolicy={isOrderOutOfPricePolicy(
                                props,
                                props.status.name
                            )}
                        />
                    </IndicatorsContainer>
                </ActionsContainer>

                <SpaceBetween>
                    <OrderNumber>
                        Pedido Nº {renderShipmentNumber()}
                    </OrderNumber>
                </SpaceBetween>

                <BasicText>{props.farm.fancyName}</BasicText>

                <SpaceBetween>
                    <BasicText>{props.category.name}</BasicText>
                </SpaceBetween>

                <BasicText>
                    Feito por <strong>{props.seller.name}</strong> em{' '}
                    <strong>{DateUtils.dateFormat(props.requestDate)}</strong>
                </BasicText>
                <Delivery>
                    <DeliveryDateContainer>
                        <DeliveryLabel>Data de Entrega:</DeliveryLabel>
                        <DeliveryDate>
                            {DateUtils.dateFormat(props.deliveryDate)}
                        </DeliveryDate>
                    </DeliveryDateContainer>
                    <ShipmentIconContainer>
                        <NewShipmentIcon />
                    </ShipmentIconContainer>
                </Delivery>
            </Card>
        </Container>
    )
}

const Container = styled.div`
    margin-top: ${props => (props.isOrderGroup ? 0 : '10px')};
    box-shadow: ${props =>
        props.isOrderGroup ? 'none' : `0 1px 4px 0 ${Colors.lightBlueGreyTwo}`};
    border-width: ${props => (props.isUrgent ? '0 0 0 8px' : 'none')};
    border-color: ${props => (props.isUrgent ? Colors.darkRed : Colors.white)};
    border-style: ${props => (props.isUrgent ? 'solid' : 'none')};
    background-color: white;
    border-radius: 4px;
    position: relative;
`

const Card = styled.div`
    width: 260px;
    padding: 10px;
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 8px;
    position: absolute;
    right: 0;
    top: 0;
`

const BasicText = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.21px;
    color: ${Colors.blueGrey};
    white-space: break-spaces;
`

const Owner = styled(BasicText)`
    font-size: 16px;
    font-weight: bold;
    color: ${Colors.black};
    max-width: 220px;
`

const SpaceBetween = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const OrderNumber = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.black};
`

const IndicatorsContainer = styled.div`
    display: flex;
    flex-direction: 'row';
    justify-content: flex-end;
`

const Delivery = styled.div`
    display: flex;
    justify-content: space-between;
`

const DeliveryLabel = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.black};
    margin-right: 5px;
`
const DeliveryDateContainer = styled.div`
    display: flex;
    justify-content: flex-start;
`

const DeliveryDate = styled(BasicText)`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.strawberry};
`

const ShipmentIconContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 8px;
    position: absolute;
    right: 0;
    bottom: 0;
`

export default SimpleBillingCardComponent
