export const firebaseConfig = {
    apiKey: 'AIzaSyAlhPPhl6c80ynEjnN728_m1PG-PmmQAUQ',
    authDomain: 'testes-desenvolvimento-273714.firebaseapp.com',
    databaseURL: 'https://testes-desenvolvimento-273714.firebaseio.com',
    projectId: 'testes-desenvolvimento-273714',
    storageBucket: 'testes-desenvolvimento-273714.appspot.com',
    messagingSenderId: '980119828305',
    appId: '1:980119828305:web:bfc20f447cc48c25938570',
    measurementId: 'G-6FNNX9R1TG',
}
