import React from 'react'
import styled from 'styled-components'
import NotificationBellIcon from '../../../assets/customIcons/NotificationBellIcon'
import Colors from '../../Colors'

export const NotificationComponent = ({ onClick, counter = 0 }) => {
    if (counter === 0)
        return (
            <Container>
                <NotificationIcon onClick={onClick}>
                    <NotificationBellIcon />
                </NotificationIcon>
            </Container>
        )

    return (
        <Container>
            <NotificationIconWithBadge onClick={onClick}>
                <NotificationBellIcon />
            </NotificationIconWithBadge>
            <Badge>{counter}</Badge>
        </Container>
    )
}

const Container = styled.div``
const NotificationIcon = styled.button`
    color: #fab500;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
`

const NotificationIconWithBadge = styled(NotificationIcon)`
    padding-top: 12px;
`

const Badge = styled.div`
    background-color: #e64858;
    border-radius: 50%;
    width: 13px;
    height: 12px;
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    letter-spacing: 0.16px;
    color: ${Colors.white};
    position: relative;
    top: -27px;
    right: -12px;
    cursor: pointer;
`

export default NotificationComponent
