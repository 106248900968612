import React from 'react'
import styled from 'styled-components'

import { IoMdClose } from 'react-icons/io'

import RoundButton from './RoundButton'
import Colors from '../Colors'

/**
 *
 * @param {boolean} visible - se o componente está visivel ou não
 * @param {React.FunctionComponent} children - componente a ser renderizado como mensagem de confirmação
 * @param {function} onClose - ação a ser feita ao fechar o componente
 * @param {string} title - titulo do componente
 * @param {string} actionButtonText - texto do botão de confirmação da ação
 * @param {function} actionButtonFunction - ação a ser feita ao clicar no botão de confirmação da ação
 */

const Confirm = ({
    visible,
    children,
    onClose,
    title,
    actionButtonText,
    actionButtonFunction,
    isLoading,
}) => {
    return (
        visible && (
            <>
                <OutsideConfirm />
                <Body>
                    <CloseIcon
                        data-testid="close-icon"
                        onClick={() => {
                            onClose && onClose()
                        }}
                    />
                    <Title>{title}</Title>
                    <ConfirmMsg>{children}</ConfirmMsg>
                    <ButtonBox>
                        <CancelButton
                            onClick={() => {
                                onClose && onClose()
                            }}
                            loading={isLoading}
                        >
                            Cancelar
                        </CancelButton>
                        <ActionButton
                            loading={isLoading}
                            onClick={() => {
                                actionButtonFunction && actionButtonFunction()
                            }}
                        >
                            {actionButtonText}
                        </ActionButton>
                    </ButtonBox>
                </Body>
            </>
        )
    )
}

const OutsideConfirm = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(5, 45, 62, 0.7);
    z-index: 9998;
    display: block;
`
const Body = styled.div`
    background-color: #fff;
    position: fixed;
    background: white;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    z-index: 9999;
    min-height: 20vh;
    width: 30vw;
    padding: 25px;
    word-wrap: break-word;
`

const CloseIcon = styled(IoMdClose)`
    margin: 17px;
    margin-top: -5px;
    position: fixed;
    right: 0;
    height: 18px;
    cursor: pointer;
`

const Title = styled.div`
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.44px;
    margin-bottom: 15px;
    word-wrap: break-word;
`
const ConfirmMsg = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.71;
    width: calc(30vw - 50px);
`
const CancelButton = styled(RoundButton)`
    width: 106px;
    background: ${Colors.white};
    border-color: ${Colors.orange};
    border-style: solid;
    color: ${Colors.orange};
`
const ActionButton = styled(RoundButton)`
    min-width: 106px;
    margin-left: 16px;
    background: ${Colors.orange};
    border-color: ${Colors.orange};
    border-style: solid;
`
const ButtonBox = styled.div`
    margin-top: 50px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
`

export default Confirm
