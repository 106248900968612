import React from 'react'
import styled from 'styled-components'

import Loading from '../../../commons/components/Loading'
import RightArrow from '../../../assets/customIcons/RightArrow'
import LeftArrow from '../../../assets/customIcons/LeftArrow'
import Colors from '../../../commons/Colors'
import { NonAssociatedClients } from './NonAssociatedClients'
import { AssociatedClients } from './AssociatedClients'

export function ClientsSelection({
    nonAssociatedClients,
    nonAssociatedClientsTotal,
    associatedClients,
    handleFilter,
    handleSelectClient,
    handleClientsAssociate,
    associatedClientsFilter,
    isValidatingClient,
    isLoadingNonAssociate,
}) {
    return (
        <ClientsContainer>
            <AssociationClientsContainer>
                <NonAssociatedClients
                    isLoading={isLoadingNonAssociate}
                    handleSelectClient={handleSelectClient}
                    nonAssociatedClientsTotal={nonAssociatedClientsTotal}
                    nonAssociatedClients={nonAssociatedClients}
                    handleFilter={handleFilter}
                />
            </AssociationClientsContainer>

            {isValidatingClient ? (
                <Loading data-testid="clients-validation-loading" />
            ) : (
                <ButtonsContainer>
                    <ArrowButton
                        type="button"
                        name="associate-button"
                        data-testid="associate-button"
                        onClick={() =>
                            handleClientsAssociate({
                                toInsert: associatedClients,
                                toRemove: nonAssociatedClients,
                                operation: 'associate',
                            })
                        }
                    >
                        <RightArrow />
                    </ArrowButton>

                    <ArrowButton
                        type="button"
                        name="unAssociate-button"
                        data-testid="unAssociate-button"
                        onClick={() =>
                            handleClientsAssociate({
                                toInsert: nonAssociatedClients,
                                toRemove: associatedClients,
                                operation: 'unAssociate',
                            })
                        }
                    >
                        <LeftArrow />
                    </ArrowButton>
                </ButtonsContainer>
            )}

            <AssociationClientsContainer>
                <AssociatedClients
                    handleSelectClient={handleSelectClient}
                    associatedClients={associatedClients}
                    associatedClientsFilter={associatedClientsFilter}
                    handleFilter={handleFilter}
                />
            </AssociationClientsContainer>
        </ClientsContainer>
    )
}

const ArrowButton = styled.button`
    cursor: pointer;
    border: transparent;
    margin-bottom: 1vh;
    background-color: transparent;
`

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;
    width: 2.5%;
`

const FormPanel = styled.div`
    padding: 2.8vh 1.3vw;
    margin-bottom: 2.2vh;

    border-radius: 3px;
    border: solid 1px #eceeee;
    background-color: ${Colors.white};
`

const ClientsContainer = styled(FormPanel)`
    display: flex;
    justify-content: space-between;
`
const AssociationClientsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1vh;
    width: 48%;
`
