import React from 'react'
import styled from 'styled-components'

import Colors from '../../../commons/Colors'
import Carousel from '../../../commons/components/Carousel'
import * as ProductUsageUtils from '../../ProductUsageUtils'

export default function ProductsCarouselComponent({ tableValues }) {
    const getCarouselData = () =>
        ProductUsageUtils.generateMonthRangeStructure()

    const renderColumn = column => {
        const columnItems = tableValues.map(
            tableValue => tableValue[column.key]
        )

        return (
            <Column>
                <HeaderCell>
                    <ColumnTitle>{column.title}</ColumnTitle>
                </HeaderCell>
                {columnItems.map((item, index) =>
                    renderProductStatus(item, index)
                )}
            </Column>
        )
    }

    const renderProductStatus = (productUsage, index) => {
        if (!productUsage) {
            return (
                <Cell key={`empty-${index}`}>
                    <ProductEmptyStatus>-</ProductEmptyStatus>
                </Cell>
            )
        }

        const { active } = productUsage

        const productStatus = active ? 'Ativo' : 'Inativo'

        return (
            <Cell key={`${productStatus}-${index}`}>
                <ProductStatus active={active}>{productStatus}</ProductStatus>
            </Cell>
        )
    }

    return (
        <Carousel
            value={getCarouselData()}
            itemTemplate={renderColumn}
            numVisible={6}
            numScroll={6}
        />
    )
}

const ProductStatus = styled.div`
    text-align: center;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    border-radius: 100px;
    padding: 7px 0 7px 0;
    width: 80px;
    height: 30px;
    background-color: ${props =>
        props.active ? Colors.lightGreen : Colors.opacityRed};
    color: ${props => (props.active ? Colors.heavyGreen : Colors.heavyRed)};
`

const ProductEmptyStatus = styled(ProductStatus)`
    background-color: ${Colors.opacityGrey};
    color: ${Colors.black};
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`

const ColumnTitle = styled.h3`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.brownishGrey};
`

const Cell = styled.div`
    height: 65px;
    background-color: ${Colors.white};
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    margin-bottom: 1px;
`
const HeaderCell = styled(Cell)`
    height: 48px;
    background-color: transparent;
`
