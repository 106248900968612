import React from 'react'
import styled from 'styled-components'

const FieldErrorMessageComponent = ({
    isInvalid,
    message = 'Campo obrigatório',
}) => {
    if (isInvalid) {
        return (
            <ErrorMessage isInvalid={isInvalid} className="error-message">
                {message}
            </ErrorMessage>
        )
    }

    return null
}

const ErrorMessage = styled.span`
    font-family: Roboto;
    color: red;
    text-align: start;
    font-size: 11px;
    padding-top: 2px;
`

export default FieldErrorMessageComponent
