import React from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'

import Colors from '../../commons/Colors'
import { getPriceString } from '../../price/PriceUtils'
import { getQuantityPlaceholder } from '../OrderFormScreenUtils'
import InputWithMessage from '../../commons/components/InputWithMessage'
import { formatDecimalPlace } from '../../commons/NumberUtils'

const ProductCardView = ({
    orderItem,
    onRemove,
    handleOnChange,
    freightPrice,
    isFreight,
    isCharges,
    chargesPrice,
    measureUnit,
    isPriceInvalid,
    invalidMessage,
    minPrice,
    maxPrice,
    isDisabled,
}) => {
    const renderFreightOrCharges = (freightOrCharges, price) => {
        if (isDisabled)
            return (
                <DisabledFreightAndChargesText>
                    {`${freightOrCharges}: ${getPriceString(
                        price
                    )} / ${measureUnit}`}
                </DisabledFreightAndChargesText>
            )
        return (
            <FreightAndChargesText>
                {`${freightOrCharges}: ${getPriceString(
                    price
                )} / ${measureUnit}`}
            </FreightAndChargesText>
        )
    }

    const renderFreightAndCharges = () => {
        if (isDisabled)
            return (
                <DisabledFreightAndChargesText>
                    {`Frete: ${getPriceString(
                        freightPrice
                    )} / Encargo: ${getPriceString(
                        chargesPrice
                    )} / ${measureUnit}`}
                </DisabledFreightAndChargesText>
            )

        return (
            <FreightAndChargesText>
                {`Frete: ${getPriceString(
                    freightPrice
                )} / Encargo: ${getPriceString(chargesPrice)} / ${measureUnit}`}
            </FreightAndChargesText>
        )
    }

    const getOrderItemPrice = () => getPriceString(parseFloat(orderItem.price))

    if (isDisabled)
        return (
            <Container>
                <CloseIcon
                    data-testid="product-remove-icon"
                    onClick={() => onRemove(orderItem.product)}
                />

                <Content>
                    <NameColumn>
                        <DisabledProductName>
                            {orderItem.product.name}
                        </DisabledProductName>
                    </NameColumn>

                    <PriceColumn>
                        <FieldContainer>
                            <DisabledPriceLabel>
                                * INSIRA O PREÇO
                            </DisabledPriceLabel>
                            <InputMessage
                                name="unitPrice"
                                value={orderItem.unitPrice}
                                readOnly
                            />
                        </FieldContainer>

                        <FieldSeparatorContainer>
                            <FieldSeparator />
                        </FieldSeparatorContainer>

                        <FieldContainer>
                            <DisabledPriceLabel>
                                * INSIRA A QUANTIDADE
                            </DisabledPriceLabel>
                            <InputMessage
                                name="quantity"
                                value={orderItem.quantity}
                                readOnly
                            />
                        </FieldContainer>
                    </PriceColumn>

                    <TotalColumn>
                        <DisabledTotalText>TOTAL DO PRODUTO:</DisabledTotalText>
                        <DisabledTotalValue>
                            {getOrderItemPrice()}
                        </DisabledTotalValue>
                    </TotalColumn>
                </Content>
                <Content>
                    <PricePolicyColumn>
                        <DisabledPricePolicyText>
                            * Produto desativado
                        </DisabledPricePolicyText>
                    </PricePolicyColumn>
                    <FreightAndChargesColumn>
                        {!isFreight &&
                            isCharges &&
                            renderFreightOrCharges('Encargo', chargesPrice)}
                        {isFreight &&
                            !isCharges &&
                            renderFreightOrCharges('Frete', freightPrice)}
                        {isFreight && isCharges && renderFreightAndCharges()}
                    </FreightAndChargesColumn>
                </Content>
            </Container>
        )

    return (
        <Container>
            <CloseIcon
                data-testid="product-remove-icon"
                onClick={() => onRemove(orderItem.product)}
            />

            <Content>
                <NameColumn>
                    <ProductName>{orderItem.product.name}</ProductName>
                </NameColumn>

                <PriceColumn>
                    <FieldContainer>
                        <PriceLabel htmlFor="unitPrice">
                            * INSIRA O PREÇO
                        </PriceLabel>
                        <InputMessage
                            id="unitPrice"
                            name="unitPrice"
                            isInvalid={isPriceInvalid}
                            invalidMessage={invalidMessage}
                            placeholder={'Preço (R$)'}
                            value={orderItem.unitPrice}
                            onChange={handleOnChange}
                        />
                    </FieldContainer>

                    <FieldSeparatorContainer>
                        <FieldSeparator />
                    </FieldSeparatorContainer>

                    <FieldContainer>
                        <PriceLabel htmlFor="quantity">
                            * INSIRA A QUANTIDADE
                        </PriceLabel>
                        <InputMessage
                            id="quantity"
                            name="quantity"
                            placeholder={getQuantityPlaceholder(
                                orderItem.product
                            )}
                            value={orderItem.quantity}
                            onChange={handleOnChange}
                        />
                    </FieldContainer>
                </PriceColumn>

                <TotalColumn>
                    <TotalText>TOTAL DO PRODUTO:</TotalText>
                    <TotalValue>{getOrderItemPrice()}</TotalValue>
                </TotalColumn>
            </Content>
            <Content>
                <PricePolicyColumn data-testid="teste">
                    <PricePolicyText>
                        * Limite de Preço para este produto: de{' '}
                        <strong>R${formatDecimalPlace(minPrice, 2)}</strong> até{' '}
                        <strong>R${formatDecimalPlace(maxPrice, 2)}</strong>
                    </PricePolicyText>
                </PricePolicyColumn>
                <FreightAndChargesColumn>
                    {!isFreight &&
                        isCharges &&
                        renderFreightOrCharges('Encargo', chargesPrice)}
                    {isFreight &&
                        !isCharges &&
                        renderFreightOrCharges('Frete', freightPrice)}
                    {isFreight && isCharges && renderFreightAndCharges()}
                </FreightAndChargesColumn>
            </Content>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(196, 196, 199, 0.5);
    border: solid 1px #eceeee;
    background-color: #fbfbfb;
    min-height: 122px;
`

const CloseIcon = styled(MdClose)`
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 0;
    margin-top: 14px;
    margin-right: 14px;
`

const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 17px 24px;
`

const NameColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50px;
`

const ProductName = styled.span`
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.11;
    letter-spacing: 0.32px;
    color: ${Colors.brownishGrey};
`

const DisabledProductName = styled(ProductName)`
    opacity: 50%;
`

const PricePolicyColumn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const PricePolicyText = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.11px;
    color: ${Colors.brownishGrey};
`

const DisabledPricePolicyText = styled(PricePolicyText)`
    color: ${Colors.strawberry};
`

const FreightAndChargesColumn = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 30px;
`

const FreightAndChargesText = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.11px;
    color: ${Colors.brownishGrey};
`

const DisabledFreightAndChargesText = styled(FreightAndChargesText)`
    opacity: 50%;
`

const PriceColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.2fr 1fr;
    min-height: 50px;
`

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const FieldSeparatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const PriceLabel = styled.label`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    letter-spacing: 0.09px;
    color: ${Colors.brownishGrey};
`

const DisabledPriceLabel = styled(PriceLabel)`
    opacity: 50%;
`

const FieldSeparator = styled(MdClose)`
    color: ${Colors.brownishGrey};
`

const InputMessage = styled(InputWithMessage)`
    max-width: 120px;
`

const TotalColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const TotalText = styled.span`
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: ${Colors.brownishGrey};
    margin-right: 0.8vw;
`

const DisabledTotalText = styled(TotalText)`
    opacity: 50%;
`

const TotalValue = styled.span`
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.17px;
    text-align: right;
    color: ${Colors.orange};
`

const DisabledTotalValue = styled(TotalValue)`
    color: ${Colors.brownishGrey};
    opacity: 50%;
`

export default ProductCardView
