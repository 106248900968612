import { getTableauInfo, getToken } from '../environment'

export const queryViewPDF = async params => {
    const { BASE_URL, viewId } = getTableauInfo()

    const searchParams = new URLSearchParams(params)
    viewId.forEach(id => searchParams.append('viewId', id))

    return fetch(`${BASE_URL}?${searchParams.toString()}`, {
        method: 'GET',
        headers: {
            Authorization: `${getToken()}`,
        },
    }).then(async response => {
        if (response.status < 200 && response.status > 299) {
            throw new Error(
                `Falha ao gerar relatório. ${await response.json()}`
            )
        }

        return response
    })
}
