import { STATUS_OPTIONS_AND_TEXT } from '../../commons/Status'

export const getMountedStatus = status => STATUS_OPTIONS_AND_TEXT[status]

export const mountOrderItems = orderItems => {
    return orderItems
        .map(orderItem => ({
            productName: orderItem.product.name,
            productId: orderItem.product?.gcpId || orderItem.product._id,
            totalQuantity: orderItem.quantity,
            remainingQuantity: orderItem.quantity,
            measurementUnit: orderItem.product.measurementUnit,
            price: orderItem.price,
            id: orderItem._id,
            fragmentQuantity: '',
            unitPrice: orderItem.unitPrice,
        }))
        .sort((prev, next) => prev.id.localeCompare(next.id))
}

export const mountFragments = (order, fragmentedOrders) => {
    const orderedFragments = fragmentedOrders.sort(
        (a, b) => a.number - b.number
    )
    const mountedFragments = orderedFragments.map(fragment => {
        const mountedItems = mountFragmentedOrderItems(
            order.items,
            fragment.fragmentedOrderItems
        )

        return {
            ...fragment,
            orderNumber: order.orderNumber,
            fragmentedOrderItems: mountedItems,
        }
    })

    return mountedFragments
}

const mountFragmentedOrderItems = (orderItems, fragentedOrderItems) => {
    return fragentedOrderItems.map(fragmentedItem => {
        const originalOrderItem = orderItems.find(
            item =>
                item.product._id === fragmentedItem.productId ||
                item.product._id === fragmentedItem.productSydleId
        )

        return {
            ...fragmentedItem,
            measurementUnit: originalOrderItem.product.measurementUnit,
            productName: originalOrderItem.product.name,
            unQuantity: originalOrderItem.unQuantity,
            legacyCode: originalOrderItem.product?.legacyCode,
        }
    })
}

export const getUnQuantity = (unQuantity, quantity) => {
    const unWithoutQuantity = unQuantity.split(' ')[1]

    return `${quantity} ${unWithoutQuantity}`
}
