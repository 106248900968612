import React from 'react'
import styled from 'styled-components'
import Colors from '../../Colors'
import { Link } from 'react-router-dom'

export const ItemMenu = ({
    label,
    icon,
    linkTo,
    onClick,
    isClickable = true,
}) => {
    if (!linkTo) {
        return (
            <Item onClick={onClick} isClickable={isClickable}>
                {icon}
                <ItemLabel>{label}</ItemLabel>
            </Item>
        )
    }

    return (
        <Link to={linkTo}>
            <Item onClick={onClick} isClickable={isClickable}>
                {icon}
                <ItemLabel>{label}</ItemLabel>
            </Item>
        </Link>
    )
}

const Item = styled.div`
    cursor: ${props => (props.isClickable ? 'pointer' : 'default')};
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    padding: 11px 15px;
    color: ${Colors.brownishGrey};
    background-color: ${Colors.white};
    &:hover {
        background-color: ${props =>
            props.isClickable ? Colors.veryLightPink : Colors.white};
    }
    &:active {
        color: ${props =>
            props.isClickable ? Colors.orange : Colors.brownishGrey};
    }
`

const ItemLabel = styled.span`
    margin-left: 17px;
`

export default ItemMenu
