import React from 'react'

const OrderGroupIcon = ({ className, onClick }) => {
    return (
        <svg
            onClick={onClick}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
        >
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <path
                        d="M0 0L20 0 20 20 0 20z"
                        transform="translate(1088 143)"
                    />
                    <path
                        fill="currentColor"
                        d="M2.5 10.833h6.667V2.5H2.5v8.333zm0 6.667h6.667v-5H2.5v5zm8.333 0H17.5V9.167h-6.667V17.5zm0-15v5H17.5v-5h-6.667z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default OrderGroupIcon
