import React from 'react'
import styled from 'styled-components'

import Colors from '../../commons/Colors'
import DatePickerCalendar from '../../commons/components/DatePickerCalendar'

export default function ShipmentDeliveryDateComponent({
    value,
    setValue,
    hasNotRemainingQuantity,
}) {
    return (
        <Container>
            <Label htmlFor="deliveryDate">* Data de entrega da Remessa</Label>
            <DatePickerCalendar
                name="datePicker"
                onChange={e => setValue(e.target.value)}
                minDate={new Date()}
                disabled={hasNotRemainingQuantity()}
                value={value}
                id="deliveryDate"
            />
        </Container>
    )
}

const Container = styled.div`
    margin-top: 16px;
    background-color: ${Colors.white};
    border-radius: 5px;
    height: 48px;
    padding: 0 4px 0 20px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 30px;
`

const Label = styled.label`
    margin: 0;
    font-family: Roboto;
    color: ${Colors.brownishGrey};
    font-size: 12px;
    font-weight: bold;
    line-height: 1.6;
`
