import React from 'react'
import styled from 'styled-components'
import { ProgressSpinner } from 'primereact/progressspinner'

/**
 * @param {string} className - className do elemento
 */

export const Loading = ({ className }) => {
    return (
        <LoadingContainer className={className}>
            <StyledLoading strokeWidth="5" />
        </LoadingContainer>
    )
}

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const StyledLoading = styled(ProgressSpinner)`
    width: 25px;
    height: 25px;
    align-self: center;

    @keyframes p-progress-spinner-color {
        100%,
        0% {
            stroke: #b15717;
        }
    }
`

export default Loading
