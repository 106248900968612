import { getBaseURL, getToken } from '../environment'

const baseURL = `${getBaseURL()}/products`

export const getAllProductsClassifications = () => {
    return fetch(`${baseURL}/classifications`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(error => {
            console.error('Erro: ' + JSON.stringify(error))
            throw error
        })
}
