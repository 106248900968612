import React from 'react'

import OrderCard from './OrderCard'
import FragmentedOrderCardComponent from './FragmentedOrderCardComponent'
import OrderGroupCard from '../orderGroup/OrderGroupCard'
import LoadingOrderCard from './LoadingOrderCard'
import SimpleBillingCardComponent from './SimpleBillingCardComponent'

const CustomCard = props => {
    if (props.loading) {
        return <LoadingOrderCard />
    }

    if (props.isOrderGroup) return <OrderGroupCard {...props} />

    if (props.isFragmentedOrder)
        return <FragmentedOrderCardComponent {...props} />

    if (props.isUseShipment) return <SimpleBillingCardComponent {...props} />

    return <OrderCard {...props} />
}

export default CustomCard
