import React from 'react'
import styled from 'styled-components'

import BasicRoundButton from '../../commons/components/RoundButton'
import Colors from '../../commons/Colors'
import { getMeasurementUnitText } from '../../commons/MeasurementUnitUtils'
import { STATUS, STATUS_TEXT } from '../../commons/Status'
import { dateFormat } from '../../commons/DateUtils'
import { getDecimalString, getPriceString } from '../../price/PriceUtils'
import { formatDecimalPlace } from '../../commons/NumberUtils'

export default function ShipmentCardComponent({
    shipment,
    simpleBilling,
    onDelete,
}) {
    const renderItems = () => {
        return shipment.shipmentItems.map(shipmentItem => {
            const orderItem = simpleBilling.items.find(
                orderItem =>
                    orderItem.product?.gcpId === shipmentItem.productId ||
                    orderItem.product._id === shipmentItem.productId ||
                    orderItem.product._id === shipmentItem?.productSydleId
            )

            return (
                <TableRow>
                    <Cell>
                        <BlackBoldText>{orderItem.product.name}</BlackBoldText>
                    </Cell>
                    <Cell>
                        <Text>
                            {getMeasurementUnitText(
                                orderItem.product.measurementUnit
                            )}
                        </Text>
                    </Cell>
                    <Cell>
                        <BlackBoldText>
                            {formatDecimalPlace(shipmentItem.quantity)}
                        </BlackBoldText>
                    </Cell>
                    <Cell>
                        <Text>{getDecimalString(shipmentItem.price, 2)}</Text>
                    </Cell>
                </TableRow>
            )
        })
    }

    const handleOnDelete = () => {
        onDelete(shipment.index, shipment.id)
    }

    const getStatusChip = () => {
        let chipColor
        switch (shipment.status.name) {
            case STATUS.EM_PRODUCAO:
                chipColor = Colors.orangeTwo
                break
            case STATUS.EM_ESTOQUE:
                chipColor = Colors.macaroniAndCheese
                break
            case STATUS.EM_EXPEDICAO_TRANSITO:
                chipColor = Colors.deepSeaBlue
                break
            case STATUS.ENTREGUE:
                chipColor = Colors.bluishGreen
                break
            default:
                chipColor = Colors.lightRed
        }

        return (
            <StatusChip backgroundColor={chipColor}>
                <StatusChipText>
                    {STATUS_TEXT[shipment.status.name]}
                </StatusChipText>
            </StatusChip>
        )
    }

    const renderShipmentInfo = () => {
        if (shipment.id) return `Remessa ${shipment.number}`
    }

    const renderOrderInfo = () => {
        if (shipment.id)
            return `Pedido Nº ${simpleBilling.orderNumber}/${shipment.number}`
    }

    const isCancelationEnabled = () =>
        shipment.status?.name === STATUS.EM_ANALISE

    return (
        <Container>
            <div>
                <CardHeader>
                    <CardShipmentInfo>{renderShipmentInfo()}</CardShipmentInfo>
                    {getStatusChip()}
                </CardHeader>
                <CardInfoContainer>
                    <BlackBoldText>
                        Entrega em: {dateFormat(shipment.deliveryDate)}
                    </BlackBoldText>
                    <CardOrderInfo>{renderOrderInfo()}</CardOrderInfo>
                </CardInfoContainer>
                <ItemsTable>
                    <TableHeader>
                        <Cell>
                            <GreyBoldText>Produto</GreyBoldText>
                        </Cell>
                        <Cell>
                            <GreyBoldText>Unidade</GreyBoldText>
                        </Cell>
                        <Cell>
                            <GreyBoldText>Qtid.</GreyBoldText>
                        </Cell>
                        <Cell>
                            <GreyBoldText>Preço (R$)</GreyBoldText>
                        </Cell>
                    </TableHeader>
                    {renderItems()}
                    <TableFooter>
                        {Boolean(shipment.subtotal !== shipment.price) && (
                            <>
                                <FooterRow>
                                    <Text>Subtotal</Text>
                                    <Text>
                                        {getDecimalString(shipment.subtotal, 2)}
                                    </Text>
                                </FooterRow>
                                {Boolean(shipment.freightPrice) && (
                                    <FooterRow>
                                        <Text>Frete</Text>
                                        <Text>
                                            {getDecimalString(
                                                shipment.freightPrice,
                                                2
                                            )}
                                        </Text>
                                    </FooterRow>
                                )}
                                {Boolean(shipment.chargesPrice) && (
                                    <FooterRow>
                                        <Text>Encargos</Text>
                                        <Text>
                                            {getDecimalString(
                                                shipment.chargesPrice,
                                                2
                                            )}
                                        </Text>
                                    </FooterRow>
                                )}
                            </>
                        )}
                        <FooterRow>
                            <GreyBoldText>TOTAL DO PEDIDO</GreyBoldText>
                            <BlackBoldText>
                                {getPriceString(shipment.price)}
                            </BlackBoldText>
                        </FooterRow>
                    </TableFooter>
                </ItemsTable>
            </div>
            {isCancelationEnabled() && (
                <CardFooter>
                    <DeleteButton onClick={handleOnDelete}>
                        Excluir Remessa
                    </DeleteButton>
                </CardFooter>
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: ${Colors.lightBlueGreyFour};
`

const CardHeader = styled.div`
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const CardInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
`
const CardShipmentInfo = styled.span``

const CardOrderInfo = styled.span`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    color: ${Colors.deepSeaBlue};
`

const ItemsTable = styled.div`
    margin: 18px 10px 10px 10px;
    display: grid;
    gap: 5px;
`

const TableRow = styled.div`
    display: grid;
    grid-template-columns: 35% 25% 20% 30%;
    gap: 5px;
    padding: 10px 10px 10px 10px;
`

const TableHeader = styled(TableRow)`
    background-color: ${Colors.white};
    border-radius: 4px;
`

const Cell = styled.div`
    display: flex;
    align-items: center;
`

const Text = styled.p`
    font-family: Roboto;
    font-size: 12px;
    color: ${Colors.blueGrey};
    margin: 0;
`

const GreyBoldText = styled(Text)`
    font-weight: bold;
`

const BlackBoldText = styled(Text)`
    font-weight: bold;
    color: ${Colors.black};
`

const DeleteButton = styled(BasicRoundButton)`
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: ${Colors.white};
    border-color: ${Colors.orange};
    color: ${Colors.orange};
`

const CardFooter = styled.div`
    display: flex;
    justify-content: center;
    justify-self: flex-end;
    padding: 10px;
`

const TableFooter = styled(TableHeader)`
    background-color: ${Colors.white};
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
`

const FooterRow = styled.div`
    display: flex;
    justify-content: space-between;
`

const StatusChip = styled.div`
    width: max-content;
    height: 20px;
    padding: 0 18px 0 18px;
    border-radius: 4px;
    background-color: ${props => props.backgroundColor};

    display: flex;
    align-items: center;

    position: relative;
`

const StatusChipText = styled.p`
    margin: 0;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: ${Colors.white};
`
