import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Colors from '../Colors'
import RoundButton from '../components/RoundButton'

/**
 * @param {string} title - titulo do formulario
 * @param {string} editionTitle - titulo do formulario de edição
 * @param {boolean} isEdition - seta que é um formulario de edição
 * @param {string} linkTo - url para ser redirecionado
 * @param {React.FunctionComponent} children - componente a ser renderizado
 */

export const BasicFormScreen = ({
    title,
    editionTitle,
    isEdition,
    linkTo,
    children,
}) => {
    return (
        <FormContainer>
            <Title>{isEdition ? editionTitle : title}</Title>

            <TopElementsContainer>
                <Information>*Informações obrigatórias</Information>
                <Link to={linkTo}>
                    <BackButton>Cancelar</BackButton>
                </Link>
            </TopElementsContainer>

            {children}
        </FormContainer>
    )
}

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const TopElementsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    padding-top: 10px;
`

const Information = styled.span`
    align-self: flex-end;
    opacity: 0.5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    color: ${Colors.black};
`

const BackButton = styled(RoundButton)`
    background-color: ${Colors.orange};
    border-color: ${Colors.orange};
    width: 106px;
    height: 32px;
`

const Title = styled.span`
    margin: 0;
    color: ${Colors.orange};
    font-family: Roboto;
    font-size: 20px;
    padding-top: 15px;
`

export default BasicFormScreen
