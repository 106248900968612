import React from 'react'
import styled from 'styled-components'
import { TabView as pTabView } from 'primereact/tabview'
import Colors from '../Colors'

/**
 *
 * @param {React.FunctionComponent} children - componente a ser renderizado
 */

export const TabView = ({ className, children, activeIndex, onTabChange }) => {
    return (
        <TabsContainer
            className={className}
            activeIndex={activeIndex}
            onTabChange={onTabChange}
        >
            {children}
        </TabsContainer>
    )
}

const TabsContainer = styled(pTabView)`
    padding: 0 !important;

    .p-tabview-nav {
        display: flex;
        justify-content: center;
        background-color: #fbfbfb !important;
    }

    a:not(.p-disabled):focus {
        box-shadow: none !important;
    }

    a[id^='pr_id_'] {
        background-color: transparent !important;
        color: ${Colors.blueGrey} !important;
        border: none !important;
        margin-bottom: 3px !important;
    }

    .p-tabview-selected.p-highlight a {
        color: ${Colors.orange} !important;
        border-bottom: 2px solid ${Colors.orange} !important;
    }

    .p-tabview-title {
        font-family: Roboto;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: -0.2px;
    }

    .p-tabview-panels {
        background-color: #fbfbfb !important;
        border: none !important;
        border-top: 1px solid #eceeee !important;
        padding: 0 !important;
    }
`

export default TabView
