import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Colors from '../Colors'

import OrderCard from '../../dashboard/OrderCard'
import AutoCompleteAsync from './AutoCompleteAsync'

/**
 *@param {array} value - valor do componente
 *@param {event} onChange - chamado quando o valor é alterado
 *@param {array} options - array com os itens a serem mostrados como opção
 **/

const OrderSearchAutoComplete = ({ value, onChange, options }) => {
    const [selectedOrders, setSelectedOrders] = useState([])

    useEffect(() => {
        const newSelectedItems = value.map(order => mountDropdownOption(order))
        setSelectedOrders(newSelectedItems)
    }, [value])

    const fetchOrders = filter => {
        return Promise.resolve(
            options
                .map(order => mountDropdownOption(order))
                .filter(option =>
                    option.label.toLowerCase().includes(filter.toLowerCase())
                )
        )
    }
    const mountDropdownOption = order => {
        return {
            value: order,
            label: `${order.farm.owner.name} ${order.orderNumber} ${order.farm.fancyName} ${order.category.name} ${order.seller.name}`,
        }
    }

    const renderOrderCard = props => {
        const { value } = props.data

        return (
            <OrderCardContainer isHighlighted={false} key={Math.random()}>
                <OrderCard
                    {...value}
                    isOrdersDropdown={true}
                    setSelectedCard={() => {}}
                />
            </OrderCardContainer>
        )
    }

    const onSelectOrder = selectedOptions => {
        if (selectedOptions.length !== selectedOrders.length) {
            setSelectedOrders(prev => prev.concat(value))
            onChange({
                value: options.filter(order =>
                    selectedOptions.some(
                        option => option.value._id === order._id
                    )
                ),
            })
        }
    }

    return (
        <AutoCompleteAsync
            isMulti
            useOrangeMode
            useCustomInput
            menuDataTestId="orderAutoComplete"
            fetchSuggestions={fetchOrders}
            placeholder="Pesquise Nº do Pedido, Cliente, Fazenda ou Rota..."
            customInputText={{
                singular: 'pedido selecionado | ',
                plural: 'pedidos selecionados | ',
            }}
            value={selectedOrders}
            onChange={onSelectOrder}
            renderCustomComponent={{
                option: renderOrderCard,
            }}
            getCustomStyle={{
                menuList: styles => ({
                    ...styles,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    padding: '0px 15px',
                    maxHeight: '520px',
                }),
                option: styles => {
                    return {
                        ...styles,
                        backgroundColor: 'transparent',
                        width: 'unset',
                        display: 'flex',
                        alignItems: 'strech',
                        padding: '0',
                        ['&:hover']: { backgroundColor: 'transparent' },
                    }
                },
            }}
        />
    )
}

const CustomAutoCompleteInput = styled.div`
    font-family: Open Sans, Helvetica Neue;
    font-size: 14px;
    color: ${Colors.brownishGrey};
`

const OrderCardContainer = styled.div`
    width: fit-content;
    padding: 17px;
    display: flex;
    align-items: stretch;
    border-radius: 4px;

    cursor: pointer;

    :hover {
        background-color: ${Colors.veryLightPink};
        transition: background-color 0.2s;
    }
`

export default OrderSearchAutoComplete
