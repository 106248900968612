import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import * as UserApi from '../login/UserAPI'

import UrgentOrderIndicator from '../assets/pedido-urgente@3x.png'
import DatePickerCalendar from '../commons/components/DatePickerCalendar'
import { getDaysDiff } from '../commons/DateUtils'
import BasicDropdown from '../commons/components/Dropdown'
import Input from '../commons/components/Input'
import { Label } from '../commons/commonStyles'

const DeliveryAndSellerInformation = ({
    setDeliveryAndSellerInformation,
    mill,
    farm,
    deliveryDate,
    seller: selectedSeller,
    isConfirmedOrder,
    isEdition,
    invalidFieldsHandler,
    isNewSimpleBilling,
}) => {
    const [orderIsUrgent, setOrderIsUrgent] = useState(false)
    const [dropDownOptions, setDropDownOptions] = useState([])
    const [sellers, setSellers] = useState([])
    const [stcUsers, setStcUsers] = useState([])
    const [availableSellers, setAvailableSellers] = useState([])
    const { invalidFields, getInvalidField, setValidField } =
        invalidFieldsHandler

    useEffect(() => {
        const getAllStc = async () => {
            const response = await UserApi.getAllStcUsers(mill.gcpId)
            if (response?.stcUsers) {
                setStcUsers(response.stcUsers)
            }
        }
        const getAllSellersAndStc = async () => {
            const response = await UserApi.getAllSellersAndStc()
            if (response?.data) {
                setAvailableSellers(response.data)
            }
        }
        try {
            getAllSellersAndStc()
            getAllStc()
        } catch (error) {
            console.error(error)
        }
    }, [mill])

    useEffect(() => {
        const loadOptions = (farm, stcUsers) => {
            const { sellerOptions, sellers } = mountSellerOptions(farm)
            const { stcOptions, stcs } = mountStcOptions(stcUsers)
            setSellers(concatSellersAndRemoveDuplicated(sellers, stcs, '_id'))

            if (sellerOptions && stcOptions) {
                const options = concatSellersAndRemoveDuplicated(
                    sellerOptions,
                    stcOptions,
                    'value'
                )
                setDropDownOptions(options)
            }
        }

        loadOptions(farm, stcUsers)
    }, [farm, stcUsers])

    useEffect(() => {
        if (isEdition) {
            const seller = sellers.find(
                seller => seller._id === selectedSeller._id
            )
            if (seller)
                setDeliveryAndSellerInformation('seller', {
                    ...seller,
                    ...selectedSeller,
                })
        }

        // eslint-disable-next-line
    }, [isEdition, sellers])

    function mountStcOptions(stcUsers) {
        if (stcUsers) {
            const stcOptions = stcUsers.map(stc => {
                return {
                    label: stc.name,
                    value: stc._id,
                }
            })

            return { stcOptions, stcs: stcUsers }
        }
        return { stcOptions: [], stcs: [] }
    }

    function mountSellerOptions(farm) {
        if (farm.categories) {
            const sellers = farm.categories.map(category => {
                return {
                    ...category.seller,
                    categoryName: category.name,
                    categoryId: category._id,
                    isSeller: true,
                }
            })

            const sellerOptions = sellers.map(seller => {
                return {
                    label: seller.name,
                    value: seller._id,
                }
            })

            return { sellerOptions, sellers }
        }
        return { sellerOptions: [], sellers: [] }
    }

    const concatSellersAndRemoveDuplicated = (sellers, stcs, fieldName) => {
        const optionsToFilter = [...sellers, ...stcs]

        const filteredOptions = new Map()
        optionsToFilter.forEach(option => {
            if (!filteredOptions.has(option[fieldName])) {
                filteredOptions.set(option[fieldName], option)
            }
        })

        return [...filteredOptions.values()]
    }

    const getCategoriesOptions = () => {
        let categoriesNames = farm?.categories?.map(category => category.name)

        if (!categoriesNames) return []

        const set = new Set(categoriesNames)
        categoriesNames = [...set.values()]

        return categoriesNames.map(categoryName => ({
            label: categoryName,
            value: categoryName,
        }))
    }

    const handleDeliveryDate = (deliveryDate, stockSafetyDays) => {
        const isInvalidField = getInvalidField('deliveryDate', invalidFields)

        if (isInvalidField) setValidField('deliveryDate')

        if (deliveryDate !== undefined || '') {
            setDeliveryAndSellerInformation('deliveryDate', deliveryDate)

            setOrderIsUrgent(
                getDaysDiff(new Date(deliveryDate, true)) <= stockSafetyDays
            )
        }
    }

    const handleSellerChange = e => {
        const sellerId = e.value
        const seller = sellers.find(seller => seller._id === sellerId)
        const currentSeller = availableSellers.find(
            sellerRate => sellerRate._id === seller._id
        )
        const discountMaxPercentage = currentSeller?.discountMaxPercentage
        const increaseMaxPercentage = currentSeller?.increaseMaxPercentage
        const sellerGcpId = currentSeller?.gcpId
        const sellerLogin = currentSeller?.login
        const selectedSeller = {
            ...seller,
            discountMaxPercentage,
            increaseMaxPercentage,
            gcpId: sellerGcpId,
            sellerLogin: sellerLogin,
        }
        setDeliveryAndSellerInformation('seller', selectedSeller)
        setDeliveryAndSellerInformation('category', getCategory(selectedSeller))
    }

    const getCategory = seller => {
        if (!seller.categoryName) return
        return {
            name: seller.categoryName,
            gcpId: seller.categoryId,
            seller: { _id: seller._id },
        }
    }

    const handleOnChangeCategory = (seller, category) => {
        const selectedSeller = {
            ...seller,
            categoryName: category,
        }

        setDeliveryAndSellerInformation('seller', selectedSeller)

        setDeliveryAndSellerInformation('category', getCategory(selectedSeller))
    }
    return (
        <UpperPanel isNewSimpleBilling={isNewSimpleBilling}>
            {!isNewSimpleBilling() && (
                <LabelWithAnswer data-testid="delivery-date-container">
                    <Label htmlFor="deliveryDate">* DATA DE ENTREGA</Label>
                    <DatePickerCalendar
                        isInvalidDate={getInvalidField(
                            'deliveryDate',
                            invalidFields
                        )}
                        onFocus={() => setValidField('deliveryDate')}
                        name="datePicker"
                        value={deliveryDate}
                        onChange={e =>
                            handleDeliveryDate(
                                e.target.value,
                                farm.stockSafetyDays
                            )
                        }
                        minDate={new Date()}
                        disabled={!farm.stockSafetyDays}
                        id="deliveryDate"
                    />
                    {orderIsUrgent && (
                        <UrgentOrderImage
                            data-testid="urgent-indicator"
                            src={UrgentOrderIndicator}
                        />
                    )}
                </LabelWithAnswer>
            )}

            <LabelWithAnswer>
                <Label htmlFor="seller">* VENDEDOR ASSOCIADO À VENDA</Label>
                <Dropdown
                    isInvalid={getInvalidField('seller', invalidFields)}
                    onFocus={() => setValidField('seller')}
                    id="seller"
                    placeholder="Selecione um vendedor"
                    options={dropDownOptions}
                    value={selectedSeller}
                    getValue={value => value._id}
                    disabled={
                        !dropDownOptions.length ||
                        !farm.stockSafetyDays ||
                        isConfirmedOrder
                    }
                    onChange={handleSellerChange}
                />
            </LabelWithAnswer>

            {Object.keys(selectedSeller).length > 0 && (
                <Label htmlFor="categoryName" id="categoryNameLabel">
                    ROTA ASSOCIADA AO VENDEDOR
                    <LabelWithAnswer>
                        {selectedSeller.isSeller || isConfirmedOrder ? (
                            <Input
                                id="categoryName"
                                value={selectedSeller.categoryName || '-'}
                                readOnly={true}
                            />
                        ) : (
                            <Dropdown
                                ariaLabelledBy="categoryNameLabel"
                                id="categoryName"
                                value={selectedSeller.categoryName}
                                onChange={e =>
                                    handleOnChangeCategory(
                                        selectedSeller,
                                        e.value
                                    )
                                }
                                options={getCategoriesOptions()}
                                disabled={
                                    !dropDownOptions.length ||
                                    !farm.stockSafetyDays ||
                                    isConfirmedOrder
                                }
                            />
                        )}
                    </LabelWithAnswer>
                </Label>
            )}
        </UpperPanel>
    )
}

const UpperPanel = styled.div`
    display: grid;
    grid-template-columns: ${props =>
        props.isNewSimpleBilling() ? '343px 343px' : '150px 343px 343px'};
    grid-gap: 2%;
    margin-bottom: 16px;
`

const LabelWithAnswer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 2%;
`

const UrgentOrderImage = styled.img`
    width: 156px;
    height: 23px;
`

const Dropdown = styled(BasicDropdown)`
    width: 343px;
`

export default DeliveryAndSellerInformation
