import * as NewsAPI from '../NewsAPI'
import * as NewsUtils from '../utils/NewsUtils'

export const NEWS_KEY = NewsUtils.KEYS.NEWS_KEY

export function newsRepository() {
    const fetchVersions = async () => {
        try {
            const result = await NewsAPI.getAllVersions()
            if (result.data) {
                return result.data
            }
        } catch (err) {
            console.error(err)
        }
    }

    return {
        getAll: async function () {
            const versions = await fetchVersions()
            const rawOldNews = localStorage.getItem(NEWS_KEY)
            if (!rawOldNews) return NewsUtils.mountFeaturesToRender(versions)
            const oldNews = JSON.parse(rawOldNews)
            const updatedNews = this.updateBaseRepo(versions, oldNews)
            return NewsUtils.mountFeaturesToRender(versions, updatedNews)
        },

        updateBaseRepo: function (versions, oldNews) {
            const featureIds = []
            versions.forEach(version =>
                version.features.forEach(feature => featureIds.push(feature.id))
            )
            const updatedRepo = oldNews.filter(feature =>
                featureIds.includes(feature.featureId)
            )
            localStorage.removeItem(NEWS_KEY)
            localStorage.setItem(NEWS_KEY, JSON.stringify(updatedRepo))
            return updatedRepo
        },

        getOldNews: function () {
            const rawOldNews = localStorage.getItem(NEWS_KEY)
            if (!rawOldNews) return null
            const oldNews = JSON.parse(rawOldNews)
            return oldNews
        },

        findById: function (featureId) {
            const rawOldNews = localStorage.getItem(NEWS_KEY)
            if (!rawOldNews) return null
            const oldNews = JSON.parse(rawOldNews)
            const result = oldNews.find(
                feature => feature.featureId === featureId
            )
            return result
        },

        update: function (featureId, isRemoved = true) {
            const rawOldNews = localStorage.getItem(NEWS_KEY)
            localStorage.removeItem(NEWS_KEY)
            if (!rawOldNews) {
                localStorage.setItem(
                    NEWS_KEY,
                    JSON.stringify([{ featureId, isRemoved }])
                )

                return
            }
            const oldNews = JSON.parse(rawOldNews)
            const filteredOldNews = oldNews.filter(
                oldFeature => oldFeature.featureId !== featureId
            )
            const itemsToInsert = [...filteredOldNews, { featureId, isRemoved }]
            localStorage.setItem(NEWS_KEY, JSON.stringify(itemsToInsert))
            return
        },
    }
}
