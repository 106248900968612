import React from 'react'
import styled from 'styled-components'
import { MdDelete, MdDone, MdInfo } from 'react-icons/md'

import Colors from '../../commons/Colors'
import Loading from '../../commons/components/Loading'
import InputWithMessage from '../../commons/components/InputWithMessage'
import BasicDropdown from '../../commons/components/Dropdown'
import RoundButton from '../../commons/components/RoundButton'
import { Label } from '../../commons/commonStyles'
import BasicFormScreen from '../../commons/components/BasicFormScreen'
import BasicPage from '../../commons/components/BasicPage'
import Tooltip from '../../commons/components/Tooltip'
import { ClientsSelection } from './ClientsSelection'

export const PorfolioForm = ({
    isLoadingSellers,
    isLoadingClients,
    isEdition,
    form,
    usersDropdownOptions,
    sendingToAPI,
    invalidFields,
    selectedUsers,
    setInvalidFields,
    handleChange,
    handleUserChange,
    handleClientsAssociate,
    handleSelectClient,
    nonAssociatedClients,
    associatedClients,
    handleOnPressSave,
    deleteSelectedUser,
    handleFilter,
    associatedClientsFilter,
    nonAssociatedClientsTotal,
    isValidatingClient,
    isLoadingNonAssociate,
}) => {
    return (
        <BasicPage
            title={'Nova Carteira'}
            isEdition={isEdition}
            editionTitle={'Editar Carteira'}
        >
            <BasicFormScreen linkTo={'/carteiras'}>
                {Object.keys(form).length === 0 ? (
                    <Loading data-testid="form-loading" />
                ) : (
                    <Container>
                        <FormDescriptionContainer>
                            <FormDescriptionText>
                                Obs: Não serão permitidas associações onde possa
                                haver duplicidade de informações. Exemplo: Duas
                                carteiras distintas cujo o mesmo cliente esteja
                                associado a um mesmo vendedor.
                            </FormDescriptionText>
                            <FormDescriptionText>
                                Para cenários semelhantes, o sistema irá
                                sinalizar as possíveis inconsistências.
                            </FormDescriptionText>
                        </FormDescriptionContainer>
                        <form autoComplete="off">
                            <MainInfoContainer>
                                <FormField>
                                    <PortfolioHeader>
                                        <Label htmlFor="name">
                                            * Nome da Carteira
                                        </Label>
                                        <Tooltip
                                            label={
                                                'Não será permitido o cadastro de duas carteiras com o mesmo nome'
                                            }
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </PortfolioHeader>
                                    <InputWithMessage
                                        id="name"
                                        name="name"
                                        value={form.name}
                                        onChange={e => handleChange(e)}
                                        isInvalid={invalidFields.name}
                                        onFocus={() =>
                                            setInvalidFields(
                                                currentInvalidFields => ({
                                                    ...currentInvalidFields,
                                                    name: false,
                                                })
                                            )
                                        }
                                    />
                                </FormField>
                            </MainInfoContainer>

                            <SellerContent>
                                <PortfolioHeader>
                                    <Label>* Vendedores associados</Label>
                                    <Tooltip
                                        label={
                                            'É possível vincular a esta carteira vendedores que já estejam cadastrados na base.'
                                        }
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                </PortfolioHeader>
                                {isLoadingSellers ? (
                                    <Loading data-testid="sellers-loading" />
                                ) : (
                                    <SelectSellerContainer>
                                        <Dropdown
                                            name="seller"
                                            dataKey={usersDropdownOptions.length.toString()}
                                            data-testid="sellers-dropdown"
                                            placeholder="Selecione um vendedor cadastrado"
                                            value={[]}
                                            options={usersDropdownOptions}
                                            onChange={target =>
                                                handleUserChange(target)
                                            }
                                            isInvalid={invalidFields.users}
                                            onFocus={() =>
                                                setInvalidFields(
                                                    currentInvalidFields => ({
                                                        ...currentInvalidFields,
                                                        users: false,
                                                    })
                                                )
                                            }
                                        />
                                    </SelectSellerContainer>
                                )}
                                {Object.keys(selectedUsers).length !== 0 && (
                                    <SelectedSellerContainer>
                                        {Object.entries(selectedUsers).map(
                                            ([id, user]) => (
                                                <SellerCard
                                                    key={id}
                                                    data-testid={`user-card-${user.name}`}
                                                >
                                                    <SellerCardText>
                                                        {user.name}
                                                    </SellerCardText>
                                                    <DeleteIcon
                                                        data-testid="delete-seller-button"
                                                        onClick={() =>
                                                            deleteSelectedUser(
                                                                id
                                                            )
                                                        }
                                                    />
                                                </SellerCard>
                                            )
                                        )}
                                    </SelectedSellerContainer>
                                )}
                            </SellerContent>

                            {!isLoadingSellers && isLoadingClients ? (
                                <Loading data-testid="clients-loading" />
                            ) : Object.keys(selectedUsers).length !== 0 ? (
                                <ClientsSelection
                                    associatedClientsFilter={
                                        associatedClientsFilter
                                    }
                                    nonAssociatedClients={nonAssociatedClients}
                                    nonAssociatedClientsTotal={
                                        nonAssociatedClientsTotal
                                    }
                                    associatedClients={associatedClients}
                                    handleFilter={handleFilter}
                                    handleSelectClient={handleSelectClient}
                                    handleClientsAssociate={
                                        handleClientsAssociate
                                    }
                                    isValidatingClient={isValidatingClient}
                                    isLoadingNonAssociate={
                                        isLoadingNonAssociate
                                    }
                                />
                            ) : null}

                            <FooterActions>
                                <RoundButton
                                    loading={sendingToAPI}
                                    onClick={handleOnPressSave}
                                    type="button"
                                    disabled={sendingToAPI || isLoadingSellers}
                                >
                                    SALVAR <MdDone />
                                </RoundButton>
                            </FooterActions>
                        </form>
                    </Container>
                )}
            </BasicFormScreen>
        </BasicPage>
    )
}

const Container = styled.div``

const FormPanel = styled.div`
    padding: 2.8vh 1.3vw;
    margin-bottom: 2.2vh;
    border-radius: 3px;
    border: solid 1px ${Colors.lightBlueGreyFour};
    background-color: ${Colors.white};
`

const FormField = styled.div`
    display: flex;
    flex-direction: column;
`

const FormDescriptionContainer = styled.div`
    padding: 15px 0 10px 0;
    display: flex;
    flex-direction: column;
`

const FormDescriptionText = styled.span`
    display: flex;
    opacity: 0.5;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    color: ${Colors.black};
`

const MainInfoContainer = styled(FormPanel)`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1vw;
`

const SelectSellerContainer = styled.div`
    display: flex;
    margin-bottom: 2.2vh;
    width: 100%;
`
const Dropdown = styled(BasicDropdown)`
    width: 46vw;
`

const SelectedSellerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.2vh;
    width: 100%;
`
const SellerCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: 4px;
    border: solid 1px ${Colors.lightBlueGreyFour};
    box-shadow: 1px 1px 1px 1px ${Colors.lightBlueGreyFour};

    width: 46vw;
    height: 50px;
    padding: 0 0.8vw 0 0.5vw;
    margin-bottom: 2vh;
    background-color: #fbfbfb;
`
const SellerCardText = styled.span`
    align-items: center;
    font-weight: bold;
    font-family: Roboto;
    letter-spacing: 0.4px;
    color: ${Colors.brownishGrey};
    font-size: 14px;
`

const SellerContent = styled(FormPanel)`
    padding-bottom: 0;
    width: 100%;
`

const PortfolioHeader = styled.div`
    display: flex;
    align-items: center;
`

const DeleteIcon = styled(MdDelete)`
    cursor: pointer;
    color: ${Colors.brownishGrey};
    font-size: 24px;
`
const InfoIcon = styled(MdInfo)`
    cursor: pointer;
    color: ${Colors.brownishGrey};
    font-size: 14px;
    margin-left: 0.2vw;
    width: 14px;
`

const FooterActions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 2.5rem 0;
`

export default PorfolioForm
