import React from 'react'
import Board from 'react-trello'
import styled from 'styled-components'

import CustomLaneHeader from '../CustomLaneHeader'
import CustomCard from '../CustomCard'
import OrderModal from '../OrderModal'
import Confirm from '../../commons/components/Confirm'
import OrderGroupModal from '../../orderGroup/OrderGroupModal'
import KanbanTopBar from '../../orderGroup/KanbanTopBar'

import { LOADING_BOARD } from '../DashboardScreenUtils'
import FragmentedOrderModal from '../FragmentedOrderModal'

export default function DashboardView({
    loading,
    selectedOrders,
    handleOrderGroupModal,
    showOrderGroupModal,
    selectedOrderCard,
    setSelectedOrderCard,
    selectedFragmentedOrder,
    setSelectedFragmentedOrder,
    filteredBoardData,
    searchTerm,
    setSearchTerm,
    setFilterType,
    setEventBus,
    handleDragEnd,
    showOrderConfirm,
    setShowOrderConfirm,
    handleButtonMoveClick,
    cardDetails,
    sourceLane,
    showOrderGroupConfirm,
    setShowOrderGroupConfirm,
    reloadDashboardData,
}) {
    return (
        <Container data-testid="dashboard">
            <KanbanTopBar
                buttonDisabled={selectedOrders.length <= 1}
                buttonOnClick={() => handleOrderGroupModal(true)}
                filterValue={searchTerm}
                setFilterValue={setSearchTerm}
                setFilterType={setFilterType}
            />

            <KanbanBoard
                data-testid="kanbanBoard"
                data={loading ? LOADING_BOARD : filteredBoardData}
                eventBusHandle={setEventBus}
                handleDragEnd={handleDragEnd}
                components={{
                    LaneHeader: CustomLaneHeader,
                    Card: CustomCard,
                }}
            />

            {showOrderGroupModal && (
                <OrderGroupModal
                    onClose={() => {
                        handleOrderGroupModal(false)
                    }}
                />
            )}

            {selectedOrderCard && (
                <OrderModal
                    selectedOrderId={selectedOrderCard}
                    clearSelectedOrderId={() => setSelectedOrderCard()}
                />
            )}

            {selectedFragmentedOrder && (
                <FragmentedOrderModal
                    clearSelectedOrderId={() => setSelectedFragmentedOrder()}
                    selectedFragment={selectedFragmentedOrder}
                    reloadDashboardData={reloadDashboardData}
                />
            )}

            <Confirm
                onClose={() => {
                    setShowOrderConfirm(false)
                }}
                visible={showOrderConfirm}
                title='Deseja mover este pedido para a situação "Entregue"?'
                actionButtonText="Mover"
                actionButtonFunction={() => {
                    handleButtonMoveClick(cardDetails, sourceLane)
                }}
            >
                {
                    <span>
                        Tem certeza de que deseja mover o{' '}
                        <strong>pedido Nº {cardDetails.orderNumber}</strong> da
                        situação <strong>{sourceLane.description}</strong> para
                        a situação <strong>"Entregue"</strong>?
                        <br />
                        Após movê-lo, esta ação <strong>não</strong> poderá ser
                        desfeita, e o pedido será dado como concluído.{' '}
                    </span>
                }
            </Confirm>

            <Confirm
                onClose={() => {
                    setShowOrderGroupConfirm(false)
                }}
                visible={showOrderGroupConfirm}
                title='Deseja mover esta carga para a situação "Entregue"?'
                actionButtonText="Mover"
                actionButtonFunction={() => {
                    handleButtonMoveClick(cardDetails, sourceLane)
                }}
            >
                {
                    <span>
                        Tem certeza de que deseja mover a
                        <strong>
                            {' '}
                            carga Nº {cardDetails.orderGroupNumber}
                        </strong>{' '}
                        da situação <strong>{sourceLane.description}</strong>{' '}
                        para a situação <strong>"Entregue"</strong>?
                        <br />
                        Após movê-la, esta ação <strong>não</strong> poderá ser
                        desfeita, e os pedidos serão dados como concluído.{' '}
                    </span>
                }
            </Confirm>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;

    section > div {
        max-height: 75vh !important;
    }
`

const KanbanBoard = styled(Board)`
    background-color: #fbfbfb;
    height: 90vh;
`
