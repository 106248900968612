import React from 'react'
import styled from 'styled-components'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import SearchInputWithCounter from '../../../commons/components/SearchInputWithCounter'
import { Label } from '../../../commons/commonStyles'
import { Card } from './Card'

export function AssociatedClients({
    handleSelectClient,
    associatedClients,
    associatedClientsFilter,
    handleFilter,
}) {
    const itemsStatusMap = {}
    const isItemLoaded = index => !!itemsStatusMap[index]

    const loadMoreItems = (startIndex, stopIndex, items) => {
        for (let i = startIndex; i <= stopIndex; i++) {
            itemsStatusMap[i] = true
        }

        return Promise.resolve(
            Object.values(items).slice(startIndex, stopIndex)
        )
    }

    const filterClients = (clients, filter) => {
        const filteredClients = Object.entries(clients).filter(
            ([id, client]) =>
                client.tradeName.toUpperCase().includes(filter.toUpperCase()) ||
                client.companyName?.toUpperCase().includes(filter.toUpperCase())
        )
        return Object.fromEntries(filteredClients)
    }

    return (
        <>
            <ClientsHeader>
                <Label>Clientes associados</Label>
            </ClientsHeader>
            <SearchInputWithCounter
                placeholder={'Buscar cliente'}
                value={associatedClientsFilter}
                onChange={e => handleFilter(e)}
                total={
                    Object.keys(
                        filterClients(
                            associatedClients,
                            associatedClientsFilter
                        )
                    ).length
                }
                selected={
                    Object.values(associatedClients).filter(
                        ({ isSelected }) => isSelected
                    ).length
                }
            />
            <ClientsListContainer>
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={Object.keys(associatedClients).length}
                    loadMoreItems={(startIndex, stopIndex) =>
                        loadMoreItems(startIndex, stopIndex, associatedClients)
                    }
                >
                    {({ onItemsRendered, ref }) => (
                        <List
                            className="List"
                            height={296}
                            itemCount={
                                Object.keys(
                                    filterClients(
                                        associatedClients,
                                        associatedClientsFilter
                                    )
                                ).length
                            }
                            itemSize={40}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                        >
                            {props =>
                                Card({
                                    ...props,
                                    items: filterClients(
                                        associatedClients,
                                        associatedClientsFilter
                                    ),
                                    association: associatedClients,
                                    name: 'associatedClientCard',
                                    onClick: handleSelectClient,
                                })
                            }
                        </List>
                    )}
                </InfiniteLoader>
            </ClientsListContainer>
        </>
    )
}

const ClientsHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`

const ClientsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.3vh;
    width: 100%;
    height: 296px;
    flex-grow: 0;
    overflow: auto;
    border: 1px solid #dadada;
    border-radius: 4px;
    padding-top: 0.5vh;
    font-family: Roboto;
`
