import React from 'react'
import styled from 'styled-components'
import { Column } from 'primereact/column'
import { MdNotInterested, MdRemoveRedEye } from 'react-icons/md'

import Table from '../../commons/components/Table'
import OrderModal from '../../dashboard/OrderModal'
import * as DateUtils from '../../commons/DateUtils'
import CancelOrderModal from '.././CancelOrderModal'
import BasicPage from '../../commons/components/BasicPage'
import BasicListScreen from '../../commons/components/BasicListScreen'
import AutoCompleteAsync from '../../commons/components/AutoCompleteAsync'
import EditedOrderIndicator from '../../commons/components/EditedOrderIndicator'
import BasicDatePickerCalendar from '../../commons/components/DatePickerCalendar'
import DebtorClientIndicator from '../../commons/components/DebtorClientIndicator'

import Colors from '../../commons/Colors'
import { EditIcon } from '../../commons/commonStyles'
import FragmentedOrderIndicator from '../../commons/components/FragmentedOrderIndicator'

export default function OrderListView({
    isLoadingOrders,
    isCancelOrderModalVisible,
    setIsCancelOrderModalVisible,
    orderToCancel,
    selectedOrderId,
    setSelectedOrderId,
    filter,
    selectedStatus,
    setSelectedStatus,
    orders,
    totalRecords,
    first,
    setFirst,
    invalidDate,
    rows,
    fetchOrders,
    handlePagination,
    handleChangeFilter,
    cancelOrder,
    onEditClick,
    isEditionAllowed,
    isOrderCanceled,
    isUseFragmentedOrder,
    fetchSellers,
    selectedSellers,
    setSelectedSellers,
    fetchStatus,
    mountFilterEvent,
}) {
    const renderRequestDateColumn = order => {
        return DateUtils.dateFormat(order.requestDate)
    }

    const renderDeliveryDateColumn = order => {
        return DateUtils.dateFormat(order.deliveryDate)
    }

    const renderClientNameColumn = order => {
        return <ClientNameColumn>{order.farm.owner.name}</ClientNameColumn>
    }

    const bodyActions = order => {
        return (
            <ActionsContainer>
                {!isOrderCanceled(order) ? (
                    <CancelIcon
                        data-testid={`cancelIcon-${order._id}`}
                        onClick={() => {
                            if (!isOrderCanceled(order)) cancelOrder(order)
                        }}
                    />
                ) : (
                    <EmptyIcon />
                )}

                {isEditionAllowed(order) ? (
                    <EditIcon
                        data-testid={`editIcon-${order._id}`}
                        onClick={() => onEditClick(order._id)}
                    />
                ) : (
                    <EmptyIcon />
                )}
                <ViewOrderIcon onClick={() => setSelectedOrderId(order._id)} />
            </ActionsContainer>
        )
    }

    const renderDebtorClientIcon = order => {
        return (
            <DebtorClientIndicator debtor={order.farm.debtor} tooltip={true} />
        )
    }

    const renderEditionOrFragmentIcon = order => {
        if (isUseFragmentedOrder(order)) return <FragmentedOrderIndicator />

        return (
            !isOrderCanceled(order) && (
                <EditedOrderIndicator edited={order.isEdited} tooltip={true} />
            )
        )
    }

    return (
        <BasicPage title={'Listagem de Pedidos'}>
            <BasicListScreen
                searchValue={filter.search}
                searchPlaceholder={
                    'Pesquise por Nº do Pedido, Cliente, Rota ou Fazenda...'
                }
                searchOnChange={handleChangeFilter}
                linkTo={'/pedidos/novo'}
                buttonWithAddIcon
                buttonText={'Novo Pedido'}
            >
                <FilterContainer>
                    <FilterGrid>
                        <FilterLabel>Visualizar por: </FilterLabel>
                        <DatePickerCalendar
                            placeholder="Data de Criação"
                            placeholderColor={Colors.orange}
                            dateIconColor={Colors.orange}
                            isInvalidDate={invalidDate}
                            maxDate={new Date()}
                            name="requestDate"
                            value={filter.requestDate}
                            onChange={handleChangeFilter}
                            showButtonBar
                            todayButtonClassName="today-button"
                        />
                        <DatePickerCalendar
                            placeholder="Data de Entrega"
                            placeholderColor={Colors.orange}
                            dateIconColor={Colors.orange}
                            isInvalidDate={invalidDate}
                            name="deliveryDate"
                            value={filter.deliveryDate}
                            onChange={handleChangeFilter}
                            showButtonBar
                            todayButtonClassName="today-button"
                        />
                        <AutoCompleteAsync
                            isMulti
                            useOrangeMode
                            useCustomInput
                            useClearIndicator={false}
                            zIndex={5}
                            menuDataTestId="statusFilter"
                            placeholder="Situação"
                            customInputText={{
                                singular: 'situação selecionada',
                                plural: 'situações selecionadas',
                            }}
                            onChange={event => {
                                setSelectedStatus(event.map(e => e))
                                handleChangeFilter(
                                    mountFilterEvent('status', event)
                                )
                            }}
                            getSelectedOption={item =>
                                selectedStatus.some(
                                    status => status.value.id === item.id
                                )
                            }
                            value={selectedStatus}
                            fetchSuggestions={fetchStatus}
                            getCustomStyle={{
                                input: styles => ({
                                    ...styles,
                                    color: Colors.orange,
                                }),
                                placeholder: styles => ({
                                    ...styles,
                                    color: Colors.orange,
                                }),
                                control: styles => ({
                                    ...styles,
                                    borderRadius: '0px',
                                    borderColor: Colors.opacityGrey,
                                    width: '18vw',
                                }),
                                dropdownIndicator: styles => ({
                                    ...styles,
                                    color: Colors.orange,
                                }),
                            }}
                        />

                        <AutoCompleteAsync
                            isMulti
                            useOrangeMode
                            useCustomInput
                            useClearIndicator={false}
                            zIndex={5}
                            menuDataTestId="sellersFilter"
                            placeholder="Vendedor"
                            customInputText={{
                                singular: 'vendedor selecionado',
                                plural: 'vendedores selecionados',
                            }}
                            value={selectedSellers}
                            fetchSuggestions={fetchSellers}
                            onChange={event => {
                                setSelectedSellers(event)
                                handleChangeFilter(
                                    mountFilterEvent('sellers', event)
                                )
                            }}
                            getSelectedOption={item =>
                                selectedSellers.some(
                                    status => status.value._id === item._id
                                )
                            }
                            getCustomStyle={{
                                input: styles => ({
                                    ...styles,
                                    color: Colors.orange,
                                }),
                                placeholder: styles => ({
                                    ...styles,
                                    color: Colors.orange,
                                }),
                                control: styles => ({
                                    ...styles,
                                    borderRadius: '0px',
                                    borderColor: Colors.opacityGrey,
                                    width: '18vw',
                                }),
                                dropdownIndicator: styles => ({
                                    ...styles,
                                    color: Colors.orange,
                                }),
                            }}
                        />
                    </FilterGrid>

                    {invalidDate && (
                        <InvalidDateField>
                            * A data de criação não pode ser posterior a data de
                            entrega
                        </InvalidDateField>
                    )}

                    <InfoText>
                        * Acompanhe nesta seção a listagem de todos os pedidos
                        realizados na plataforma. Utilize os filtros para uma
                        listagem de pedidos eficiente.
                    </InfoText>
                </FilterContainer>

                <Table
                    value={orders}
                    loading={isLoadingOrders}
                    onPage={handlePagination}
                    rows={rows}
                    first={first}
                    totalRecords={totalRecords}
                    dataKey="_id"
                    totalRecordsText="{totalRecords} Pedidos Encontrados"
                >
                    <Column
                        header={'Nº do Pedido'}
                        field={'orderNumber'}
                        style={centerAlignColumn}
                    />
                    <Column
                        body={rowData => renderEditionOrFragmentIcon(rowData)}
                        style={littleColumn}
                    />
                    <Column
                        header={'Nome do Cliente'}
                        field={'farm.owner.name'}
                        body={renderClientNameColumn}
                        style={startAlignColumn}
                    />
                    <Column
                        body={rowData => renderDebtorClientIcon(rowData)}
                        style={littleColumn}
                    />
                    <Column
                        header={'Fazenda'}
                        field={'farm.fancyName'}
                        style={startAlignColumn}
                    />
                    <Column
                        header={'Vendedor'}
                        field={'seller.name'}
                        style={startAlignColumn}
                    />
                    <Column
                        header={'Carteira/Rota'}
                        field={'category.name'}
                        style={centerAlignColumn}
                    />
                    <Column
                        header={'Data de Criação'}
                        field={'requestDate'}
                        body={renderRequestDateColumn}
                        style={centerAlignColumn}
                    />
                    <Column
                        header={'Data de Entrega'}
                        field={'deliveryDate'}
                        body={renderDeliveryDateColumn}
                        style={centerAlignColumn}
                    />
                    <Column
                        header={'Situação'}
                        field={'statusText'}
                        style={centerAlignColumn}
                    />

                    <Column body={bodyActions} style={centerAlignColumn} />
                </Table>
            </BasicListScreen>

            {selectedOrderId && (
                <OrderModal
                    selectedOrderId={selectedOrderId}
                    clearSelectedOrderId={() => setSelectedOrderId()}
                />
            )}

            <CancelOrderModal
                visible={isCancelOrderModalVisible}
                onClose={() => setIsCancelOrderModalVisible(false)}
                onCancel={() => {
                    setIsCancelOrderModalVisible(false)
                    setFirst(0)
                    fetchOrders(0, rows, filter)
                }}
                order={orderToCancel}
            />
        </BasicPage>
    )
}

const FilterContainer = styled.div`
    margin-bottom: 16px;
`

const InvalidDateField = styled.label`
    display: flex;
    justify-content: left;
    align-items: center;
    color: ${Colors.strawberry};
    font-size: 12px;
    margin-left: 136px;
    margin-bottom: 12px;
`

const FilterGrid = styled.div`
    display: grid;
    grid-template-columns: 100px repeat(4, 18vw);
    column-gap: 16px;
    margin-bottom: 12px;
`

const FilterLabel = styled.span`
    font-family: Roboto;
    font-size: 14px;
    color: ${Colors.brownishGrey};
    align-self: center;
`

const InfoText = styled(FilterLabel)`
    opacity: 0.5;
    font-style: italic;
    color: ${Colors.black};
`

const DatePickerCalendar = styled(BasicDatePickerCalendar)`
    .p-inputtext {
        border-radius: 2px;
    }

    .p-button {
        border-radius: 2px;
    }

    .today-button {
        display: none;
    }
`

const centerAlignColumn = {
    textAlign: 'center',
}

const startAlignColumn = {
    textAlign: 'start',
}

const littleColumn = {
    textAlign: 'center',
    width: '6%',
}

const ClientNameColumn = styled.span`
    color: ${Colors.brownishGrey};
`

const ActionsContainer = styled.div`
    display: flex;
    gap: 15px;
`

const CancelIcon = styled(MdNotInterested)`
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    font-size: 22px;
    color: ${Colors.brownishGrey};
    opacity: ${props => (props.disabled ? 0.45 : 1)};
`

const ViewOrderIcon = styled(MdRemoveRedEye)`
    cursor: pointer;
    font-size: 22px;
    color: ${Colors.brownishGrey};
`

const EmptyIcon = styled.div`
    width: 24px;
    height: 24px;
`
