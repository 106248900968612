import React, { useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import OrderFormContainer from './OrderFormContainer'

const OrderFormScreen = () => {
    const history = useHistory()

    const path = history.location.pathname
    const { id } = useParams()
    const { state } = useLocation()

    const [orderId] = useState(id)
    const [orderFrom] = useState(state)

    const goToOrders = () => history.push('/pedidos')
    const goToHistory = () => history.push('/pedidos/historico')
    const goToSimpleBillings = () => history.push('/home/simples-faturamentos')

    return (
        <OrderFormContainer
            goToOrders={goToOrders}
            goToHistory={goToHistory}
            goToSimpleBillings={goToSimpleBillings}
            orderFrom={orderFrom}
            orderId={orderId}
            path={path}
        />
    )
}

export default OrderFormScreen
