import React from 'react'
import styled from 'styled-components'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'
import { EditIcon, DeleteIcon } from '../../commons/commonStyles'

import Colors from '../../commons/Colors'
import Table from '../../commons/components/Table'
import BasicPage from '../../commons/components/BasicPage'
import BasicListScreen from '../../commons/components/BasicListScreen'
import DeletionAlertModalComponent from '../../productUsage/DeletionAlertModalComponent'
import Tooltip from '../../commons/components/Tooltip'
import InfoIcon from '../../assets/customIcons/InfoIcon'

export default function ProductListView({
    filter,
    products,
    showConfirmationModal,
    setShowConfirmationModal,
    productToDelete,
    isLoadingProducts,
    isDeletingProduct,
    totalRecords,
    first,
    rows,
    handleOnClickDeleteIcon,
    handleOnFilterChange,
    handlePagination,
    deleteProduct,
}) {
    const renderNameColumn = (product, column) => {
        return (
            <span
                style={{
                    color: product.active
                        ? Colors.peacockBlue
                        : Colors.lightBlueGreyTwo,
                }}
            >
                {product?.name}
            </span>
        )
    }

    const renderColumn = (product, column) => {
        return (
            <span
                style={{
                    color: product.active ? 'inherit' : Colors.lightBlueGreyTwo,
                }}
            >
                {product[column.field]}
            </span>
        )
    }

    const renderClassificationColumn = (product, column) => {
        return (
            <span
                style={{
                    color: product.active ? 'inherit' : Colors.lightBlueGreyTwo,
                }}
            >
                {product?.classification?.name}
            </span>
        )
    }

    const renderWeightColumn = product => {
        return (
            <span
                style={{
                    color: product.active ? 'inherit' : Colors.lightBlueGreyTwo,
                }}
            >
                {`${product?.weight} ${product?.measurementUnit?.unit}`}
            </span>
        )
    }

    const renderActionsColumn = product => {
        return (
            <ActionsContent>
                <Link
                    to={{
                        pathname: `/produtos/gestao/editar/${product.id}`,
                        state: { productId: product.id },
                    }}
                >
                    <EditIcon />
                </Link>
                <DeleteIcon
                    data-testid="delete-icon"
                    onClick={() => handleOnClickDeleteIcon(product)}
                />
            </ActionsContent>
        )
    }
    const renderDeletionText = () => {
        return (
            <Subtitle>
                Tem certeza de que deseja excluir o produto{' '}
                <strong>{productToDelete.name}</strong> da lista de produtos?
            </Subtitle>
        )
    }
    const handleInfoIcon = product => {
        return (
            <>
                {product.active ? (
                    <></>
                ) : (
                    <Tooltip
                        label={
                            <TooltipLabel>
                                Produto indisponível: este produto não se
                                encontra disponível para venda. (Não será
                                exibido na tela de emissão de pedidos ou
                                aplicativo)
                            </TooltipLabel>
                        }
                    >
                        <InfoIcon />
                    </Tooltip>
                )}
            </>
        )
    }

    return (
        <BasicPage title={'Produtos'}>
            <BasicListScreen
                searchValue={filter}
                searchOnChange={handleOnFilterChange}
                searchPlaceholder={
                    'Pesquise por Produto e demais informações vinculadas'
                }
                linkTo={'/produtos/gestao/novo'}
                buttonWithAddIcon
                buttonText={'Novo Produto'}
            >
                <TableContainer>
                    <Table
                        value={products}
                        loading={isLoadingProducts}
                        onPage={handlePagination}
                        rows={rows}
                        first={first}
                        totalRecords={totalRecords}
                        totalRecordsText="{totalRecords} Produtos Encontrados"
                    >
                        <Column
                            header={'Código'}
                            field={'legacyCode'}
                            bodyStyle={{ textAlign: 'center' }}
                            body={renderColumn}
                        />
                        <Column body={handleInfoIcon} style={iconColumn} />
                        <Column
                            header={'Nome do Produto'}
                            field={'name'}
                            body={renderNameColumn}
                            style={nameColumn}
                        />
                        <Column
                            header={'Espécie'}
                            field={'category'}
                            bodyStyle={{ textAlign: 'center' }}
                            body={renderColumn}
                        />
                        <Column
                            header={'Classificação'}
                            field={'classification.name'}
                            bodyStyle={{ textAlign: 'center' }}
                            body={renderClassificationColumn}
                        />
                        <Column
                            header={'Peso'}
                            field={'weight'}
                            body={renderWeightColumn}
                            bodyStyle={{ textAlign: 'center' }}
                        />
                        <Column
                            body={renderActionsColumn}
                            style={{ textAlign: 'center', width: '10%' }}
                        />
                    </Table>
                </TableContainer>
            </BasicListScreen>

            <DeletionAlertModalComponent
                onClose={() => {
                    setShowConfirmationModal(false)
                }}
                isVisible={showConfirmationModal}
                title="Deseja excluir este produto?"
                renderDeletionText={renderDeletionText}
                onConfirm={() => {
                    deleteProduct(productToDelete)
                }}
                isLoading={isDeletingProduct}
            />
        </BasicPage>
    )
}

const ActionsContent = styled.div`
    display: grid;
    grid-template-columns: repeat(3, min-content);
    column-gap: 8px;
`

const TableContainer = styled.div`
    display: flex;
`

const TooltipLabel = styled.p`
    max-width: 310px;
    padding: 0 10px;
    font-size: 12px;
`

const nameColumn = {
    color: Colors.orange,
    textAlign: 'start',
    width: '25%',
}

const iconColumn = {
    textAlign: 'center',
    width: '5%',
}
const Subtitle = styled.span`
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #6c6e6f;
`
