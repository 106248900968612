export const numberFormat = (num, precision = 2, prefix = '', suffix = '') => {
    if (isNaN(num) || num === null) return ''

    if (typeof num === 'string') num = Number(num)
    return (
        prefix +
        num
            .toFixed(precision)
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
        suffix
    )
}

export const round = (num, precision = 2) => {
    if (isNaN(num) || num === null) return null

    if (typeof num === 'string') num = Number(num)

    const exp = Math.pow(10, precision)
    return Math.round(num * exp) / exp
}

export const parseNumber = (value, defaultValue = null) => {
    if (value === null || value === undefined) return defaultValue

    if (typeof value === 'string') {
        value = value.replace(',', '.')
        if (value === '' || isNaN(value)) return defaultValue
        return Number(value)
    }

    if (isNaN(value)) return defaultValue

    if (typeof value !== 'number') return defaultValue

    return value
}

export const isValid = (value, precision = 2) => {
    if (value === null || value === undefined) return false

    if (typeof value === 'string') {
        const regex = new RegExp('^$|^\\d+((,|.)\\d{0,' + precision + '})?$')
        return value.match(regex) !== null
    }

    if (isNaN(value)) return false

    if (typeof value !== 'number') return false

    return true
}

export const isIntegerPositive = value => {
    try {
        const intValue = parseInt(value)

        if (isNaN(intValue)) return false

        return intValue >= 0
    } catch (err) {
        return false
    }
}

export const formatsDecimalNumber = (value, precision = 3) => {
    return (String(value).replace(/\D/g, '') / Math.pow(10, precision)).toFixed(
        precision
    )
}

export const formatDecimalPlace = (number, precision = 3) => {
    return parseFloat(number).toLocaleString('pt-br', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    })
}
