import React from 'react'

const LoadingStaticIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="145"
            height="128"
            viewBox="0 0 145 128"
            fill="none"
        >
            <path
                d="M68.042 29.789a3.958 3.958 0 0 0 7.916 0V17.958a3.958 3.958 0 1 0-7.916 0v11.83z"
                fill="#F44336"
                stroke="#F44336"
                stroke-width="2"
            />
            <path
                d="M72 113a2.958 2.958 0 0 1-2.958-2.958v-11.83a2.958 2.958 0 0 1 5.916 0v11.83A2.958 2.958 0 0 1 72 113z"
                fill="#F44336"
                fill-opacity=".3"
            />
            <path
                d="M68.042 110.042a3.958 3.958 0 1 0 7.916 0v-11.83a3.958 3.958 0 1 0-7.916 0v11.83z"
                stroke="#F44336"
                stroke-opacity=".3"
                stroke-width="2"
            />
            <path
                d="m45.551 26.105 5.916 10.246a3.958 3.958 0 0 0 6.855-3.958l-5.916-10.246a3.958 3.958 0 0 0-6.855 3.958z"
                fill="#F44336"
                stroke="#F44336"
                stroke-width="2"
            />
            <path
                d="M96.5 106.435a2.958 2.958 0 0 1-4.04-1.082l-5.916-10.246a2.958 2.958 0 0 1 5.123-2.958l5.916 10.246a2.957 2.957 0 0 1-1.083 4.04z"
                fill="#F44336"
                fill-opacity=".3"
            />
            <path
                d="m85.678 95.607 5.916 10.246a3.957 3.957 0 0 0 6.855-3.958l-5.916-10.246a3.958 3.958 0 1 0-6.855 3.958z"
                stroke="#F44336"
                stroke-opacity=".3"
                stroke-width="2"
            />
            <path
                d="M44.934 48.373a2.958 2.958 0 0 1-4.04 1.083L30.646 43.54a2.958 2.958 0 0 1 2.958-5.123l10.246 5.916a2.958 2.958 0 0 1 1.083 4.04z"
                fill="#F44336"
                fill-opacity=".7"
            />
            <path
                d="M40.393 50.322a3.958 3.958 0 1 0 3.958-6.855L34.105 37.55a3.958 3.958 0 1 0-3.958 6.855l10.246 5.916z"
                stroke="#F44336"
                stroke-opacity=".7"
                stroke-width="2"
            />
            <path
                d="M114.435 88.5a2.957 2.957 0 0 1-4.04 1.083l-10.246-5.916a2.958 2.958 0 1 1 2.958-5.123l10.246 5.916a2.958 2.958 0 0 1 1.082 4.04z"
                fill="#F44336"
                fill-opacity=".1"
            />
            <path
                d="M109.895 90.449a3.957 3.957 0 1 0 3.958-6.855l-10.246-5.916a3.958 3.958 0 0 0-3.958 6.855l10.246 5.916z"
                stroke="#F44336"
                stroke-opacity=".1"
                stroke-width="2"
            />
            <path
                d="M40.746 64a2.958 2.958 0 0 1-2.957 2.958H25.958a2.958 2.958 0 1 1 0-5.916h11.83A2.958 2.958 0 0 1 40.747 64z"
                fill="#F44336"
                fill-opacity=".7"
            />
            <path
                d="M37.789 67.958a3.958 3.958 0 0 0 0-7.916H25.958a3.958 3.958 0 1 0 0 7.916h11.83z"
                stroke="#F44336"
                stroke-opacity=".7"
                stroke-width="2"
            />
            <path
                d="M121 64a2.958 2.958 0 0 1-2.958 2.958h-11.831a2.958 2.958 0 0 1 0-5.916h11.831A2.958 2.958 0 0 1 121 64z"
                fill="#F44336"
                fill-opacity=".1"
            />
            <path
                d="M118.042 67.958a3.958 3.958 0 1 0 0-7.916h-11.831a3.958 3.958 0 1 0 0 7.916h11.831z"
                stroke="#F44336"
                stroke-opacity=".1"
                stroke-width="2"
            />
            <path
                d="M44.934 79.627a2.958 2.958 0 0 1-1.083 4.04l-10.246 5.916a2.958 2.958 0 1 1-2.958-5.123l10.246-5.916a2.958 2.958 0 0 1 4.04 1.083z"
                fill="#F44336"
                fill-opacity=".7"
            />
            <path
                d="m34.105 90.449 10.246-5.916a3.958 3.958 0 0 0-3.958-6.855l-10.246 5.916a3.958 3.958 0 0 0 3.958 6.855z"
                stroke="#F44336"
                stroke-opacity=".7"
                stroke-width="2"
            />
            <path
                d="M56.373 91.066a2.958 2.958 0 0 1 1.083 4.04l-5.916 10.247a2.958 2.958 0 0 1-5.123-2.958l5.916-10.246a2.958 2.958 0 0 1 4.04-1.083z"
                fill="#F44336"
                fill-opacity=".3"
            />
            <path
                d="M58.322 95.607a3.958 3.958 0 1 0-6.855-3.958l-5.916 10.246a3.957 3.957 0 1 0 6.855 3.958l5.916-10.246z"
                stroke="#F44336"
                stroke-opacity=".3"
                stroke-width="2"
            />
            <path
                d="M98.449 26.105a3.958 3.958 0 0 0-6.855-3.958l-5.916 10.246a3.958 3.958 0 1 0 6.855 3.958l5.916-10.246z"
                fill="#F44336"
                stroke="#F44336"
                stroke-width="2"
            />
        </svg>
    )
}

export default LoadingStaticIcon
