import React, { useState, useEffect } from 'react'

import { MessageType, addMessage } from '../../commons/MessageUtils'
import { debounce } from '../../commons/components/Debounce'
import PortfolioListView from './PortfolioListView'
import * as ClientPorfolioAPI from '../ClientPortfolioAPI'

export default function ClientListContainer() {
    const [filter, setFilter] = useState('')
    const [portfolios, setPortfolios] = useState([])
    const [showConfirm, setShowConfirm] = useState(false)
    const [portfolioToDelete, setPortfolioToDelete] = useState(null)
    const [isLoadingPortfolios, setIsLoadingPortfolios] = useState(true)
    const [isDeletingPortfolio, setIsDeletingPortfolio] = useState(false)

    const [totalRecords, setTotalRecords] = useState(0)
    const [first, setFirst] = useState(0)

    const rows = 10
    const firstPage = 1

    useEffect(() => {
        fetchPortfolios(rows, firstPage)
        // eslint-disable-next-line
    }, [])

    async function fetchPortfolios(size, from, term) {
        try {
            setIsLoadingPortfolios(true)
            const response = await ClientPorfolioAPI.searchPortfoliosByFilter(
                size,
                from,
                term
            )
            if (response && response.data && Array.isArray(response.data)) {
                setPortfolios(response.data)
                setTotalRecords(response.total)
            }
            setIsLoadingPortfolios(false)
        } catch (err) {
            console.error(err.message)
            setIsLoadingPortfolios(false)
        }
    }

    const openConfirmationModal = portfolio => {
        setShowConfirm(true)
        setPortfolioToDelete(portfolio)
    }

    const onConfirmDeletePortfolio = async portfolioToDelete => {
        try {
            setIsDeletingPortfolio(true)
            await ClientPorfolioAPI.deletePortfolio(portfolioToDelete.id)
            setShowConfirm(false)
            addMessage(
                MessageType.SUCCESS,
                <span>
                    Carteira <strong>{portfolioToDelete.name}</strong> deletada
                    com sucesso!
                </span>
            )
            fetchPortfolios(rows, first)
            setPortfolioToDelete(null)
        } catch (err) {
            addMessage(
                MessageType.ERROR,
                `Erro ao tentar excluir a carteira: ${portfolioToDelete.name}`
            )
            setShowConfirm(false)
            console.error(err.message)
        } finally {
            setIsDeletingPortfolio(false)
        }
    }

    const handlePagination = event => {
        setFirst(event.first)
        fetchPortfolios(event.rows, event.page + 1, filter)
    }

    const handleChangeFilter = e => {
        const filter = e.target.value
        setFilter(filter)
        debounce(() => {
            setFirst(0)
            fetchPortfolios(rows, 0, filter)
        })
    }

    return (
        <PortfolioListView
            filter={filter}
            portfolios={portfolios}
            showConfirm={showConfirm}
            setShowConfirm={setShowConfirm}
            portfolioToDelete={portfolioToDelete}
            setPortfolioToDelete={setPortfolioToDelete}
            isLoadingPortfolios={isLoadingPortfolios}
            isDeletingPortfolio={isDeletingPortfolio}
            totalRecords={totalRecords}
            first={first}
            rows={rows}
            openConfirmationModal={openConfirmationModal}
            onConfirmDeletePortfolio={onConfirmDeletePortfolio}
            handlePagination={handlePagination}
            handleChangeFilter={handleChangeFilter}
        />
    )
}
