import { getAPIKey, getBaseURL, getToken } from '../environment'

const baseURL = `${getBaseURL()}/clients/configurations`

export const getMillConfig = () => {
    return fetch(baseURL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': getAPIKey(),
        },
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}
