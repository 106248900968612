import React from 'react'

export default function DollarCoinIcon() {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.5 0a7.45 7.45 0 0 1 5.303 2.197A7.45 7.45 0 0 1 15 7.5a7.45 7.45 0 0 1-2.197 5.303A7.45 7.45 0 0 1 7.5 15a7.45 7.45 0 0 1-5.303-2.197A7.451 7.451 0 0 1 0 7.5a7.45 7.45 0 0 1 2.197-5.303A7.45 7.45 0 0 1 7.5 0zm2.11 9.14c0-1.162-.947-2.109-2.11-2.109a1.173 1.173 0 0 1-1.169-1.255A1.176 1.176 0 0 1 7.416 4.69a1.173 1.173 0 0 1 1.256 1.17c0 .263.217.476.482.468a.476.476 0 0 0 .455-.482 2.113 2.113 0 0 0-1.533-2.015.147.147 0 0 1-.107-.141V3.28a.469.469 0 0 0-.492-.468.477.477 0 0 0-.446.481v.396a.147.147 0 0 1-.108.14 2.113 2.113 0 0 0-1.532 2.03c0 1.162.946 2.109 2.109 2.109.674 0 1.217.571 1.169 1.255a1.176 1.176 0 0 1-1.085 1.086 1.173 1.173 0 0 1-1.256-1.17.469.469 0 0 0-.482-.468.476.476 0 0 0-.455.482 2.113 2.113 0 0 0 1.533 2.015.147.147 0 0 1 .107.141v.409c0 .266.222.48.492.468a.478.478 0 0 0 .446-.481v-.396c0-.066.044-.123.108-.14a2.113 2.113 0 0 0 1.532-2.03z"
                fill="#fff"
            />
        </svg>
    )
}
