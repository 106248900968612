import { getBaseURL, getToken } from '../environment'

const gcpBaseURL = `${getBaseURL()}/products`

export const searchByLoggedUser = async (size, page, query) => {
    try {
        const queryParams = new URLSearchParams({
            size,
            page,
        })
        if (query) queryParams.append('filter', query)

        const response = await fetch(`${gcpBaseURL}/products/?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
        })
        return await response.json()
    } catch (err) {
        console.error('Erro: ' + JSON.stringify(err))
        throw err
    }
}

export const findById = id => {
    return fetch(`${gcpBaseURL}/products/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(error => {
            console.error('Erro: ' + JSON.stringify(error))
            throw error
        })
}

export const saveAll = async prices => {
    try {
        return fetch(`${gcpBaseURL}/products/bulkPriceChange`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({ prices }),
        })
            .then(response => (response.ok ? { data: null } : response.json()))
            .catch(error => {
                console.error('Erro: ' + JSON.stringify(error.message))
                throw error
            })
    } catch (err) {
        console.error('Erro: ' + err.message)
        throw err
    }
}

export const saveWarrantyLevels = async request => {
    const { warrantyLevels, labelId } = request
    try {
        const response = await fetch(
            `${gcpBaseURL}/products/label/${labelId}/warrantyLevels/bulkUpdate`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify({ warrantyLevels }),
            }
        )
        if (response.status > 299 || response.status < 200)
            throw new Error(await response.text())
        return await response.json()
    } catch (err) {
        console.error('Erro: ' + err.message)
        throw err
    }
}

export const saveProductLabel = async request => {
    const { id, productLabel } = request
    try {
        const response = await fetch(`${gcpBaseURL}/products/${id}/label`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(productLabel),
        })

        if (response.status > 299 || response.status < 200)
            throw new Error(await response.text())

        return await response.json()
    } catch (err) {
        console.error('Erro: ' + err.message)
        throw err
    }
}

export const updateProductLabel = async request => {
    const { productId, labelId, productLabel } = request
    try {
        const response = await fetch(
            `${gcpBaseURL}/products/${productId}/label/${labelId}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(productLabel),
            }
        )

        if (response.status > 299 || response.status < 200)
            throw new Error(await response.text())

        return await response.json()
    } catch (err) {
        console.error('Erro: ' + err.message)
        throw err
    }
}

export const createProduct = async product => {
    try {
        const response = await fetch(`${gcpBaseURL}/products`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(product),
        })

        if (response.status > 299 || response.status < 200)
            throw new Error(await response.text())

        return await response.json()
    } catch (err) {
        console.error('Erro: ' + err.message)
        throw err
    }
}

export const editProduct = async product => {
    const { id } = product
    try {
        const response = await fetch(`${gcpBaseURL}/products/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(product),
        })

        if (response.status > 299 || response.status < 200)
            throw new Error(await response.text())

        return await response.json()
    } catch (err) {
        console.error('Erro: ' + err.message)
        throw err
    }
}

export const deleteAndDeactivate = async id => {
    try {
        const response = await fetch(`${gcpBaseURL}/products/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
        })

        if (response.status > 299 || response.status < 200)
            throw new Error(await response.text())

        return await response.json()
    } catch (err) {
        console.error('Erro: ' + err.message)
        throw err
    }
}
