import React, { useState, useEffect } from 'react'

import {
    validatesFields,
    isUnitPriceValid,
    getUnitPriceInvalidMessage,
    isFreight,
    isCharges,
    getFreightPricePerQuantity,
    getChargesPricePerQuantity,
    getOrderItemMeasureUnit,
} from '../OrderFormScreenUtils'
import { calculatesMinAndMaxPrice } from '../../price/PriceUtils'
import { useMillContext } from '../../context/MillContext'

import ProductCardView from './ProductCardView'

const ProductCardContainer = ({
    order,
    orderItem,
    onRemove,
    onChange,
    freightTable,
    sellerRates,
    tributes,
    isDisabled,
}) => {
    const [isPriceInvalid, setIsPriceInvalid] = useState(false)
    const [invalidMessage, setInvalidMessage] = useState(null)

    const { millConfig } = useMillContext()

    const { minPrice, maxPrice } = calculatesMinAndMaxPrice(
        orderItem.product,
        sellerRates,
        tributes
    )

    useEffect(() => {
        const isPriceInvalid = !isUnitPriceValid(
            orderItem.unitPrice,
            minPrice,
            maxPrice
        )

        const message = getUnitPriceInvalidMessage(
            orderItem.unitPrice,
            minPrice,
            maxPrice
        )

        setIsPriceInvalid(isPriceInvalid)
        setInvalidMessage(message)
    }, [orderItem.unitPrice, minPrice, maxPrice])

    useEffect(() => {
        if (isDisabled) {
            setIsPriceInvalid(false)
            setInvalidMessage(null)
            return
        }
    }, [isDisabled])

    const handleOnChange = e => {
        const target = {
            name: e.target.name,
            value: e.target.value,
        }
        const { isValid, alteredOrderItem } = validatesFields(
            target,
            orderItem,
            minPrice,
            maxPrice,
            isUnitPriceValid
        )

        if (isValid) {
            const { isUnitPriceValid } = alteredOrderItem
            onChange(alteredOrderItem)

            if (!isUnitPriceValid && isUnitPriceValid !== undefined) {
                setIsPriceInvalid(true)
                setInvalidMessage(
                    getUnitPriceInvalidMessage(
                        alteredOrderItem.unitPrice,
                        minPrice,
                        maxPrice
                    )
                )
                return
            }

            setIsPriceInvalid(false)
        }
    }

    return (
        <ProductCardView
            orderItem={orderItem}
            onRemove={onRemove}
            handleOnChange={handleOnChange}
            freightPrice={getFreightPricePerQuantity(
                orderItem,
                order,
                freightTable,
                millConfig
            )}
            isFreight={isFreight(order)}
            isCharges={isCharges(order)}
            chargesPrice={getChargesPricePerQuantity(
                orderItem,
                order,
                freightTable,
                millConfig
            )}
            measureUnit={getOrderItemMeasureUnit(orderItem)}
            isPriceInvalid={isPriceInvalid}
            invalidMessage={invalidMessage}
            minPrice={minPrice.toFixed(2)}
            maxPrice={maxPrice.toFixed(2)}
            isDisabled={isDisabled}
        />
    )
}

export default ProductCardContainer
