import { getBaseURL, getAPIKey, getToken } from '../environment'
import * as ProductUsageUtils from './ProductUsageUtils'

const endpoint = 'products/productsUsage'
const apiKey = getAPIKey()

const url = `${getBaseURL()}/${endpoint}`

export const getProductsUsage = async (clientId, firstDate, secondDate) => {
    const query = ProductUsageUtils.mountProductsUsageQuery(
        clientId,
        firstDate,
        secondDate
    )

    return fetch(`${url}/${query}`, {
        method: 'get',
        headers: {
            'x-api-key': apiKey,
            Authorization: `Bearer ${getToken()}`,
        },
    })
        .then(response => response.json())
        .catch(error => {
            console.error('Erro: ' + JSON.stringify(error))
            throw error
        })
}

export const createProductUsage = async productUsage => {
    return fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
        },
        body: JSON.stringify(productUsage),
    })
        .then(response => response.json())
        .catch(error => {
            console.error('Erro: ' + JSON.stringify(error))
            throw error
        })
}

// TODO - Redefinir como a rota funciona
export const updateProductUsage = async productUsage => {
    return fetch(`${url}/id`, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
        },
        body: JSON.stringify(productUsage),
    })
        .then(response => response.json())
        .catch(err => {
            console.error('Erro: ', JSON.stringify(err))
            throw err
        })
}

export const deleteProductsUsage = async productsUsageIds => {
    const queryParams = new URLSearchParams({})

    productsUsageIds.forEach(productUsageId =>
        queryParams.append('id', productUsageId)
    )

    return fetch(`${url}?${queryParams.toString()}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': apiKey,
        },
    })
        .then(response => (response.ok ? { data: null } : response.json()))
        .catch(error => {
            console.error('Erro: ' + JSON.stringify(error.message))
            throw error
        })
}
