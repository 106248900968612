import React from 'react'
import styled from 'styled-components'
import Colors from '../Colors'

/**
 *
 * @param {string} id - identificação unica do elemento
 * @param {string} name - nome do elemento
 * @param {string} className - className do elemento
 * @param {string} type - tipo do elemento
 * @param {string} placeholder - texto a ser motrado quando o elemento não tem valor
 * @param {array} value - valor do elemento
 * @param {any} onChange - chamado quando o valor é alterado
 * @param {any} onBlur - chamado quando o elemento perde o foco
 * @param {boolean} disabled - quando verdadeiro especifica que o componente esta desabilitado
 * @param {boolean} readOnly - quando verdadeiro especifica que o componente não permite input, e sim apenas visualização
 * @param {any} onFocus - chamado quando o elemento recebe foco
 *
 * */

export default function Input({
    id,
    name,
    className,
    type = 'text',
    placeholder = '',
    value,
    onChange,
    onBlur,
    disabled,
    readOnly = false,
    onFocus,
}) {
    return (
        <InputStyled
            id={id}
            name={name}
            type={type}
            className={className}
            placeholder={placeholder}
            value={value === null || value === undefined ? '' : value}
            onChange={onChange}
            onBlur={onBlur}
            readOnly={readOnly}
            disabled={disabled}
            onFocus={onFocus}
        />
    )
}

const InputStyled = styled.input`
    outline: 0;
    padding: 0.429em;

    font-family: 'Open Sans', 'Helvetica Neue';
    font-size: 14px;
    color: #666666;
    background-color: white;
    height: 40px;

    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: #dadada;
    opacity: ${props => (props.readOnly || props.disabled ? 0.5 : 1)};

    transition: box-shadow 0.2s;

    :focus {
        box-shadow: ${props =>
            props.readOnly || props.disabled ? 'none' : '0 0 0 0.2em #e4e9ec'};
    }
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::placeholder {
        font-family: 'Roboto';
        color: ${Colors.lightBlueGreyTwo};
        font-size: 14px !important;
    }
`
