import * as DateUtils from '../commons/DateUtils'

export const mountProductsUsageQuery = (clientId, firstDate, secondDate) => {
    return `?clientId=${clientId}&month[gte]=${firstDate}&month[lte]=${secondDate}`
}

export const getDateRange = (range = 18) => {
    const currentYear = new Date().getFullYear()
    const firstRawDate = new Date(currentYear, 0, 1)

    const secondRawDate = new Date(firstRawDate)
    secondRawDate.setMonth(secondRawDate.getMonth() + range)

    const firstDate = DateUtils.dateFormatToReport(firstRawDate)
    const secondDate = DateUtils.dateFormatToReport(secondRawDate)

    return {
        firstDate,
        secondDate,
    }
}

export const getMinimumRequiredDates = () => {
    const date = new Date()
    date.setDate(1)

    const requiredDates = []

    const MINIMUM_MONTHS = 6
    for (let i = 0; i < MINIMUM_MONTHS; i++) {
        requiredDates.push(DateUtils.dateFormatToReport(date))
        date.setMonth(date.getMonth() + 1)
    }

    return requiredDates
}

export const getDefaultYearRange = (range = 6) => {
    const currentDate = new Date()
    const dateAdjustment = 1

    const firstYear = currentDate.getFullYear() - dateAdjustment
    const lastYear = new Date(
        currentDate.setMonth(currentDate.getMonth() + range)
    ).getFullYear()

    return `${firstYear}:${lastYear}`
}

export const isValidDateRange = date => {
    const selectedDate = new Date(date)
    const currentDate = new Date()
    const limit = 13

    if (selectedDate < currentDate) {
        const monthsDifference = getMonthsDifference(selectedDate, currentDate)

        return monthsDifference > limit ? false : true
    }

    if (selectedDate > currentDate) {
        return false
    }
}

const getMonthsDifference = (dateFrom, dateTo) => {
    const monthsInYear = 12
    const dateAdjustment = 1

    return (
        dateTo.getMonth() -
        dateFrom.getMonth() +
        monthsInYear * (dateTo.getFullYear() - dateFrom.getFullYear()) -
        dateAdjustment
    )
}

export const filterItemsToPaginate = (from, to, values) => {
    return values.filter((value, index) => index >= from && index < to && value)
}

export const standardizeProducts = (products, clientData, millData) => {
    return products.map(product => ({
        name: `${product.value.legacyCode} - ${product.value.name}`,
        productId: product.value.id,
        clientId: clientData.clientId,
        millId: millData.millId,
    }))
}

export const handleProductUsageData = productsUsage => {
    try {
        const productUsage = productsUsage.reduce((newObject, productUsage) => {
            if (!newObject[productUsage.productId]) {
                newObject[productUsage.productId] = {
                    productId: productUsage.productId,
                    name: `${productUsage.product.legacyCode} - ${productUsage.product.name}`,
                    clientId: productUsage.clientId,
                    millId: productUsage.millId,
                }
            }

            newObject[productUsage.productId][productUsage.month] = {
                active: productUsage.active,
                month: productUsage.month,
                id: productUsage.id,
                clientId: productUsage.clientId,
                millId: productUsage.millId,
                productId: productUsage.productId,
            }

            return newObject
        }, {})

        return Object.values(productUsage)
    } catch (error) {
        console.error(error)
    }
}

export const isNotSavedProductUsage = productsUsage => {
    const currentDate = new Date()

    const { fullYear } = DateUtils.getYear(currentDate)
    const { monthNumber } = DateUtils.getMonth(currentDate)

    const currentDateKey = `${fullYear}-${monthNumber}-01`

    return !productsUsage.every(product => product[currentDateKey])
}

export const generateMonthRangeStructure = () => {
    const currentYear = new Date().getFullYear()
    const firstDate = new Date(currentYear, 0, 1)

    const generatedMonths = []

    for (let index = 0; index <= 17; index += 1) {
        const firstDateMonth = firstDate.getMonth()

        const intialDate = new Date(firstDate)

        const dateToHandle = intialDate.setMonth(firstDateMonth + index)

        const day = '01'
        const { fullYear, shortYear } = DateUtils.getYear(dateToHandle)
        const { monthNumber, shortMonth } = DateUtils.getMonth(dateToHandle)

        generatedMonths.push({
            title: `${shortMonth}/${shortYear}`,
            key: `${fullYear}-${monthNumber}-${day}`,
        })
    }

    return generatedMonths
}

export const handleProductsUsageToSave = productsUsage => {
    const productsUsageToUpdate = productsUsage.filter(
        productUsage => productUsage.id
    )

    const productsUsageToCreate = productsUsage.filter(
        productUsage => !productUsage.id && productUsage.active !== undefined
    )

    return {
        UPDATE: productsUsageToUpdate,
        CREATE: productsUsageToCreate,
    }
}

export const isCurrentDate = date => {
    if (!date) return false

    const requestDate = new Date(date.replace('-', '/'))
    const requestMonth = requestDate.getMonth()
    const requestYear = requestDate.getFullYear()

    const currentDate = new Date()
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()

    return requestMonth === currentMonth && requestYear === currentYear
}

export const getCurrentDate = () => new Date().setDate(1)
